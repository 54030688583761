import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useAppState } from "../state";

interface NavigationButtonsProps {
  showEdellinen?: boolean;
  edellinenSivu?: string;
  seuraavaSivu?: string;
  buttonSeuraava?: string;
  lahetaData?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  showEdellinen = true,
  edellinenSivu,
  seuraavaSivu,
  buttonSeuraava = "buttonSeuraava",
  lahetaData,
  type = "button",
}) => {
  const navigate = useNavigate();
  const { state } = useAppState();

  const urlPolku = `/${state.formId}/${state.identifier}/`; // esim: "/8plusnps1/QPQ6eJ/";
  const edellinenSivuUrl = edellinenSivu !== undefined ? urlPolku + edellinenSivu : "";
  const seuraavaSivuUrl = seuraavaSivu !== undefined ? urlPolku + seuraavaSivu : "";

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: showEdellinen ? "space-between" : "center",
        mt: 5,
      }}
    >
      {showEdellinen && (
        <Button
          id="edellinen"
          type="button"
          onClick={() => navigate(edellinenSivuUrl)}
          variant="contained"
          sx={{
            width: "48%",
            color: "#fff",
            py: 0.85,
            letterSpacing: "0.2rem",
            fontSize: "0.95rem",
            backgroundColor: "#a0a7ad",
            "&:hover": {
              backgroundColor: "#90969b",
            },
          }}
        >
          {t("buttonEdellinen")}
        </Button>
      )}
      {!lahetaData && (
        // jos ei olla vikalla sivulla, käytetään onClickia
        <Button
          id="seuraava"
          type={type}
          // type="submit"
          // onClick={() => navigate(seuraavaSivuUrl)} // TODO luo jokaiselle kyselysivulle onSubmittiin oma funktio kuten RooliSivu.tsx:ssa
          variant="contained"
          sx={{
            width: showEdellinen ? "48%" : "100%",
            color: "#fff",
            py: 0.85,
            letterSpacing: "0.2rem",
            fontSize: "0.95rem",
            backgroundColor: "#d1983a",
            "&:hover": {
              backgroundColor: "#b87c2c",
            },
          }}
        >
          {t(buttonSeuraava)}
        </Button>
      )}
      {lahetaData && (
        <Button
          id="laheta"
          type={type}
          variant="contained"
          sx={{
            width: showEdellinen ? "48%" : "100%",
            color: "#fff",
            py: 0.85,
            letterSpacing: "0.2rem",
            fontSize: "0.95rem",
            backgroundColor: "#d1983a",
            "&:hover": {
              backgroundColor: "#b87c2c",
            },
          }}
        >
          {t(buttonSeuraava)}
        </Button>
      )}
    </Box>
  );
};

export default NavigationButtons;
