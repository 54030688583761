import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from '@mui/material/Card';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardContent from '@mui/material/CardContent';

import KolmeVaihetta from "../Sisalto/KolmeVaihetta";
import OsioOtsikko from "../../../../components/OsioOtsikko";
import Spacer from "../../../../components/Spacer";
import { useTheme } from '@mui/material/styles';
interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Miksi: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  const theme = useTheme();
  return (
    <div id="miksi">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <OsioOtsikko otsikko="Miksi? - Kehittävä ja positiivinen" />
        <Container>
          <Box>
            <Grid
              container
              spacing={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                md={8}
                xs={12}
              >
                <Box
                  sx={{
                    padding: 2,
                    backgroundColor: theme.palette.background.paper,
                    margin: 0,
                  }}
                  component={Card}
                  height={1}
                  display="flex"
                  flexDirection="column"
                  boxShadow={0}
                >
                  <CardContent>

                    <Box
                      marginBottom={0}
                      display="flex"
                      flexDirection="column"
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          textTransform: "uppercase",
                          marginTop: 1,
                          color: theme.palette.primary.main,
                        }}
                      >
                        1. MITTAA ASIAKASKOKEMUKSEN KEHITTYMISEN EDELLYTYKSET
                      </Typography>
                      <Typography
                        sx={{
                          marginBottom: 1,
                          lineHeight: 1.5,
                          marginTop: 1,
                        }}
                        variant="subtitle1"
                        marginTop={theme.spacing(1)}
                        color={theme.palette.text.secondary}
                      >
                        Asiakkailla ja henkilöstöllä on paras käsitys, millaiset edellytykset tai esteet vallitsevat asiakaslähtöisyyden toteutumiselle.
                      </Typography>
                    </Box>

                    <Box
                      marginBottom={0}
                      display="flex"
                      flexDirection="column"
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          textTransform: "uppercase",
                          marginTop: 1,
                          color: theme.palette.primary.main
                        }}
                      >
                        2. JOHDA TOIMINTATAPOJA ASIAKASLÄHTÖISEKSI
                      </Typography>
                      <Typography
                        sx={{
                          marginBottom: 1,
                          lineHeight: 1.5,
                          marginTop: 1,
                        }}
                        variant="subtitle1"
                        marginTop={theme.spacing(1)}
                        color={theme.palette.text.secondary}
                      >
                        Samanaikainen näkymä sisäiseen ja ulkoiseen asiakaslähtöisyyteen mahdollistaa kehityksen
                      </Typography>
                    </Box>

                    <Box
                      marginBottom={0}
                      display="flex"
                      flexDirection="column"
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          textTransform: "uppercase",
                          marginTop: 1,
                          color: theme.palette.primary.main
                        }}
                      >
                        3. OHJAA POSITIIVISEN PALAUTTEEN ANTAMISEEN
                      </Typography>
                      <Typography
                        sx={{
                          marginBottom: 1,
                          lineHeight: 1.5,
                          marginTop: 1,
                        }}
                        variant="subtitle1"
                        marginTop={theme.spacing(1)}
                        color={theme.palette.text.secondary}
                      >
                        Malli on kehitetty hyödyntäen positiivisen organisaatiopsykologian ymmärrystä siitä, mikä organisaatiossa innostaa, on hyvää ja energiaa-antavaa.
                      </Typography>
                    </Box>

                    <Box
                      marginBottom={0}
                      display="flex"
                      flexDirection="column"
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          textTransform: "uppercase",
                          marginTop: 1,
                          color: theme.palette.primary.main
                        }}
                      >
                        4. AKTIVOI MYÖS YKSILÖITÄ
                      </Typography>
                      <Typography
                        sx={{
                          marginBottom: 1,
                          lineHeight: 1.5,
                          marginTop: 1,
                        }}
                        variant="subtitle1"
                        marginTop={theme.spacing(1)}
                        color={theme.palette.text.secondary}
                      >
                        Innosta henkilöstöä ottamaan kantaa liiketoiminnan ytimeen - asiakaslähtöisyyteen.
                      </Typography>
                    </Box>

                  </CardContent>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Miksi;
