import React from "react";
import { useState, ReactNode } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField, Box } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useFormContext } from "./FormContext";

interface RHFAutocompleteFieldProps<O extends { id: string; label: string }> {
  name: string;
  options: O[];
  fieldLabel: string;
  sx?: SxProps<Theme>;
  defaultHelperText?: ReactNode; // mahdollistaa normaalin tekstin syöttämisen defaultHelperText='esim näin' lisäksi pitkän tekstin syöttämisen rivitetysti defaultHelperText={`esim näin`} defaultHelperText={<div>esim näin</div>}
  renderOption?: (props: any, option: O) => JSX.Element;
}

// const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
//   event.preventDefault();
//   event.stopPropagation();
// };

export const RHFAutocompleteField = <O extends { id: string; label: string }>(props: RHFAutocompleteFieldProps<O>) => {
  const { name, options, fieldLabel, sx, defaultHelperText, renderOption } = props;

  const { control, errors } = useFormContext();
  const error = errors[name] ? true : false;
  const helperText = errors[name] ? errors[name].message : defaultHelperText;
  const [open, setOpen] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field;
        return (
          <>
            <Autocomplete
              value={value ? options.find((option) => option.id === value) ?? null : null}
              getOptionLabel={(option) => option.label}
              onChange={(event: any, newValue) => onChange(newValue ? newValue.id : null)}
              options={options}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={fieldLabel}
                  inputRef={ref}
                  variant="filled"
                  sx={{ my: 1, ...sx }}
                  onFocus={() => setOpen(true)}
                  //   onBlur={handleBlur}
                  data-testid={`autocomplete-${name}`}
                  // helperText={helperText}
                />
              )}
              renderOption={(props, option) => {
                if (renderOption) {
                  return renderOption(props, option);
                } else {
                  return (
                    <Box
                      data-testid={`autocomplete-option-${option.label}`}
                      component="li"
                      {...props}
                    >
                      {option.label}
                    </Box>
                  );
                }
              }}
            />
            <FormHelperText error>{errors[name]?.message}</FormHelperText>
          </>
        );
      }}
    />
  );
};
