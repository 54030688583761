import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import NoSsr from "@mui/material/NoSsr";
import Zoom from "@mui/material/Zoom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";
import { Tooltip, Typography } from "@mui/material";
// useTranslation-hookin avulla voidaan käyttää käännöksiä
import { useTranslation } from "react-i18next";

import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";


interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const esittely = t("esittely");

  // Piilotetaan Tilaa -huomionappi oikeasta alareunasta jos sivun yläreuna lähestyy (marginaalin verran ennen) Tilaa -osiota
  const marginaali = 400;
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const thresholdElement = document.getElementById("tilaa");
    const handleScroll = () => {
      if (thresholdElement && window.pageYOffset > thresholdElement.offsetTop - marginaali) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isLg = useMediaQuery(theme.breakpoints.up("lg"), { defaultMatches: true });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = (): void => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = (): void => {
    setOpenSidebar(false);
  };

  const open = openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const scrollTo = (id: string): void => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`) as HTMLElement;
      if (!element) {
        return;
      }
      window.scrollTo({ left: 0, top: element.offsetTop, behavior: "smooth" });
    });
  };

  const scrollToHome = () => {
    const homeElement = document.getElementById("home");
    if (homeElement) {
      const yOffset = -64; // TODO oletus: yläpalkin korkeus on 64px. Säädä tarvittaessa.
      const y = homeElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  };

  // Uuden välilehden avaaminen
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const varauskalenteri = "https://calendar.app.google/aTzFQKiJSWK1p5pD8";

  return (
    <Box
      id="page-top"
      sx={{
        backgroundColor: theme.palette.background.default,
        height: "100%",
        minWidth: 300,
      }}
    >
      <Header
        onSidebarOpen={handleSidebarOpen}
        position="fixed"
        isSidebarOpen={openSidebar}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
      />
      <Box
        width={1}
        margin="0 auto"
      >
        {children}
      </Box>
      <Footer />
      <NoSsr>
        <Zoom in={trigger}>
          <Box
            onClick={scrollToHome}
            role="presentation"
            sx={{ position: "fixed", bottom: 24, right: 32 }}
          >
            <Tooltip
              title={t("Sivun alkuun")}
              placement="top"
            >
              <Fab
                color="primary"
                size="small"
                sx={{
                  color: theme.palette.common.black,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: theme.palette.primary.main,
                    border: "2px solid" + theme.palette.primary.main,
                  },
                }}
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </Tooltip>
          </Box>
        </Zoom>

        <Tooltip
            title={t("Varauskalenteriin")}
            placement="top"
        >
          <Box
            id="varaa_esittely"
            onClick={() => openInNewTab(varauskalenteri)} 
            role="presentation"
            sx={{ 
              position: "fixed", bottom: 24, right: 90,
            }}
          >
            <Fab
              variant="extended"
              color="primary"
              size="large"
              aria-label={esittely}
              sx={{
                color: theme.palette.common.black,
                "&:hover": {
                  color: theme.palette.primary.main,
                  border: "2px solid" + theme.palette.primary.main,
                },
                maxWidth: "5%",
                width: "5%",
                display: "flex", // Muutettu 'block' -> 'flex'
                justifyContent: "center", // Asetetaan sisältö keskelle
                alignItems: "center", // Asetetaan sisältö keskelle vertikaalisesti
                textAlign: "center",
                padding: "6px 16px", // Lisätään padding ympärille
                minWidth: "fit-content", // Asetetaan minimileveys sisällön mukaan
                boxSizing: "border-box", // Varmistetaan, että padding sisältyy kokoon
                // p: 0,
              }}
            >
              <Typography 
                component="p"
                sx={{
                  color: theme.palette.common.black,
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {esittely}
              </Typography>
            </Fab>
          </Box>
        </Tooltip>
      </NoSsr>
    </Box>
  );
};

export default Layout;
