import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { TextField, Box, Container, Modal, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import axiosUnauthenticatedInstance from '../axiosUnauthenticatedInstance'
import config from "../../../../config";
import CustomButton from "../../../components/FormComponents/CustomButton";
import Kielipalkki from "../../../components/Kielipalkki";


interface IFormInput {
  y_tunnus: string;
  yrityksen_nimi: string;
  yhteys_nimi: string;
  yhteys_sposti: string;
  yhteys_puh: string;
  language: string;
}
interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgba(31, 48, 69, .75)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RegistrationForm: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const sivun_otsikko = t("Voit rekisteröityä jälleenmyyjäksi täyttämällä oheiset tiedot") as string;
  const y_tunnus_vaaditaan = t("Y-tunnus vaaditaan") as string;
  const yhteyshenkilön_nimi_vaaditaan = t("Yhteyshenkilön nimi vaaditaan") as string;
  const tarkista_sahkopostiosoite = t("Tarkista sähköpostiosoite") as string;
  const sahkoposti_vaaditaan = t("Sähköposti vaaditaan") as string;
  const puhelinnumero_vaaditaan = t("Puhelinnumero vaaditaan") as string;
  const tarkista_y_tunnus = t("Tarkista Y-tunnus") as string;
  const haetaanAutomaattisesti = t("Haetaan automaattisesti Y-tunnuksen perusteella") as string;
  const yrityksen_y_tunnus = t("Yrityksen Y-tunnus") as string;
  const yrityksen_nimi = t("Yrityksen nimi") as string;
  const yhteyshenkilön_nimi = t("Yhteyshenkilön nimi") as string;
  const yhteyshenkilön_sähköposti = t("Yhteyshenkilön sähköposti") as string;
  const yhteyshenkilön_puhelinnumero = t("Yhteyshenkilön puhelinnumero") as string;
  const rekisteröidy = t("Rekisteröidy") as string;
  const kiitos_rekisteröitymisestä = t("KIITOS REKISTERÖITYMISESTÄ!") as string;
  const lähetimme_vahvistuksen_sähköpostiosoitteeseenne = t("Lähetimme vahvistuksen sähköpostiosoitteeseenne.") as string;
  const tama_y_tunnus_on_jo_rekisteroity = t("TÄMÄ Y-TUNNUS ON JO REKISTERÖITY") as string;
  const tarkista_y_tunnus_ja_yritä_uudelleen = t("Tarkista y-tunnus ja yritä uudelleen, tai ota yhteys: info@toimialadata.fi") as string;
  const jokin_meni_pieleen = t("Jokin meni pieleen :(") as string;

  const schema = yup.object<IFormInput>().shape({
    y_tunnus: yup.string().required(y_tunnus_vaaditaan),
    yrityksen_nimi: yup.string(),
    yhteys_nimi: yup.string().required(yhteyshenkilön_nimi_vaaditaan),
    yhteys_sposti: yup.string().email(tarkista_sahkopostiosoite).required(sahkoposti_vaaditaan),
    yhteys_puh: yup.string().required(puhelinnumero_vaaditaan),
  });

  const { register, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<IFormInput>({
    resolver: yupResolver(schema)
  });
  const [openModal, setOpenModal] = useState(false);
  const [virheLomakkeella, setVirheLomakkeella] = useState(false);
  const [modaaliOtsikko, setModaaliOtsikko] = useState("");
  const [modaaliKuvaus, setModaaliKuvaus] = useState("");

  const yTunnuksenVirheet = () => {
    setError("y_tunnus", { type: "manual", message: tarkista_y_tunnus });
    setValue("yrityksen_nimi", haetaanAutomaattisesti);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (virheLomakkeella) {
      setVirheLomakkeella(false);
    } else {
      window.location.reload();
    }
  };

  const handleModal = (ok: boolean, otsikko: string, kuvaus: string) => {
    setModaaliOtsikko(otsikko);
    setModaaliKuvaus(kuvaus);
    setOpenModal(true);
  };

  async function haeNimi() {
    const y = getValues("y_tunnus");
    if (y) {
      try {
        const response = await fetch(`https://avoindata.prh.fi/bis/v1/${y}`);
        if (response.ok && response.status !== 404) {
          const jsonData = await response.json();
          const nimi = jsonData.results[0].name;
          clearErrors("y_tunnus");
          setValue("yrityksen_nimi", nimi);
        } else if (response.status === 404) {
          // If no results from PRH, attempt VIES API
          const formattedVatNumber = y.replace(/[-\s]/g, '');  // Removes dashes and spaces
          const viesResponse = await fetch(`${config.viesCheck}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              countryCode: 'FI',
              vatNumber: formattedVatNumber
            })
          });
          if (viesResponse.ok) {
            const viesData = await viesResponse.json();
            if (viesData.valid) {
              const nimi = viesData.name || 'Nimi ei saatavilla';
              setValue("yrityksen_nimi", nimi);
              clearErrors("y_tunnus");
            } else {
              yTunnuksenVirheet();
            }
          } else {
            yTunnuksenVirheet();
          }
        } else {
          yTunnuksenVirheet();
        }
      } catch (error) {
        yTunnuksenVirheet();
      }
    } else {
      yTunnuksenVirheet();
    }
  }


  const onSubmit: SubmitHandler<IFormInput> = async data => {
    await haeNimi();
    const selectedLanguage = localStorage.getItem('i18nextLng') || 'fi';
    data.language = selectedLanguage;

    if (errors.y_tunnus || data.yrityksen_nimi === haetaanAutomaattisesti) {
      setVirheLomakkeella(true);
      handleModal(true, tarkista_y_tunnus, "");
    } else {
      if (data) {
        axiosUnauthenticatedInstance.post(`${config.jalleenmyyjaApiUrl}`, data)
          .then(response => {
            // console.log("Registration successful:", response.data);
            setVirheLomakkeella(false);
            handleModal(true, kiitos_rekisteröitymisestä, lähetimme_vahvistuksen_sähköpostiosoitteeseenne);
          })
          .catch(error => {
            // console.error("Registration error:", error.response.data);
            if (error.response.data.non_field_errors[0] === "Tämä Y-tunnus on jo rekisteröity jälleenmyyjäksi.") { // ks. validate() @serializers.JalleenmyyjaSerializer
              setVirheLomakkeella(true);
              handleModal(true, tama_y_tunnus_on_jo_rekisteroity, tarkista_y_tunnus_ja_yritä_uudelleen);
            } else {
              setVirheLomakkeella(true);
              handleModal(true, jokin_meni_pieleen, "");
            }
          });
      }
    }
  };

  return (
    <React.Fragment>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {modaaliOtsikko}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {modaaliKuvaus}
          </Typography>
        </Box>
      </Modal>

      <Box sx={{
        mt: 4,
        pt: 4,
        pb: 16,
        backgroundColor: theme.palette.background.paper
      }}
      >
        <Container maxWidth="sm">
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate >
            <Kielipalkki sx={{ mb: 4 }} />
            <Typography variant="h4" sx={{ mb: 2 }}>
              {sivun_otsikko}
            </Typography>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              label={yrityksen_y_tunnus}
              {...register('y_tunnus', { required: true })}
              error={!!errors.y_tunnus}
              helperText={errors.y_tunnus?.message}
              onBlur={haeNimi}
            />
            <TextField
              disabled
              variant="filled"
              margin="normal"
              required
              fullWidth
              label={yrityksen_nimi}
              {...register('yrityksen_nimi', { required: true })}
              value={getValues("yrityksen_nimi") || haetaanAutomaattisesti}
              error={!!errors.yrityksen_nimi}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              label={yhteyshenkilön_nimi}
              {...register('yhteys_nimi', { required: true })}
              error={!!errors.yhteys_nimi}
              helperText={errors.yhteys_nimi?.message}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              label={yhteyshenkilön_sähköposti}
              {...register('yhteys_sposti', { required: true })}
              error={!!errors.yhteys_sposti}
              helperText={errors.yhteys_sposti?.message}
            />
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              label={yhteyshenkilön_puhelinnumero}
              {...register('yhteys_puh', { required: true })}
              error={!!errors.yhteys_puh}
              helperText={errors.yhteys_puh?.message}
            />
            <CustomButton
              id="lahetaTilaus"
              fullWidth={true}
              buttonText={rekisteröidy}
              type="submit"
              sx={{ mt: 2 }}
            />
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default RegistrationForm;
