// Jennin värimaailma:
const vari_1 = "#1f3045";
const vari_2 = "#d06149"; // uusi punainen
const vari_3 = "#dfa092";
const vari_4 = "#5e8a94";
const vari_5 = "#d1983a"; // uusi oranssi
const vari_6 = "#a0a7ad"; // harmaa
const vari_7 = "#b0cfd6";
const vari_8 = "#cae5cc"; // vaalea vihrea
const vari_9 = "#22D7C1"; // uusi vihrea
const vari_10 = "#545759"; // toimialadatan netti (harmaa)
const vari_11 = "#ff8a3a"; // toimialadatan netti (oranssi)

// const lomakekentan_tausta = rgba(0, 0, 0, 0.06);

const valkoinen = "#ffffff";
const GhostWhite = "#F8F8FF";
const OldLace = "#FDF5E6";

const vaaleanOranssi_1 = "#faf4eb";
const vaaleanOranssi_2 = "#f5ead7";
const vaaleanOranssi_3 = "#f1e0c3";
const musta = "#000000";

export {
  vari_1,
  vari_2,
  vari_3,
  vari_4,
  vari_5,
  vari_6,
  vari_7,
  vari_8,
  vari_9,
  vari_10,
  vari_11,
  valkoinen,
  GhostWhite,
  OldLace,
  musta,
  vaaleanOranssi_1,
  vaaleanOranssi_2,
  vaaleanOranssi_3,
};
