import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {HenkilostoTaustaFormValues} from '../CommonFormComponents/types';
import { useAppState } from '../CommonFormComponents/state';

import NagigationButtons from "../CommonFormComponents/KyselysivunOsat/NavigationButtons";
import TervetuloaTeksti from "./TervetuloaTeksti";
import Kielipalkki from "../../../components/Kielipalkki";

interface TervetuloaSivuProps {
  seuraavaSivu: string;
  buttonSeuraava?: string;
}

const TervetuloaSivu: React.FC<TervetuloaSivuProps> = ({ seuraavaSivu, buttonSeuraava }) => {
  const navigate = useNavigate();
  const formMethods = useForm<HenkilostoTaustaFormValues>({});
  const { handleSubmit, register, formState: { errors } } = formMethods;
  const { state, setState } = useAppState();

  const onSubmit: SubmitHandler<HenkilostoTaustaFormValues> = (data) => {
    const nextUrl = `/${state.formId}/${state.identifier}/${seuraavaSivu}`
    navigate(nextUrl);
  };

  return (
    <Box>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Kielipalkki />
          <TervetuloaTeksti />
          <NagigationButtons
            showEdellinen={false}
            buttonSeuraava={buttonSeuraava}
            seuraavaSivu={seuraavaSivu}
            type="submit"
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default TervetuloaSivu;
