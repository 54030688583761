import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const HeroButtons = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), { defaultMatches: true });

  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "stretched", sm: "flex-start" }}
        justifyContent="center"
        marginTop={4}
      >
        <Button
          variant="contained"
          size="large"
          fullWidth={isMd ? false : true}
          href="#miksi"
          endIcon={<ArrowForwardIcon />}
          disableElevation={true}
          sx={{
            color: theme.palette.common.black,
            padding: "15px 30px",
            marginRight: "15px",
            fontSize: "16px",
            textTransform: "uppercase",
            border: "2px solid " + theme.palette.primary.main,
            "&:hover": {
              backgroundColor: "transparent",
              color: theme.palette.primary.main,
              border: "2px solid " + theme.palette.primary.main,
            },
          }}
        >
          Lue lisää
        </Button>
      </Box>
    </>
  );
};

export default HeroButtons;
