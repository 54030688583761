import React from "react";
import { ReactNode } from "react";
import { TextField, FormHelperText } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useFormContext } from "./FormContext";

type RHFTextFieldProps = {
  name: string;
  label: string;
  onBlur?: () => void;
  disabled?: boolean;
  id?: string;
  value?: string | number;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  required?: boolean;
  multiline?: boolean;
  defaultHelperText?: ReactNode; // mahdollistaa normaalin tekstin syöttämisen defaultHelperText='esim näin' lisäksi pitkän tekstin syöttämisen rivitetysti defaultHelperText={`esim näin`} defaultHelperText={<div>esim näin</div>}
  rawHelperText?: string;
  requiredErrorMessage?: string;
  // errors?: Record<string, any>;
};

const RHFTextField: React.FC<RHFTextFieldProps> = ({
  name,
  label,
  onBlur: customOnBlur,
  disabled,
  id,
  value,
  sx,
  fullWidth = true,
  required = true,
  multiline = false,
  defaultHelperText,
  requiredErrorMessage = "Tämä kenttä vaaditaan",
  // errors,
}) => {
  const { register, errors, setValue } = useFormContext();
  const error = errors[name] ? true : false;
  const helperText = errors[name] ? errors[name].message : defaultHelperText;

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // First, it checks if a custom onBlur function (customOnBlur) was provided as a prop.
    // If customOnBlur exists, it gets called. This allows for any additional custom logic 
    // that needs to run when the input field loses focus.
    if (customOnBlur) {
      customOnBlur();
    }

    // Next, the form value is updated using the setValue method from React Hook Form.
    // 'name' is the name of the form field (e.g., 'kampanjakoodi'), and event.target.value
    // is the current value of the input field. This line ensures that the form state in React Hook Form
    // is updated whenever this field loses focus.
    setValue(name, event.target.value);
};


  return (
    <React.Fragment>
      <TextField
        {...register(name, { required: required ? requiredErrorMessage : false })}
        disabled={disabled}
        id={id}
        label={label}
        variant="filled"
        sx={{ my: 1, ...sx }}
        fullWidth={fullWidth}
        required={required}
        multiline={multiline}
        onBlur={handleBlur}
        // error={errors}
        // helperText={helperText}
        value={value}
      />
      <FormHelperText sx={{ mt:0, mb:2, color: errors[name] ? "#f44336" : "rgb(207, 207, 207)" }} >
        {helperText}
      </FormHelperText>
    </React.Fragment>
  );
};

export default RHFTextField;
