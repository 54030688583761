import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PauseIcon from '@mui/icons-material/Pause';
import { useMediaQuery } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Typography } from "@mui/material";


import OsioOtsikko from "../../../../components/OsioOtsikko";
import Spacer from "../../../../components/Spacer";
import swot_esimerkki from "../../../../assets/images/swot_esimerkki.png";
import swot_bm from "../../../../assets/images/swot_bm.png";
import oura_kaksi_palkkia from "../../../../assets/images/oura_kaksi_palkkia.png";
import oura_yksi_palkki_Asiakkaat from "../../../../assets/images/oura_yksi_palkki_Asiakkaat.png";
import someEsim from "../../../../assets/images/someEsim.jpeg";
import lomake_sisainen from "../../../../assets/images/lomake_sisainen.png";
import lomake_ulkoinen from "../../../../assets/images/lomake_ulkoinen.png";

const slides = [
  {
    otsikko: "Analyysissa tarkastellaan asiakas- ja henkilöstökokemuksen suhdetta 8+1:ssä menestystekijässä.",
    image: swot_esimerkki,
    image_width_percentage: '100%'
  },
  {
    otsikko: "Tuloksia vertaillaan muihin yrityksiin.",
    image: swot_bm,
    image_width_percentage: '100%'
  },
  {
    otsikko: "Tuloksia tarkastellaan vastaajaryhmittäin.",
    image: oura_yksi_palkki_Asiakkaat,
    image_width_percentage: '100%'
  },
  {
    otsikko: "Asiakas- ja henkilöstökokemusta vertaillaan 8+1 menestystekijässä.",
    image: oura_kaksi_palkkia,
    image_width_percentage: '100%'
  },
  {
    otsikko: "Henkilöstön vastauslomakkeella aktivoidaan antamaan positiivista palautetta ja kehitysideoita.",
    image: lomake_sisainen,
    image_width_percentage: '50%'
  },
  {
    otsikko: "Asiakkaiden vastauslomakkeella aktivoidaan antamaan positiivista palautetta ja kehitysideoita.",
    image: lomake_ulkoinen,
    image_width_percentage: '50%'
  },
  {
    otsikko: "Viestintäpakettiin kuuluu mm. parhaita palautteita (kuvina), joita voi jakaa esim. sosiaalisessa mediassa.",
    image: someEsim,
    image_width_percentage: '50%'
  },
];


interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Analyysi: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  const theme = useTheme();
  const [activeImageIndex, setActiveImageIndex] = React.useState(0);
  const [isAutoPlay, setIsAutoPlay] = React.useState(true);
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));

  const handlePrevClick = () => {
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const handleNextClick = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const toggleAutoPlay = () => {
    setIsAutoPlay(!isAutoPlay); // Vaihda automaattisen vaihdon tilaa
  };

  // Kuvien automaattinen vaihtuminen
  React.useEffect(() => {
    if (isAutoPlay) {
    const intervalId = setInterval(() => {
      handleNextClick(); // Vaihda kuvaa automaattisesti
    }, 5000); // 5 sekunnin välein

    return () => clearInterval(intervalId); // Puhdista aikaväli komponentin purkautuessa
    }
  }, [activeImageIndex, isAutoPlay]); // Riippuvuuslista varmistaa, että interval päivittyy, jos aktiivinen kuva vaihtuu

  return (
    <div id="analyysi">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <OsioOtsikko otsikko="Positify 8+NPS analyysi" />
        <Container>
          <Box>
            <Grid
              container 
              spacing={4}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <Grid item xs={12}>
                <Box
                  id="analyysiAlue"
                  sx={{ 
                    // padding: 2, 
                    backgroundColor: theme.palette.background.paper, 
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  component={Card}
                  display="flex"
                  flexDirection="column"
                  boxShadow={0}
                >
                  <CardContent style={{ display: 'flex', flexDirection: 'column', flexGrow: 1  }}>
                    <Box
                      flexGrow={1}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center" 
                      alignItems="center"
                    >

                      <img
                        alt="Active"
                        src={slides[activeImageIndex].image}
                        style={{ 
                          maxWidth: slides[activeImageIndex].image_width_percentage, 
                          // maxWidth: "95%", 
                          height: isXsScreen ? "150px" : isSmScreen ? "250px" : "550px",
                          objectFit: "contain", // Säilyttää kuvan mittasuhteet
                          borderRadius: 8,
                        }}
                      />
                      <Typography 
                        variant="body1"
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          alignItems: "flex-end",
                          fontSize: isXsScreen ? "1.1rem" : "1.35rem" ,
                          mb: 0, 
                          height: "80px",
                          // align:"center",
                          maxWidth: "90%", 
                          color: theme.palette.primary.main,
                          lineHeight: "1.0em",
                        }}
                      >
                        {slides[activeImageIndex].otsikko}
                      </Typography>
                    
                    </Box>
                  </CardContent>
                  <CardContent style={{paddingBottom: 20, paddingTop: 0}}>
                                            
                      <Box mt={0} display="flex" justifyContent="space-evenly" fontSize={13}>
                        {activeImageIndex +1 } / {slides.length}
                      </Box>
                      
                      <Box 
                        sx={{
                          // mt: 1, 
                          mb: 0, 
                          height: "20px" 
                        }} 
                        display="flex" 
                        justifyContent="space-evenly" 
                      >

                        <Button // TODO: vie nämä lähes identtiset buttonit omaan komponenttiin
                          sx={{
                            color: theme.palette.text.secondary,
                            backgroundColor: "transparent",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: theme.palette.primary.main,
                            },
                          }}
                          onClick={handlePrevClick}
                        >
                          <ArrowBackIosIcon />
                        </Button>
                        <Button 
                          sx={{
                            color: theme.palette.text.secondary,
                            backgroundColor: "transparent",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: theme.palette.primary.main,
                            },
                          }}
                          onClick={toggleAutoPlay}
                        >
                          {isAutoPlay ? <PauseIcon /> : <PlayArrowIcon />}
                        </Button>
                        <Button 
                          sx={{
                            color: theme.palette.text.secondary,
                            backgroundColor: "transparent",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: theme.palette.primary.main,
                            },
                          }}
                          onClick={handleNextClick}
                        >
                          <ArrowForwardIosIcon />
                        </Button>
                      </Box>


                  </CardContent>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Analyysi;
