import React from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface OsioOtsikkoProps {
  otsikko: string;
  box_sx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
}

const OsioOtsikko: React.FC<OsioOtsikkoProps> = ({ otsikko, box_sx, sx }) => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: 4, ...box_sx }}>
      <Typography
        variant="h4"
        align="center"
        // fontWeight={700}
        marginTop={theme.spacing(1)}
        gutterBottom
        sx={{
          my: 0,
          color: theme.palette.text.primary,
          textTransform: 'uppercase',

          ...sx,
        }}
      >
        {otsikko}
      </Typography>
    </Box>
  );
};

export default OsioOtsikko;
