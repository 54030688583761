import React from "react";

const FormContext = React.createContext<{
  register: any;
  control: any;
  errors: Record<string, any>;
  setValue: (name: string, value: any, options?: any) => void;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  register: () => {},
  control: {},
  errors: {},
  setValue: (name, value) => {
    console.warn(`Attempted to set form value for field '${name}' without context. Value:`, value);
  },
});

export const FormProvider = FormContext.Provider;
export const useFormContext = () => React.useContext(FormContext);
