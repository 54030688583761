import React, { useState } from 'react';

import { TextField, Box, Container, Modal } from '@mui/material';
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import checkbox from "../../../../assets/images/checkbox.png";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ThemeProvider, createTheme } from '@mui/material';


import { useTranslation } from "react-i18next";

import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import toimialadataLogoPunainen from "../../../../assets/images/toimialadata-logo-punainen.png";
import axiosUnauthenticatedInstance from '../../axiosUnauthenticatedInstance'
import config from "../../../../../config";
import CustomButton from "../../../../components/FormComponents/CustomButton";

// Luo muokattu teema
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    // Määrittele yleisiä värejä, joita käytetään alla
    primary: {
      main: '#22D7C1', // Esimerkki, muuta tämä haluamaksesi väriksi
    },
  },
  typography: {
    // Määrittele kaikki teksti käyttämään Exo 2 -fonttia
    fontFamily: '"Exo 2", sans-serif',
    // Voit myös määritellä eri fonttipaksuuksia tai tyylejä tarpeen mukaan
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  components: {
    // Määritä InputLabel-tyylit
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Kun label on aktiivinen (fokusoitu)
          '&.Mui-focused': {
            color: '#22D7C1', // Muuta aktiivisen labelin väriä
          },
        },
      },
    },
    // Määritä OutlinedInput-tyylit
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Kun input-kenttä on aktiivinen (fokusoitu)
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#22D7C1', // Muuta aktiivisen kentän reunan väriä
          },
        },
      },
    },
  },
});


interface IFormInput {
  y_tunnus: string;
  yrityksen_nimi: string;
  yhteys_nimi: string;
  yhteys_sposti: string;
  yhteys_puh: string;
}


interface KiitosTekstiProps {
  sx?: SxProps<Theme>;
  otsikko: string;
  p1: string;
  p2: string;
  p3?: string;
  naytaLiidilomake: boolean;
}

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgba(31, 48, 69, .75)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const KiitosTeksti: React.FC<KiitosTekstiProps> = ({ sx, otsikko, p1, p2, p3 = "", naytaLiidilomake }) => {
  const { t } = useTranslation();

  const schema = yup.object<IFormInput>().shape({
    y_tunnus: yup.string(),
    yrityksen_nimi: yup.string(),
    // yhteys_nimi: yup.string().matches(/^[a-zA-ZäöåÄÖÅ -]+$/, "Nimi saa sisältää vain kirjaimia ja välilyöntejä").required("Yhteyshenkilön nimi vaaditaan"),
    yhteys_nimi: yup.string().required(t('formErrors.nameRequired') as string),
    yhteys_sposti: yup.string(),
    yhteys_puh: yup.string(),
  });

  const theme = useTheme();
  const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>({
    resolver: yupResolver(schema)
  });
  const [openModal, setOpenModal] = useState(false);
  const [virheLomakkeella, setVirheLomakkeella] = useState(false);
  const [modaaliOtsikko, setModaaliOtsikko] = useState("");
  const [modaaliKuvaus, setModaaliKuvaus] = useState("");


  const handleCloseModal = () => {
    setOpenModal(false);
    if (virheLomakkeella) {
      setVirheLomakkeella(false);
    } else {
      window.location.reload();
    }
  };

  const handleModal = (ok: boolean, otsikko: string, kuvaus: string) => {
    setModaaliOtsikko(otsikko);
    setModaaliKuvaus(kuvaus);
    setOpenModal(true);
  };

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    if (data) {
      axiosUnauthenticatedInstance.post(`${config.liidiApiUrl}`, data)
        .then(response => {
          // console.log("Registration successful:", response.data);
          setVirheLomakkeella(false);
          handleModal(true, t("modalTexts.kiitos"), t("modalTexts.otammeYhteytta"));
        })
        .catch(error => {
          // console.error("Registration error:", error.response.data);
          setVirheLomakkeella(true);
          handleModal(true, t("modalTexts.jokinMeniPieleen"), "");
        });
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {modaaliOtsikko}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {modaaliKuvaus}
          </Typography>
        </Box>
      </Modal>

      <div
        style={{
          marginTop: "20px",
          position: "relative",
          height: "125px",
          marginBottom: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={checkbox}
          alt="Kiitos"
          style={{
            position: "absolute",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            maxHeight: "100%",
            maxWidth: "100%",
            borderRadius: 8,
            height: "100%",
          }}
        />
      </div>
      <Typography
        variant="h3"
        sx={{
          ...sx,
          textAlign: "center",
          margin: "1.5rem 0",
        }}
      >
        {t(otsikko)}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          ...sx,
          lineHeight: 1.5,
          textAlign: "left",
          margin: "1rem 0",
        }}
      >
        {t(p1) + " "}
        <a href="https://toimialadata.fi">
          <img
            src={toimialadataLogoPunainen}
            alt="Toimialadata"
            style={{
              height: "1.0rem",
            }}
          />
        </a>
        .
      </Typography>
      <Typography
        variant="h5"
        sx={{
          ...sx,
          // fontWeight: 400,
          lineHeight: 1.5,
          textAlign: "left",
          margin: "1rem 0",
        }}
      >
        {" " + t(p2)}
      </Typography>



      {naytaLiidilomake && (

        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate >
          <Typography variant="h5" sx={{ pt: 0, px: 0, lineHeight: 1.5, textAlign: "left" }} >
            {t(p3)}
          </Typography>
          <ThemeProvider theme={lightTheme}>

            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              label={t("kiitos.yhteys_nimi")}
              {...register('yhteys_nimi', { required: true })}
              error={!!errors.yhteys_nimi}
              helperText={errors.yhteys_nimi?.message}
            />
            <TextField
              variant="filled"
              margin="normal"
              fullWidth
              label={t("kiitos.yhteys_sposti")}
              {...register('yhteys_sposti', { required: true })}
            />
            <TextField
              variant="filled"
              margin="normal"
              fullWidth
              label={t("kiitos.yhteys_puh")}
              {...register('yhteys_puh', { required: true })}
            />
            <TextField
              variant="filled"
              margin="normal"
              fullWidth
              label={t("kiitos.yrityksen_nimi")}
              {...register('yrityksen_nimi', { required: true })}
            />
            <CustomButton
              id="lahetaTilaus"
              fullWidth={true}
              buttonText={t("kiitos.laheta")} // TODO vie käännöstiedostoihin
              type="submit"
              sx={{
                mt: 2,
                backgroundColor: "#22D7C1",
                borderColor: "#22D7C1",
                color: "#fff", // Vaihda tekstin väri hover-tilassa
                '&:hover': {
                  // tämä on tärkeä, koska tyhjänäkin se tummentaa nappia kun hover
                }
              }}
            />
          </ThemeProvider>
        </Box>
      )}
    </>
  );
};

export default KiitosTeksti;
