import React from "react";
import { Helmet } from "react-helmet-async";

import Hero from "./Hero/Hero";
import Hinta from "./Hinta/Hinta";
import Meista from "./Meista/Meista";
import Mita from "./Mita/Mita";
import Tilaa from "./Tilaa/Tilaa";
import Miten from "./Miten/Miten";
import Miksi from "./Miksi/Miksi";
import Analyysi from "./Analyysi/Analyysi";

const Home = (): JSX.Element => {
  return (
    <>
      <div id="home">
        <Hero />
        <Miksi />
        <Mita />
        <Miten />
        <Analyysi />
        <Hinta />
        <Meista />
        <Tilaa />
      </div>
    </>
  );
};

export default Home;
