import React from "react";

const FormContext = React.createContext<{
  register: any;
  control: any;
  errors: Record<string, any>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  register: () => {},
  control: {},
  errors: {},
});

export const FormProvider = FormContext.Provider;
export const useFormContext = () => React.useContext(FormContext);
