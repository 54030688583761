import React from "react";
import { Box, Container } from "@mui/material";

const Error401Unauthorized = () => {
  return (
    <Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <h1>401 - Pääsy kielletty!</h1>
      </Container>
    </Box>
  );
};

export default Error401Unauthorized;
