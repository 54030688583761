import React from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Tooltip } from "@mui/material";
import { Controller } from "react-hook-form";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useFormContext } from "./FormContext";

interface RHFCheckboxFieldProps {
  options: { id: number; name: string, price: number }[];
  fieldNamePrefix: string;
  fieldLabel: string;
  errorName: string;
  kampanjakoodi?: string;
}

const RHFCheckboxField: React.FC<RHFCheckboxFieldProps> = ({ options, fieldNamePrefix, fieldLabel, errorName, kampanjakoodi }) => {
  const { control, errors } = useFormContext();

  const checkboxFieldSx: SxProps<Theme> = {
    backgroundColor: "rgba(77, 86, 99, .7)",
    color: "rgb(207, 207, 207)",
    m: 0,
    p: 0,
    borderRadius: "4px",
    mt: 1,
    mb: 1,
  };

  const errorTextSx: SxProps<Theme> = {
    color: "#f44336",
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: 1.66,
    textAlign: "left",
    mt: "3px",
    mr: "14px",
    mb: "8px",
    ml: "14px",
  };

  return (
    <Box sx={checkboxFieldSx}>
      <FormControl
        sx={{ p: 0 }}
        fullWidth
        margin="normal"
      >
        <FormLabel
          sx={{
            pl: "12px",
            color: errors[errorName] ? "#f44336" : "inherit",
          }}
          component="legend"
        >
          {fieldLabel}
        </FormLabel>
        {options.map((product) => (
          <CustomFormControlLabel
            key={product.id}
            product={product}
            fieldNamePrefix={fieldNamePrefix}
            control={control}
            kampanjakoodi={kampanjakoodi}
          />
        ))}
      </FormControl>
      {errors[errorName] && (
        <Box
          component="p"
          sx={errorTextSx}
        >
          {errors[errorName].message}
        </Box>
      )}
    </Box>
  );
};

export default RHFCheckboxField;



interface CustomFormControlLabelProps {
  product: { id: number; name: string, price: number };
  fieldNamePrefix: string;
  control: any;
  kampanjakoodi?: string;
}

const CustomFormControlLabel: React.FC<CustomFormControlLabelProps> = ({ product, fieldNamePrefix, control, kampanjakoodi }) => {
  const labelContent = `${kampanjakoodi && product.id !== 2 ? `${product.name} (${kampanjakoodi})` : `${product.name} (${product.price} € + alv)`}`;
  const tooltipContent = "Asiantuntijamme esittelee raportin nettipalaverissa, jonka jälkeen lyhyt keskustelu tulosten merkityksestä yrityksellenne. Kokonaiskesto 30-60 min. Tämän lisäpalvelun voi tilata myös jälkikäteen.";


  if (product.id === 2) {
    return (
      <Tooltip title={tooltipContent}>
        <FormControlLabel
          sx={{ mx: 1, textDecoration: 'underline', textDecorationStyle: 'dotted' }}
          key={product.id}
          control={
            <Controller
              name={`${fieldNamePrefix}.${product.id}`}
              control={control}
              render={({ field }) => (
                <Checkbox
                  data-testid={`${fieldNamePrefix}.${product.id}`}
                  sx={{ color: "rgb(207, 207, 207)" }}
                  {...field}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value ?? false}
                />
              )}
            />
          }
          label={labelContent}
        />
      </Tooltip>
    );
  } else {
    return (
      <FormControlLabel
        sx={{ mx: 1 }}
        key={product.id}
        control={
          <Controller
            name={`${fieldNamePrefix}.${product.id}`}
            control={control}
            render={({ field }) => (
              <Checkbox
                data-testid={`${fieldNamePrefix}.${product.id}`}
                sx={{ color: "rgb(207, 207, 207)" }}
                {...field}
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value ?? false}
              />
            )}
          />
        }
        label={labelContent}
      />
    );
  }
};
