import React, { useState } from "react";

import axios from "axios";

import { Box, Container, FormControl, Modal, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import config from "../../../../../../config";
import CustomButton from "../../../../../components/FormComponents/CustomButton";
import { FormProvider } from "../../../../../components/FormComponents/FormContext";
import { RHFAutocompleteField } from "../../../../../components/FormComponents/RHFAutocompleteField";
import RHFCheckboxField from "../../../../../components/FormComponents/RHFCheckboxField";
import RFHTextField from "../../../../../components/FormComponents/RHFTextField";
import RHFSingleCheckboxField from "../../../../../components/FormComponents/RHFSingleCheckboxField";
import RHFRadioButtonField from "../../../../../components/FormComponents/RHFRadioButtonField";

import { liikevaihdon_kokoluokka } from "./liikevaihdon_kokoluokka";
import { postinumerot } from "./postinumerot";
import { toimialat } from "./toimialat";

import Positify_8plusNPS_TOIMITUSEHDOT from "./Positify_8plusNPS_TOIMITUSEHDOT.pdf";

import "./Tilauslomake.scss";
import { t } from "i18next";

type Product = {
  id: number;
  name: string;
  price: number;
};

type BusinessLine = {
  language: string;
  code: string;
  name: string;
};

// TODO tuo nämä suoraan kannasta apilla
const products: Product[] = [
  { id: 1, name: "Positify 8+NPS", price: 780 },
  { id: 2, name: "Tulosten online-esittely", price: 190 },
];

type FormValues = {
  y_tunnus: string;
  yrityksen_nimi: string;
  markkinointi_nimi?: string;
  toimiala_koodi: string;
  toimiala_nimi: string;
  onko_paatoimiala: string;
  paatoimiala: string;
  yrityksen_postinumero: string;
  liikevaihdon_kokoluokka: string;
  tilaajan_nimi: string;
  yhteys_sposti: string;
  yhteys_puh: string;
  kampanjakoodi?: string;
  vertailuraporttikoodi?: string;
  muuta?: string;
  selectedProducts: Record<number, boolean>;
  toimitusehdot: boolean;
  [key: string]: any;
};

type FormValuesWithSelectedProducts = FormValues & Record<string, boolean>;

const schema = yup
  .object<FormValues>({
    y_tunnus: yup.string().required("Tämä on pakollinen tieto."),
    yrityksen_nimi: yup.string().required(),
    markkinointi_nimi: yup.string(),
    toimiala_koodi: yup.string().required("Tämä on pakollinen tieto."),
    toimiala_nimi: yup.string().required("Tämä on pakollinen tieto."),
    onko_paatoimiala: yup.string().required("Tämä on pakollinen tieto."),
    paatoimiala: yup.string().required("Tämä on pakollinen tieto."),
    yrityksen_postinumero: yup.string().required("Tämä on pakollinen tieto."),
    liikevaihdon_kokoluokka: yup.string().required("Tämä on pakollinen tieto."),
    tilaajan_nimi: yup.string().required("Tämä on pakollinen tieto."),
    yhteys_sposti: yup.string().email("Tarkista sähköpostiosoite").required("Tämä on pakollinen tieto."),
    yhteys_puh: yup.string().required("Tämä on pakollinen tieto."),
    kampanjakoodi: yup.string(),
    vertailuraporttikoodi: yup.string(),
    selectedProducts: yup.object().test({
      name: "atLeastOneProductSelected",
      message: "Valitse vähintään yksi tutkimus.",
      test: (obj) => obj && Object.values(obj).some((selected) => selected),
    }),
    toimitusehdot: yup.boolean().oneOf([true], "Hyväksy toimitusehdot ennen tilausta."),
    muuta: yup.string(),
  })
  .required();

export default function Tilauslomake() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), { defaultMatches: true });

  const haetaanAutomaattisesti = "Haetaan automaattisesti Y-tunnuksen perusteella";
  const [openModal, setOpenModal] = useState(false);
  const [virheLomakkeella, setVirheLomakkeella] = useState(false);
  const [modaaliOtsikko, setModaaliOtsikko] = useState("");
  const [modaaliKuvaus, setModaaliKuvaus] = useState("");
  const [kampanjakoodiState, setKampanjakoodiState] = useState("");
  const [paatoimialaKaksi, setPaatoimialaKaksi] = useState("Yrityksenne päätoimiala, ellei yllä oleva");
  const [kaytaPaatoimialaaKaksi, setKaytaPaatoimialaaKaksi] = useState(false);
  const [onkoPaatoimialaYksipakollinen, setOnkoPaatoimialaYksipakollinen] = useState(true);
  const [onkoPaatoimialaRadioDisabled, setOnkoPaatoimialaRadioDisabled] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    if (virheLomakkeella) {
      setVirheLomakkeella(false);
    } else {
      window.location.reload();
    }
  };
  const handleModal = (ok: boolean, otsikko: string, kuvaus: string) => {
    setModaaliOtsikko(otsikko);
    setModaaliKuvaus(kuvaus);
    setOpenModal(true);
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm<FormValuesWithSelectedProducts>({
    defaultValues: {
      y_tunnus: "",
      yrityksen_nimi: "",
      markkinointi_nimi: "",
      toimiala_koodi: "",
      toimiala_nimi: "",
      paatoimiala: "",
      yrityksen_postinumero: "",
      liikevaihdon_kokoluokka: "",
      tilaajan_nimi: "",
      yhteys_sposti: "",
      yhteys_puh: "",
      kampanjakoodi: "",
      vertailuraporttikoodi: "",
      muuta: "",
      toimitusehdot: false,
      selectedProducts: {},
    },
    resolver: yupResolver(schema),
  });

  const onkoPaatoimiala = watch("onko_paatoimiala");
  const toimiala_koodi = watch("toimiala_koodi");

  React.useEffect(() => {
    // merkitään toimiala_koodi myös paatoimiala-kenttään, joka lähetetään backendiin
    if (getValues("toimiala_koodi")) {
      setValue("paatoimiala", toimiala_koodi);

      // jos onkoPaatoimiala on 'Kyllä', merkitään toimiala_koodi myös paatoimiala-kenttään
      // jos vaikka vaihtaa välillä "Ei" ja valkkaa listalta jonkun toisen, mutta sitten valitsee "Kyllä
      if (onkoPaatoimiala === "Kyllä") {
        setValue("paatoimiala", toimiala_koodi);
      }
    }
  }, [onkoPaatoimiala, toimiala_koodi, setValue]);

  function isAnyProductSelected() {
    const selectedProducts = getValues("selectedProducts");
    return selectedProducts && Object.values(selectedProducts).some((value) => value);
  }

  const yTunnuksenVirheet = () => {
    setError("y_tunnus", { type: "manual", message: "Tarkista Y-tunnus" });
    setValue("yrityksen_nimi", haetaanAutomaattisesti);
  };

  const [businessLines, setBusinessLines] = useState<BusinessLine[]>([]);

  async function haeNimi() {
    const y = getValues("y_tunnus");
    if (y) {
      try {
        const response = await fetch(`https://avoindata.prh.fi/bis/v1/${y}`);
        if (response.ok && response.status !== 404) {
          const jsonData = await response.json();
          const nimi = jsonData.results[0].name;
          const businessLines = jsonData.results[0].businessLines;
          setBusinessLines(businessLines);
          const toimiala_koodi = businessLines.filter((k: BusinessLine) => k.language === "FI")[0].code;
          const toimiala_nimi = businessLines.filter((k: BusinessLine) => k.language === "FI")[0].name;

          clearErrors("y_tunnus");
          setValue("yrityksen_nimi", nimi);
          setValue("toimiala_koodi", toimiala_koodi);
          setValue("toimiala_nimi", toimiala_nimi);
          setValue("paatoimiala", toimiala_koodi); //uusi: merkitään toimiala_koodi myös paatoimiala-kenttään, joka lähetetään backendiin
        } else if (response.status === 404) {
          // If no results from PRH, attempt VIES API
          const formattedVatNumber = y.replace(/[-\s]/g, ""); // Removes dashes and spaces
          const viesResponse = await fetch(`${config.viesCheck}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              countryCode: "FI",
              vatNumber: formattedVatNumber,
            }),
          });
          if (viesResponse.ok) {
            const viesData = await viesResponse.json();
            if (viesData.valid) {
              const nimi = viesData.name || "Nimi ei saatavilla";
              setValue("yrityksen_nimi", nimi);
              setValue("toimiala_koodi", "NA");
              setValue("toimiala_nimi", "Toimiala ei saatavilla");
              setPaatoimialaKaksi("Valitse yrityksenne päätoimiala");
              setKaytaPaatoimialaaKaksi(true);
              setOnkoPaatoimialaYksipakollinen(false);
              setValue("onko_paatoimiala", "Ei");
              setOnkoPaatoimialaRadioDisabled((current) => true);
              clearErrors("y_tunnus");
            } else {
              yTunnuksenVirheet();
            }
          } else {
            yTunnuksenVirheet();
          }
        } else {
          yTunnuksenVirheet();
        }
      } catch (error) {
        yTunnuksenVirheet();
      }
    } else {
      yTunnuksenVirheet();
    }
  }

  function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    return parts.length === 2 ? parts.pop()?.split(";").shift() ?? "" : "";
  }

  const csrftoken = getCookie("csrftoken");

  async function onSubmit(data: FormValues) {
    if (!isAnyProductSelected()) {
      setError("selectedProducts", {
        type: "manual",
        message: "Valitse vähintään yksi tutkimus.",
      });
      return;
    }

    // Collect the selected products into an array
    const tilatut_tuotteet: Array<{ id: number; name: string }> = [];
    for (const product of products) {
      if (data.selectedProducts[product.id]) {
        tilatut_tuotteet.push({ id: product.id, name: product.name });
      }
    }
    const { selectedProducts, ...otherData } = data;
    const { onko_paatoimiala, toimiala_koodi, toimiala_nimi, ...dataWithoutUnwantedKeys } = otherData;
    const modifiedData = { ...dataWithoutUnwantedKeys, tilatut_tuotteet };

    await haeNimi();

    if (errors.y_tunnus || data.yrityksen_nimi === haetaanAutomaattisesti) {
      setVirheLomakkeella(true);
      handleModal(true, "Tarkista Y-tunnus!", "");
    } else {
      try {
        axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
        await axios.post(`${config.tilausApiUrl}`, modifiedData);
        setVirheLomakkeella(false);
        handleModal(true, "KIITOS TILAUKSESTA!", "Lähetimme tilausvahvistuksen sähköpostiosoitteeseenne.");
      } catch (error: unknown) {
        if (error instanceof Error && "response" in error && "data" in (error as any).response) {
          console.log((error as any).response.data);
        } else {
          console.log("Unexpected error format:", error);
        }
        setVirheLomakkeella(true);
        handleModal(true, "Jokin meni pieleen :(", "");
      }
    }
  }

  const modalStyle = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "rgba(31, 48, 69, .75)",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const validateResellerCode = async (code: string) => {
    try {
      const response = await axios.get(`${config.baseApiUrl}/validate_campaign_code/`, {
        params: { code },
      });
      return response.data;
    } catch (error) {
      console.error("Error validating campaign code:", error);
      return { isValid: false };
    }
  };

  const handleKampanjakoodiBlur = async () => {
    // Use the ?? operator to provide an empty string as a fallback value
    const kampanjakoodiValue = getValues("kampanjakoodi") ?? "";

    // Tutkitaan onko kampanjakoodi validi,
    // mutta armistetaan ensin että kampanjakoodi on string (ei undefined tai null)
    if (typeof kampanjakoodiValue === "string") {
      if (kampanjakoodiValue === "") {
        // If kampanjakoodi is an empty string, clear any existing errors
        setKampanjakoodiState("");
        clearErrors("kampanjakoodi");
        return;
      }
      const result = await validateResellerCode(kampanjakoodiValue);
      if (!result) {
        setError("kampanjakoodi", { type: "manual", message: "Tarkista kampanjakoodi" });
      } else {
        // Tarkistetaan, sisältääkö vastaus 'ilmainen' kentän ja onko se true
        if (result.ilmainen) {
          setKampanjakoodiState("100% alennus: 0 € + alv");
        } else {
          setKampanjakoodiState("20% alennus: 624 € + alv");
        }
        clearErrors("kampanjakoodi");
      }
    } else {
      // TODO: Handle the case where kampanjakoodi is undefined, if necessary
      // For example, show an error or take some default action
    }
  };

  const validateVertailuraporttiCode = async (code: string) => {
    try {
      const response = await axios.get(`${config.baseApiUrl}/validate_vertailuraportti_code/`, {
        params: { code },
      });
      return response.data.isValid;
    } catch (error) {
      console.error("Error validating vertailuraportti code:", error);
      return false;
    }
  };

  const handleVertailuraporttikoodiBlur = async () => {
    // Use the ?? operator to provide an empty string as a fallback value
    const vertailuraporttikoodiValue = getValues("vertailuraporttikoodi") ?? "";

    // Tutkitaan onko vertailuraporttikoodi validi,
    // mutta armistetaan ensin että vertailuraporttikoodi on string (ei undefined tai null)
    if (typeof vertailuraporttikoodiValue === "string") {
      if (vertailuraporttikoodiValue === "") {
        // If vertailuraporttikoodi is an empty string, clear any existing errors
        clearErrors("vertailuraporttikoodi");
        return;
      }
      const isVertailuraporttiValid = await validateVertailuraporttiCode(vertailuraporttikoodiValue);
      if (!isVertailuraporttiValid) {
        setError("vertailuraporttikoodi", { type: "manual", message: "Tarkista vertailuraporttikoodi" });
      } else {
        clearErrors("vertailuraporttikoodi");
      }
    } else {
      // TODO: Handle the case where vertailuraporttikoodi is undefined, if necessary
      // For example, show an error or take some default action
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {modaaliOtsikko}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {modaaliKuvaus}
          </Typography>
        </Box>
      </Modal>

      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider value={{ register, control, errors, setValue }}>
            <FormControl
              fullWidth
              margin="normal"
            >
              <RFHTextField
                name="y_tunnus"
                label="Y-tunnus"
                onBlur={haeNimi}
              />

              <RFHTextField
                disabled
                name="yrityksen_nimi"
                label="Yrityksen nimi"
                value={getValues("yrityksen_nimi") || haetaanAutomaattisesti}
                sx={{ fontWeight: "500" }}
              />

              <RFHTextField
                required={false}
                name="markkinointi_nimi"
                label="(Tarvittaessa) Yrityksen markkinointinimi"
                defaultHelperText={`Vaihtoehtoinen nimi, jota haluatte käyttää kyselylomakkeella 
                  yrityksen virallisen nimen sijaan.`}
              />

              {!kaytaPaatoimialaaKaksi && (
                <>
                  <RFHTextField
                    disabled
                    name="toimiala_nimi"
                    label="Toimiala"
                    value={getValues("toimiala_nimi") || haetaanAutomaattisesti}
                    sx={{ fontWeight: "500" }}
                  />

                  <RHFRadioButtonField
                    disabled={onkoPaatoimialaRadioDisabled}
                    options={[
                      { id: 1, name: "Kyllä" },
                      { id: 2, name: "Ei" },
                    ]}
                    fieldName="onko_paatoimiala"
                    fieldLabel="Onko yllä mainittu yrityksenne päätoimiala?"
                    errorName="onko_paatoimiala"
                    required={onkoPaatoimialaYksipakollinen}
                  />
                </>
              )}

              {(onkoPaatoimiala === "Ei" || kaytaPaatoimialaaKaksi) && (
                <RHFAutocompleteField
                  options={toimialat}
                  fieldLabel={paatoimialaKaksi}
                  name="paatoimiala"
                  defaultHelperText={`Toimialaa käytetään vertailuaineiston muodostamiseen raportoinnissa.`}
                  renderOption={(props, option) => (
                    <span
                      {...props}
                      style={{ backgroundColor: theme.palette.background.paper }}
                    >
                      {option.label}
                    </span>
                  )}
                />
              )}

              <RHFAutocompleteField
                options={liikevaihdon_kokoluokka}
                fieldLabel="Valitse yrityksenne liikevaihdon kokoluokka"
                name="liikevaihdon_kokoluokka"
                renderOption={(props, option) => (
                  <span
                    {...props}
                    style={{ backgroundColor: theme.palette.background.paper }}
                  >
                    {option.label}
                  </span>
                )}
              />

              <RHFAutocompleteField
                options={postinumerot}
                fieldLabel="Yrityksen toiminta-aluetta parhaiten kuvaava postinumero"
                name="yrityksen_postinumero"
                renderOption={(props, option) => (
                  <span
                    {...props}
                    style={{ backgroundColor: theme.palette.background.paper }}
                  >
                    {option.label}
                  </span>
                )}
              />

              <RFHTextField
                name="tilaajan_nimi"
                label="Tilaajan nimi"
              />

              <RFHTextField
                name="yhteys_sposti"
                label="Tilaajan sähköpostiosoite"
              />

              <RFHTextField
                name="yhteys_puh"
                label="Tilaajan puhelinnumero"
              />

              <RFHTextField
                required={false}
                name="kampanjakoodi"
                label="Kampanjakoodi"
                defaultHelperText={`Voit kysyä kampanjakoodia kirjanpitäjältäsi.`}
                onBlur={handleKampanjakoodiBlur}
              />

              <RFHTextField
                required={false}
                name="vertailuraporttikoodi"
                label="Vertailuraporttikoodi"
                defaultHelperText={`Edellisen tilauksen tilauskoodi (tilausvahvistuksesta ja raporteista), jos haluat verrata aikaisempaan tulokseesi.`}
                onBlur={handleVertailuraporttikoodiBlur}
              />

              <RHFCheckboxField
                fieldNamePrefix="selectedProducts"
                options={products}
                fieldLabel="Tilattavat palvelut *"
                errorName="selectedProducts"
                kampanjakoodi={kampanjakoodiState}
              />

              <RFHTextField
                required={false}
                name="muuta"
                label="Muut tilaukseen liittyvät huomiot"
                multiline
              />

              <RHFSingleCheckboxField
                name="toimitusehdot"
                fieldLabel="Toimitusehdot *"
                optionLabel="Hyväksyn toimitusehdot"
                errorName="toimitusehdot"
                pdfLink={Positify_8plusNPS_TOIMITUSEHDOT}
              />

              <CustomButton
                id="lahetaTilaus"
                fullWidth={!isMd}
                onClick={haeNimi}
                buttonText="Lähetä tilaus"
                type="submit"
              />
            </FormControl>
          </FormProvider>
        </form>
      </Container>
    </>
  );
}
