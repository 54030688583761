import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface CustomButtonProps extends ButtonProps {
  buttonText: string;
  sx?: SxProps<Theme>;
  type?: "submit" | "reset" | "button";
}

const CustomButton: React.FC<CustomButtonProps> = ({ buttonText, sx, type, ...buttonProps }) => {
  return (
    <Button
      type={type}
      variant="contained"
      size="large"
      color="primary"
      disableElevation
      sx={{
        mt: 1,
        color: "common.black",
        padding: "15px 30px",
        fontSize: 18,
        letterSpacing: 2,
        textTransform: "uppercase",
        border: "2px solid",
        borderColor: "primary.main",
        "&:hover": {
          backgroundColor: "transparent",
          color: "primary.main",
          borderColor: "primary.main",
        },
        ...sx,
      }}
      {...buttonProps}
    >
      {buttonText}
    </Button>
  );
};

export default CustomButton;
