import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from "@mui/material";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useFormContext } from "./FormContext";

interface RHFRadioButtonFieldProps {
  required?: boolean;
  options: { id: number; name: string }[];
  fieldName: string;
  fieldLabel: string;
  errorName: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

const RHFRadioButtonField: React.FC<RHFRadioButtonFieldProps> = ({
  required, options, fieldName, fieldLabel, errorName, sx, disabled
}) => {
  const { control, errors } = useFormContext();

  const kysymystekstinVari = disabled ? "rgba(255, 255, 255, 0.5)" : "rgb(207, 207, 207)";

  const radiobuttonFieldSx: SxProps<Theme> = {
    backgroundColor: "rgba(77, 86, 99, .7)",
    color: "rgb(207, 207, 207)",
    m: 0,
    pt: 2,
    pl: 1.5,
    borderRadius: "4px",
    mt: 1,
    mb: 1,
    ...sx,
  };

  const errorTextSx: SxProps<Theme> = {
    color: "#f44336",
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: 1.66,
    textAlign: "left",
    mt: "3px",
    mr: "14px",
    mb: "8px",
    ml: "14px",
  };

  return (
    <Box sx={radiobuttonFieldSx}>
      <Controller
        name={fieldName}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <RadioGroup  {...field} sx={sx} value={field.value ?? ''}>
            <FormLabel
              component="legend"
              sx={{ color: kysymystekstinVari }}
            >
              {required ? `${fieldLabel} *` : fieldLabel}
            </FormLabel>
            {options.map((option) => (
              <FormControlLabel
                sx={{ ml: -0.5 }}
                key={option.id}
                value={option.name}
                control={<Radio />}
                label={option.name}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        )
        }
      />
      {
        errors[errorName] && (
          <Box
            component="p"
            sx={errorTextSx}
          >
            {errors[errorName].message}
          </Box>
        )
      }
    </Box >
  );
};

export default RHFRadioButtonField;
