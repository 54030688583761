import { Theme, PaletteMode, responsiveFontSizes } from "@mui/material";
import { createTheme, ComponentsOverrides } from "@mui/material/styles";
import "@fontsource/exo-2";
import type {} from '@mui/x-date-pickers/themeAugmentation';


const getTheme = (): Theme =>
  responsiveFontSizes(
    createTheme({
      palette: {
        mode: "dark" as PaletteMode,
        background: {
          default: "#545759", // toimialadatan netti (harmaa)
          // default: "#1f3045", // toimialadatan netti (sininen)
          paper: "rgba(31, 48, 69, .65)", // vari_1 (vaaleampi)
        },
        text: {
          primary: "rgb(255, 255, 255)",
          secondary: "rgb(207, 207, 207)",
        },
        primary: {
          main: "#22D7C1",
          contrastText: "rgb(100, 101, 98)",
        },
        divider: "rgba(145, 158, 171, 0.24)",
      },
      typography: {
        fontFamily: '"Exo 2", sans-serif',
      },
      components: {
        MuiPickersPopper: { // datepicker
          styleOverrides: {
            root: {
              backgroundColor: "rgba(31, 48, 69, 1.0)",
            },
          },
        },
        MuiPickersDay: { // in datepicker
          styleOverrides: {
            root: {
              '&.Mui-selected': {
                color: "#fff",
                fontWeight: 600,
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            // Add your default styles here
            root: {
              // backgroundColor: "#fff",
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              backgroundColor: "#545759",
              // backgroundColor: "#1f3045",
              height: '100%',
              '& #root': {
                height: '100%',
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 600,
              borderRadius: 0,
              paddingTop: 10,
              paddingBottom: 10,
            },
          } as ComponentsOverrides["MuiButton"],
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
          } as ComponentsOverrides["MuiInputBase"],
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
            input: {
              borderRadius: 0,
            },
          } as ComponentsOverrides["MuiOutlinedInput"],
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 0,
            },
          } as ComponentsOverrides["MuiCard"],
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "1rem",
              padding: "1rem",
            },
          },
        },
      },
    })
  );

export default getTheme;
