import React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import CustomizedSlider from "./CustomizedSlider";
import AsiakasArvosanaAvot from "../../Asiakaskysely/AsiakasArvosanaAvot";

import { useAppState } from "../state";

interface PaakysymysOsioProps {
  sx?: SxProps<Theme>;
  name_q: string;
  name_a1: string;
  name_a2: string;
  name_a3: string;
  name_eos: string;
  eriMielta: string;
  samaaMielta: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  avo1?: string | undefined | null;
  avo2?: string | undefined | null;
  avo3?: string | undefined | null;
}

const PaakysymysOsio: React.FC<PaakysymysOsioProps> = ({
  sx,
  name_q,
  name_a1,
  name_a2,
  name_a3,
  name_eos,
  eriMielta,
  samaaMielta,
  handleChange,
  handleCheckboxChange,
  avo1,
  avo2,
  avo3,
}) => {
  const { state } = useAppState();
  const sliderValue = state[name_q] || null;
  const eosValue = typeof state[name_eos] === "boolean" ? state[name_eos] : false;

  return (
    <>
      <CustomizedSlider
        name={name_q}
        value={sliderValue}
        valueLabelDisplay="on"
        eriMieltaOtsikko={eriMielta}
        samaaMieltaOtsikko={samaaMielta}
        handleChange={handleChange}
        name_eos={name_eos}
        checked={eosValue}
        handleCheckboxChange={handleCheckboxChange}
      />
      <AsiakasArvosanaAvot
        name_a1={name_a1}
        name_a2={name_a2}
        name_a3={name_a3}
        handleChange={handleChange}
        avo1={avo1}
        avo2={avo2}
        avo3={avo3}
      />
    </>
  );
};

export default PaakysymysOsio;
