import axios from "axios";
import config from "../../../config";

const axiosUnauthenticatedInstance = axios.create({
  baseURL: config.baseApiUrl,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  withCredentials: true, // Needed for cookies, e.g., CSRF
});

export default axiosUnauthenticatedInstance;
