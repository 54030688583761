import React from 'react';
import { useTheme } from "@mui/material/styles";
import { SxProps, Link } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { JalleenmyyjaData } from './tauluInterfaces';

interface JalleenmyyjatauluComponentProps {
  data: JalleenmyyjaData[];
  otsikko: string;
  otsikkoSx?: SxProps;
  naytaLinkki?: boolean;
}

const JalleenmyyjatauluComponent: React.FC<JalleenmyyjatauluComponentProps> = ({
  data, otsikko, otsikkoSx, naytaLinkki }) => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h5" sx={{ mb: 1, pl: 2, ...otsikkoSx }}>{otsikko}</Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium" aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.background.paper }}>
              <TableCell>#</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Yrityksen nimi</TableCell>
              <TableCell>Kampanjakoodi</TableCell>
              <TableCell>Y-tunnus</TableCell>
              <TableCell>Kieli</TableCell>
              <TableCell>Yhteys nimi</TableCell>
              <TableCell>Sähköposti</TableCell>
              <TableCell>Puhelin</TableCell>
              <TableCell>Rekisteröinti PVM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: JalleenmyyjaData, index: number) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">{index + 1}</TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  {naytaLinkki ?
                    (<Link href={`/pr/jalleenmyyjataulu/${row.y_tunnus}/`} underline="hover">
                      {row.yrityksen_nimi}
                    </Link>
                    ) : (
                      row.yrityksen_nimi
                    )
                  }
                </TableCell>
                <TableCell>{row.jalleenmyyjakoodi}</TableCell>
                <TableCell>{row.y_tunnus}</TableCell>
                <TableCell>{row.language}</TableCell>
                <TableCell>{row.yhteys_nimi}</TableCell>
                <TableCell>{row.yhteys_sposti}</TableCell>
                <TableCell>{row.yhteys_puh}</TableCell>
                <TableCell>{new Date(row.rekisterointi_pvm).toLocaleString('fi-FI', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                }).replace(' ', ' klo ')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default JalleenmyyjatauluComponent;