import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

import OsioOtsikko from "../../../../components/OsioOtsikko";
import Perustelu from "./Perustelu";
import Spacer from "../../../../components/Spacer";

interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Mita: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  const theme = useTheme();
  return (
    <div id="mita">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <OsioOtsikko otsikko="Mitä? - 8+NPS -malli mittaa seuraavia kokonaisuuksia" />
        <Container>
          <Grid
            container
            spacing={4}
          >
            <Perustelu
              otsikko="ASIOINTI"
              ikoni="shopping_cart" // https://fonts.google.com/icons?selected=Material+Symbols+Outlined:search:FILL@0;wght@400;GRAD@0;opsz@24
              kuvaus="kertoo kuinka helppona yhteydenpito ja palvelun käyttö koetaan."
              korttibgcolor={theme.palette.background.paper}
              ikonibgcolor={theme.palette.primary.main}
              />
            <Perustelu
              otsikko="LÄMPÖ"
              ikoni="local_fire_department"
              kuvaus="mittaa kuinka asiakkaista välittävänä toiminta koetaan."
              korttibgcolor={theme.palette.background.paper}
              ikonibgcolor={theme.palette.primary.main}
              />
            <Perustelu
              otsikko="BRÄNDI"
              ikoni="ac_unit"
              kuvaus="kartoittaa, kuinka organisaatio erottuu positiivisesti alan muista toimijoista."
              korttibgcolor={theme.palette.background.paper}
              ikonibgcolor={theme.palette.primary.main}
              />
            <Perustelu
              otsikko="LUOTETTAVUUS"
              ikoni="lock"
              kuvaus="tutkii kuinka hyvin lupaukset pidetään."
              korttibgcolor={theme.palette.background.paper}
              ikonibgcolor={theme.palette.primary.main}
              />
            <Perustelu
              otsikko="VASTUULLISUUS"
              ikoni="done_all"
              kuvaus="selvittää, kuinka vastuullisena toimintaa pidetään."
              korttibgcolor={theme.palette.background.paper}
              ikonibgcolor={theme.palette.primary.main}
              />
            <Perustelu
              otsikko="VIESTINTÄ"
              ikoni="cell_tower"
              kuvaus="kuvaa kuinka toimivana yrityksen viestintää koetaan."
              korttibgcolor={theme.palette.background.paper}
              ikonibgcolor={theme.palette.primary.main}
              />
            <Perustelu
              otsikko="UUDISTUMISKYKY"
              ikoni="autorenew"
              kuvaus="analysoi organisaation kykyä reagoida toimintaympäristön muutoksiin."
              korttibgcolor={theme.palette.background.paper}
              ikonibgcolor={theme.palette.primary.main}
              />
            <Perustelu
              otsikko="TULEVAISUUS"
              ikoni="grade"
              kuvaus="kertoo kuinka kuinka menestykseen uskotaan pitkällä tähtäimellä."
              korttibgcolor={theme.palette.background.paper}
              ikonibgcolor={theme.palette.primary.main}
              />
            <Perustelu
              otsikko="SUOSITTELU"
              ikoni="thumb_up"
              kuvaus="antaa perinteisen NPS lukeman tuotteiden /palveluiden suosittelusta."
              korttibgcolor={theme.palette.background.paper}
              // korttibgcolor="#22D7C1"
              // ikonibgcolor={theme.palette.primary.main}
              // ikonibgcolor="#1f3045"
              ikonibgcolor="#d1983a"
            />
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Mita;
