//  NOTE: jos muutat näitä, muuta myös toimialat.py

export const toimialat = [
  { id: "A", label: "Maatalous, metsätalous ja kalatalous" },
  { id: "01", label: "Kasvinviljely ja kotieläintalous, riistatalous ja niihin liittyvät palvelut" },
  { id: "011", label: "Yksivuotisten kasvien viljely" },
  { id: "01110", label: "Viljakasvien (pl. riisin), palkokasvien ja öljysiemenkasvien viljely" },
  { id: "01120", label: "Riisin viljely" },
  { id: "0113", label: "Vihannesten ja melonien, juuresten ja mukulakasvien viljely" },
  { id: "01131", label: "Vihannesten viljely avomaalla (pl. peruna ja sokerijuurikas)" },
  { id: "01132", label: "Vihannesten viljely kasvihuoneessa" },
  { id: "01133", label: "Perunan viljely" },
  { id: "01134", label: "Sokerijuurikkaan viljely" },
  { id: "01140", label: "Sokeriruo’on viljely" },
  { id: "01150", label: "Tupakan viljely" },
  { id: "01160", label: "Kuitukasvien viljely" },
  { id: "0119", label: "Muu yksivuotisten ja koristekasvien viljely" },
  { id: "01191", label: "Koristekasvien viljely" },
  { id: "01199", label: "Muiden yksivuotisten kasvien viljely" },
  { id: "012", label: "Monivuotisten kasvien viljely" },
  { id: "01210", label: "Rypäleiden viljely" },
  { id: "01220", label: "Trooppisten ja subtrooppisten hedelmien viljely" },
  { id: "01230", label: "Sitrushedelmien viljely" },
  { id: "01240", label: "Omenoiden, kirsikoiden, luumujen ym. kota- ja kivihedelmien viljely" },
  { id: "01250", label: "Marjojen, pähkinöiden ja muiden puissa ja pensaissa kasvavien hedelmien viljely" },
  { id: "01260", label: "Öljyä sisältävien hedelmien viljely" },
  { id: "01270", label: "Juomakasvien viljely" },
  { id: "01280", label: "Mauste-, aromi-, rohdos- ja lääkekasvien viljely" },
  { id: "01290", label: "Muu monivuotisten kasvien viljely" },
  { id: "01300", label: "Taimien kasvatus ja muu kasvien lisääminen" },
  { id: "014", label: "Kotieläintalous" },
  { id: "01410", label: "Lypsykarjan kasvatus" },
  { id: "01420", label: "Muun nautakarjan ja puhvelien kasvatus" },
  { id: "01430", label: "Hevosten ja muiden hevoseläinten kasvatus" },
  { id: "01440", label: "Kamelien ja kamelieläinten kasvatus" },
  { id: "01450", label: "Lampaiden ja vuohien kasvatus" },
  { id: "0146", label: "Sikojen kasvatus" },
  { id: "01461", label: "Porsastuotanto" },
  { id: "01462", label: "Lihasikojen kasvatus" },
  { id: "0147", label: "Siipikarjan kasvatus" },
  { id: "01471", label: "Kananmunien tuotanto" },
  { id: "01472", label: "Broilerien tuotanto" },
  { id: "01479", label: "Muu siipikarjatalous" },
  { id: "0149", label: "Muiden eläinten kasvatus" },
  { id: "01491", label: "Turkistarhaus" },
  { id: "01492", label: "Poronhoito" },
  { id: "01499", label: "Muu eläinten hoito" },
  { id: "01500", label: "Yhdistetty kasvinviljely ja kotieläintalous (sekatilat)" },
  { id: "016", label: "Maataloutta palveleva toiminta" },
  { id: "0161", label: "Kasvinviljelyä palveleva toiminta" },
  { id: "01611", label: "Kasvinviljelyn tukipalvelut" },
  { id: "01612", label: "Maatalousmaan pitäminen viljelykelpoisena" },
  { id: "01620", label: "Kotieläintaloutta palveleva toiminta" },
  { id: "01630", label: "Sadon jatkokäsittely" },
  { id: "01640", label: "Siementen käsittely kasvinviljelyä varten" },
  { id: "01700", label: "Metsästys ja sitä palveleva toiminta" },
  { id: "02", label: "Metsätalous ja puunkorjuu" },
  { id: "02100", label: "Metsänhoito" },
  { id: "02200", label: "Puunkorjuu" },
  { id: "02300", label: "Luonnon tuotteiden keruu (pl. polttopuu)" },
  { id: "02400", label: "Metsätaloutta palveleva toiminta" },
  { id: "03", label: "Kalastus ja vesiviljely" },
  { id: "031", label: "Kalastus" },
  { id: "03110", label: "Merikalastus" },
  { id: "03120", label: "Sisävesikalastus" },
  { id: "032", label: "Vesiviljely" },
  { id: "03210", label: "Kalanviljely meressä" },
  { id: "03220", label: "Kalanviljely sisävesissä" },
  { id: "B", label: "Kaivostoiminta ja louhinta" },
  { id: "05", label: "Kivihiilen ja ruskohiilen kaivu" },
  { id: "05100", label: "Kivihiilen kaivu" },
  { id: "05200", label: "Ruskohiilen kaivu" },
  { id: "06", label: "Raakaöljyn ja maakaasun tuotanto" },
  { id: "06100", label: "Raakaöljyn tuotanto" },
  { id: "06200", label: "Maakaasun tuotanto" },
  { id: "07", label: "Metallimalmien louhinta" },
  { id: "07100", label: "Rautamalmien louhinta" },
  { id: "072", label: "Värimetallimalmien louhinta" },
  { id: "07210", label: "Uraani- ja toriummalmien louhinta" },
  { id: "07290", label: "Muiden värimetallimalmien louhinta" },
  { id: "08", label: "Muu kaivostoiminta ja louhinta" },
  { id: "081", label: "Kiven louhinta, hiekan ja saven otto" },
  { id: "0811", label: "Koriste- ja rakennuskiven, kalkkikiven, kipsin, liidun ja liuskekiven louhinta" },
  { id: "08111", label: "Koriste- ja rakennuskiven louhinta" },
  { id: "08112", label: "Kalkkikiven, kipsin, liidun ja dolomiitin louhinta" },
  { id: "08113", label: "Liuskekiven louhinta" },
  { id: "08120", label: "Soran, hiekan, saven ja kaoliinin otto" },
  { id: "089", label: "Muu mineraalien kaivu" },
  { id: "08910", label: "Kemiallisten ja lannoitemineraalien louhinta" },
  { id: "08920", label: "Turpeen nosto" },
  { id: "08930", label: "Suolan tuotanto" },
  { id: "08990", label: "Muualla luokittelematon kaivostoiminta ja louhinta" },
  { id: "09", label: "Kaivostoimintaa palveleva toiminta" },
  { id: "09100", label: "Raakaöljyn ja maakaasun tuotantoa palveleva toiminta" },
  { id: "09900", label: "Muuta kaivostoimintaa ja louhintaa palveleva toiminta" },
  { id: "C", label: "Teollisuus" },
  { id: "10", label: "Elintarvikkeiden valmistus" },
  { id: "101", label: "Teurastus, lihan säilyvyyskäsittely ja lihatuotteiden valmistus" },
  { id: "10110", label: "Teurastus ja lihan säilyvyyskäsittely (pl. siipikarja)" },
  { id: "10120", label: "Siipikarjan teurastus ja lihan säilyvyyskäsittely" },
  { id: "10130", label: "Liha- ja siipikarjatuotteiden valmistus" },
  { id: "10200", label: "Kalan, äyriäisten ja nilviäisten jalostus ja säilöntä" },
  { id: "103", label: "Hedelmien ja kasvisten jalostus ja säilöntä" },
  { id: "10310", label: "Perunoiden jalostus ja säilöntä" },
  { id: "10320", label: "Hedelmä-, marja- ja kasvismehujen valmistus" },
  { id: "10390", label: "Muu hedelmien, marjojen ja kasvisten jalostus ja säilöntä" },
  { id: "104", label: "Kasvi- ja eläinöljyjen ja -rasvojen valmistus" },
  { id: "10410", label: "Kasvi- ja eläinperäisten öljyjen ja -rasvojen valmistus (pl. ravintorasvat)" },
  { id: "10420", label: "Margariinin ja sen kaltaisten ravintorasvojen valmistus" },
  { id: "105", label: "Maitotaloustuotteiden valmistus" },
  { id: "10510", label: "Maitotaloustuotteiden ja juuston valmistus" },
  { id: "10520", label: "Jäätelön valmistus" },
  { id: "106", label: "Mylly- ja tärkkelystuotteiden valmistus" },
  { id: "10610", label: "Myllytuotteiden valmistus" },
  { id: "10620", label: "Tärkkelyksen ja tärkkelystuotteiden valmistus" },
  { id: "107", label: "Leipomotuotteiden, makaronien yms. valmistus" },
  { id: "10710", label: "Leivän valmistus; tuoreiden leivonnaisten ja kakkujen valmistus" },
  { id: "10720", label: "Näkkileivän ja keksien valmistus; säilyvien leivonnaisten ja kakkujen valmistus" },
  { id: "10730", label: "Makaronin, nuudelien, kuskusin ja vastaavien jauhotuotteiden valmistus" },
  { id: "108", label: "Muiden elintarvikkeiden valmistus" },
  { id: "10810", label: "Sokerin valmistus" },
  { id: "10820", label: "Kaakaon, suklaan ja makeisten valmistus" },
  { id: "10830", label: "Teen ja kahvin valmistus" },
  { id: "10840", label: "Mausteiden ja maustekastikkeiden valmistus" },
  { id: "10850", label: "Einesten ja valmisruokien valmistus" },
  { id: "10860", label: "Homogenoitujen ravintovalmisteiden ja dieettiruokien valmistus" },
  { id: "10890", label: "Muualla luokittelematon elintarvikkeiden valmistus" },
  { id: "109", label: "Eläinten ruokien valmistus" },
  { id: "10910", label: "Kotieläinten rehujen valmistus" },
  { id: "10920", label: "Lemmikkieläinten ruokien valmistus" },
  { id: "110", label: "Juomien valmistus" },
  { id: "11010", label: "Alkoholijuomien tislaus ja sekoittaminen; etyylialkoholin valmistus käymisteitse" },
  { id: "11020", label: "Viinin valmistus rypäleistä" },
  { id: "11030", label: "Siiderin, hedelmä- ja marjaviinien valmistus" },
  { id: "11040", label: "Muiden tislaamattomien juomien valmistus käymisteitse" },
  { id: "11050", label: "Oluen valmistus" },
  { id: "11060", label: "Maltaiden valmistus" },
  { id: "11070", label: "Virvoitusjuomien valmistus; kivennäisvesien ja muiden pullotettujen vesien tuotanto" },
  { id: "12000", label: "Tupakkatuotteiden valmistus" },
  { id: "13", label: "Tekstiilien valmistus" },
  { id: "13100", label: "Tekstiilikuitujen valmistelu ja kehruu" },
  { id: "13200", label: "Kankaiden kudonta" },
  { id: "13300", label: "Tekstiilien viimeistely" },
  { id: "139", label: "Muiden tekstiilituotteiden valmistus" },
  { id: "13910", label: "Neulosten valmistus" },
  { id: "1392", label: "Sovitettujen tekstiilituotteiden valmistus (pl. vaatteet)" },
  { id: "13921", label: "Sisustustekstiilien valmistus" },
  { id: "13922", label: "Tavaranpeitteiden, purjeiden ja muiden sovitettujen tekstiilituotteiden valmistus" },
  { id: "13930", label: "Mattojen valmistus" },
  { id: "13940", label: "Purjelankojen, nuoran, sidelangan ja verkkojen valmistus" },
  { id: "13950", label: "Kuitukankaiden ja kuitukangastuotteiden valmistus (pl. vaatteet)" },
  { id: "13960", label: "Teknisten ja teollisuustekstiilien valmistus" },
  { id: "13990", label: "Muualla luokittelematon tekstiilituotteiden valmistus" },
  { id: "14", label: "Vaatteiden valmistus" },
  { id: "141", label: "Vaatteiden valmistus (pl. turkisvaatteet)" },
  { id: "14110", label: "Nahkavaatteiden valmistus" },
  { id: "14120", label: "Työvaatteiden valmistus" },
  { id: "14130", label: "Muu takkien, pukujen, housujen, hameiden yms. valmistus" },
  { id: "14140", label: "Alusvaatteiden valmistus" },
  { id: "14190", label: "Muiden vaatteiden ja asusteiden valmistus" },
  { id: "14200", label: "Turkisvaatteiden ja -tuotteiden valmistus" },
  { id: "143", label: "Neulevaatteiden ja sukkien valmistus" },
  { id: "14310", label: "Sukkien ja sukkahousujen valmistus" },
  { id: "14390", label: "Muiden neulevaatteiden valmistus" },
  { id: "15", label: "Nahan ja nahkatuotteiden valmistus" },
  {
    id: "151",
    label:
      "Nahan parkitseminen ja muokkaus; matka- ja käsilaukkujen, satuloiden ja valjaiden valmistus; turkisten muokkaus ja värjäys",
  },
  { id: "15110", label: "Turkisten ja nahan muokkaus ja värjäys" },
  { id: "15120", label: "Matka-, käsi- ym. laukkujen, satuloiden ja valjaiden valmistus" },
  { id: "15200", label: "Jalkineiden valmistus" },
  {
    id: "16",
    label: "Sahatavaran sekä puu- ja korkkituotteiden valmistus (pl. huonekalut); olki- ja punontatuotteiden valmistus",
  },
  { id: "16100", label: "Puun sahaus, höyläys ja kyllästys" },
  { id: "162", label: "Puu-, korkki-, olki- ja punontatuotteiden valmistus" },
  { id: "1621", label: "Vaneriviilun ja puupaneelien valmistus" },
  { id: "16211", label: "Vanerin ja vaneriviilun valmistus" },
  { id: "16212", label: "Lastulevyn valmistus" },
  { id: "16213", label: "Kuitulevyn valmistus" },
  { id: "16220", label: "Asennettavien parkettilevyjen valmistus" },
  { id: "1623", label: "Muiden rakennuspuusepäntuotteiden valmistus" },
  { id: "16231", label: "Puutalojen valmistus" },
  { id: "16239", label: "Muu rakennuspuusepäntuotteiden valmistus" },
  { id: "16240", label: "Puupakkausten valmistus" },
  { id: "16290", label: "Muiden puutuotteiden valmistus; korkki-, olki- ja punontatuotteiden valmistus" },
  { id: "17", label: "Paperin, paperi- ja kartonkituotteiden valmistus" },
  { id: "171", label: "Massan, paperin, kartongin ja pahvin valmistus" },
  { id: "17110", label: "Massan valmistus" },
  { id: "17120", label: "Paperin, kartongin ja pahvin valmistus" },
  { id: "172", label: "Paperi-, kartonki- ja pahvituotteiden valmistus" },
  { id: "1721", label: "Aaltopaperin ja -pahvin sekä paperi-, kartonki- ja pahvipakkausten valmistus" },
  { id: "17211", label: "Paperisäkkien ja -pussien valmistus" },
  { id: "17212", label: "Aaltopahvin sekä paperi- ja kartonkipakkausten valmistus" },
  { id: "17220", label: "Paperisten talous- ja hygieniatarvikkeiden valmistus" },
  { id: "17230", label: "Paperikauppatavaroiden valmistus" },
  { id: "17240", label: "Tapettien valmistus" },
  { id: "17290", label: "Muiden paperi-, kartonki- ja pahvituotteiden valmistus" },
  { id: "18", label: "Painaminen ja tallenteiden jäljentäminen" },
  { id: "181", label: "Painaminen ja siihen liittyvät palvelut" },
  { id: "18110", label: "Sanomalehtien painaminen" },
  { id: "18120", label: "Muu painaminen" },
  { id: "18130", label: "Painamista ja julkaisemista edeltävät palvelut" },
  { id: "18140", label: "Sidonta ja siihen liittyvät palvelut" },
  { id: "18200", label: "Ääni-, kuva- ja atk-tallenteiden tuotanto" },
  { id: "19", label: "Koksin ja jalostettujen öljytuotteiden valmistus" },
  { id: "19100", label: "Koksituotteiden valmistus" },
  { id: "19200", label: "Jalostettujen öljytuotteiden valmistus" },
  { id: "20", label: "Kemikaalien ja kemiallisten tuotteiden valmistus" },
  {
    id: "201",
    label:
      "Peruskemikaalien, lannoitteiden ja typpiyhdisteiden, muoviaineiden ja synteettisen kumiraaka-aineen valmistus",
  },
  { id: "20110", label: "Teollisuuskaasujen valmistus" },
  { id: "20120", label: "Värien ja pigmenttien valmistus" },
  { id: "20130", label: "Muiden epäorgaanisten peruskemikaalien valmistus" },
  { id: "20140", label: "Muiden orgaanisten peruskemikaalien valmistus" },
  { id: "20150", label: "Lannoitteiden ja typpiyhdisteiden valmistus" },
  { id: "20160", label: "Muoviaineiden valmistus" },
  { id: "20170", label: "Synteettisen kumiraaka-aineen valmistus" },
  { id: "20200", label: "Torjunta-aineiden ja muiden maatalouskemikaalien valmistus" },
  { id: "20300", label: "Maalien, lakan, painovärien yms. valmistus" },
  { id: "204", label: "Saippuan, pesu-, puhdistus- ja kiillotusaineiden; hajuvesien ja hygieniatuotteiden valmistus" },
  { id: "20410", label: "Saippuan, pesu-, puhdistus- ja kiillotusaineiden valmistus" },
  { id: "20420", label: "Hajuvesien ja hygieniatuotteiden valmistus" },
  { id: "205", label: "Muiden kemiallisten tuotteiden valmistus" },
  { id: "20510", label: "Räjähdysaineiden valmistus" },
  { id: "20520", label: "Liimojen valmistus" },
  { id: "20530", label: "Eteeristen öljyjen valmistus" },
  { id: "20590", label: "Muualla luokittelematon kemiallisten tuotteiden valmistus" },
  { id: "20600", label: "Tekokuitujen valmistus" },
  { id: "21", label: "Lääkeaineiden ja lääkkeiden valmistus" },
  { id: "21100", label: "Lääkeaineiden valmistus" },
  { id: "21200", label: "Lääkkeiden ja muiden lääkevalmisteiden valmistus" },
  { id: "22", label: "Kumi- ja muovituotteiden valmistus" },
  { id: "221", label: "Kumituotteiden valmistus" },
  { id: "22110", label: "Renkaiden valmistus ja uudelleenpinnoitus" },
  { id: "22190", label: "Muiden kumituotteiden valmistus" },
  { id: "222", label: "Muovituotteiden valmistus" },
  { id: "22210", label: "Muovilevyjen, -kalvojen, -putkien ja -profiilien valmistus" },
  { id: "22220", label: "Muovipakkausten valmistus" },
  { id: "22230", label: "Rakennusmuovien valmistus" },
  { id: "22290", label: "Muiden muovituotteiden valmistus" },
  { id: "23", label: "Muiden ei-metallisten mineraalituotteiden valmistus" },
  { id: "231", label: "Lasin ja lasituotteiden valmistus" },
  { id: "23110", label: "Tasolasin valmistus" },
  { id: "23120", label: "Tasolasin muotoilu ja muokkaus" },
  { id: "23130", label: "Onton lasitavaran valmistus" },
  { id: "23140", label: "Lasikuitujen valmistus" },
  { id: "23190", label: "Muu lasin valmistus ja muokkaus ml. tekniset lasituotteet" },
  { id: "23200", label: "Tulenkestävien keraamisten tuotteiden valmistus" },
  { id: "233", label: "Keraamisten rakennusaineiden valmistus" },
  { id: "23310", label: "Keraamisten tiilien ja laattojen valmistus" },
  { id: "23320", label: "Poltettujen tiilien ja muun rakennuskeramiikan valmistus" },
  { id: "234", label: "Muiden posliini- ja keramiikkatuotteiden valmistus" },
  { id: "23410", label: "Keraamisten talous- ja koriste-esineiden valmistus" },
  { id: "23420", label: "Keraamisten saniteettikalusteiden valmistus" },
  { id: "23430", label: "Keraamisten eristystuotteiden valmistus" },
  { id: "23440", label: "Muiden teknisten keraamisten tuotteiden valmistus" },
  { id: "23490", label: "Muiden keramiikkatuotteiden valmistus" },
  { id: "235", label: "Sementin, kalkin ja kipsin valmistus" },
  { id: "23510", label: "Sementin valmistus" },
  { id: "23520", label: "Kalkin ja kipsin valmistus" },
  { id: "236", label: "Betoni-, kipsi- ja sementtituotteiden valmistus" },
  { id: "23610", label: "Betonituotteiden valmistus rakennustarkoituksiin" },
  { id: "23620", label: "Kipsituotteiden valmistus rakennustarkoituksiin" },
  { id: "23630", label: "Valmisbetonin valmistus" },
  { id: "23640", label: "Muurauslaastin valmistus" },
  { id: "23650", label: "Kuitusementin valmistus" },
  { id: "23690", label: "Muiden betoni-, kipsi- ja sementtituotteiden valmistus" },
  { id: "23700", label: "Kiven leikkaaminen, muotoilu ja viimeistely" },
  { id: "239", label: "Hiontatuotteiden ja muualla luokittelemattomien ei-metallisten mineraalituotteiden valmistus" },
  { id: "23910", label: "Hiontatuotteiden valmistus" },
  { id: "23990", label: "Muualla luokittelemattomien ei-metallisten mineraalituotteiden valmistus" },
  { id: "24", label: "Metallien jalostus" },
  { id: "24100", label: "Raudan, teräksen ja rautaseosten valmistus" },
  { id: "24200", label: "Putkien, profiiliputkien ja niihin liittyvien tarvikkeiden valmistus teräksestä" },
  { id: "243", label: "Muu teräksen jalostus" },
  { id: "24310", label: "Raudan ja teräksen kylmävetäminen" },
  { id: "24320", label: "Rainan kylmävalssaus" },
  { id: "24330", label: "Kylmämuovaus tai kylmätaitto" },
  { id: "24340", label: "Teräslangan veto" },
  { id: "244", label: "Jalometallien ja muiden värimetallien valmistus" },
  { id: "24410", label: "Jalometallien valmistus" },
  { id: "24420", label: "Alumiinin valmistus" },
  { id: "24430", label: "Lyijyn, sinkin ja tinan valmistus" },
  { id: "24440", label: "Kuparin valmistus" },
  { id: "24450", label: "Muiden värimetallien valmistus" },
  { id: "24460", label: "Ydinpolttoaineen valmistus" },
  { id: "245", label: "Metallien valu" },
  { id: "24510", label: "Raudan valu" },
  { id: "24520", label: "Teräksen valu" },
  { id: "24530", label: "Kevytmetallien valu" },
  { id: "24540", label: "Muiden värimetallien valu" },
  { id: "25", label: "Metallituotteiden valmistus (pl. koneet ja laitteet)" },
  { id: "251", label: "Metallirakenteiden valmistus" },
  { id: "25110", label: "Metallirakenteiden ja niiden osien valmistus" },
  { id: "25120", label: "Metalliovien ja -ikkunoiden valmistus" },
  { id: "252", label: "Metallisäiliöiden ja -altaiden yms. valmistus" },
  { id: "25210", label: "Keskuslämmityspatterien ja kuumavesivaraajien valmistus" },
  { id: "25290", label: "Muiden metallisäiliöiden ja -altaiden yms. valmistus" },
  { id: "25300", label: "Höyrykattiloiden valmistus (pl. keskuslämmityslaitteet)" },
  { id: "25400", label: "Aseiden ja ammusten valmistus" },
  { id: "25500", label: "Metallin takominen, puristaminen, meistäminen ja valssaus; jauhemetallurgia" },
  { id: "256", label: "Metallien käsittely, päällystäminen ja työstö" },
  { id: "25610", label: "Metallien käsittely ja päällystäminen" },
  { id: "25620", label: "Metallien työstö" },
  { id: "257", label: "Ruokailu- ja leikkuuvälineiden yms. sekä työkalujen ja rautatavaran valmistus" },
  { id: "25710", label: "Ruokailu- ja leikkuuvälineiden yms. valmistus" },
  { id: "25720", label: "Lukkojen ja saranoiden valmistus" },
  { id: "25730", label: "Työkalujen valmistus" },
  { id: "259", label: "Muu metallituotteiden valmistus" },
  { id: "25910", label: "Metallipakkausten ja -astioiden valmistus" },
  { id: "25920", label: "Kevytmetallipakkausten valmistus" },
  { id: "25930", label: "Metallilankatuotteiden, ketjujen ja jousien valmistus" },
  { id: "25940", label: "Kiinnittimien ja ruuvituotteiden valmistus" },
  { id: "25990", label: "Muiden metallituotteiden valmistus" },
  { id: "26", label: "Tietokoneiden sekä elektronisten ja optisten tuotteiden valmistus" },
  { id: "261", label: "Elektronisten komponenttien ja piirilevyjen valmistus" },
  { id: "26110", label: "Elektronisten komponenttien valmistus" },
  { id: "26120", label: "Kalustettujen piirilevyjen valmistus" },
  { id: "26200", label: "Tietokoneiden ja niiden oheislaitteiden valmistus" },
  { id: "26300", label: "Viestintälaitteiden valmistus" },
  { id: "26400", label: "Viihde-elektroniikan valmistus" },
  { id: "265", label: "Mittaus-, testaus- ja navigointivälineiden ja -laitteiden valmistus; kellot" },
  { id: "26510", label: "Mittaus-, testaus- ja navigointivälineiden ja -laitteiden valmistus" },
  { id: "26520", label: "Kellojen valmistus" },
  { id: "26600", label: "Säteilylaitteiden sekä elektronisten lääkintä- ja terapialaitteiden valmistus" },
  { id: "26700", label: "Optisten instrumenttien ja valokuvausvälineiden valmistus" },
  { id: "26800", label: "Tallennevälineiden valmistus" },
  { id: "27", label: "Sähkölaitteiden valmistus" },
  {
    id: "271",
    label: "Sähkömoottorien, generaattorien, muuntajien sekä sähkönjakelu- ja valvontalaitteiden valmistus",
  },
  { id: "27110", label: "Sähkömoottorien, generaattorien ja muuntajien valmistus" },
  { id: "27120", label: "Sähkönjakelu- ja valvontalaitteiden valmistus" },
  { id: "27200", label: "Paristojen ja akkujen valmistus" },
  { id: "273", label: "Sähköjohtojen ja kytkentälaitteiden valmistus" },
  { id: "27310", label: "Optisten kuitukaapelien valmistus" },
  { id: "27320", label: "Muiden elektronisten ja sähköjohtojen sekä -kaapelien valmistus" },
  { id: "27330", label: "Kytkentälaitteiden valmistus" },
  { id: "27400", label: "Sähkölamppujen ja valaisimien valmistus" },
  { id: "275", label: "Kodinkoneiden valmistus" },
  { id: "27510", label: "Sähköisten kodinkoneiden valmistus" },
  { id: "27520", label: "Sähköistämättömien kodinkoneiden valmistus" },
  { id: "27900", label: "Muiden sähkölaitteiden valmistus" },
  { id: "28", label: "Muiden koneiden ja laitteiden valmistus" },
  { id: "281", label: "Yleiskäyttöön tarkoitettujen voimakoneiden valmistus" },
  { id: "28110", label: "Moottorien ja turbiinien valmistus (pl. lentokoneiden ja ajoneuvojen moottorit)" },
  { id: "28120", label: "Hydraulisten voimalaitteiden valmistus" },
  { id: "28130", label: "Pumppujen ja kompressoreiden valmistus" },
  { id: "28140", label: "Muiden hanojen ja venttiilien valmistus" },
  { id: "28150", label: "Laakereiden, hammaspyörien, vaihteisto- ja ohjauselementtien valmistus" },
  { id: "282", label: "Muiden yleiskäyttöön tarkoitettujen koneiden valmistus" },
  { id: "28210", label: "Teollisuusuunien, lämmitysjärjestelmien ja tulipesäpolttimien valmistus" },
  { id: "28220", label: "Nosto- ja siirtolaitteiden valmistus" },
  { id: "28230", label: "Konttorikoneiden ja -laitteiden valmistus (pl. tietokoneet ja niiden oheislaitteet)" },
  { id: "28240", label: "Voimakäyttöisten käsityökalujen valmistus" },
  { id: "28250", label: "Muuhun kuin kotitalouskäyttöön tarkoitettujen jäähdytys- ja tuuletuslaitteiden valmistus" },
  { id: "28290", label: "Muualla luokittelematon yleiskäyttöön tarkoitettujen koneiden valmistus" },
  { id: "28300", label: "Maa- ja metsätalouskoneiden valmistus" },
  { id: "284", label: "Metallin työstökoneiden ja konetyökalujen valmistus" },
  { id: "28410", label: "Metallin työstökoneiden valmistus" },
  { id: "28490", label: "Muiden konetyökalujen valmistus" },
  { id: "289", label: "Muiden erikoiskoneiden valmistus" },
  { id: "28910", label: "Metallinjalostuskoneiden valmistus" },
  { id: "28920", label: "Kaivos-, louhinta- ja rakennuskoneiden valmistus" },
  { id: "28930", label: "Elintarvike-, juoma- ja tupakkateollisuuden koneiden valmistus" },
  { id: "28940", label: "Tekstiili-, vaate- ja nahkateollisuuden koneiden valmistus" },
  { id: "28950", label: "Paperi-, kartonki- ja pahviteollisuuden koneiden valmistus" },
  { id: "28960", label: "Muovi- ja kumiteollisuuden koneiden valmistus" },
  { id: "28990", label: "Muualla luokittelematon erikoiskoneiden valmistus" },
  { id: "29", label: "Moottoriajoneuvojen, perävaunujen ja puoliperävaunujen valmistus" },
  { id: "29100", label: "Moottoriajoneuvojen valmistus" },
  { id: "29200", label: "Moottoriajoneuvojen korien valmistus; perävaunujen ja puoliperävaunujen valmistus" },
  { id: "293", label: "Osien ja tarvikkeiden valmistus moottoriajoneuvoihin" },
  { id: "29310", label: "Sähkö- ja elektroniikkalaitteiden valmistus moottoriajoneuvoihin" },
  { id: "29320", label: "Muiden osien ja tarvikkeiden valmistus moottoriajoneuvoihin" },
  { id: "30", label: "Muiden kulkuneuvojen valmistus" },
  { id: "301", label: "Laivojen ja veneiden rakentaminen" },
  { id: "30110", label: "Laivojen ja kelluvien rakenteiden rakentaminen" },
  { id: "30120", label: "Huvi- ja urheiluveneiden rakentaminen" },
  { id: "30200", label: "Raideliikenteen kulkuneuvojen valmistus" },
  { id: "30300", label: "Ilma- ja avaruusalusten ja niihin liittyvien koneiden valmistus" },
  { id: "30400", label: "Taisteluajoneuvojen valmistus" },
  { id: "309", label: "Muualla luokittelematon kulkuneuvojen valmistus" },
  { id: "30910", label: "Moottoripyörien valmistus" },
  { id: "30920", label: "Polkupyörien ja invalidiajoneuvojen valmistus" },
  { id: "30990", label: "Muiden muualla luokittelemattomien kulkuneuvojen valmistus" },
  { id: "310", label: "Huonekalujen valmistus" },
  { id: "31010", label: "Konttori- ja myymäläkalusteiden valmistus" },
  { id: "31020", label: "Keittiökalusteiden valmistus" },
  { id: "31030", label: "Patjojen valmistus" },
  { id: "31090", label: "Muiden huonekalujen valmistus" },
  { id: "32", label: "Muu valmistus" },
  { id: "321", label: "Korujen, kultasepäntuotteiden ja muiden vastaavien tuotteiden valmistus" },
  { id: "32110", label: "Kolikoiden ja mitalien valmistus" },
  { id: "32120", label: "Jalokivikorujen ja muiden kultasepäntuotteiden valmistus" },
  { id: "32130", label: "Jäljitelmäkorujen ja muiden vastaavien tuotteiden valmistus" },
  { id: "32200", label: "Soitinten valmistus" },
  { id: "32300", label: "Urheiluvälineiden valmistus" },
  { id: "32400", label: "Pelien ja leikkikalujen valmistus" },
  { id: "3250", label: "Lääkintä- ja hammaslääkintäinstrumenttien ja -tarvikkeiden valmistus" },
  { id: "32501", label: "Lääkintä- ja hammaslääkintäinstrumenttien ja -tarvikkeiden valmistus (pl. hammasproteesit)" },
  { id: "32502", label: "Hammasproteesien, keinohampaiden ym. valmistus" },
  { id: "329", label: "Muualla luokittelematon valmistus" },
  { id: "32910", label: "Luutien ja harjojen valmistus" },
  { id: "3299", label: "Muu muualla luokittelematon valmistus" },
  { id: "32991", label: "Turvavarusteiden valmistus" },
  { id: "32999", label: "Muu muualla luokittelemattomien tuotteiden valmistus" },
  { id: "33", label: "Koneiden ja laitteiden korjaus, huolto ja asennus" },
  { id: "331", label: "Metallituotteiden, teollisuuden koneiden ja laitteiden korjaus ja huolto" },
  { id: "33110", label: "Metallituotteiden korjaus ja huolto" },
  { id: "3312", label: "Teollisuuden koneiden ja laitteiden korjaus ja huolto" },
  { id: "33121", label: "Yleiskäyttöön tarkoitettujen koneiden korjaus ja huolto" },
  { id: "33122", label: "Maa- ja metsätalouskoneiden korjaus ja huolto" },
  { id: "33123", label: "Metallintyöstökoneiden ja muiden konetyökalujen korjaus ja huolto" },
  { id: "33129", label: "Muiden erikoiskoneiden korjaus ja huolto" },
  { id: "33130", label: "Elektronisten ja optisten laitteiden korjaus ja huolto" },
  { id: "33140", label: "Sähkölaitteiden korjaus ja huolto" },
  { id: "33150", label: "Laivojen ja veneiden korjaus ja huolto" },
  { id: "33160", label: "Ilma- ja avaruusalusten korjaus ja huolto" },
  { id: "33170", label: "Muiden kulkuneuvojen korjaus ja huolto" },
  { id: "33190", label: "Muiden laitteiden korjaus ja huolto" },
  { id: "33200", label: "Teollisuuden koneiden ja laitteiden ym. asennus" },
  { id: "35", label: "Sähkö-, kaasu- ja lämpöhuolto, jäähdytysliiketoiminta" },
  { id: "351", label: "Sähkövoiman tuotanto, siirto ja jakelu" },
  { id: "3511", label: "Sähkön tuotanto" },
  { id: "35111", label: "Sähkön tuotanto vesi- ja tuulivoimalla" },
  { id: "35112", label: "Sähkön erillistuotanto lämpövoimalla" },
  { id: "35113", label: "Sähkön ja kaukolämmön yhteistuotanto" },
  { id: "35114", label: "Sähkön tuotanto ydinvoimalla" },
  { id: "35115", label: "Teollisuutta palveleva sähkön ja lämmön tuotanto" },
  { id: "35120", label: "Sähkön siirto" },
  { id: "35130", label: "Sähkön jakelu" },
  { id: "35140", label: "Sähkön kauppa" },
  { id: "352", label: "Kaasun tuotanto; kaasumaisten polttoaineiden jakelu putkiverkossa" },
  { id: "35210", label: "Kaasun tuotanto" },
  { id: "35220", label: "Kaasumaisten polttoaineiden jakelu putkiverkossa" },
  { id: "35230", label: "Kaasun kauppa putkiverkossa" },
  { id: "3530", label: "Lämmön ja kylmän tuotanto ja jakelu" },
  { id: "35301", label: "Kaukolämmön ja -kylmän erillistuotanto ja jakelu" },
  { id: "35302", label: "Teollisuutta palveleva lämmön ja kylmän erillistuotanto" },
  { id: "E", label: "Vesihuolto, viemäri- ja jätevesihuolto, jätehuolto ja muu ympäristön puhtaanapito" },
  { id: "36000", label: "Veden otto, puhdistus ja jakelu" },
  { id: "37000", label: "Viemäri- ja jätevesihuolto" },
  { id: "38", label: "Jätteen keruu, käsittely ja loppusijoitus; materiaalien kierrätys" },
  { id: "381", label: "Jätteen keruu" },
  { id: "38110", label: "Tavanomaisen jätteen keruu" },
  { id: "38120", label: "Ongelmajätteen keruu" },
  { id: "382", label: "Jätteen käsittely ja loppusijoitus" },
  { id: "38210", label: "Tavanomaisen jätteen käsittely ja loppusijoitus" },
  { id: "38220", label: "Ongelmajätteen käsittely, loppusijoitus ja hävittäminen" },
  { id: "383", label: "Materiaalien kierrätys" },
  { id: "38310", label: "Romujen purkaminen" },
  { id: "38320", label: "Lajiteltujen materiaalien kierrätys" },
  { id: "39000", label: "Maaperän ja vesistöjen kunnostus ja muut ympäristönhuoltopalvelut" },
  { id: "F", label: "Rakentaminen" },
  { id: "41", label: "Talonrakentaminen" },
  { id: "41100", label: "Rakennuttaminen ja rakennushankkeiden kehittäminen" },
  { id: "41200", label: "Asuin- ja muiden rakennusten rakentaminen" },
  { id: "42", label: "Maa- ja vesirakentaminen" },
  { id: "421", label: "Teiden ja rautateiden rakentaminen" },
  { id: "42110", label: "Teiden ja moottoriteiden rakentaminen" },
  { id: "42120", label: "Rautateiden ja metrolinjojen rakentaminen" },
  { id: "42130", label: "Siltojen ja tunneleiden rakentaminen" },
  { id: "422", label: "Yleisten jakeluverkkojen rakentaminen" },
  { id: "42210", label: "Yleisten jakeluverkkojen rakentaminen nestemäisiä ja kaasumaisia aineita varten" },
  { id: "42220", label: "Sähkö- ja tietoliikenneverkkojen rakentaminen" },
  { id: "429", label: "Muu maa- ja vesirakentaminen" },
  { id: "42910", label: "Vesirakentaminen" },
  { id: "4299", label: "Muualla luokittelematon maa- ja vesirakentaminen" },
  { id: "42991", label: "Maa- ja vesirakennushankkeiden kehittäminen ja rakennuttaminen" },
  { id: "42999", label: "Muu muualla luokittelematon maa- ja vesirakentaminen" },
  { id: "43", label: "Erikoistunut rakennustoiminta" },
  { id: "431", label: "Rakennusten ja rakennelmien purku ja rakennuspaikan valmistelutyöt" },
  { id: "43110", label: "Rakennusten ja rakennelmien purku" },
  { id: "43120", label: "Rakennuspaikan valmistelutyöt" },
  { id: "43130", label: "Koeporaus" },
  { id: "432", label: "Sähkö-, vesijohto- ja muu rakennusasennus" },
  { id: "43210", label: "Sähköasennus" },
  { id: "43220", label: "Lämpö-, vesijohto- ja ilmastointiasennus" },
  { id: "4329", label: "Muu rakennusasennus" },
  { id: "43291", label: "Lämpö-, ääni- ja tärinäeristeiden asennus" },
  { id: "43292", label: "Hissien ja liukuportaiden asennus" },
  { id: "43299", label: "Muualla luokittelematon rakennusasennus" },
  { id: "433", label: "Rakennusten ja rakennelmien viimeistely" },
  { id: "43310", label: "Rappaus" },
  { id: "43320", label: "Rakennuspuusepän asennustyöt" },
  { id: "43330", label: "Lattianpäällystys ja seinien verhoilu" },
  { id: "4334", label: "Maalaus ja lasitus" },
  { id: "43341", label: "Maalaus" },
  { id: "43342", label: "Lasitus" },
  { id: "43390", label: "Muu rakennusten viimeistely" },
  { id: "439", label: "Muu erikoistunut rakennustoiminta" },
  { id: "43910", label: "Kattorakenteiden asennus ja kattaminen" },
  { id: "4399", label: "Muualla luokittelematon erikoistunut rakennustoiminta" },
  { id: "43991", label: "Rakennuskonevuokraus käyttäjineen" },
  { id: "43999", label: "Muu muualla luokittelematon erikoistunut rakennustoiminta" },
  { id: "G", label: "Tukku- ja vähittäiskauppa; moottoriajoneuvojen ja moottoripyörien korjaus" },
  { id: "45", label: "Moottoriajoneuvojen ja moottoripyörien tukku- ja vähittäiskauppa sekä korjaus" },
  { id: "451", label: "Moottoriajoneuvojen kauppa" },
  { id: "4511", label: "Henkilöautojen ja kevyiden moottoriajoneuvojen kauppa" },
  { id: "45111", label: "Henkilöautojen ja kevyiden moottoriajoneuvojen tukkukauppa" },
  { id: "45112", label: "Henkilöautojen ja kevyiden moottoriajoneuvojen vähittäiskauppa" },
  { id: "4519", label: "Muiden moottoriajoneuvojen myynti" },
  { id: "45191", label: "Kuorma-autojen ja muiden raskaiden moottoriajoneuvojen tukkukauppa" },
  { id: "45192", label: "Matkailuvaunujen ja muualla luokittelemattomien moottoriajoneuvojen vähittäiskauppa" },
  { id: "4520", label: "Moottoriajoneuvojen huolto ja korjaus (pl. moottoripyörät)" },
  { id: "45201", label: "Moottoriajoneuvojen huolto ja korjaus (pl. renkaat)" },
  { id: "45202", label: "Renkaiden korjaus" },
  { id: "453", label: "Moottoriajoneuvojen osien ja varusteiden kauppa" },
  { id: "4531", label: "Moottoriajoneuvojen osien ja varusteiden tukkukauppa" },
  { id: "45311", label: "Moottoriajoneuvojen osien ja varusteiden tukkukauppa (pl. renkaat)" },
  { id: "45312", label: "Renkaiden tukkukauppa" },
  { id: "4532", label: "Moottoriajoneuvojen osien ja varusteiden vähittäiskauppa" },
  { id: "45321", label: "Moottoriajoneuvojen osien ja varusteiden vähittäiskauppa (pl. renkaat)" },
  { id: "45322", label: "Renkaiden vähittäiskauppa" },
  { id: "4540", label: "Moottoripyörien sekä niiden osien ja varusteiden myynti, huolto ja korjaus" },
  { id: "45401", label: "Moottoripyörien sekä niiden osien ja varusteiden tukkukauppa" },
  { id: "45402", label: "Moottoripyörien sekä niiden osien ja varusteiden vähittäiskauppa" },
  { id: "45403", label: "Moottoripyörien huolto ja korjaus" },
  { id: "46", label: "Tukkukauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa)" },
  { id: "461", label: "Agentuuritoiminta" },
  {
    id: "46110",
    label:
      "Maatalousraaka-aineiden, elävien eläinten, tekstiiliraaka-aineiden sekä puolivalmisteiden agentuuritoiminta",
  },
  { id: "46120", label: "Polttoaineiden, malmien, metallien ja teollisuuskemikaalien agentuuritoiminta" },
  { id: "46130", label: "Puutavaran ja rakennusmateriaalien agentuuritoiminta" },
  { id: "46140", label: "Koneiden ja laitteiden agentuuritoiminta" },
  { id: "46150", label: "Huonekalujen, taloustavaroiden ja rautakauppatavaroiden agentuuritoiminta" },
  { id: "46160", label: "Tekstiilien, vaatteiden, jalkineiden ja nahkavalmisteiden agentuuritoiminta" },
  { id: "46170", label: "Elintarvikkeiden, juomien ja tupakan agentuuritoiminta" },
  { id: "4618", label: "Muu erikoistunut agentuuritoiminta" },
  { id: "46181", label: "Paperialan agentuuritoiminta" },
  { id: "46189", label: "Muualla luokittelematon erikoistunut agentuuritoiminta" },
  { id: "46190", label: "Yleisagentuuritoiminta" },
  { id: "462", label: "Maatalousperäisten raaka-aineiden ja elävien eläinten tukkukauppa" },
  { id: "46210", label: "Viljan, raakatupakan, siementen ja eläinrehujen tukkukauppa" },
  { id: "46220", label: "Kukkien ja taimien tukkukauppa" },
  { id: "46230", label: "Elävien eläinten tukkukauppa" },
  { id: "46240", label: "Turkisten ja nahkojen tukkukauppa" },
  { id: "463", label: "Elintarvikkeiden, juomien ja tupakan tukkukauppa" },
  { id: "46310", label: "Juures-, vihannes- marja- ja hedelmätukkukauppa" },
  { id: "46320", label: "Lihan ja lihatuotteiden tukkukauppa" },
  { id: "4633", label: "Maitotaloustuotteiden, munien sekä ravintoöljyjen ja -rasvojen tukkukauppa" },
  { id: "46331", label: "Maitotaloustuotteiden, ravintoöljyjen ja -rasvojen tukkukauppa" },
  { id: "46332", label: "Munatukkukauppa" },
  { id: "46340", label: "Alkoholi- ja muiden juomien tukkukauppa" },
  { id: "46350", label: "Tupakkatuotteiden tukkukauppa" },
  { id: "46360", label: "Sokerin, suklaan, makeisten ja leipomotuotteiden tukkukauppa" },
  { id: "46370", label: "Kahvin, teen, kaakaon ja mausteiden tukkukauppa" },
  { id: "4638", label: "Kalan, luontaistuotteiden ja muiden elintarvikkeiden tukkukauppa" },
  { id: "46381", label: "Kalatukkukauppa" },
  { id: "46382", label: "Luontaistuotteiden tukkukauppa" },
  { id: "46383", label: "Lemmikkieläinten ruokien tukkukauppa" },
  { id: "46389", label: "Muualla luokittelematon elintarvikkeiden tukkukauppa" },
  { id: "46390", label: "Elintarvikkeiden, juomien ja tupakan yleistukkukauppa" },
  { id: "464", label: "Taloustavaroiden tukkukauppa" },
  { id: "4641", label: "Tekstiilien tukkukauppa" },
  { id: "46411", label: "Kangas- ja lankatukkukauppa" },
  { id: "46412", label: "Tekstiilivalmisteiden tukkukauppa" },
  { id: "4642", label: "Vaatteiden ja jalkineiden tukkukauppa" },
  { id: "46421", label: "Vaatteiden tukkukauppa" },
  { id: "46422", label: "Jalkineiden tukkukauppa" },
  { id: "4643", label: "Kodinkoneiden tukkukauppa" },
  { id: "46431", label: "Kodinkoneiden ja kodin sähkölaitteiden tukkukauppa" },
  { id: "46432", label: "Viihde-elektroniikan tukkukauppa" },
  { id: "46433", label: "Valokuvausvälineiden ja -tarvikkeiden tukkukauppa" },
  { id: "46434", label: "Optisen alan tukkukauppa" },
  { id: "4644", label: "Posliini-, lasi- ja muiden taloustavaroiden sekä puhdistusaineiden tukkukauppa" },
  { id: "46441", label: "Taloustavaroiden ja -tarvikkeiden tukkukauppa" },
  { id: "46442", label: "Puhdistusaineiden tukkukauppa" },
  { id: "46450", label: "Hajuvesien ja kosmetiikan tukkukauppa" },
  { id: "4646", label: "Farmaseuttisten tuotteiden tukkukauppa" },
  { id: "46461", label: "Lääketukkukauppa" },
  { id: "46462", label: "Laboratorio- ja sairaanhoitovälineiden tukkukauppa" },
  { id: "46470", label: "Huonekalujen, mattojen ja valaisimien tukkukauppa" },
  { id: "46480", label: "Kellojen ja korujen tukkukauppa" },
  { id: "4649", label: "Muiden taloustavaroiden tukkukauppa" },
  { id: "46491", label: "Paperi- ja toimistotarvikkeiden tukkukauppa" },
  { id: "46492", label: "Kirjatukkukauppa" },
  { id: "46493", label: "Urheilualan tukkukauppa" },
  { id: "46494", label: "Musiikkitarvikkeiden tukkukauppa" },
  { id: "46495", label: "Veneiden ja veneilytarvikkeiden tukkukauppa" },
  { id: "46496", label: "Lelujen ja pelien tukkukauppa" },
  { id: "46499", label: "Muu kotitaloustavaroiden tukkukauppa" },
  { id: "465", label: "Tieto- ja viestintäteknisten laitteiden tukkukauppa" },
  { id: "46510", label: "Tietokoneiden, oheislaitteiden ja ohjelmistojen tukkukauppa" },
  { id: "4652", label: "Elektroniikka- ja viestintälaitteiden ja osien tukkukauppa" },
  { id: "46521", label: "Viestintälaitteiden tukkukauppa" },
  { id: "46522", label: "Elektronisten komponenttien tukkukauppa" },
  { id: "46610", label: "Maa- ja metsätalouskoneiden ja -tarvikkeiden tukkukauppa ml. traktorit" },
  { id: "46620", label: "Työstökoneiden tukkukauppa" },
  { id: "46630", label: "Kaivos- ja rakennuskoneiden tukkukauppa" },
  { id: "46640", label: "Tekstiiliteollisuuden koneiden sekä ompelu- ja kutomakoneiden tukkukauppa" },
  { id: "46650", label: "Toimitilakalusteiden tukkukauppa" },
  { id: "46660", label: "Muiden konttorikoneiden ja -laitteiden tukkukauppa" },
  { id: "4669", label: "Muiden koneiden, laitteiden ja tarvikkeiden tukkukauppa" },
  { id: "46691", label: "Sähkötarviketukkukauppa" },
  { id: "46692", label: "Teollisuudessa käytettävien muiden koneiden tukkukauppa" },
  { id: "46699", label: "Muualla luokittelemattomien koneiden ja laitteiden tukkukauppa" },
  { id: "467", label: "Muu erikoistunut tukkukauppa" },
  { id: "4671", label: "Kiinteiden, nestemäisten ja kaasumaisten polttoaineiden yms. tukkukauppa" },
  { id: "46711", label: "Nestemäisten ja kaasumaisten polttoaineiden tukkukauppa" },
  { id: "46719", label: "Muiden polttoaineiden tukkukauppa" },
  { id: "46720", label: "Raakametallien ja metallimalmien tukkukauppa" },
  { id: "4673", label: "Puun, rakennusmateriaalien ja saniteettilaitteiden tukkukauppa" },
  { id: "46731", label: "Raakapuutukkukauppa" },
  { id: "46732", label: "Puutavaratuotetukkukauppa" },
  { id: "46733", label: "Metalli- ja mineraalituotteiden tukkukauppa" },
  { id: "46734", label: "Kylpyhuonekalusteiden ja -tarvikkeiden tukkukauppa" },
  { id: "46735", label: "Lattianpäällysteiden ja tapettien tukkukauppa" },
  { id: "46739", label: "Rakennustarvikkeiden yleistukkukauppa" },
  { id: "4674", label: "Rautakauppatavaroiden, LVI-laitteiden ja -tarvikkeiden tukkukauppa" },
  { id: "46741", label: "Työkalu- ja tarviketukkukauppa" },
  { id: "46742", label: "Lämpö-, vesi- ja ilmastointilaitteiden ja -tarvikkeiden tukkukauppa" },
  { id: "46750", label: "Peruskemikaalien, lannoitteiden yms. tukkukauppa" },
  { id: "46760", label: "Muiden välituotteiden tukkukauppa" },
  { id: "46770", label: "Jätteen ja romun tukkukauppa" },
  { id: "4690", label: "Muu tukkukauppa" },
  { id: "46901", label: "Yleistukkukauppa" },
  { id: "46909", label: "Muualla luokittelematon tukkukauppa" },
  { id: "47", label: "Vähittäiskauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa)" },
  { id: "471", label: "Vähittäiskauppa erikoistumattomissa myymälöissä" },
  { id: "4711", label: "Elintarvikkeiden, juomien ja tupakan erikoistumaton vähittäiskauppa" },
  { id: "47111", label: "Isot supermarketit (yli 1000 m²)" },
  { id: "47112", label: "Pienet supermarketit (yli 400 m², enintään 1000 m²)" },
  { id: "47113", label: "Valintamyymälät (yli 100 m², enintään 400 m²)" },
  { id: "47114", label: "Elintarvike-, makeis- ym. kioskit (enintään 100 m²)" },
  { id: "4719", label: "Muu vähittäiskauppa erikoistumattomissa myymälöissä" },
  { id: "47191", label: "Itsepalvelutavaratalot (yli 2500 m²)" },
  { id: "47192", label: "Tavaratalot (yli 2500 m²)" },
  { id: "47199", label: "Pienoistavaratalot ja muut erikoistumattomat myymälät (enintään 2500 m²)" },
  { id: "472", label: "Elintarvikkeiden, juomien ja tupakan vähittäiskauppa erikoismyymälöissä" },
  { id: "47210", label: "Hedelmien, marjojen ja vihannesten vähittäiskauppa" },
  { id: "47220", label: "Lihan ja lihatuotteiden vähittäiskauppa" },
  { id: "47230", label: "Kalan, äyriäisten ja nilviäisten vähittäiskauppa" },
  { id: "4724", label: "Leipomotuotteiden ja makeisten vähittäiskauppa" },
  { id: "47241", label: "Leipomotuotteiden vähittäiskauppa" },
  { id: "47242", label: "Makeisten vähittäiskauppa" },
  { id: "47250", label: "Alkoholi- ja muiden juomien vähittäiskauppa" },
  { id: "47260", label: "Tupakkatuotteiden vähittäiskauppa" },
  { id: "4729", label: "Muu vähittäiskauppa erikoismyymälöissä" },
  { id: "47291", label: "Jäätelökioskit" },
  { id: "47292", label: "Luontaistuotteiden vähittäiskauppa" },
  { id: "47299", label: "Muu päivittäistavaroiden erikoisvähittäiskauppa" },
  { id: "4730", label: "Ajoneuvojen polttoaineen vähittäiskauppa" },
  { id: "47301", label: "Huoltamotoiminta" },
  { id: "47302", label: "Polttoaineiden vähittäiskauppa automaateista" },
  { id: "474", label: "Tieto- ja viestintäteknisten laitteiden vähittäiskauppa erikoismyymälöissä" },
  { id: "47410", label: "Tietokoneiden, niiden oheislaitteiden ja ohjelmistojen vähittäiskauppa" },
  { id: "47420", label: "Televiestintälaitteiden vähittäiskauppa" },
  { id: "47430", label: "Viihde-elektroniikan vähittäiskauppa" },
  { id: "475", label: "Muiden kotitaloustavaroiden vähittäiskauppa erikoismyymälöissä" },
  { id: "4751", label: "Tekstiilien vähittäiskauppa" },
  { id: "47511", label: "Kankaiden vähittäiskauppa" },
  { id: "47512", label: "Lankojen ja käsityötarvikkeiden vähittäiskauppa" },
  { id: "4752", label: "Rautakauppatavaran, maalien ja lasin vähittäiskauppa" },
  { id: "47521", label: "Rauta- ja rakennustarvikkeiden yleisvähittäiskauppa" },
  { id: "47522", label: "Maalien vähittäiskauppa" },
  { id: "47523", label: "Keittiö- ja saniteettitilojen kalusteiden vähittäiskauppa" },
  { id: "47529", label: "Muu rauta- ja rakennusalan vähittäiskauppa" },
  { id: "4753", label: "Mattojen, tapettien ja lattianpäällysteiden vähittäiskauppa" },
  { id: "47531", label: "Mattojen ja verhojen vähittäiskauppa" },
  { id: "47532", label: "Tapettien ja lattianpäällysteiden vähittäiskauppa" },
  { id: "47540", label: "Sähköisten kodinkoneiden vähittäiskauppa" },
  {
    id: "4759",
    label:
      "Huonekalujen, valaisimien ja muualla luokittelemattomien taloustarvikkeiden vähittäiskauppa erikoismyymälöissä",
  },
  { id: "47591", label: "Huonekalujen vähittäiskauppa" },
  { id: "47592", label: "Sähkötarvikkeiden ja valaisimien vähittäiskauppa" },
  { id: "47593", label: "Kumi- ja muovitavaroiden vähittäiskauppa" },
  { id: "47594", label: "Taloustavaroiden vähittäiskauppa" },
  { id: "47595", label: "Soittimien ja musiikkitarvikkeiden vähittäiskauppa" },
  { id: "47596", label: "Lukkoseppä- ja avainliikkeet" },
  { id: "47599", label: "Muualla luokittelemattomien kotitaloustarvikkeiden vähittäiskauppa" },
  { id: "476", label: "Kulttuuri- ja vapaa-ajan tuotteiden vähittäiskauppa erikoismyymälöissä" },
  { id: "47610", label: "Kirjojen vähittäiskauppa" },
  { id: "4762", label: "Sanomalehtien ja paperitavaran vähittäiskauppa" },
  { id: "47621", label: "Paperi- ja toimistotarvikkeiden vähittäiskauppa" },
  { id: "47622", label: "Aikakausjulkaisujen ja lehtien vähittäiskauppa" },
  { id: "47630", label: "Musiikki- ja videotallenteiden vähittäiskauppa" },
  { id: "4764", label: "Urheiluvälineiden vähittäiskauppa" },
  { id: "47641", label: "Urheiluvälineiden ja polkupyörien vähittäiskauppa" },
  { id: "47642", label: "Veneiden ja veneilytarvikkeiden vähittäiskauppa" },
  { id: "47650", label: "Pelien ja leikkikalujen vähittäiskauppa" },
  { id: "477", label: "Muiden tavaroiden vähittäiskauppa erikoismyymälöissä" },
  { id: "4771", label: "Vaatteiden vähittäiskauppa" },
  { id: "47711", label: "Naisten vaatteiden vähittäiskauppa" },
  { id: "47712", label: "Miesten vaatteiden vähittäiskauppa" },
  { id: "47713", label: "Lastenvaatteiden vähittäiskauppa" },
  { id: "47714", label: "Turkisten ja nahkavaatteiden vähittäiskauppa" },
  { id: "47715", label: "Lakkien ja hattujen vähittäiskauppa" },
  { id: "47719", label: "Vaatteiden yleisvähittäiskauppa" },
  { id: "4772", label: "Jalkineiden ja nahkatavaroiden vähittäiskauppa" },
  { id: "47721", label: "Jalkineiden vähittäiskauppa" },
  { id: "47722", label: "Laukkujen vähittäiskauppa" },
  { id: "47730", label: "Apteekit" },
  { id: "47740", label: "Terveydenhoitotarvikkeiden vähittäiskauppa" },
  { id: "47750", label: "Kosmetiikka- ja hygieniatuotteiden vähittäiskauppa" },
  {
    id: "4776",
    label: "Kukkien, kasvien, siementen, lannoitteiden, lemmikkieläinten ja niiden ruokien vähittäiskauppa",
  },
  { id: "47761", label: "Kukkien vähittäiskauppa" },
  { id: "47762", label: "Kukkakioskit" },
  { id: "47763", label: "Puutarha-alan vähittäiskauppa" },
  { id: "47764", label: "Lemmikkieläinten, niiden ruokien ja tarvikkeiden vähittäiskauppa" },
  { id: "47770", label: "Kultasepänteosten ja kellojen vähittäiskauppa" },
  { id: "4778", label: "Muu uusien tavaroiden vähittäiskauppa" },
  { id: "47781", label: "Taideliikkeet" },
  { id: "47782", label: "Valokuvausalan vähittäiskauppa" },
  { id: "47783", label: "Optisen alan vähittäiskauppa" },
  { id: "47784", label: "Lastenvaunujen ja -tarvikkeiden vähittäiskauppa" },
  { id: "47785", label: "Lahjatavaroiden ja askartelutarvikkeiden vähittäiskauppa" },
  { id: "47789", label: "Muiden uusien tavaroiden vähittäiskauppa" },
  { id: "4779", label: "Käytettyjen tavaroiden vähittäiskauppa myymälöissä" },
  { id: "47791", label: "Antiikkiliikkeet" },
  { id: "47792", label: "Antikvariaattikauppa" },
  { id: "47793", label: "Huutokauppakamarit" },
  { id: "47799", label: "Muiden käytettyjen tavaroiden vähittäiskauppa" },
  { id: "478", label: "Tori- ja markkinakauppa" },
  { id: "47810", label: "Elintarvikkeiden, juomien ja tupakkatuotteiden vähittäiskauppa kojuista ja toreilla" },
  { id: "47820", label: "Tekstiilien, vaatteiden ja jalkineiden vähittäiskauppa kojuista ja toreilla" },
  { id: "47890", label: "Muiden tavaroiden vähittäiskauppa kojuista ja toreilla" },
  { id: "479", label: "Vähittäiskauppa muualla kuin myymälöissä (pl. tori- ja markkinakauppa)" },
  { id: "4791", label: "Vähittäiskauppa postimyyntiliikkeiden tai Internetin välityksellä" },
  { id: "47911", label: "Kirjojen, musiikki- ja videotallenteiden postimyynti ja verkkokauppa" },
  { id: "47912", label: "Vaatteiden postimyynti ja verkkokauppa" },
  { id: "47913", label: "Laajan valikoiman postimyynti ja verkkokauppa" },
  { id: "47919", label: "Muu postimyynti ja verkkokauppa" },
  { id: "47990", label: "Muu vähittäiskauppa muualla kuin myymälöissä" },
  { id: "H", label: "Kuljetus ja varastointi" },
  { id: "49", label: "Maaliikenne ja putkijohtokuljetus" },
  { id: "49100", label: "Rautateiden henkilöliikenne, kaukoliikenne" },
  { id: "49200", label: "Rautateiden tavaraliikenne" },
  { id: "493", label: "Muu maaliikenteen henkilöliikenne" },
  { id: "49310", label: "Paikallisliikenne" },
  { id: "49320", label: "Taksiliikenne" },
  { id: "4939", label: "Muualla luokittelematon maaliikenteen henkilöliikenne" },
  { id: "49391", label: "Säännöllinen linja-autojen kaukoliikenne" },
  { id: "49392", label: "Linja-autojen tilausliikenne" },
  { id: "49399", label: "Muualla luokittelematon muu maaliikenteen henkilöliikenne" },
  { id: "494", label: "Tieliikenteen tavarankuljetus ja muuttopalvelut" },
  { id: "49410", label: "Tieliikenteen tavarankuljetus" },
  { id: "49420", label: "Muuttopalvelut" },
  { id: "49500", label: "Putkijohtokuljetus" },
  { id: "50", label: "Vesiliikenne" },
  { id: "5010", label: "Meri- ja rannikkovesiliikenteen henkilökuljetus" },
  { id: "50101", label: "Meriliikenteen henkilökuljetus" },
  { id: "50102", label: "Rannikkovesiliikenteen henkilökuljetus" },
  { id: "5020", label: "Meri- ja rannikkovesiliikenteen tavarankuljetus" },
  { id: "50201", label: "Meriliikenteen tavarankuljetus" },
  { id: "50202", label: "Rannikkovesiliikenteen tavarankuljetus" },
  { id: "50300", label: "Sisävesiliikenteen henkilökuljetus" },
  { id: "50400", label: "Sisävesiliikenteen tavarankuljetus" },
  { id: "51", label: "Ilmaliikenne" },
  { id: "5110", label: "Matkustajalentoliikenne" },
  { id: "51101", label: "Säännöllinen lentoliikenne" },
  { id: "51102", label: "Tilauslentoliikenne" },
  { id: "512", label: "Lentoliikenteen tavarankuljetus ja avaruusliikenne" },
  { id: "51210", label: "Lentoliikenteen tavarankuljetus" },
  { id: "51220", label: "Avaruusliikenne" },
  { id: "52", label: "Varastointi ja liikennettä palveleva toiminta" },
  { id: "52100", label: "Varastointi" },
  { id: "522", label: "Liikennettä palveleva toiminta" },
  { id: "5221", label: "Maaliikennettä palveleva toiminta" },
  { id: "52211", label: "Linja-autoasemat" },
  { id: "52212", label: "Tieliikenteen terminaalitoiminta" },
  { id: "52213", label: "Maksullinen pysäköinti" },
  { id: "52219", label: "Muu maaliikennettä palveleva toiminta" },
  { id: "5222", label: "Vesiliikennettä palveleva toiminta" },
  { id: "52221", label: "Satamat" },
  { id: "52229", label: "Muu vesiliikennettä palveleva toiminta" },
  { id: "52230", label: "Ilmaliikennettä palveleva toiminta" },
  { id: "52240", label: "Lastinkäsittely" },
  { id: "5229", label: "Muu liikennettä palveleva toiminta" },
  { id: "52291", label: "Huolinta ja rahtaus" },
  { id: "52299", label: "Muu kuljetusvälitys" },
  { id: "53", label: "Posti- ja kuriiritoiminta" },
  { id: "53100", label: "Postin yleispalvelu" },
  { id: "53200", label: "Muu posti-, jakelu- ja kuriiritoiminta" },
  { id: "I", label: "Majoitus- ja ravitsemistoiminta" },
  { id: "55", label: "Majoitus" },
  { id: "5510", label: "Hotellit ja vastaavat majoitusliikkeet" },
  { id: "55101", label: "Hotellit" },
  { id: "55109", label: "Motellit, matkustajakodit ja vastaavat majoitusliikkeet" },
  { id: "5520", label: "Lomakylät, retkeilymajat yms. majoitus" },
  { id: "55201", label: "Retkeilymajat" },
  { id: "55209", label: "Lomakylät yms. majoitus" },
  { id: "55300", label: "Leirintäalueet, asuntovaunu- ja matkailuvaunualueet" },
  { id: "5590", label: "Muu majoitus" },
  { id: "55901", label: "Asuntolat ja täysihoitolat yms." },
  { id: "55902", label: "Maatilamatkailu, bed & breakfast" },
  { id: "55903", label: "Lomamökkien vuokraus" },
  { id: "55909", label: "Muualla luokittelematon majoitustoiminta" },
  { id: "56", label: "Ravitsemistoiminta" },
  { id: "5610", label: "Ravintolat ja vastaava ravitsemistoiminta" },
  { id: "56101", label: "Ravintolat" },
  { id: "56102", label: "Kahvila-ravintolat" },
  { id: "56103", label: "Ruokakioskit" },
  { id: "562", label: "Ateriapalvelut ja muut ravitsemispalvelut" },
  { id: "56210", label: "Pitopalvelu" },
  { id: "56290", label: "Henkilöstö- ja laitosruokalat" },
  { id: "5630", label: "Baarit ja kahvilat" },
  { id: "56301", label: "Olut- ja drinkkibaarit" },
  { id: "56302", label: "Kahvilat ja kahvibaarit" },
  { id: "J", label: "Informaatio ja viestintä" },
  { id: "58", label: "Kustannustoiminta" },
  { id: "581", label: "Kirjojen ja lehtien kustantaminen ja muu kustannustoiminta" },
  { id: "58110", label: "Kirjojen kustantaminen" },
  { id: "58120", label: "Hakemistojen ja postituslistojen julkaiseminen" },
  { id: "58130", label: "Sanomalehtien kustantaminen" },
  { id: "5814", label: "Aikakauslehtien ja harvemmin ilmestyvien sanomalehtien kustantaminen" },
  { id: "58141", label: "Paikallislehtien ja harvemmin ilmestyvien sanomalehtien kustantaminen" },
  { id: "58142", label: "Aikakauslehtien kustantaminen" },
  { id: "58190", label: "Muu kustannustoiminta" },
  { id: "582", label: "Ohjelmistojen kustantaminen" },
  { id: "58210", label: "Tietokonepelien kustantaminen" },
  { id: "58290", label: "Muu ohjelmistojen kustantaminen" },
  { id: "59", label: "Elokuva-, video- ja televisio-ohjelmatuotanto, äänitteiden ja musiikin kustantaminen" },
  { id: "591", label: "Elokuva-, video- ja televisio-ohjelmatoiminta" },
  { id: "59110", label: "Elokuvien, videoiden ja televisio-ohjelmien tuotanto" },
  { id: "59120", label: "Elokuvien, video- ja televisio-ohjelmien jälkituotanto" },
  { id: "59130", label: "Elokuvien, videoiden ja televisio-ohjelmien levitys" },
  { id: "59140", label: "Elokuvien esittäminen" },
  { id: "59200", label: "Äänitysstudiot; äänitteiden ja musiikin kustantaminen" },
  { id: "60", label: "Radio- ja televisiotoiminta" },
  { id: "60100", label: "Radio-ohjelmien tuottaminen ja lähettäminen" },
  { id: "6020", label: "Televisio-ohjelmien tuottaminen ja lähettäminen" },
  { id: "60201", label: "Televisio-ohjelmien tuottaminen ja lähettäminen (pl. maksulliset tv-kanavat)" },
  { id: "60202", label: "Maksulliset tv-kanavat" },
  { id: "61", label: "Televiestintä" },
  { id: "61100", label: "Langallisen verkon hallinta ja palvelut" },
  { id: "61200", label: "Langattoman verkon hallinta ja palvelut" },
  { id: "61300", label: "Satelliittiviestintä" },
  { id: "61900", label: "Muut televiestintäpalvelut" },
  { id: "620", label: "Ohjelmistot, konsultointi ja siihen liittyvä toiminta" },
  { id: "62010", label: "Ohjelmistojen suunnittelu ja valmistus" },
  { id: "62020", label: "Atk-laitteisto- ja ohjelmistokonsultointi" },
  { id: "62030", label: "Tietojenkäsittelyn ja laitteistojen käyttö- ja hallintapalvelut" },
  { id: "62090", label: "Muu laitteisto- ja tietotekninen palvelutoiminta" },
  { id: "63", label: "Tietopalvelutoiminta" },
  { id: "631", label: "Tietojenkäsittely, palvelintilan vuokraus ja niihin liittyvät palvelut; verkkoportaalit" },
  { id: "63110", label: "Tietojenkäsittely, palvelintilan vuokraus ja niihin liittyvät palvelut" },
  { id: "63120", label: "Verkkoportaalit" },
  { id: "639", label: "Muu tietopalvelutoiminta" },
  { id: "63910", label: "Uutistoimistot" },
  { id: "63990", label: "Muualla luokittelematon tietopalvelutoiminta" },
  { id: "K", label: "Rahoitus- ja vakuutustoiminta" },
  { id: "64", label: "Rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)" },
  { id: "641", label: "Pankkitoiminta" },
  { id: "64110", label: "Keskuspankkitoiminta" },
  { id: "64190", label: "Muu pankkitoiminta" },
  { id: "64200", label: "Rahoitusalan holdingyhtiöiden toiminta" },
  { id: "64300", label: "Rahastotoiminta" },
  { id: "649", label: "Muut rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)" },
  { id: "64910", label: "Rahoitusleasing" },
  { id: "64920", label: "Muu luotonanto" },
  { id: "64990", label: "Muualla luokittelemattomat rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)" },
  { id: "65", label: "Vakuutus-, jälleenvakuutus- ja eläkevakuutustoiminta (pl. pakollinen sosiaalivakuutus)" },
  { id: "651", label: "Vakuutustoiminta" },
  { id: "65110", label: "Henkivakuutustoiminta" },
  { id: "6512", label: "Muu vakuutustoiminta" },
  { id: "65121", label: "Vahinkovakuutusyhtiöt" },
  { id: "65122", label: "Vakuutusyhdistykset" },
  { id: "65129", label: "Muu vahinkovakuutus" },
  { id: "65200", label: "Jälleenvakuutustoiminta" },
  { id: "65300", label: "Eläkevakuutustoiminta" },
  { id: "66", label: "Rahoitusta ja vakuuttamista palveleva toiminta" },
  { id: "661", label: "Rahoitusta ja vakuuttamista palveleva toiminta (pl. vakuutus- ja eläkevakuutustoiminta)" },
  { id: "66110", label: "Pörssitoiminta ja rahoitusmarkkinoiden hallinnolliset tukipalvelut" },
  { id: "66120", label: "Arvopaperien ja raaka-ainesopimusten välittäminen" },
  { id: "66190", label: "Muu rahoitusta palveleva toiminta (pl. vakuutus- ja eläkevakuutustoiminta)" },
  { id: "662", label: "Vakuutus- ja eläkevakuutustoimintaa avustava toiminta" },
  { id: "66210", label: "Riskin- ja vahingonarviointi" },
  { id: "66220", label: "Vakuutusasiamiesten ja -välittäjien toiminta" },
  { id: "66290", label: "Muu vakuutus- ja eläkevakuutustoimintaa avustava toiminta" },
  { id: "66300", label: "Omaisuudenhoitotoiminta" },
  { id: "68", label: "Kiinteistöalan toiminta" },
  { id: "68100", label: "Omien kiinteistöjen kauppa" },
  { id: "6820", label: "Omien tai leasing-kiinteistöjen vuokraus ja hallinta" },
  { id: "68201", label: "Asuntojen vuokraus" },
  { id: "68202", label: "Asuntojen ja asuinkiinteistöjen hallinta" },
  { id: "68209", label: "Muiden kiinteistöjen vuokraus ja hallinta" },
  { id: "683", label: "Kiinteistöalan toiminta palkkio- tai sopimusperusteisesti" },
  { id: "68310", label: "Kiinteistönvälitys" },
  { id: "68320", label: "Kiinteistöjen isännöinti" },
  { id: "M", label: "Ammatillinen, tieteellinen ja tekninen toiminta" },
  { id: "69", label: "Lakiasiain- ja laskentatoimen palvelut" },
  { id: "6910", label: "Lakiasiainpalvelut" },
  { id: "69101", label: "Asianajotoimistot" },
  { id: "69102", label: "Lakiasiaintoimistot" },
  { id: "69103", label: "Patenttitoimistot" },
  { id: "69109", label: "Muu lakiasiain palvelu" },
  { id: "6920", label: "Laskentatoimi, kirjanpito ja tilintarkastus; veroneuvonta" },
  { id: "69201", label: "Kirjanpito- ja tilinpäätöspalvelu" },
  { id: "69202", label: "Tilintarkastuspalvelu" },
  { id: "69209", label: "Muu laskentatoimen palvelu" },
  { id: "70", label: "Pääkonttorien toiminta; liikkeenjohdon konsultointi" },
  { id: "70100", label: "Pääkonttorien toiminta" },
  { id: "702", label: "Liikkeenjohdon konsultointi" },
  { id: "70210", label: "Suhdetoiminta ja viestintä" },
  { id: "70220", label: "Muu liikkeenjohdon konsultointi" },
  { id: "71", label: "Arkkitehti- ja insinööripalvelut; tekninen testaus ja analysointi" },
  { id: "711", label: "Arkkitehti- ja insinööripalvelut ja niihin liittyvä tekninen konsultointi" },
  { id: "71110", label: "Arkkitehtipalvelut" },
  { id: "7112", label: "Insinööripalvelut ja niihin liittyvä tekninen konsultointi" },
  { id: "71121", label: "Yhdyskuntasuunnittelu" },
  { id: "71122", label: "Maa- ja vesirakentamisen tekninen palvelu" },
  { id: "71123", label: "Rakennetekninen palvelu" },
  { id: "71124", label: "LVI-tekninen suunnittelu" },
  { id: "71125", label: "Sähkötekninen suunnittelu" },
  { id: "71126", label: "Muu rakennustekninen palvelu" },
  { id: "71127", label: "Kone- ja prosessisuunnittelu" },
  { id: "71129", label: "Muu tekninen palvelu" },
  { id: "7120", label: "Tekninen testaus ja analysointi" },
  { id: "71201", label: "Autokatsastus" },
  { id: "71202", label: "Muu tekninen testaus ja analysointi" },
  { id: "72", label: "Tieteellinen tutkimus ja kehittäminen" },
  { id: "721", label: "Luonnontieteen ja tekniikan tutkimus ja kehittäminen" },
  { id: "72110", label: "Biotekninen tutkimus ja kehittäminen" },
  { id: "7219", label: "Muu luonnontieteellinen ja tekninen tutkimus ja kehittäminen" },
  { id: "72191", label: "Lääketieteellinen tutkimus ja kehittäminen" },
  { id: "72192", label: "Muu luonnontieteellinen tutkimus ja kehittäminen" },
  { id: "72193", label: "Tekniikan tutkimus ja kehittäminen" },
  { id: "72200", label: "Yhteiskuntatieteellinen ja humanistinen tutkimus ja kehittäminen" },
  { id: "73", label: "Mainostoiminta ja markkinatutkimus" },
  { id: "731", label: "Mainostoiminta" },
  { id: "7311", label: "Mainostoimistot ja mainospalvelu" },
  { id: "73111", label: "Mainostoimistot" },
  { id: "73112", label: "Suora- ja ulkomainonta" },
  { id: "73119", label: "Muu mainospalvelu" },
  { id: "73120", label: "Mainostilan vuokraus ja myynti" },
  { id: "73200", label: "Markkina- ja mielipidetutkimukset" },
  { id: "74", label: "Muut erikoistuneet palvelut liike-elämälle" },
  { id: "7410", label: "Taideteollinen muotoilu ja suunnittelu" },
  { id: "74101", label: "Graafinen muotoilu" },
  { id: "74102", label: "Sisustussuunnittelu" },
  { id: "74109", label: "Teollinen muotoilu ym." },
  { id: "7420", label: "Valokuvaustoiminta" },
  { id: "74201", label: "Valokuvaamot ja muu kuvaustoiminta" },
  { id: "74202", label: "Valokuvien kehittäminen" },
  { id: "74300", label: "Kääntäminen ja tulkkaus" },
  { id: "7490", label: "Muualla luokittelemattomat erikoistuneet palvelut liike-elämälle" },
  { id: "74901", label: "Ohjelmatoimistot ja manageripalvelut" },
  { id: "74909", label: "Muu ammatillinen, tieteellinen ja tekninen toiminta" },
  { id: "75000", label: "Eläinlääkintäpalvelut" },
  { id: "N", label: "Hallinto- ja tukipalvelutoiminta" },
  { id: "77", label: "Vuokraus- ja leasingtoiminta" },
  { id: "771", label: "Moottoriajoneuvojen vuokraus ja leasing" },
  { id: "77110", label: "Autojen ja kevyiden moottoriajoneuvojen vuokraus ja leasing" },
  { id: "77120", label: "Kuorma-autojen ja muiden raskaiden ajoneuvojen vuokraus ja leasing" },
  { id: "772", label: "Henkilökohtaisten ja kotitaloustavaroiden vuokraus ja leasing" },
  { id: "77210", label: "Vapaa-ajan ja urheiluvälineiden vuokraus ja leasing" },
  { id: "77220", label: "Videofilmien vuokraus" },
  { id: "77290", label: "Muiden henkilökohtaisten ja kotitaloustavaroiden vuokraus ja leasing" },
  { id: "773", label: "Koneiden ja laitteiden vuokraus ja leasing" },
  { id: "77310", label: "Maatalouskoneiden ja -laitteiden vuokraus ja leasing" },
  { id: "77320", label: "Rakennuskoneiden ja -laitteiden vuokraus ja leasing" },
  { id: "77330", label: "Toimistokoneiden ja -laitteiden sekä tietokoneiden vuokraus ja leasing" },
  { id: "77340", label: "Vesiliikennevälineiden vuokraus ja leasing" },
  { id: "77350", label: "Ilmaliikennevälineiden vuokraus ja leasing" },
  { id: "77390", label: "Muiden koneiden ja laitteiden vuokraus ja leasing" },
  {
    id: "77400",
    label: "Henkisen omaisuuden ja vastaavien tuotteiden leasing (pl. tekijänoikeuden suojaamat teokset)",
  },
  { id: "78", label: "Työllistämistoiminta" },
  { id: "78100", label: "Työnvälitystoiminta" },
  { id: "78200", label: "Työvoiman vuokraus" },
  { id: "78300", label: "Muut henkilöstön hankintapalvelut" },
  { id: "79", label: "Matkatoimistojen ja matkanjärjestäjien toiminta; varauspalvelut" },
  { id: "791", label: "Matkatoimistojen ja matkanjärjestäjien toiminta" },
  { id: "79110", label: "Matkatoimistojen toiminta" },
  { id: "79120", label: "Matkanjärjestäjien toiminta" },
  { id: "79900", label: "Varauspalvelut, matkaoppaiden palvelut ym." },
  { id: "80", label: "Turvallisuus-, vartiointi- ja etsiväpalvelut" },
  { id: "80100", label: "Yksityiset turvallisuuspalvelut" },
  { id: "80200", label: "Turvallisuusjärjestelmät" },
  { id: "80300", label: "Etsivätoiminta" },
  { id: "81", label: "Kiinteistön- ja maisemanhoito" },
  { id: "81100", label: "Kiinteistönhoito" },
  { id: "812", label: "Siivouspalvelut" },
  { id: "81210", label: "Kiinteistöjen siivous" },
  { id: "81220", label: "Muu rakennus- ja teollisuussiivous" },
  { id: "8129", label: "Muu siivoustoiminta" },
  { id: "81291", label: "Katujen ja teiden puhtaanapito" },
  { id: "81299", label: "Muualla luokittelemattomat siivouspalvelut" },
  { id: "81300", label: "Maisemanhoitopalvelut" },
  { id: "82", label: "Hallinto- ja tukipalvelut liike-elämälle" },
  { id: "821", label: "Hallinto- ja toimistopalvelut" },
  { id: "82110", label: "Yhdistetyt toimistopalvelut" },
  { id: "8219", label: "Sihteeri-, toimisto- ja postituspalvelut" },
  { id: "82191", label: "Sihteeri- ja muu toimistopalvelu" },
  { id: "82192", label: "Postituspalvelut" },
  { id: "82200", label: "Puhelinpalvelukeskusten toiminta" },
  { id: "82300", label: "Messujen ja kongressien järjestäminen" },
  { id: "829", label: "Muu liike-elämää palveleva toiminta" },
  { id: "82910", label: "Perintä- ja luottotietopalvelut" },
  { id: "82920", label: "Pakkauspalvelut" },
  { id: "82990", label: "Muut palvelut liike-elämälle" },
  { id: "84", label: "Julkinen hallinto ja maanpuolustus; pakollinen sosiaalivakuutus" },
  { id: "841", label: "Julkinen hallinto" },
  { id: "84110", label: "Julkinen yleishallinto" },
  { id: "8412", label: "Terveydenhuollon, opetuksen, kulttuurin ja muiden yhteiskuntapalvelujen hallinto" },
  { id: "84121", label: "Opetuksen ja kulttuuriasiain hallinto" },
  { id: "84122", label: "Terveydenhuollon ja sosiaaliturvan hallinto" },
  { id: "84130", label: "Työvoima- ja elinkeinoasiain hallinto" },
  { id: "842", label: "Ulkoasiain hallinto, maanpuolustus ja järjestystoimi" },
  { id: "84210", label: "Ulkoasiainhallinto" },
  { id: "84220", label: "Maanpuolustus" },
  { id: "8423", label: "Oikeustoimi" },
  { id: "84231", label: "Oikeudenhoito" },
  { id: "84232", label: "Vankeinhoito" },
  { id: "8424", label: "Poliisitoimi ja rajojen vartiointi" },
  { id: "84241", label: "Poliisitoimi" },
  { id: "84242", label: "Rajojen vartiointi ja väestönsuojelu" },
  { id: "84250", label: "Palo- ja pelastustoimi" },
  { id: "8430", label: "Pakollinen sosiaalivakuutustoiminta" },
  { id: "84301", label: "Kansaneläkelaitoksen vakuutustoiminta" },
  { id: "84302", label: "Lakisääteinen työeläkevakuutus" },
  { id: "84309", label: "Muu pakollinen sosiaalivakuutustoiminta" },
  { id: "85", label: "Koulutus" },
  { id: "85100", label: "Esiasteen koulutus" },
  { id: "85200", label: "Alemman perusasteen koulutus" },
  { id: "853", label: "Ylemmän perusasteen ja keskiasteen koulutus" },
  { id: "8531", label: "Ylemmän perusasteen koulutus ja lukiokoulutus" },
  { id: "85311", label: "Ylemmän perusasteen koulutus" },
  { id: "85312", label: "Lukiokoulutus" },
  { id: "85320", label: "Keskiasteen ammatillinen koulutus" },
  { id: "854", label: "Korkea-asteen koulutus" },
  { id: "85410", label: "Korkea-asteen koulutus (pl. yliopistot ja ammattikorkeakoulut)" },
  { id: "85420", label: "Korkea-asteen koulutus yliopistoissa ja ammattikorkeakouluissa" },
  { id: "855", label: "Muu koulutus" },
  { id: "85510", label: "Urheilu- ja liikuntakoulutus" },
  { id: "85520", label: "Taiteen ja musiikin koulutus" },
  { id: "85530", label: "Kuljettajakoulutus" },
  { id: "8559", label: "Muualla luokittelematon koulutus" },
  { id: "85591", label: "Kansanopistot, kansalaisopistot, työväenopistot yms." },
  { id: "85592", label: "Koulutuskeskukset" },
  { id: "85593", label: "Kielikoulut ja -opistot" },
  { id: "85599", label: "Muut koulutusta antavat yksiköt" },
  { id: "85600", label: "Koulutusta palveleva toiminta" },
  { id: "Q", label: "Terveys- ja sosiaalipalvelut" },
  { id: "86", label: "Terveyspalvelut" },
  { id: "8610", label: "Terveydenhuollon laitospalvelut" },
  { id: "86101", label: "Varsinaiset sairaalapalvelut" },
  { id: "86102", label: "Kuntoutuslaitokset ja sairaskodit" },
  { id: "862", label: "Lääkäri- ja hammaslääkäripalvelut" },
  { id: "86210", label: "Terveyskeskus- ja vastaavat yleislääkäripalvelut" },
  { id: "86220", label: "Lääkäriasemat, yksityislääkärit ja vastaavat erikoislääkäripalvelut" },
  { id: "86230", label: "Hammaslääkäripalvelut" },
  { id: "8690", label: "Muut terveydenhuoltopalvelut" },
  { id: "86901", label: "Fysioterapia" },
  { id: "86902", label: "Laboratoriotutkimukset" },
  { id: "86903", label: "Kuvantamistutkimukset" },
  { id: "86904", label: "Sairaankuljetuspalvelut" },
  { id: "86909", label: "Muu terveyspalvelu" },
  { id: "87", label: "Sosiaalihuollon laitospalvelut" },
  { id: "8710", label: "Sosiaalihuollon hoitolaitokset" },
  { id: "87101", label: "Ikääntyneiden hoitolaitokset" },
  { id: "87102", label: "Vammaisten hoitolaitokset (pl. kehitysvammaisten laitospalvelut)" },
  { id: "8720", label: "Kehitysvammaisten sekä mielenterveys- ja päihdeongelmaisten asumispalvelut" },
  { id: "87201", label: "Kehitysvammaisten laitokset ja asumispalvelut" },
  { id: "87202", label: "Mielenterveysongelmaisten asumispalvelut" },
  { id: "87203", label: "Päihdeongelmaisten laitokset" },
  { id: "87204", label: "Päihdeongelmaisten asumispalvelut" },
  { id: "8730", label: "Vanhusten ja vammaisten asumispalvelut" },
  { id: "87301", label: "Ikääntyneiden palveluasuminen" },
  { id: "87302", label: "Vammaisten palveluasuminen (pl. kehitysvammaisten palveluasuminen)" },
  { id: "8790", label: "Muut sosiaalihuollon laitospalvelut" },
  { id: "87901", label: "Lasten ja nuorten laitokset ja ammatillinen perhehoito" },
  { id: "87902", label: "Ensi- ja turvakodit" },
  { id: "87909", label: "Muut laitokset ja asumispalvelut" },
  { id: "88", label: "Sosiaalihuollon avopalvelut" },
  { id: "8810", label: "Vanhusten ja vammaisten sosiaalihuollon avopalvelut" },
  { id: "88101", label: "Kotipalvelut ikääntyneille ja vammaisille" },
  { id: "88102", label: "Ikääntyneiden päivätoiminta" },
  { id: "88103", label: "Vammaisten päivä- ja työtoiminta" },
  { id: "88109", label: "Muut vanhusten ja vammaisten avopalvelut" },
  { id: "889", label: "Muut sosiaalihuollon avopalvelut" },
  { id: "8891", label: "Lasten päivähoitopalvelut" },
  { id: "88911", label: "Lasten päiväkodit" },
  { id: "88919", label: "Muu lasten päivähoito" },
  { id: "8899", label: "Muualla luokittelemattomat sosiaalihuollon avopalvelut" },
  { id: "88991", label: "Kotipalvelut muille kuin ikääntyneille ja vammaisille" },
  { id: "88992", label: "Päivä- ja työtoiminta muille kuin ikääntyneille ja vammaisille" },
  { id: "88993", label: "Avomuotoinen päihdekuntoutus" },
  { id: "88999", label: "Muut muualla luokittelemattomat sosiaalihuollon avopalvelut" },
  { id: "R", label: "Taiteet, viihde ja virkistys" },
  { id: "900", label: "Kulttuuri- ja viihdetoiminta" },
  { id: "90010", label: "Esittävät taiteet" },
  { id: "90020", label: "Esittäviä taiteita palveleva toiminta" },
  { id: "90030", label: "Taiteellinen luominen" },
  { id: "90040", label: "Taidelaitosten toiminta" },
  { id: "910", label: "Kirjastojen, arkistojen, museoiden ja muiden kulttuurilaitosten toiminta" },
  { id: "91010", label: "Kirjastojen ja arkistojen toiminta" },
  { id: "91020", label: "Museoiden toiminta" },
  { id: "91030", label: "Historiallisten nähtävyyksien, rakennusten ja vastaavien kohteiden toiminta" },
  { id: "91040", label: "Kasvitieteellisten puutarhojen, eläintarhojen ja luonnonpuistojen toiminta" },
  { id: "92000", label: "Rahapeli- ja vedonlyöntipalvelut" },
  { id: "93", label: "Urheilutoiminta sekä huvi- ja virkistyspalvelut" },
  { id: "931", label: "Urheilutoiminta" },
  { id: "93110", label: "Urheilulaitosten toiminta" },
  { id: "93120", label: "Urheiluseurojen toiminta" },
  { id: "93130", label: "Kuntokeskukset" },
  { id: "93190", label: "Muu urheilutoiminta" },
  { id: "932", label: "Huvi- ja virkistystoiminta" },
  { id: "93210", label: "Huvi- ja teemapuistojen toiminta" },
  { id: "9329", label: "Muu huvi- ja virkistystoiminta" },
  { id: "93291", label: "Hiihto- ja laskettelukeskukset" },
  { id: "93299", label: "Muualla luokittelematon huvi- ja virkistystoiminta" },
  { id: "S", label: "Muu palvelutoiminta" },
  { id: "94", label: "Järjestöjen toiminta" },
  { id: "941", label: "Elinkeinoelämän, työnantaja- ja ammattialajärjestöjen toiminta" },
  { id: "94110", label: "Elinkeinoelämän ja työnantajajärjestöjen toiminta" },
  { id: "94120", label: "Ammattialajärjestöjen toiminta" },
  { id: "94200", label: "Ammattiyhdistysten toiminta" },
  { id: "949", label: "Muiden järjestöjen toiminta" },
  { id: "94910", label: "Seurakunnat ja uskonnolliset järjestöt" },
  { id: "94920", label: "Poliittiset järjestöt" },
  { id: "9499", label: "Muut järjestöt" },
  { id: "94991", label: "Tutkimusta ja kulttuuria palvelevat järjestöt" },
  { id: "94999", label: "Muualla luokittelemattomat muut järjestöt" },
  { id: "95", label: "Tietokoneiden, henkilökohtaisten ja kotitaloustavaroiden korjaus" },
  { id: "951", label: "Tietokoneiden ja viestintälaitteiden korjaus" },
  { id: "95110", label: "Tietokoneiden ja niiden oheislaitteiden korjaus" },
  { id: "95120", label: "Viestintälaitteiden korjaus" },
  { id: "952", label: "Henkilökohtaisten ja kotitaloustavaroiden korjaus" },
  { id: "95210", label: "Viihde-elektroniikan korjaus" },
  { id: "95220", label: "Kotitalouskoneiden sekä kodin ja puutarhan laitteiden korjaus" },
  { id: "95230", label: "Jalkineiden ja nahkatavaroiden korjaus" },
  { id: "95240", label: "Huonekalujen ja kodin kalusteiden korjaus" },
  { id: "95250", label: "Kellojen ja korujen korjaus" },
  { id: "95290", label: "Muiden henkilökohtaisten ja kotitaloustavaroiden korjaus" },
  { id: "960", label: "Muut henkilökohtaiset palvelut" },
  { id: "9601", label: "Pesulapalvelut" },
  { id: "96011", label: "Pesulapalvelut yrityksille" },
  { id: "96012", label: "Pesulapalvelut kotitalouksille" },
  { id: "9602", label: "Kampaamo- ja kauneudenhoitopalvelut" },
  { id: "96021", label: "Parturit ja kampaamot" },
  { id: "96022", label: "Kauneudenhoitopalvelut" },
  { id: "96030", label: "Hautaustoimistojen palvelut" },
  { id: "96040", label: "Kylpylaitokset, saunat, solariumit yms. palvelut" },
  { id: "96090", label: "Muualla luokittelemattomat henkilökohtaiset palvelut" },
  {
    id: "T",
    label:
      "Kotitalouksien toiminta työnantajina; kotitalouksien eriyttämätön toiminta tavaroiden ja palvelujen tuottamiseksi omaan käyttöön",
  },
  { id: "97000", label: "Kotitalouksien toiminta kotitaloustyöntekijöiden työnantajina" },
  { id: "98", label: "Kotitalouksien eriyttämätön toiminta tavaroiden ja palvelujen tuottamiseksi omaan käyttöön" },
  { id: "98100", label: "Kotitalouksien eriyttämätön toiminta tavaroiden tuottamiseksi omaan käyttöön" },
  { id: "98200", label: "Kotitalouksien eriyttämätön toiminta palvelujen tuottamiseksi omaan käyttöön" },
  { id: "99000", label: "Kansainvälisten organisaatioiden ja toimielinten toiminta" },
];
