import React, { FC, Suspense, ReactNode } from "react";
import { CircularProgress, Box } from "@mui/material";

interface SuspenseWrapperProps {
  children: ReactNode;
}

const SuspenseWrapper: FC<SuspenseWrapperProps> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="50vh"
        >
          <CircularProgress />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
};

export default SuspenseWrapper;
