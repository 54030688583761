import React, { ReactNode } from "react";
import { UseFormRegister } from "react-hook-form";

import { TextField } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

import { useFormContext } from "./FormContext";

type RHFTextFieldProps = {
  name: string;
  label: string;
  onBlur?: () => void;
  disabled?: boolean;
  id?: string;
  value?: string | number;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  required?: boolean;
  multiline?: boolean;
  defaultHelperText?: ReactNode; // mahdollistaa normaalin tekstin syöttämisen defaultHelperText='esim näin' lisäksi pitkän tekstin syöttämisen rivitetysti defaultHelperText={`esim näin`} defaultHelperText={<div>esim näin</div>}
  rawHelperText?: string;
  requiredErrorMessage?: string;
  type?: string;
  register?: UseFormRegister<any>;
  rows?: number;
  variant?: "standard" | "filled" | "outlined";
  customInputLabelProps?: {
    fontSize?: string;
    transform?: string;
    maxWidth?: string;  // maksimileveys rivitystä varten
  };
  darkColors?: boolean;
  focusedLabelColor?: string;
  underlineColor?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RHFTextField: React.FC<RHFTextFieldProps> = ({
  name,
  label,
  onBlur,
  disabled,
  id,
  value,
  sx,
  fullWidth = true,
  required = true,
  multiline = false,
  defaultHelperText,
  requiredErrorMessage = "Tämä kenttä vaaditaan",
  type = "text",
  register: customRegister,
  rows = 1,
  variant = "filled",
  customInputLabelProps,
  darkColors = false,
  focusedLabelColor,
  underlineColor,
  onChange,
}) => {
  const { register: contextRegister, errors } = useFormContext();
  const register = customRegister || contextRegister;

  const error = errors[name] ? true : false;
  const helperText = errors[name] ? errors[name].message : defaultHelperText;

  return (
    <TextField
      {...register(name, { required: required ? requiredErrorMessage : false })}
      onChange={onChange ? onChange : undefined}
      value={value}
      inputProps={{ name }}
      disabled={disabled}
      id={id}
      label={label}
      variant={variant}
      sx={{
        my: 1,
        ...(darkColors && {
          "& .MuiFilledInput-root": {
            backgroundColor: "rgba(100, 101, 98, 0.09)", // darker background color for the filled div
          },
          "& .MuiFilledInput-root:hover": {
            backgroundColor: "rgba(100, 101, 98, 0.13)", // darker background color for the filled div on hover
          },
          "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "rgba(100, 101, 98, 0.09)", // darker background color for the filled div on focus
          },
          "& .MuiFilledInput-root.Mui-disabled": {
            backgroundColor: "rgba(100, 101, 98, 0.05)", // darker background color for the filled div when disabled
          },
        }),
        ...sx,
      }}
      fullWidth={fullWidth}
      required={required}
      multiline={multiline}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      type={type}
      minRows={multiline ? rows : undefined}
      InputProps={{
        sx: {
          ...(darkColors
            ? {
              color: "rgb(100, 101, 98)", // dark text color for input
              paddingBottom: "1.8rem",
              paddingLeft: "1.25rem",
            }
            : {}),
          ...(underlineColor && {
            "&.MuiFilledInput-underline": {
              "&:after": {
                borderBottomColor: underlineColor,
              },
            },
          }),
        },
      }}
      InputLabelProps={{
        sx: {
          textAlign: "left", // labelin teksti vasemmalle
          fontSize: customInputLabelProps?.fontSize || "1rem", // labelin fonttikokos
          whiteSpace: "normal", // rivittää labelin
          maxWidth: customInputLabelProps?.maxWidth || "95%", // labelin maksimileveys
          ...(customInputLabelProps && {
            "&.Mui-focused": {
              fontSize: customInputLabelProps.fontSize || "1rem",
              transform: customInputLabelProps.transform || "translate(14px, -20px) scale(0.75)",
              fontWeight: 900,
              color: focusedLabelColor,
            },
            "&.MuiInputLabel-shrink": {
              fontSize: customInputLabelProps.fontSize || "1rem",
              transform: customInputLabelProps.transform || "translate(14px, -20px) scale(0.75)",
            },
          }),
          ...(darkColors && {
            color: "rgb(100, 101, 98)", // dark text color for label
          }),
        },
      }}
    />
  );
};

export default RHFTextField;
