export const postinumerot = [
  { id: '00100', label: '00100  Helsinki keskusta - Etu-Töölö (Helsinki)' },
  { id: '00120', label: '00120  Punavuori - Bulevardi (Helsinki)' },
  { id: '00130', label: '00130  Kaartinkaupunki (Helsinki)' },
  { id: '00140', label: '00140  Kaivopuisto - Ullanlinna (Helsinki)' },
  { id: '00150', label: '00150  Punavuori - Eira - Hernesaari (Helsinki)' },
  { id: '00160', label: '00160  Katajanokka (Helsinki)' },
  { id: '00170', label: '00170  Kruununhaka (Helsinki)' },
  { id: '00180', label: '00180  Kamppi - Ruoholahti (Helsinki)' },
  { id: '00190', label: '00190  Suomenlinna (Helsinki)' },
  { id: '00200', label: '00200  Pohjois-Lauttasaari (Helsinki)' },
  { id: '00210', label: '00210  Vattuniemi (Helsinki)' },
  { id: '00220', label: '00220  Jätkäsaari (Helsinki)' },
  { id: '00230', label: '00230  Helsinki postikeskus (Helsinki)' },
  { id: '00240', label: '00240  Länsi-Pasila (Helsinki)' },
  { id: '00250', label: '00250  Taka-Töölö (Helsinki)' },
  { id: '00260', label: '00260  Keski-Töölö (Helsinki)' },
  { id: '00270', label: '00270  Pohjois-Meilahti (Helsinki)' },
  { id: '00280', label: '00280  Ruskeasuo (Helsinki)' },
  { id: '00290', label: '00290  Meilahden sairaala-alue (Helsinki)' },
  { id: '00300', label: '00300  Pikku Huopalahti (Helsinki)' },
  { id: '00310', label: '00310  Kivihaka (Helsinki)' },
  { id: '00320', label: '00320  Etelä-Haaga (Helsinki)' },
  { id: '00330', label: '00330  Munkkiniemi (Helsinki)' },
  { id: '00340', label: '00340  Kuusisaari - Lehtisaari (Helsinki)' },
  { id: '00350', label: '00350  Munkkivuori - Niemenmäki (Helsinki)' },
  { id: '00360', label: '00360  Pajamäki (Helsinki)' },
  { id: '00370', label: '00370  Reimarla (Helsinki)' },
  { id: '00380', label: '00380  Pitäjänmäen yritysalue (Helsinki)' },
  { id: '00390', label: '00390  Konala (Helsinki)' },
  { id: '00400', label: '00400  Pohjois-Haaga (Helsinki)' },
  { id: '00410', label: '00410  Malminkartano (Helsinki)' },
  { id: '00420', label: '00420  Kannelmäki (Helsinki)' },
  { id: '00430', label: '00430  Maununneva - Kuninkaantammi (Helsinki)' },
  { id: '00440', label: '00440  Lassila (Helsinki)' },
  { id: '00500', label: '00500  Sörnäinen - Harju (Helsinki)' },
  { id: '00510', label: '00510  Alppila - Vallila (Helsinki)' },
  { id: '00520', label: '00520  Itä- ja Keski-Pasila (Helsinki)' },
  { id: '00530', label: '00530  Kallio (Helsinki)' },
  { id: '00540', label: '00540  Kalasatama - Sompasaari (Helsinki)' },
  { id: '00550', label: '00550  Vallila - Hermanni (Helsinki)' },
  { id: '00560', label: '00560  Toukola - Kumpula - Vanhakaupunki (Helsinki)' },
  { id: '00570', label: '00570  Kulosaari (Helsinki)' },
  { id: '00580', label: '00580  Kalasatama - Kyläsaari (Helsinki)' },
  { id: '00590', label: '00590  Kruunuvuorenranta (Helsinki)' },
  { id: '00600', label: '00600  Koskela (Helsinki)' },
  { id: '00610', label: '00610  Käpylä (Helsinki)' },
  { id: '00620', label: '00620  Metsälä - Etelä-Oulunkylä (Helsinki)' },
  { id: '00630', label: '00630  Maunula (Helsinki)' },
  { id: '00640', label: '00640  Oulunkylä - Patola (Helsinki)' },
  { id: '00650', label: '00650  Veräjämäki (Helsinki)' },
  { id: '00660', label: '00660  Länsi-Pakila (Helsinki)' },
  { id: '00670', label: '00670  Paloheinä (Helsinki)' },
  { id: '00680', label: '00680  Itä-Pakila (Helsinki)' },
  { id: '00690', label: '00690  Torpparinmäki (Helsinki)' },
  { id: '00700', label: '00700  Malmi (Helsinki)' },
  { id: '00710', label: '00710  Pihlajamäki - Viikinmäki (Helsinki)' },
  { id: '00720', label: '00720  Pukinmäki (Helsinki)' },
  { id: '00730', label: '00730  Tapanila (Helsinki)' },
  { id: '00740', label: '00740  Siltamäki (Helsinki)' },
  { id: '00750', label: '00750  Tapulikaupunki (Helsinki)' },
  { id: '00760', label: '00760  Puistola - Heikinlaakso (Helsinki)' },
  { id: '00770', label: '00770  Jakomäki - Alppikylä (Helsinki)' },
  { id: '00780', label: '00780  Tapaninvainio (Helsinki)' },
  { id: '00790', label: '00790  Viikki (Helsinki)' },
  { id: '00800', label: '00800  Länsi-Herttoniemi (Helsinki)' },
  { id: '00810', label: '00810  Herttoniemenranta (Helsinki)' },
  { id: '00820', label: '00820  Roihuvuori (Helsinki)' },
  { id: '00830', label: '00830  Tammisalo (Helsinki)' },
  { id: '00840', label: '00840  Yliskylä (Helsinki)' },
  { id: '00850', label: '00850  Jollas (Helsinki)' },
  { id: '00860', label: '00860  Santahamina (Helsinki)' },
  { id: '00870', label: '00870  Gunillanmäki - Hevossalmi (Helsinki)' },
  { id: '00880', label: '00880  Keski-Herttoniemi - Roihupelto (Helsinki)' },
  { id: '00890', label: '00890  Östersundom (Helsinki)' },
  { id: '00900', label: '00900  Puotinharju (Helsinki)' },
  { id: '00910', label: '00910  Puotila (Helsinki)' },
  { id: '00920', label: '00920  Myllypuro (Helsinki)' },
  { id: '00930', label: '00930  Itäkeskus - Marjaniemi (Helsinki)' },
  { id: '00940', label: '00940  Kontula - Vesala - Kivikko (Helsinki)' },
  { id: '00950', label: '00950  Vartioharju (Helsinki)' },
  { id: '00960', label: '00960  Pohjois-Vuosaari (Helsinki)' },
  { id: '00970', label: '00970  Mellunmäki (Helsinki)' },
  { id: '00980', label: '00980  Etelä-Vuosaari (Helsinki)' },
  { id: '00990', label: '00990  Aurinkolahti (Helsinki)' },
  { id: '01120', label: '01120  Västerskog (Sipoo)' },
  { id: '01150', label: '01150  Söderkulla (Sipoo)' },
  { id: '01180', label: '01180  Kalkkiranta (Sipoo)' },
  { id: '01190', label: '01190  Box (Sipoo)' },
  { id: '01200', label: '01200  Hakunila (Vantaa)' },
  { id: '01230', label: '01230  Vaarala (Vantaa)' },
  { id: '01260', label: '01260  Itä-Hakkila (Vantaa)' },
  { id: '01280', label: '01280  Länsimäki (Vantaa)' },
  { id: '01300', label: '01300  Tikkurila (Vantaa)' },
  { id: '01340', label: '01340  Leinelä (Vantaa)' },
  { id: '01350', label: '01350  Hiekkaharju (Vantaa)' },
  { id: '01360', label: '01360  Koivukylä-Havukoski (Vantaa)' },
  { id: '01370', label: '01370  Jokiniemi (Vantaa)' },
  { id: '01380', label: '01380  Kuusikko-Hakkila (Vantaa)' },
  { id: '01390', label: '01390  Ruskeasanta-Ilola (Vantaa)' },
  { id: '01400', label: '01400  Rekola (Vantaa)' },
  { id: '01420', label: '01420  Päiväkumpu (Vantaa)' },
  { id: '01450', label: '01450  Korso (Vantaa)' },
  { id: '01480', label: '01480  Mikkola (Vantaa)' },
  { id: '01490', label: '01490  Nikinmäki (Vantaa)' },
  { id: '01510', label: '01510  Kirkonkylä-Veromäki (Vantaa)' },
  { id: '01520', label: '01520  Tammisto (Vantaa)' },
  { id: '01530', label: '01530  Veromiehenkylä (Vantaa)' },
  { id: '01600', label: '01600  Myyrmäki (Vantaa)' },
  { id: '01610', label: '01610  Kaivoksela (Vantaa)' },
  { id: '01620', label: '01620  Martinlaakso (Vantaa)' },
  { id: '01630', label: '01630  Hämeenkylä (Vantaa)' },
  { id: '01640', label: '01640  Hämevaara (Vantaa)' },
  { id: '01650', label: '01650  Vapaala (Vantaa)' },
  { id: '01660', label: '01660  Varisto (Vantaa)' },
  { id: '01670', label: '01670  Vantaanlaakso (Vantaa)' },
  { id: '01680', label: '01680  Askisto (Vantaa)' },
  { id: '01690', label: '01690  Ylästö (Vantaa)' },
  { id: '01700', label: '01700  Kivistö (Vantaa)' },
  { id: '01710', label: '01710  Pähkinärinne (Vantaa)' },
  { id: '01720', label: '01720  Petikko (Vantaa)' },
  { id: '01730', label: '01730  Vantaanpuisto (Vantaa)' },
  { id: '01740', label: '01740  Tuupakan teollisuusalue (Vantaa)' },
  { id: '01750', label: '01750  Keimola (Vantaa)' },
  { id: '01760', label: '01760  Seutula (Vantaa)' },
  { id: '01770', label: '01770  Martinlaakson teollisuusalue (Vantaa)' },
  { id: '01800', label: '01800  Klaukkala Keskus (Nurmijärvi)' },
  { id: '01810', label: '01810  Luhtajoki (Nurmijärvi)' },
  { id: '01820', label: '01820  Haikala (Nurmijärvi)' },
  { id: '01830', label: '01830  Lepsämä (Nurmijärvi)' },
  { id: '01840', label: '01840  Klaukkala (Nurmijärvi)' },
  { id: '01860', label: '01860  Perttula (Nurmijärvi)' },
  { id: '01900', label: '01900  Nurmijärvi Keskus (Nurmijärvi)' },
  { id: '01940', label: '01940  Palojoki (Nurmijärvi)' },
  { id: '02100', label: '02100  Tapiola (Espoo)' },
  { id: '02110', label: '02110  Otsolahti (Espoo)' },
  { id: '02120', label: '02120  Länsikorkee-Suvikumpu (Espoo)' },
  { id: '02130', label: '02130  Pohjois-Tapiola (Espoo)' },
  { id: '02140', label: '02140  Laajalahti (Espoo)' },
  { id: '02150', label: '02150  Otaniemi (Espoo)' },
  { id: '02160', label: '02160  Westend (Espoo)' },
  { id: '02170', label: '02170  Haukilahti (Espoo)' },
  { id: '02180', label: '02180  Mankkaa (Espoo)' },
  { id: '02200', label: '02200  Niittykumpu (Espoo)' },
  { id: '02210', label: '02210  Olari (Espoo)' },
  { id: '02230', label: '02230  Matinkylä (Espoo)' },
  { id: '02240', label: '02240  Friisilä (Espoo)' },
  { id: '02250', label: '02250  Henttaa (Espoo)' },
  { id: '02260', label: '02260  Kaitaa (Espoo)' },
  { id: '02270', label: '02270  Finnoo-Eestinmalmi (Espoo)' },
  { id: '02280', label: '02280  Malminmäki-Eestinlaakso (Espoo)' },
  { id: '02290', label: '02290  Puolarmetsän sairaala (Espoo)' },
  { id: '02300', label: '02300  Nöykkiönpuro (Espoo)' },
  { id: '02320', label: '02320  Espoonlahti (Espoo)' },
  { id: '02330', label: '02330  Saunalahti-Kattilalaakso (Espoo)' },
  { id: '02340', label: '02340  Latokaski (Espoo)' },
  { id: '02360', label: '02360  Soukka (Espoo)' },
  { id: '02380', label: '02380  Suvisaaristo (Espoo)' },
  { id: '02390', label: '02390  Sarvvik (Kirkkonummi)' },
  { id: '02400', label: '02400  Kirkkonummi Keskus (Kirkkonummi)' },
  { id: '02410', label: '02410  Gesterby (Kirkkonummi)' },
  { id: '02420', label: '02420  Jorvas (Kirkkonummi)' },
  { id: '02430', label: '02430  Masala (Kirkkonummi)' },
  { id: '02440', label: '02440  Luoma (Kirkkonummi)' },
  { id: '02450', label: '02450  Sundsberg (Kirkkonummi)' },
  { id: '02460', label: '02460  Kantvik (Kirkkonummi)' },
  { id: '02470', label: '02470  Upinniemi (Kirkkonummi)' },
  { id: '02480', label: '02480  Porkkala (Kirkkonummi)' },
  { id: '02490', label: '02490  Pikkala (Siuntio)' },
  { id: '02510', label: '02510  Oitmäki (Kirkkonummi)' },
  { id: '02520', label: '02520  Lapinkylä (Kirkkonummi)' },
  { id: '02540', label: '02540  Kylmälä (Kirkkonummi)' },
  { id: '02550', label: '02550  Evitskog (Kirkkonummi)' },
  { id: '02570', label: '02570  Siuntio Kirkonkylä (Siuntio)' },
  { id: '02580', label: '02580  Siuntio Asemanseutu (Siuntio)' },
  { id: '02590', label: '02590  Lappers (Siuntio)' },
  { id: '02600', label: '02600  Etelä-Leppävaara (Espoo)' },
  { id: '02610', label: '02610  Kilo (Espoo)' },
  { id: '02620', label: '02620  Karakallio (Espoo)' },
  { id: '02630', label: '02630  Nihtisilta (Espoo)' },
  { id: '02650', label: '02650  Pohjois-Leppävaara (Espoo)' },
  { id: '02660', label: '02660  Lintuvaara (Espoo)' },
  { id: '02680', label: '02680  Uusmäki (Espoo)' },
  { id: '02700', label: '02700  Kauniainen (Kauniainen)' },
  { id: '02710', label: '02710  Viherlaakso (Espoo)' },
  { id: '02720', label: '02720  Lähderanta (Espoo)' },
  { id: '02730', label: '02730  Jupperi (Espoo)' },
  { id: '02740', label: '02740  Bemböle-Pakankylä (Espoo)' },
  { id: '02750', label: '02750  Sepänkylä-Kuurinniitty (Espoo)' },
  { id: '02760', label: '02760  Tuomarila-Suvela (Espoo)' },
  { id: '02770', label: '02770  Espoon Keskus (Espoo)' },
  { id: '02780', label: '02780  Kauklahti (Espoo)' },
  { id: '02810', label: '02810  Gumböle-Karhusuo (Espoo)' },
  { id: '02820', label: '02820  Nupuri-Nuuksio (Espoo)' },
  { id: '02860', label: '02860  Siikajärvi (Espoo)' },
  { id: '02880', label: '02880  Veikkola (Kirkkonummi)' },
  { id: '02920', label: '02920  Niipperi (Espoo)' },
  { id: '02940', label: '02940  Lippajärvi-Järvenperä (Espoo)' },
  { id: '02970', label: '02970  Kalajärvi (Espoo)' },
  { id: '02980', label: '02980  Lakisto (Espoo)' },
  { id: '03100', label: '03100  Nummela (Vihti)' },
  { id: '03150', label: '03150  Huhmari (Vihti)' },
  { id: '03220', label: '03220  Tervalampi (Vihti)' },
  { id: '03250', label: '03250  Ojakkala (Vihti)' },
  { id: '03300', label: '03300  Otalampi (Vihti)' },
  { id: '03320', label: '03320  Selki (Vihti)' },
  { id: '03400', label: '03400  Vihti Kirkonkylä (Vihti)' },
  { id: '03430', label: '03430  Jokikunta (Vihti)' },
  { id: '03600', label: '03600  Karkkila Keskus (Karkkila)' },
  { id: '03620', label: '03620  Polari (Karkkila)' },
  { id: '03710', label: '03710  Uusitalo (Karkkila)' },
  { id: '03790', label: '03790  Vihtijärvi (Vihti)' },
  { id: '03810', label: '03810  Ikkala (Lohja)' },
  { id: '03850', label: '03850  Pusula (Lohja)' },
  { id: '03870', label: '03870  Hyönölä (Lohja)' },
  { id: '04130', label: '04130  Sipoo (Sipoo)' },
  { id: '04150', label: '04150  Martinkylä (Sipoo)' },
  { id: '04170', label: '04170  Paippinen (Sipoo)' },
  { id: '04200', label: '04200  Kerava Keskus (Kerava)' },
  { id: '04220', label: '04220  Ahjo-Kaskela (Kerava)' },
  { id: '04230', label: '04230  Pihkaniitty (Kerava)' },
  { id: '04240', label: '04240  Talma (Sipoo)' },
  { id: '04250', label: '04250  Alikerava (Kerava)' },
  { id: '04260', label: '04260  Savio (Kerava)' },
  { id: '04300', label: '04300  Hyrylä (Tuusula)' },
  { id: '04310', label: '04310  Tuusula Kirkonkylä (Tuusula)' },
  { id: '04320', label: '04320  Riihikallio (Tuusula)' },
  { id: '04330', label: '04330  Lahela (Tuusula)' },
  { id: '04340', label: '04340  Mattila (Tuusula)' },
  { id: '04350', label: '04350  Nahkela (Tuusula)' },
  { id: '04360', label: '04360  Ruotsinkylä-Kiilinmäki (Tuusula)' },
  { id: '04370', label: '04370  Rusutjärvi (Tuusula)' },
  { id: '04380', label: '04380  Tuomala (Tuusula)' },
  { id: '04390', label: '04390  Jäniksenlinna (Tuusula)' },
  { id: '04400', label: '04400  Loutti (Järvenpää)' },
  { id: '04410', label: '04410  Järvenpää Keskus (Järvenpää)' },
  { id: '04420', label: '04420  Kyrölä (Järvenpää)' },
  { id: '04430', label: '04430  Kinnari-Mikonkorpi (Järvenpää)' },
  { id: '04440', label: '04440  Jamppa (Järvenpää)' },
  { id: '04460', label: '04460  Nummenkylä (Järvenpää)' },
  { id: '04480', label: '04480  Haarajoki (Järvenpää)' },
  { id: '04500', label: '04500  Kellokoski (Tuusula)' },
  { id: '04530', label: '04530  Ohkola (Mäntsälä)' },
  { id: '04600', label: '04600  Mäntsälä Keskus (Mäntsälä)' },
  { id: '04620', label: '04620  Kaunismäki (Mäntsälä)' },
  { id: '04630', label: '04630  Sääksjärvi (Mäntsälä)' },
  { id: '04660', label: '04660  Numminen (Mäntsälä)' },
  { id: '04680', label: '04680  Hirvihaara (Mäntsälä)' },
  { id: '04740', label: '04740  Sälinkää (Mäntsälä)' },
  { id: '04770', label: '04770  Sahakylä (Mäntsälä)' },
  { id: '04820', label: '04820  Kaukalampi (Mäntsälä)' },
  { id: '04840', label: '04840  Hautjärvi (Mäntsälä)' },
  { id: '04920', label: '04920  Saarentaus (Mäntsälä)' },
  { id: '04940', label: '04940  Levanto (Mäntsälä)' },
  { id: '05100', label: '05100  Röykkä (Nurmijärvi)' },
  { id: '05200', label: '05200  Rajamäki (Nurmijärvi)' },
  { id: '05250', label: '05250  Kiljava (Nurmijärvi)' },
  { id: '05400', label: '05400  Jokela (Tuusula)' },
  { id: '05430', label: '05430  Nuppulinna (Tuusula)' },
  { id: '05440', label: '05440  Hyvinkää (Hyvinkää)' },
  { id: '05450', label: '05450  Nukari (Nurmijärvi)' },
  { id: '05460', label: '05460  Hakalanmäki-Hakala (Hyvinkää)' },
  { id: '05470', label: '05470  Palopuro (Hyvinkää)' },
  { id: '05510', label: '05510  Päivärinta (Hyvinkää)' },
  { id: '05620', label: '05620  Noppo (Hyvinkää)' },
  { id: '05720', label: '05720  Kytäjä (Hyvinkää)' },
  { id: '05800', label: '05800  Hyvinkää Keskus (Hyvinkää)' },
  { id: '05810', label: '05810  Martti (Hyvinkää)' },
  { id: '05820', label: '05820  Mustamännistö (Hyvinkää)' },
  { id: '05830', label: '05830  Kirjavatolppa-Kruununpuisto (Hyvinkää)' },
  { id: '05840', label: '05840  Hyvinkäänkylä-Vehkoja (Hyvinkää)' },
  { id: '05850', label: '05850  Hyvinkää, sairaala-alue (Hyvinkää)' },
  { id: '05860', label: '05860  Talvisilta (Hyvinkää)' },
  { id: '05880', label: '05880  Paavola-Vaivero (Hyvinkää)' },
  { id: '05900', label: '05900  Vieremä (Hyvinkää)' },
  { id: '05950', label: '05950  Ridasjärvi (Hyvinkää)' },
  { id: '06100', label: '06100  Porvoo Keskus (Porvoo)' },
  { id: '06150', label: '06150  Kevätkumpu (Porvoo)' },
  { id: '06200', label: '06200  Porvoo itäinen (Porvoo)' },
  { id: '06400', label: '06400  Gammelbacka (Porvoo)' },
  { id: '06450', label: '06450  Eestinmäki-Ernestas (Porvoo)' },
  { id: '06500', label: '06500  Saksala-Suomenkylä (Porvoo)' },
  { id: '06530', label: '06530  Kerkkoo (Porvoo)' },
  { id: '06650', label: '06650  Hamari (Porvoo)' },
  { id: '06750', label: '06750  Tolkkinen (Porvoo)' },
  { id: '06830', label: '06830  Kulloonkylä (Porvoo)' },
  { id: '06850', label: '06850  Kulloo (Porvoo)' },
  { id: '06880', label: '06880  Kärrby (Sipoo)' },
  { id: '06950', label: '06950  Emäsalo (Porvoo)' },
  { id: '07110', label: '07110  Hinthaara (Porvoo)' },
  { id: '07130', label: '07130  Anttila (Porvoo)' },
  { id: '07150', label: '07150  Laukkoski (Pornainen)' },
  { id: '07170', label: '07170  Keski-Pornainen (Pornainen)' },
  { id: '07190', label: '07190  Halkia (Pornainen)' },
  { id: '07220', label: '07220  Kaarenkylä (Porvoo)' },
  { id: '07230', label: '07230  Monninkylä (Askola)' },
  { id: '07280', label: '07280  Ilola (Porvoo)' },
  { id: '07310', label: '07310  Sannainen (Porvoo)' },
  { id: '07320', label: '07320  Jakari-Sannäs (Porvoo)' },
  { id: '07350', label: '07350  Gäddrag (Porvoo)' },
  { id: '07360', label: '07360  Tirmo (Porvoo)' },
  { id: '07370', label: '07370  Pellinki (Porvoo)' },
  { id: '07390', label: '07390  Suurpellinki (Porvoo)' },
  { id: '07410', label: '07410  Kråkö (Porvoo)' },
  { id: '07450', label: '07450  Voolahti (Porvoo)' },
  { id: '07500', label: '07500  Askola Kirkonkylä (Askola)' },
  { id: '07510', label: '07510  Vakkola (Askola)' },
  { id: '07530', label: '07530  Onkimaa (Askola)' },
  { id: '07550', label: '07550  Torpinkylä (Pukkila)' },
  { id: '07560', label: '07560  Pukkila Keskus (Pukkila)' },
  { id: '07565', label: '07565  Kantele (Pukkila)' },
  { id: '07580', label: '07580  Tiilää (Askola)' },
  { id: '07590', label: '07590  Huuvari-Tiilää (Askola)' },
  { id: '07600', label: '07600  Myrskylä Keskus (Myrskylä)' },
  { id: '07680', label: '07680  Juornaankylä (Askola)' },
  { id: '07690', label: '07690  Kankkila (Myrskylä)' },
  { id: '07700', label: '07700  Koskenkylän saha-alue (Loviisa)' },
  { id: '07720', label: '07720  Malmgård (Loviisa)' },
  { id: '07740', label: '07740  Gammelby (Loviisa)' },
  { id: '07750', label: '07750  Isnäs (Loviisa)' },
  { id: '07780', label: '07780  Härkäpää (Loviisa)' },
  { id: '07800', label: '07800  Lapinjärvi Keskus (Lapinjärvi)' },
  { id: '07810', label: '07810  Ingermaninkylä (Lapinjärvi)' },
  { id: '07820', label: '07820  Porlammi (Lapinjärvi)' },
  { id: '07830', label: '07830  Pukaro (Lapinjärvi)' },
  { id: '07840', label: '07840  Lindkoski (Lapinjärvi)' },
  { id: '07850', label: '07850  Heikinkylä (Lapinjärvi)' },
  { id: '07870', label: '07870  Skinnarby (Loviisa)' },
  { id: '07880', label: '07880  Liljendal Keskus (Loviisa)' },
  { id: '07890', label: '07890  Mickelspiltom (Loviisa)' },
  { id: '07900', label: '07900  Loviisa Keskus (Loviisa)' },
  { id: '07910', label: '07910  Valko (Loviisa)' },
  { id: '07920', label: '07920  Etelä-Harju (Loviisa)' },
  { id: '07930', label: '07930  Pernaja Kirkonkylä (Loviisa)' },
  { id: '07940', label: '07940  Määrlahti (Loviisa)' },
  { id: '07945', label: '07945  Kuggom (Loviisa)' },
  { id: '07955', label: '07955  Tesjoki (Loviisa)' },
  { id: '07960', label: '07960  Ahvenkoski (Loviisa)' },
  { id: '07970', label: '07970  Ruotsinpyhtää Keskus (Loviisa)' },
  { id: '07980', label: '07980  Kuninkaankylä (Loviisa)' },
  { id: '07990', label: '07990  Ruotsinkylä-Koskisto (Loviisa)' },
  { id: '08100', label: '08100  Lohja Keskus (Lohja)' },
  { id: '08150', label: '08150  Keskilohja (Lohja)' },
  { id: '08200', label: '08200  Ojamo (Lohja)' },
  { id: '08350', label: '08350  Routio (Lohja)' },
  { id: '08360', label: '08360  Kisakallio (Lohja)' },
  { id: '08450', label: '08450  Hormajärvi (Lohja)' },
  { id: '08480', label: '08480  Karstu (Lohja)' },
  { id: '08500', label: '08500  Ventelä-Millola (Lohja)' },
  { id: '08680', label: '08680  Muijala (Lohja)' },
  { id: '08700', label: '08700  Virkkala (Lohja)' },
  { id: '08800', label: '08800  Kirkniemi (Lohja)' },
  { id: '09120', label: '09120  Karjalohja (Lohja)' },
  { id: '09220', label: '09220  Sammatti (Lohja)' },
  { id: '09430', label: '09430  Saukkola (Lohja)' },
  { id: '09520', label: '09520  Millola (Lohja)' },
  { id: '09630', label: '09630  Koisjärvi (Lohja)' },
  { id: '09810', label: '09810  Nummi-Oinola (Lohja)' },
  { id: '09930', label: '09930  Leppäkorpi (Lohja)' },
  { id: '10120', label: '10120  Tähtelä (Inkoo)' },
  { id: '10140', label: '10140  Päivölä (Inkoo)' },
  { id: '10160', label: '10160  Degerby (Inkoo)' },
  { id: '10210', label: '10210  Inkoo Keskus (Inkoo)' },
  { id: '10230', label: '10230  Inkoo Asemanseutu (Inkoo)' },
  { id: '10250', label: '10250  Fagervik (Inkoo)' },
  { id: '10270', label: '10270  Barösund (Inkoo)' },
  { id: '10300', label: '10300  Karjaa Keskus (Raasepori)' },
  { id: '10320', label: '10320  Bäljars (Raasepori)' },
  { id: '10330', label: '10330  Pinjainen (Raasepori)' },
  { id: '10350', label: '10350  Meltolan sairaala-alue (Raasepori)' },
  { id: '10360', label: '10360  Mustio (Raasepori)' },
  { id: '10380', label: '10380  Kaunislahti (Raasepori)' },
  { id: '10410', label: '10410  Åminnefors (Raasepori)' },
  { id: '10420', label: '10420  Pohjankuru (Raasepori)' },
  { id: '10440', label: '10440  Bollsta (Raasepori)' },
  { id: '10470', label: '10470  Fiskari (Raasepori)' },
  { id: '10480', label: '10480  Antskog (Raasepori)' },
  { id: '10520', label: '10520  Tenhola Kirkonkylä (Raasepori)' },
  { id: '10570', label: '10570  Bromarv (Raasepori)' },
  { id: '10600', label: '10600  Tammisaari Keskus (Raasepori)' },
  { id: '10620', label: '10620  Tammisaari (Raasepori)' },
  { id: '10640', label: '10640  Dragsvik (Raasepori)' },
  { id: '10650', label: '10650  Flyet (Raasepori)' },
  { id: '10660', label: '10660  Trollböle (Raasepori)' },
  { id: '10680', label: '10680  Skogby (Raasepori)' },
  { id: '10710', label: '10710  Snappertuna (Raasepori)' },
  { id: '10820', label: '10820  Lappohja (Hanko)' },
  { id: '10900', label: '10900  Hanko Keskus (Hanko)' },
  { id: '10940', label: '10940  Hangonkylä (Hanko)' },
  { id: '10960', label: '10960  Hanko Pohjoinen (Hanko)' },
  { id: '11100', label: '11100  Riihimäki Keskus (Riihimäki)' },
  { id: '11120', label: '11120  Pohjois-Riihimäki (Riihimäki)' },
  { id: '11130', label: '11130  Peltosaari-Patastenmäki (Riihimäki)' },
  { id: '11310', label: '11310  Taka-Kasarmi (Riihimäki)' },
  { id: '11710', label: '11710  Herajoki (Riihimäki)' },
  { id: '11910', label: '11910  Lasi-Kumela (Riihimäki)' },
  { id: '12100', label: '12100  Oitti (Hausjärvi)' },
  { id: '12130', label: '12130  Mommila (Hausjärvi)' },
  { id: '12170', label: '12170  Hietoinen (Hausjärvi)' },
  { id: '12210', label: '12210  Hausjärvi kk (Hausjärvi)' },
  { id: '12240', label: '12240  Hikiä (Hausjärvi)' },
  { id: '12310', label: '12310  Ryttylä (Hausjärvi)' },
  { id: '12350', label: '12350  Turkhauta (Hausjärvi)' },
  { id: '12380', label: '12380  Leppäkoski (Janakkala)' },
  { id: '12400', label: '12400  Tervakoski (Janakkala)' },
  { id: '12450', label: '12450  Vähikkälä (Janakkala)' },
  { id: '12520', label: '12520  Kormu (Loppi)' },
  { id: '12540', label: '12540  Launonen (Loppi)' },
  { id: '12600', label: '12600  Läyliäinen (Loppi)' },
  { id: '12630', label: '12630  Sajaniemi (Loppi)' },
  { id: '12640', label: '12640  Joentaka (Loppi)' },
  { id: '12700', label: '12700  Loppi kk (Loppi)' },
  { id: '12750', label: '12750  Pilpala (Loppi)' },
  { id: '12820', label: '12820  Räyskälä (Loppi)' },
  { id: '12920', label: '12920  Topeno (Loppi)' },
  { id: '12950', label: '12950  Vojakkala-Eerolankulma (Loppi)' },
  { id: '13100', label: '13100  Hämeenlinna Keskus (Hämeenlinna)' },
  { id: '13110', label: '13110  Kantola (Hämeenlinna)' },
  { id: '13130', label: '13130  Kauriala (Hämeenlinna)' },
  { id: '13200', label: '13200  Keinusaari (Hämeenlinna)' },
  { id: '13210', label: '13210  Hätilä (Hämeenlinna)' },
  { id: '13220', label: '13220  Sairio (Hämeenlinna)' },
  { id: '13250', label: '13250  Katuma (Hämeenlinna)' },
  { id: '13270', label: '13270  Ruununmylly (Hämeenlinna)' },
  { id: '13300', label: '13300  Käikälä (Hämeenlinna)' },
  { id: '13330', label: '13330  Harviala (Janakkala)' },
  { id: '13430', label: '13430  Hattelmala (Hämeenlinna)' },
  { id: '13500', label: '13500  Jukola (Hämeenlinna)' },
  { id: '13530', label: '13530  Hämeenlinna sairaala-alue (Hämeenlinna)' },
  { id: '13600', label: '13600  Ojoinen (Hämeenlinna)' },
  { id: '13700', label: '13700  Parolannummi (Hattula)' },
  { id: '13720', label: '13720  Parola (Hattula)' },
  { id: '13800', label: '13800  Katinala (Hattula)' },
  { id: '13880', label: '13880  Rahkoila (Hattula)' },
  { id: '13900', label: '13900  Pekola (Hattula)' },
  { id: '14140', label: '14140  Jokimaa (Janakkala)' },
  { id: '14200', label: '14200  Turenki (Janakkala)' },
  { id: '14240', label: '14240  Väli-Janakkala (Janakkala)' },
  { id: '14300', label: '14300  Renko kk (Hämeenlinna)' },
  { id: '14430', label: '14430  Vuohiniemi (Hattula)' },
  { id: '14450', label: '14450  Rimmilä (Hämeenlinna)' },
  { id: '14500', label: '14500  Iittala (Hämeenlinna)' },
  { id: '14530', label: '14530  Leteensuo (Hattula)' },
  { id: '14610', label: '14610  Lepaa (Hattula)' },
  { id: '14620', label: '14620  Tyrväntö (Hattula)' },
  { id: '14680', label: '14680  Alvettula (Hämeenlinna)' },
  { id: '14690', label: '14690  Torvoila (Hämeenlinna)' },
  { id: '14700', label: '14700  Hauho kk (Hämeenlinna)' },
  { id: '14770', label: '14770  Eteläinen (Hämeenlinna)' },
  { id: '14810', label: '14810  Pohjoinen (Hämeenlinna)' },
  { id: '14820', label: '14820  Syrjäntaka (Hämeenlinna)' },
  { id: '14840', label: '14840  Sairiala (Hämeenlinna)' },
  { id: '14870', label: '14870  Juttila (Hämeenlinna)' },
  { id: '14930', label: '14930  Sappee (Hämeenlinna)' },
  { id: '14960', label: '14960  Puutikkala (Pälkäne)' },
  { id: '14980', label: '14980  Kuohijoki (Pälkäne)' },
  { id: '15100', label: '15100  Lahti Asemanseutu (Lahti)' },
  { id: '15110', label: '15110  Keski-Lahti (Lahti)' },
  { id: '15140', label: '15140  Kartano-Paavola (Lahti)' },
  { id: '15150', label: '15150  Möysä (Lahti)' },
  { id: '15160', label: '15160  Kolava (Lahti)' },
  { id: '15170', label: '15170  Tenava-Tonttila (Lahti)' },
  { id: '15200', label: '15200  Kiveriö (Lahti)' },
  { id: '15210', label: '15210  Kivistönmäki (Lahti)' },
  { id: '15230', label: '15230  Kytölä (Lahti)' },
  { id: '15240', label: '15240  Mukkula (Lahti)' },
  { id: '15270', label: '15270  Kukkila (Hollola)' },
  { id: '15300', label: '15300  Myllypohja-Koiskala (Lahti)' },
  { id: '15320', label: '15320  Ahtiala (Lahti)' },
  { id: '15340', label: '15340  Kunnas-Hiekkanummi (Lahti)' },
  { id: '15460', label: '15460  Mäkelä (Lahti)' },
  { id: '15500', label: '15500  Liipola (Lahti)' },
  { id: '15520', label: '15520  Saksala (Lahti)' },
  { id: '15540', label: '15540  Villähde (Lahti)' },
  { id: '15550', label: '15550  Rakokivi (Lahti)' },
  { id: '15560', label: '15560  Nastola kk (Lahti)' },
  { id: '15580', label: '15580  Ruuhijärvi (Lahti)' },
  { id: '15610', label: '15610  Laune-Nikkilä (Lahti)' },
  { id: '15680', label: '15680  Renkomäki-Ämmälä (Lahti)' },
  { id: '15700', label: '15700  Hennala-Jokimaa (Lahti)' },
  { id: '15800', label: '15800  Kärpänen (Lahti)' },
  { id: '15810', label: '15810  Kasakkamäki (Lahti)' },
  { id: '15820', label: '15820  Okeroinen (Hollola)' },
  { id: '15830', label: '15830  Metsäkangas (Lahti)' },
  { id: '15840', label: '15840  Riihelä (Lahti)' },
  { id: '15850', label: '15850  Likolampi (Lahti)' },
  { id: '15860', label: '15860  Kartano (Hollola)' },
  { id: '15870', label: '15870  Salpakangas (Hollola)' },
  { id: '15880', label: '15880  Soramäki (Hollola)' },
  { id: '15900', label: '15900  Jalkaranta (Lahti)' },
  { id: '15950', label: '15950  Kiikkula (Lahti)' },
  { id: '15980', label: '15980  Messilä (Hollola)' },
  { id: '16100', label: '16100  Uusikylä (Lahti)' },
  { id: '16160', label: '16160  Okkeri (Lahti)' },
  { id: '16200', label: '16200  Artjärvi Keskus (Orimattila)' },
  { id: '16230', label: '16230  Artjärvi Kirkonkylä (Orimattila)' },
  { id: '16260', label: '16260  Hietana (Orimattila)' },
  { id: '16270', label: '16270  Villikkala (Orimattila)' },
  { id: '16280', label: '16280  Kuivanto (Orimattila)' },
  { id: '16300', label: '16300  Orimattila Keskus (Orimattila)' },
  { id: '16310', label: '16310  Virenoja (Orimattila)' },
  { id: '16320', label: '16320  Pennala (Orimattila)' },
  { id: '16330', label: '16330  Heinämaa (Orimattila)' },
  { id: '16350', label: '16350  Niinikoski (Orimattila)' },
  { id: '16390', label: '16390  Pakaa (Orimattila)' },
  { id: '16450', label: '16450  Mallusjoki (Orimattila)' },
  { id: '16500', label: '16500  Herrala (Hollola)' },
  { id: '16510', label: '16510  Luhtikylä (Orimattila)' },
  { id: '16540', label: '16540  Mertie (Kärkölä)' },
  { id: '16600', label: '16600  Järvelä (Kärkölä)' },
  { id: '16610', label: '16610  Kärkölä kk (Kärkölä)' },
  { id: '16630', label: '16630  Tennilä (Hollola)' },
  { id: '16670', label: '16670  Lappila (Kärkölä)' },
  { id: '16710', label: '16710  Hollola kk (Hollola)' },
  { id: '16730', label: '16730  Kutajärvi (Hollola)' },
  { id: '16790', label: '16790  Manskivi (Hollola)' },
  { id: '16800', label: '16800  Hämeenkoski (Hollola)' },
  { id: '16900', label: '16900  Lammi kk (Hämeenlinna)' },
  { id: '16960', label: '16960  Iso-Evo (Hämeenlinna)' },
  { id: '16970', label: '16970  Evo (Hämeenlinna)' },
  { id: '17110', label: '17110  Sipilä (Hollola)' },
  { id: '17120', label: '17120  Paimela (Hollola)' },
  { id: '17130', label: '17130  Vesivehmaa (Asikkala)' },
  { id: '17150', label: '17150  Urajärvi (Asikkala)' },
  { id: '17200', label: '17200  Vääksy Keskus (Asikkala)' },
  { id: '17220', label: '17220  Pietilä (Asikkala)' },
  { id: '17240', label: '17240  Kalkkinen (Asikkala)' },
  { id: '17320', label: '17320  Asikkala (Asikkala)' },
  { id: '17410', label: '17410  Viitaila (Asikkala)' },
  { id: '17430', label: '17430  Kurhila (Asikkala)' },
  { id: '17440', label: '17440  Vähimaa (Asikkala)' },
  { id: '17450', label: '17450  Iso-Äiniö-Vähimaa (Asikkala)' },
  { id: '17470', label: '17470  Maakeski (Padasjoki)' },
  { id: '17500', label: '17500  Padasjoki Keskus (Padasjoki)' },
  { id: '17510', label: '17510  Nyystölä (Padasjoki)' },
  { id: '17530', label: '17530  Arrakoski (Padasjoki)' },
  { id: '17610', label: '17610  Auttoinen (Padasjoki)' },
  { id: '17630', label: '17630  Vesijako (Padasjoki)' },
  { id: '17710', label: '17710  Torittu (Padasjoki)' },
  { id: '17740', label: '17740  Kasiniemi-Torittu (Padasjoki)' },
  { id: '17780', label: '17780  Harmoinen (Kuhmoinen)' },
  { id: '17800', label: '17800  Kuhmoinen Keskus (Kuhmoinen)' },
  { id: '17840', label: '17840  Harjunsalmi (Kuhmoinen)' },
  { id: '17850', label: '17850  Pihlajakoski (Kuhmoinen)' },
  { id: '17870', label: '17870  Ruolahti (Kuhmoinen)' },
  { id: '17930', label: '17930  Päijälä (Kuhmoinen)' },
  { id: '17950', label: '17950  Kylämä (Kuhmoinen)' },
  { id: '17970', label: '17970  Puukkoinen (Kuhmoinen)' },
  { id: '18100', label: '18100  Heinola Keskus (Heinola)' },
  { id: '18120', label: '18120  Reumasairaala-alue (Heinola)' },
  { id: '18130', label: '18130  Tommola-Sahanniemi (Heinola)' },
  { id: '18150', label: '18150  Jyränkö (Heinola)' },
  { id: '18200', label: '18200  Kaakonlampi-Pyhätön (Heinola)' },
  { id: '18300', label: '18300  Heinola kk (Heinola)' },
  { id: '18600', label: '18600  Myllyoja (Heinola)' },
  { id: '19110', label: '19110  Vierumäki taajama (Heinola)' },
  { id: '19120', label: '19120  Vierumäki urheiluopisto (Heinola)' },
  { id: '19160', label: '19160  Huutotöyry (Iitti)' },
  { id: '19210', label: '19210  Lusi (Heinola)' },
  { id: '19230', label: '19230  Onkiniemi (Sysmä)' },
  { id: '19250', label: '19250  Syrjäkoski (Heinola)' },
  { id: '19260', label: '19260  Paaso-Syrjäkoski (Heinola)' },
  { id: '19310', label: '19310  Karilanmaa (Sysmä)' },
  { id: '19320', label: '19320  Nikkaroinen (Sysmä)' },
  { id: '19370', label: '19370  Nuoramoinen (Sysmä)' },
  { id: '19410', label: '19410  Kuortti (Pertunmaa)' },
  { id: '19420', label: '19420  Mansikkamäki (Pertunmaa)' },
  { id: '19430', label: '19430  Pertunmaa Keskus (Pertunmaa)' },
  { id: '19460', label: '19460  Ruorasmäki (Pertunmaa)' },
  { id: '19470', label: '19470  Lihavanpää-Hartosenpää (Pertunmaa)' },
  { id: '19480', label: '19480  Hartosenpää (Pertunmaa)' },
  { id: '19510', label: '19510  Lepsala (Hartola)' },
  { id: '19540', label: '19540  Koitti-Lepsala (Hartola)' },
  { id: '19600', label: '19600  Hartola Keskus (Hartola)' },
  { id: '19610', label: '19610  Murakka (Hartola)' },
  { id: '19620', label: '19620  Pohela (Hartola)' },
  { id: '19630', label: '19630  Kalhonkylä (Hartola)' },
  { id: '19650', label: '19650  Joutsa Keskus (Joutsa)' },
  { id: '19670', label: '19670  Mieskonmäki (Joutsa)' },
  { id: '19700', label: '19700  Sysmä Keskus (Sysmä)' },
  { id: '19730', label: '19730  Särkilampi (Sysmä)' },
  { id: '19740', label: '19740  Liikola (Sysmä)' },
  { id: '19770', label: '19770  Valittula (Sysmä)' },
  { id: '19850', label: '19850  Putkijärvi-Siltasuo (Hartola)' },
  { id: '19910', label: '19910  Tammijärvi (Luhanka)' },
  { id: '19920', label: '19920  Pappinen (Joutsa)' },
  { id: '19950', label: '19950  Luhanka (Luhanka)' },
  { id: '20100', label: '20100  Turku Keskus (Turku)' },
  { id: '20200', label: '20200  Iso-Heikkilä (Turku)' },
  { id: '20210', label: '20210  Pahaniemi (Turku)' },
  { id: '20240', label: '20240  Pansio-Perno (Turku)' },
  { id: '20250', label: '20250  Pitkämäki-Muhkuri (Turku)' },
  { id: '20300', label: '20300  Pohjola-Kastu (Turku)' },
  { id: '20320', label: '20320  Kuninkoja-Mälikkälä (Turku)' },
  { id: '20360', label: '20360  Runosmäki-Kärsämäki (Turku)' },
  { id: '20380', label: '20380  Räntämäki-Saramäki (Turku)' },
  { id: '20400', label: '20400  Moisio (Turku)' },
  { id: '20460', label: '20460  Jäkärlä (Turku)' },
  { id: '20500', label: '20500  Vesilinna (Turku)' },
  { id: '20520', label: '20520  Kupittaa (Turku)' },
  { id: '20540', label: '20540  Nummi-Ylioppilaskylä (Turku)' },
  { id: '20610', label: '20610  Varissuo (Turku)' },
  { id: '20660', label: '20660  Littoinen (Kaarina)' },
  { id: '20700', label: '20700  Vartiovuori-Samppalinna (Turku)' },
  { id: '20720', label: '20720  Vasaramäki (Turku)' },
  { id: '20730', label: '20730  Skanssi (Turku)' },
  { id: '20740', label: '20740  Ilpoinen-Harittu (Turku)' },
  { id: '20750', label: '20750  Huhkola-Lauste-Vaala (Turku)' },
  { id: '20760', label: '20760  Piispanristi (Kaarina)' },
  { id: '20780', label: '20780  Kaarina Keskus (Kaarina)' },
  { id: '20800', label: '20800  Ammattikorkeakoulu (Turku)' },
  { id: '20810', label: '20810  Martti-Korppolaismäki (Turku)' },
  { id: '20880', label: '20880  Uittamo (Turku)' },
  { id: '20900', label: '20900  Moikoinen-Pikisaari (Turku)' },
  { id: '20960', label: '20960  Kakskerta (Turku)' },
  { id: '21100', label: '21100  Naantali Keskus (Naantali)' },
  { id: '21110', label: '21110  Taimo-Nuhjala-Lietsala (Naantali)' },
  { id: '21120', label: '21120  Viherjäinen (Raisio)' },
  { id: '21130', label: '21130  Poikko (Naantali)' },
  { id: '21140', label: '21140  Rymättylä Keskus (Naantali)' },
  { id: '21150', label: '21150  Röölä (Naantali)' },
  { id: '21160', label: '21160  Merimasku (Naantali)' },
  { id: '21180', label: '21180  Livonsaari (Naantali)' },
  { id: '21195', label: '21195  Velkua (Naantali)' },
  { id: '21200', label: '21200  Raisio Keskus (Raisio)' },
  { id: '21210', label: '21210  Hakinmäki (Raisio)' },
  { id: '21220', label: '21220  Somersoja (Raisio)' },
  { id: '21230', label: '21230  Lemu (Masku)' },
  { id: '21240', label: '21240  Askainen (Masku)' },
  { id: '21250', label: '21250  Masku (Masku)' },
  { id: '21260', label: '21260  Ihala (Raisio)' },
  { id: '21270', label: '21270  Nousiainen (Nousiainen)' },
  { id: '21280', label: '21280  Kuloinen (Raisio)' },
  { id: '21290', label: '21290  Rusko (Rusko)' },
  { id: '21310', label: '21310  Vahto (Rusko)' },
  { id: '21330', label: '21330  Paattinen (Turku)' },
  { id: '21340', label: '21340  Tortinmäki (Turku)' },
  { id: '21350', label: '21350  Ilmarinen (Lieto)' },
  { id: '21360', label: '21360  Lieto Asemanseutu (Lieto)' },
  { id: '21370', label: '21370  Aura kk. (Aura)' },
  { id: '21380', label: '21380  Aura Keskus (Aura)' },
  { id: '21410', label: '21410  Vanhalinna (Lieto)' },
  { id: '21420', label: '21420  Lieto Keskus (Lieto)' },
  { id: '21430', label: '21430  Yliskulma (Lieto)' },
  { id: '21450', label: '21450  Tarvasjoki Keskus (Lieto)' },
  { id: '21480', label: '21480  Prunkila (Marttila)' },
  { id: '21490', label: '21490  Marttila (Marttila)' },
  { id: '21500', label: '21500  Piikkiö (Kaarina)' },
  { id: '21510', label: '21510  Hevonpää (Paimio)' },
  { id: '21520', label: '21520  Naskarla (Paimio)' },
  { id: '21530', label: '21530  Paimio Keskus (Paimio)' },
  { id: '21540', label: '21540  Preitilä (Paimio)' },
  { id: '21555', label: '21555  Taatila (Paimio)' },
  { id: '21560', label: '21560  Ollila (Marttila)' },
  { id: '21570', label: '21570  Sauvo Keskus (Sauvo)' },
  { id: '21590', label: '21590  Karuna (Sauvo)' },
  { id: '21600', label: '21600  Parainen Keskus (Parainen)' },
  { id: '21610', label: '21610  Kirjala (Parainen)' },
  { id: '21620', label: '21620  Kuusisto (Kaarina)' },
  { id: '21630', label: '21630  Lielahti (Parainen)' },
  { id: '21650', label: '21650  Lillandet (Parainen)' },
  { id: '21660', label: '21660  Nauvo Keskus (Parainen)' },
  { id: '21670', label: '21670  Mattnäs-Nötö (Parainen)' },
  { id: '21680', label: '21680  Nötö (Parainen)' },
  { id: '21710', label: '21710  Korppoo Keskus-Utö (Parainen)' },
  { id: '21720', label: '21720  Korpoström (Parainen)' },
  { id: '21740', label: '21740  Utö (Parainen)' },
  { id: '21750', label: '21750  Norrskata (Parainen)' },
  { id: '21760', label: '21760  Houtskär Keskus (Parainen)' },
  { id: '21770', label: '21770  Mossala (Parainen)' },
  { id: '21800', label: '21800  Kyrö (Pöytyä)' },
  { id: '21820', label: '21820  Kumila (Pöytyä)' },
  { id: '21840', label: '21840  Karinainen (Pöytyä)' },
  { id: '21860', label: '21860  Auvainen (Pöytyä)' },
  { id: '21870', label: '21870  Riihikoski (Pöytyä)' },
  { id: '21880', label: '21880  Pöytyä (Pöytyä)' },
  { id: '21890', label: '21890  Haveri (Pöytyä)' },
  { id: '21900', label: '21900  Yläne Keskus (Pöytyä)' },
  { id: '21930', label: '21930  Uusikartano (Pöytyä)' },
  { id: '22100', label: '22100  Maarianhamina (Maarianhamina - Mariehamn)' },
  { id: '22120', label: '22120  Möckelö (Jomala)' },
  { id: '22130', label: '22130  Gottby (Jomala)' },
  { id: '22140', label: '22140  Önningby (Jomala)' },
  { id: '22150', label: '22150  Jomala Centrum (Jomala)' },
  { id: '22160', label: '22160  Järsö (Lemland)' },
  { id: '22220', label: '22220  Emkarby (Finström)' },
  { id: '22240', label: '22240  Hammarland (Hammarland)' },
  { id: '22270', label: '22270  Storby (Eckerö)' },
  { id: '22310', label: '22310  Pålsböle (Finström)' },
  { id: '22320', label: '22320  Ödkarby (Saltvik)' },
  { id: '22330', label: '22330  Tjudö (Finström)' },
  { id: '22340', label: '22340  Geta (Geta)' },
  { id: '22410', label: '22410  Godby (Finström)' },
  { id: '22430', label: '22430  Saltvik (Saltvik)' },
  { id: '22520', label: '22520  Tosarby (Sund)' },
  { id: '22530', label: '22530  Sund (Sund)' },
  { id: '22550', label: '22550  Vårdö (Vårdö)' },
  { id: '22610', label: '22610  Lemland (Lemland)' },
  { id: '22630', label: '22630  Lumparland (Lumparland)' },
  { id: '22710', label: '22710  Ålands Degerby (Föglö)' },
  { id: '22720', label: '22720  Sottunga (Sottunga)' },
  { id: '22730', label: '22730  Kökar (Kökar)' },
  { id: '22810', label: '22810  Seglinge (Kumlinge)' },
  { id: '22820', label: '22820  Kumlinge (Kumlinge)' },
  { id: '22830', label: '22830  Enklinge (Kumlinge)' },
  { id: '22840', label: '22840  Lappo (Brändö)' },
  { id: '22910', label: '22910  Torsholma (Brändö)' },
  { id: '22920', label: '22920  Brändö-Åland (Brändö)' },
  { id: '22930', label: '22930  Fiskö (Brändö)' },
  { id: '22940', label: '22940  Åva (Brändö)' },
  { id: '22950', label: '22950  Jurmo (Brändö)' },
  { id: '23100', label: '23100  Mynämäki Keskus (Mynämäki)' },
  { id: '23120', label: '23120  Mietoinen Keskus (Mynämäki)' },
  { id: '23140', label: '23140  Hietamäki (Mynämäki)' },
  { id: '23200', label: '23200  Vinkkilä (Vehmaa)' },
  { id: '23210', label: '23210  Vehmaa (Vehmaa)' },
  { id: '23260', label: '23260  Rautila (Vehmaa)' },
  { id: '23310', label: '23310  Taivassalo (Taivassalo)' },
  { id: '23360', label: '23360  Kustavi (Kustavi)' },
  { id: '23390', label: '23390  Iniö (Parainen)' },
  { id: '23450', label: '23450  Lokalahti (Uusikaupunki)' },
  { id: '23500', label: '23500  Uusikaupunki Keskus (Uusikaupunki)' },
  { id: '23600', label: '23600  Kalanti Keskus (Uusikaupunki)' },
  { id: '23660', label: '23660  Kalanti Asemanseutu (Uusikaupunki)' },
  { id: '23800', label: '23800  Laitila Keskus (Laitila)' },
  { id: '23840', label: '23840  Soukainen (Laitila)' },
  { id: '23880', label: '23880  Suontaka (Laitila)' },
  { id: '23930', label: '23930  Pyhämaa (Uusikaupunki)' },
  { id: '23950', label: '23950  Pyhäranta (Pyhäranta)' },
  { id: '23960', label: '23960  Santtio (Pyhäranta)' },
  { id: '24100', label: '24100  Salo Keskus (Salo)' },
  { id: '24130', label: '24130  Ylhäisi (Salo)' },
  { id: '24240', label: '24240  Rappula (Salo)' },
  { id: '24260', label: '24260  Ollikkala (Salo)' },
  { id: '24280', label: '24280  Tupuri-Hähkänä (Salo)' },
  { id: '24800', label: '24800  Halikko Keskus (Salo)' },
  { id: '24910', label: '24910  Halikko Asemanseutu (Salo)' },
  { id: '25110', label: '25110  Kruusila (Salo)' },
  { id: '25130', label: '25130  Muurla (Salo)' },
  { id: '25160', label: '25160  Tuohittu (Salo)' },
  { id: '25170', label: '25170  Kotalato (Salo)' },
  { id: '25180', label: '25180  Romsila (Salo)' },
  { id: '25190', label: '25190  Inkere (Salo)' },
  { id: '25210', label: '25210  Vartsala (Salo)' },
  { id: '25230', label: '25230  Angelniemi (Salo)' },
  { id: '25240', label: '25240  Hajala (Salo)' },
  { id: '25250', label: '25250  Märynummi (Salo)' },
  { id: '25260', label: '25260  Vaskio (Salo)' },
  { id: '25320', label: '25320  Raatala (Salo)' },
  { id: '25330', label: '25330  Kuusjoki Keskus (Salo)' },
  { id: '25340', label: '25340  Kanunki (Salo)' },
  { id: '25360', label: '25360  Pertteli Keskus (Salo)' },
  { id: '25370', label: '25370  Hiisi (Salo)' },
  { id: '25380', label: '25380  Rekijoki (Salo)' },
  { id: '25390', label: '25390  Kiikala Keskus (Salo)' },
  { id: '25410', label: '25410  Suomusjärvi Keskus (Salo)' },
  { id: '25420', label: '25420  Lahnajärvi (Salo)' },
  { id: '25430', label: '25430  Kettula (Salo)' },
  { id: '25460', label: '25460  Toija (Salo)' },
  { id: '25470', label: '25470  Kisko kk (Salo)' },
  { id: '25500', label: '25500  Perniö Keskus (Salo)' },
  { id: '25520', label: '25520  Perniö Asemanseutu (Salo)' },
  { id: '25540', label: '25540  Knaapila (Salo)' },
  { id: '25550', label: '25550  Aijala (Salo)' },
  { id: '25560', label: '25560  Koski Asemanseutu (Salo)' },
  { id: '25570', label: '25570  Teijo-Mathildedal (Salo)' },
  { id: '25610', label: '25610  Ylönkylä (Salo)' },
  { id: '25630', label: '25630  Finby (Salo)' },
  { id: '25640', label: '25640  Förby (Salo)' },
  { id: '25650', label: '25650  Strömma (Kemiönsaari)' },
  { id: '25660', label: '25660  Mathildedal (Salo)' },
  { id: '25700', label: '25700  Kemiö Keskus (Kemiönsaari)' },
  { id: '25710', label: '25710  Vreta-Vestlax (Kemiönsaari)' },
  { id: '25730', label: '25730  Mjösund (Kemiönsaari)' },
  { id: '25760', label: '25760  Kiila (Kemiönsaari)' },
  { id: '25810', label: '25810  Mark (Kemiönsaari)' },
  { id: '25820', label: '25820  Vestlax (Kemiönsaari)' },
  { id: '25830', label: '25830  Västanfjärd Keskus (Kemiönsaari)' },
  { id: '25840', label: '25840  Nivelax (Kemiönsaari)' },
  { id: '25860', label: '25860  Björkboda (Kemiönsaari)' },
  { id: '25870', label: '25870  Dragsfjärd (Kemiönsaari)' },
  { id: '25900', label: '25900  Taalintehdas (Kemiönsaari)' },
  { id: '25910', label: '25910  Vänoxa (Kemiönsaari)' },
  { id: '25930', label: '25930  Kasnäs (Kemiönsaari)' },
  { id: '25940', label: '25940  Hiittinen (Kemiönsaari)' },
  { id: '25950', label: '25950  Rosala-Kasnäs-Hitis (Kemiönsaari)' },
  { id: '25960', label: '25960  Högsåra (Kemiönsaari)' },
  { id: '26100', label: '26100  Rauma Keskus (Rauma)' },
  { id: '26200', label: '26200  Merirauma-Kappelinluhta (Rauma)' },
  { id: '26410', label: '26410  Kaaro (Rauma)' },
  { id: '26510', label: '26510  Uotila (Rauma)' },
  { id: '26560', label: '26560  Kolla (Rauma)' },
  { id: '26660', label: '26660  Paroalho (Rauma)' },
  { id: '26720', label: '26720  Monnanummi (Rauma)' },
  { id: '26740', label: '26740  Vasarainen (Rauma)' },
  { id: '26820', label: '26820  Sampaanala (Rauma)' },
  { id: '26840', label: '26840  Kortela (Rauma)' },
  { id: '26910', label: '26910  Unaja (Rauma)' },
  { id: '26930', label: '26930  Vermuntila (Rauma)' },
  { id: '26950', label: '26950  Voiluoto (Rauma)' },
  { id: '27100', label: '27100  Eurajoki Keskus (Eurajoki)' },
  { id: '27110', label: '27110  Irjanne (Eurajoki)' },
  { id: '27130', label: '27130  Eurajoki asemanseutu (Eurajoki)' },
  { id: '27150', label: '27150  Lapijoki (Eurajoki)' },
  { id: '27160', label: '27160  Olkiluoto (Eurajoki)' },
  { id: '27170', label: '27170  Kuivalahti (Eurajoki)' },
  { id: '27220', label: '27220  Ala-Kieri (Rauma)' },
  { id: '27230', label: '27230  Lappi (Rauma)' },
  { id: '27250', label: '27250  Kodiksami (Rauma)' },
  { id: '27260', label: '27260  Kuolimaa (Rauma)' },
  { id: '27310', label: '27310  Kodisjoki (Rauma)' },
  { id: '27320', label: '27320  Ihode (Pyhäranta)' },
  { id: '27340', label: '27340  Reila (Pyhäranta)' },
  { id: '27400', label: '27400  Kiukainen Keskus (Eura)' },
  { id: '27420', label: '27420  Panelia Asemanseutu (Eura)' },
  { id: '27430', label: '27430  Panelia (Eura)' },
  { id: '27450', label: '27450  Harola (Eura)' },
  { id: '27500', label: '27500  Kauttua (Eura)' },
  { id: '27510', label: '27510  Eura Keskus (Eura)' },
  { id: '27600', label: '27600  Hinnerjoki (Eura)' },
  { id: '27640', label: '27640  Honkilahti kk (Eura)' },
  { id: '27650', label: '27650  Honkilahti (Eura)' },
  { id: '27670', label: '27670  Mannila (Eura)' },
  { id: '27710', label: '27710  Köyliö Keskus (Säkylä)' },
  { id: '27730', label: '27730  Tuiskula (Säkylä)' },
  { id: '27740', label: '27740  Voitoinen (Säkylä)' },
  { id: '27750', label: '27750  Yttilä (Säkylä)' },
  { id: '27800', label: '27800  Säkylä Keskus (Säkylä)' },
  { id: '27820', label: '27820  Iso-Vimma (Säkylä)' },
  { id: '27840', label: '27840  Kakkuri (Säkylä)' },
  { id: '27860', label: '27860  Vuori (Säkylä)' },
  { id: '27910', label: '27910  Huovinrinne (Säkylä)' },
  { id: '27920', label: '27920  Otajoki (Säkylä)' },
  { id: '28100', label: '28100  Pori Keskus (Pori)' },
  { id: '28120', label: '28120  Käppärä-Isomäki (Pori)' },
  { id: '28130', label: '28130  Impola (Pori)' },
  { id: '28190', label: '28190  Pormestarinluoto (Pori)' },
  { id: '28200', label: '28200  Toejoki (Pori)' },
  { id: '28220', label: '28220  Ruosniemi (Pori)' },
  { id: '28240', label: '28240  Isojoenranta (Pori)' },
  { id: '28260', label: '28260  Harjunpää (Ulvila)' },
  { id: '28300', label: '28300  Kalaholma-Väinölä (Pori)' },
  { id: '28330', label: '28330  Koivistonluoto (Pori)' },
  { id: '28360', label: '28360  Vanhakoivisto (Pori)' },
  { id: '28370', label: '28370  Sampola (Pori)' },
  { id: '28400', label: '28400  Ulvila Keskus (Ulvila)' },
  { id: '28430', label: '28430  Kartano-Harmaalinna (Pori)' },
  { id: '28450', label: '28450  Vanha-Ulvila (Ulvila)' },
  { id: '28500', label: '28500  Keskussairaala-alue-Tiilimäki (Pori)' },
  { id: '28540', label: '28540  Pinomäki (Pori)' },
  { id: '28560', label: '28560  Lattomeri (Pori)' },
  { id: '28580', label: '28580  Niittymaa (Pori)' },
  { id: '28600', label: '28600  Vähärauma (Pori)' },
  { id: '28610', label: '28610  Musa-Tiiliruukki (Pori)' },
  { id: '28660', label: '28660  Pietniemi-Leppäkorpi (Pori)' },
  { id: '28760', label: '28760  Kyläsaari (Pori)' },
  { id: '28800', label: '28800  Pihlava (Pori)' },
  { id: '28840', label: '28840  Yyteri-Kaanaa (Pori)' },
  { id: '28880', label: '28880  Mäntyluoto (Pori)' },
  { id: '28900', label: '28900  Tahkoluoto-Reposaari (Pori)' },
  { id: '29100', label: '29100  Luvia Keskus (Eurajoki)' },
  { id: '29180', label: '29180  Peränkylä (Eurajoki)' },
  { id: '29200', label: '29200  Harjavalta Keskus (Harjavalta)' },
  { id: '29250', label: '29250  Nakkila Keskus (Nakkila)' },
  { id: '29270', label: '29270  Hormisto (Nakkila)' },
  { id: '29280', label: '29280  Järvimaa (Nakkila)' },
  { id: '29310', label: '29310  Kaasmarkku (Ulvila)' },
  { id: '29320', label: '29320  Leineperi (Ulvila)' },
  { id: '29340', label: '29340  Kullaa Keskus (Ulvila)' },
  { id: '29350', label: '29350  Palus (Ulvila)' },
  { id: '29570', label: '29570  Söörmarkku (Pori)' },
  { id: '29600', label: '29600  Noormarkku Keskus (Pori)' },
  { id: '29630', label: '29630  Pomarkku Keskus (Pomarkku)' },
  { id: '29640', label: '29640  Honkakoski (Pomarkku)' },
  { id: '29680', label: '29680  Kairila (Pori)' },
  { id: '29700', label: '29700  Ahlainen (Pori)' },
  { id: '29720', label: '29720  Lamppi (Pori)' },
  { id: '29740', label: '29740  Etelämaa (Merikarvia)' },
  { id: '29750', label: '29750  Pohjansaha (Merikarvia)' },
  { id: '29760', label: '29760  Köörtilä (Merikarvia)' },
  { id: '29790', label: '29790  Tuorila (Merikarvia)' },
  { id: '29810', label: '29810  Siikainen Keskus (Siikainen)' },
  { id: '29830', label: '29830  Sammi (Siikainen)' },
  { id: '29860', label: '29860  Otamo (Siikainen)' },
  { id: '29880', label: '29880  Leväsjoki-Saarineva (Siikainen)' },
  { id: '29900', label: '29900  Merikarvia Keskus-Rannikko (Merikarvia)' },
  { id: '29940', label: '29940  Kuvaskangas (Merikarvia)' },
  { id: '30100', label: '30100  Forssa Keskus (Forssa)' },
  { id: '30300', label: '30300  Viksberg (Forssa)' },
  { id: '30420', label: '30420  Tölö (Forssa)' },
  { id: '31110', label: '31110  Matku (Forssa)' },
  { id: '31130', label: '31130  Koijärvi (Forssa)' },
  { id: '31140', label: '31140  Menonen (Urjala)' },
  { id: '31160', label: '31160  Nuutajärvi (Urjala)' },
  { id: '31170', label: '31170  Savijoki (Forssa)' },
  { id: '31230', label: '31230  Susikas (Tammela)' },
  { id: '31250', label: '31250  Teuro (Tammela)' },
  { id: '31300', label: '31300  Tammela Keskus (Tammela)' },
  { id: '31310', label: '31310  Mustiala (Tammela)' },
  { id: '31340', label: '31340  Porras (Tammela)' },
  { id: '31350', label: '31350  Vistinkoski (Tammela)' },
  { id: '31370', label: '31370  Eerikkilän Urheiluopisto (Tammela)' },
  { id: '31380', label: '31380  Letku-Torro (Tammela)' },
  { id: '31400', label: '31400  Somero Keskus (Somero)' },
  { id: '31410', label: '31410  Ylöpirtti (Somero)' },
  { id: '31450', label: '31450  Häntälä (Somero)' },
  { id: '31460', label: '31460  Hirsjärvi (Somero)' },
  { id: '31470', label: '31470  Somerniemi (Somero)' },
  { id: '31490', label: '31490  Terttilä (Somero)' },
  { id: '31500', label: '31500  Koski Tl (Koski Tl)' },
  { id: '31520', label: '31520  Pitkäjärvi (Somero)' },
  { id: '31530', label: '31530  Pyöli (Somero)' },
  { id: '31600', label: '31600  Jokioinen Keskus (Jokioinen)' },
  { id: '31610', label: '31610  Vaulammi (Jokioinen)' },
  { id: '31620', label: '31620  Latovainio (Jokioinen)' },
  { id: '31630', label: '31630  Minkiö (Jokioinen)' },
  { id: '31640', label: '31640  Humppila Keskus (Humppila)' },
  { id: '31650', label: '31650  Venäjänkangas (Humppila)' },
  { id: '31700', label: '31700  Urjala Asemanseutu-Hanhisuo (Urjala)' },
  { id: '31720', label: '31720  Urjalankylä-Kehro (Urjala)' },
  { id: '31730', label: '31730  Honkola (Urjala)' },
  { id: '31750', label: '31750  Kehro (Urjala)' },
  { id: '31760', label: '31760  Urjala Keskus (Urjala)' },
  { id: '31810', label: '31810  Oriniemi (Punkalaidun)' },
  { id: '31830', label: '31830  Halkivaha (Urjala)' },
  { id: '31850', label: '31850  Koskioinen (Punkalaidun)' },
  { id: '31860', label: '31860  Tursa (Urjala)' },
  { id: '31900', label: '31900  Punkalaidun Keskus (Punkalaidun)' },
  { id: '31930', label: '31930  Vanttila (Punkalaidun)' },
  { id: '31950', label: '31950  Sadonmaa (Punkalaidun)' },
  { id: '31960', label: '31960  Orisuo (Loimaa)' },
  { id: '31970', label: '31970  Kanteenmaa (Punkalaidun)' },
  { id: '32100', label: '32100  Ypäjä Keskus (Ypäjä)' },
  { id: '32140', label: '32140  Ypäjä Asemanseutu (Ypäjä)' },
  { id: '32200', label: '32200  Loimaa Keskus (Loimaa)' },
  { id: '32210', label: '32210  Loimaa kk (Loimaa)' },
  { id: '32250', label: '32250  Kojonkulma (Loimaa)' },
  { id: '32260', label: '32260  Kojonperä (Loimaa)' },
  { id: '32270', label: '32270  Metsämaa-Murto (Loimaa)' },
  { id: '32280', label: '32280  Metsäkorpi (Loimaa)' },
  { id: '32300', label: '32300  Mellilä (Loimaa)' },
  { id: '32410', label: '32410  Niinijoki (Loimaa)' },
  { id: '32430', label: '32430  Ylhäinen (Loimaa)' },
  { id: '32440', label: '32440  Alastaro Keskus (Loimaa)' },
  { id: '32450', label: '32450  Tammiainen (Loimaa)' },
  { id: '32500', label: '32500  Oripää Keskus (Oripää)' },
  { id: '32520', label: '32520  Latva (Oripää)' },
  { id: '32560', label: '32560  Virttaa (Loimaa)' },
  { id: '32610', label: '32610  Vampula Keskus (Huittinen)' },
  { id: '32620', label: '32620  Rutava (Huittinen)' },
  { id: '32700', label: '32700  Huittinen Keskus (Huittinen)' },
  { id: '32710', label: '32710  Sampu (Huittinen)' },
  { id: '32730', label: '32730  Keikyä (Sastamala)' },
  { id: '32740', label: '32740  Pehula (Sastamala)' },
  { id: '32760', label: '32760  Suttila (Huittinen)' },
  { id: '32770', label: '32770  Huhtamo (Huittinen)' },
  { id: '32800', label: '32800  Kokemäki Keskus (Kokemäki)' },
  { id: '32810', label: '32810  Peipohja (Kokemäki)' },
  { id: '32830', label: '32830  Riste (Kokemäki)' },
  { id: '32860', label: '32860  Korkeaoja (Kokemäki)' },
  { id: '32910', label: '32910  Kauvatsa Asemanseutu (Kokemäki)' },
  { id: '32920', label: '32920  Kauvatsa (Kokemäki)' },
  { id: '33100', label: '33100  Tampere Keskus (Tampere)' },
  { id: '33180', label: '33180  Lapinniemi-Käpylä (Tampere)' },
  { id: '33200', label: '33200  Tampere Keskus Läntinen (Tampere)' },
  { id: '33210', label: '33210  Itä-Amuri-Tammerkoski (Tampere)' },
  { id: '33230', label: '33230  Länsi-Amuri (Tampere)' },
  { id: '33240', label: '33240  Tahmela (Tampere)' },
  { id: '33250', label: '33250  Pispala (Tampere)' },
  { id: '33270', label: '33270  Epilä (Tampere)' },
  { id: '33300', label: '33300  Rahola (Tampere)' },
  { id: '33310', label: '33310  Tesoma (Tampere)' },
  { id: '33330', label: '33330  Myllypuro-Kalkku (Tampere)' },
  { id: '33340', label: '33340  Haukiluoma-Ikuri (Tampere)' },
  { id: '33380', label: '33380  Pitkäniemi (Nokia)' },
  { id: '33400', label: '33400  Luoteis-Tampere (Tampere)' },
  { id: '33410', label: '33410  Lentävänniemi (Tampere)' },
  { id: '33420', label: '33420  Lamminpää (Tampere)' },
  { id: '33430', label: '33430  Vuorentausta (Ylöjärvi)' },
  { id: '33450', label: '33450  Siivikkala (Ylöjärvi)' },
  { id: '33470', label: '33470  Ylöjärvi Keskus (Ylöjärvi)' },
  { id: '33480', label: '33480  Manninmäki-Haavisto (Ylöjärvi)' },
  { id: '33500', label: '33500  Osmonmäki-Petsamo (Tampere)' },
  { id: '33520', label: '33520  Keskussairaala-alue-Kauppi (Tampere)' },
  { id: '33530', label: '33530  Kissanmaa (Tampere)' },
  { id: '33540', label: '33540  Kaleva (Tampere)' },
  { id: '33560', label: '33560  Takahuhti (Tampere)' },
  { id: '33580', label: '33580  Atala-Linnainmaa (Tampere)' },
  { id: '33610', label: '33610  Olkahinen-Tasanne (Tampere)' },
  { id: '33680', label: '33680  Aitolahti (Tampere)' },
  { id: '33700', label: '33700  Messukylä (Tampere)' },
  { id: '33710', label: '33710  Kaukajärvi (Tampere)' },
  { id: '33720', label: '33720  Hervanta (Tampere)' },
  { id: '33730', label: '33730  Leinola-Vehmainen (Tampere)' },
  { id: '33800', label: '33800  Nekala (Tampere)' },
  { id: '33820', label: '33820  Koivistonkylä (Tampere)' },
  { id: '33840', label: '33840  Peltolammi (Tampere)' },
  { id: '33850', label: '33850  Multisilta (Tampere)' },
  { id: '33870', label: '33870  Vuores (Tampere)' },
  { id: '33880', label: '33880  Höytämö (Lempäälä)' },
  { id: '33900', label: '33900  Härmälä-Rantaperkiö (Tampere)' },
  { id: '33920', label: '33920  Toivio (Pirkkala)' },
  { id: '33950', label: '33950  Killo (Pirkkala)' },
  { id: '33960', label: '33960  Suuppa (Pirkkala)' },
  { id: '33980', label: '33980  Pirkkala kk (Pirkkala)' },
  { id: '34110', label: '34110  Lakiala (Ylöjärvi)' },
  { id: '34130', label: '34130  Ylinen (Ylöjärvi)' },
  { id: '34140', label: '34140  Mutala (Ylöjärvi)' },
  { id: '34150', label: '34150  Kyrönlahti (Ylöjärvi)' },
  { id: '34180', label: '34180  Länsi-Teisko (Ylöjärvi)' },
  { id: '34240', label: '34240  Kämmenteisko (Tampere)' },
  { id: '34260', label: '34260  Terälahti (Tampere)' },
  { id: '34270', label: '34270  Velaatta (Tampere)' },
  { id: '34300', label: '34300  Kuru Keskus (Ylöjärvi)' },
  { id: '34320', label: '34320  Parkkuu (Ylöjärvi)' },
  { id: '34330', label: '34330  Poikelus (Ylöjärvi)' },
  { id: '34410', label: '34410  Murole (Ruovesi)' },
  { id: '34420', label: '34420  Muroleen Kanava (Ruovesi)' },
  { id: '34430', label: '34430  Kekkonen (Ruovesi)' },
  { id: '34450', label: '34450  Jäminkipohja (Ruovesi)' },
  { id: '34510', label: '34510  Luode (Ylöjärvi)' },
  { id: '34530', label: '34530  Länsi-Aure (Ylöjärvi)' },
  { id: '34550', label: '34550  Itä-Aure (Ylöjärvi)' },
  { id: '34600', label: '34600  Ruovesi Keskus-Nenonen (Ruovesi)' },
  { id: '34640', label: '34640  Mustajärvi (Ruovesi)' },
  { id: '34710', label: '34710  Vaskivesi (Virrat)' },
  { id: '34730', label: '34730  Vaskuu (Virrat)' },
  { id: '34740', label: '34740  Koro (Virrat)' },
  { id: '34770', label: '34770  Kurjenkylä (Virrat)' },
  { id: '34800', label: '34800  Virrat Keskus (Virrat)' },
  { id: '34870', label: '34870  Visuvesi (Ruovesi)' },
  { id: '34910', label: '34910  Äijänneva (Virrat)' },
  { id: '34930', label: '34930  Liedenpohja (Virrat)' },
  { id: '34950', label: '34950  Ohtola (Virrat)' },
  { id: '34970', label: '34970  Toisvesi (Virrat)' },
  { id: '34980', label: '34980  Killinkoski (Virrat)' },
  { id: '35100', label: '35100  Orivesi Asemanseutu (Orivesi)' },
  { id: '35220', label: '35220  Eräjärvi (Orivesi)' },
  { id: '35240', label: '35240  Ristakallio (Orivesi)' },
  { id: '35260', label: '35260  Leväslahti (Orivesi)' },
  { id: '35270', label: '35270  Västilä (Orivesi)' },
  { id: '35300', label: '35300  Orivesi Keskus (Orivesi)' },
  { id: '35320', label: '35320  Hirsilä (Orivesi)' },
  { id: '35400', label: '35400  Länkipohja (Jämsä)' },
  { id: '35420', label: '35420  Talviainen (Orivesi)' },
  { id: '35480', label: '35480  Längelmäki (Orivesi)' },
  { id: '35500', label: '35500  Korkeakoski (Juupajoki)' },
  { id: '35520', label: '35520  Pirttikangas (Juupajoki)' },
  { id: '35530', label: '35530  Lyly (Juupajoki)' },
  { id: '35540', label: '35540  Juupajoki (Juupajoki)' },
  { id: '35550', label: '35550  Salokunta (Juupajoki)' },
  { id: '35600', label: '35600  Halli (Jämsä)' },
  { id: '35610', label: '35610  Pihlaisto (Jämsä)' },
  { id: '35630', label: '35630  Suinula-Valkamanperä (Jämsä)' },
  { id: '35700', label: '35700  Vilppula Keskus (Mänttä-Vilppula)' },
  { id: '35740', label: '35740  Ylä-Vääri (Ruovesi)' },
  { id: '35750', label: '35750  Väärinmaja (Ruovesi)' },
  { id: '35800', label: '35800  Mänttä (Mänttä-Vilppula)' },
  { id: '35820', label: '35820  Asemakulma-Mustalahti (Mänttä-Vilppula)' },
  { id: '35990', label: '35990  Kolho (Mänttä-Vilppula)' },
  { id: '36100', label: '36100  Kangasala Asemanseutu (Kangasala)' },
  { id: '36110', label: '36110  Ruutana (Kangasala)' },
  { id: '36120', label: '36120  Suinula-Haviseva (Kangasala)' },
  { id: '36200', label: '36200  Kangasala Keskus (Kangasala)' },
  { id: '36220', label: '36220  Suorama (Kangasala)' },
  { id: '36240', label: '36240  Nattari (Kangasala)' },
  { id: '36270', label: '36270  Huutijärvi (Kangasala)' },
  { id: '36280', label: '36280  Pikonlinna (Kangasala)' },
  { id: '36340', label: '36340  Tohkala (Kangasala)' },
  { id: '36420', label: '36420  Sahalahti Keskus (Kangasala)' },
  { id: '36430', label: '36430  Pakkala (Kangasala)' },
  { id: '36450', label: '36450  Salmentaka (Pälkäne)' },
  { id: '36520', label: '36520  Raikku (Kangasala)' },
  { id: '36570', label: '36570  Kaivanto (Kangasala)' },
  { id: '36600', label: '36600  Pälkäne Keskus (Pälkäne)' },
  { id: '36640', label: '36640  Iltasmäki (Pälkäne)' },
  { id: '36660', label: '36660  Laitikkala (Pälkäne)' },
  { id: '36720', label: '36720  Aitoo (Pälkäne)' },
  { id: '36760', label: '36760  Luopioinen Keskus (Pälkäne)' },
  { id: '36810', label: '36810  Kuhmalahti Keskus (Kangasala)' },
  { id: '36840', label: '36840  Kuhmajärvi (Kangasala)' },
  { id: '36880', label: '36880  Vehkajärvi (Kangasala)' },
  { id: '36910', label: '36910  Rautajärvi (Pälkäne)' },
  { id: '36930', label: '36930  Kyynärö (Pälkäne)' },
  { id: '37100', label: '37100  Nokia Keskus (Nokia)' },
  { id: '37120', label: '37120  Koskenmäki (Nokia)' },
  { id: '37130', label: '37130  Alhoniitty (Nokia)' },
  { id: '37140', label: '37140  Harjuniitty (Nokia)' },
  { id: '37150', label: '37150  Kankaantaka (Nokia)' },
  { id: '37180', label: '37180  Sarkola (Nokia)' },
  { id: '37200', label: '37200  Siuro (Nokia)' },
  { id: '37210', label: '37210  Kulovesi (Nokia)' },
  { id: '37240', label: '37240  Linnavuori (Nokia)' },
  { id: '37310', label: '37310  Tottijärvi (Nokia)' },
  { id: '37350', label: '37350  Rämsöö (Vesilahti)' },
  { id: '37370', label: '37370  Narva (Vesilahti)' },
  { id: '37380', label: '37380  Krääkkiö (Vesilahti)' },
  { id: '37420', label: '37420  Valkkinen (Vesilahti)' },
  { id: '37470', label: '37470  Vesilahti Keskus (Vesilahti)' },
  { id: '37500', label: '37500  Lempäälä Keskus (Lempäälä)' },
  { id: '37530', label: '37530  Säijä (Lempäälä)' },
  { id: '37550', label: '37550  Kuivaspää (Lempäälä)' },
  { id: '37560', label: '37560  Kulju (Lempäälä)' },
  { id: '37570', label: '37570  Koivuniemi (Lempäälä)' },
  { id: '37600', label: '37600  Valkeakoski Keskus (Valkeakoski)' },
  { id: '37630', label: '37630  Vääräkoivu (Valkeakoski)' },
  { id: '37700', label: '37700  Sääksmäki (Valkeakoski)' },
  { id: '37720', label: '37720  Ritvala (Valkeakoski)' },
  { id: '37740', label: '37740  Haukila (Valkeakoski)' },
  { id: '37770', label: '37770  Tarttila (Valkeakoski)' },
  { id: '37800', label: '37800  Toijala keskus (Akaa)' },
  { id: '37830', label: '37830  Viiala Keskus (Akaa)' },
  { id: '37850', label: '37850  Metsäkansa (Valkeakoski)' },
  { id: '37860', label: '37860  Kärjenniemi (Valkeakoski)' },
  { id: '37900', label: '37900  Kylmäkoski Asemanseutu (Akaa)' },
  { id: '37910', label: '37910  Kylmäkoski Keskus (Akaa)' },
  { id: '37960', label: '37960  Sotkia (Akaa)' },
  { id: '38100', label: '38100  Karkku (Sastamala)' },
  { id: '38120', label: '38120  Kutala (Sastamala)' },
  { id: '38130', label: '38130  Ellivuori (Sastamala)' },
  { id: '38140', label: '38140  Kärppälä (Sastamala)' },
  { id: '38200', label: '38200  Vammala Keskus (Sastamala)' },
  { id: '38210', label: '38210  Vammala Asemanseutu (Sastamala)' },
  { id: '38220', label: '38220  Stormi (Sastamala)' },
  { id: '38250', label: '38250  Roismala (Sastamala)' },
  { id: '38270', label: '38270  Sammaljoki (Sastamala)' },
  { id: '38280', label: '38280  Illo (Sastamala)' },
  { id: '38300', label: '38300  Kiikka (Sastamala)' },
  { id: '38360', label: '38360  Kiikoinen (Sastamala)' },
  { id: '38420', label: '38420  Häijää (Sastamala)' },
  { id: '38460', label: '38460  Mouhijärvi Keskus (Sastamala)' },
  { id: '38470', label: '38470  Hyynilä (Sastamala)' },
  { id: '38490', label: '38490  Haukijärvi (Hämeenkyrö)' },
  { id: '38510', label: '38510  Suodenniemi (Sastamala)' },
  { id: '38600', label: '38600  Lavia Keskus (Pori)' },
  { id: '38650', label: '38650  Yli-Putto (Pori)' },
  { id: '38670', label: '38670  Kalliala-Haunia (Pori)' },
  { id: '38680', label: '38680  Tuunajärvi (Pomarkku)' },
  { id: '38700', label: '38700  Kankaanpää Keskus (Kankaanpää)' },
  { id: '38710', label: '38710  Myllymäki (Kankaanpää)' },
  { id: '38720', label: '38720  Vihteljärvi (Kankaanpää)' },
  { id: '38740', label: '38740  Hapuoja (Kankaanpää)' },
  { id: '38750', label: '38750  Veneskoski (Kankaanpää)' },
  { id: '38760', label: '38760  Venesjärvi (Kankaanpää)' },
  { id: '38770', label: '38770  Lohikko (Kankaanpää)' },
  { id: '38800', label: '38800  Jämijärvi Keskus (Jämijärvi)' },
  { id: '38840', label: '38840  Niinisalo (Kankaanpää)' },
  { id: '38860', label: '38860  Suurimaa (Jämijärvi)' },
  { id: '38910', label: '38910  Ala-Honkajoki (Kankaanpää)' },
  { id: '38920', label: '38920  Vatajankoski (Kankaanpää)' },
  { id: '38950', label: '38950  Honkajoki Keskus (Kankaanpää)' },
  { id: '38970', label: '38970  Lauhala (Kankaanpää)' },
  { id: '39100', label: '39100  Hämeenkyrö Keskus (Hämeenkyrö)' },
  { id: '39110', label: '39110  Simuna (Hämeenkyrö)' },
  { id: '39130', label: '39130  Sasi (Hämeenkyrö)' },
  { id: '39150', label: '39150  Pinsiö (Hämeenkyrö)' },
  { id: '39160', label: '39160  Julkujärvi (Ylöjärvi)' },
  { id: '39170', label: '39170  Jumesniemi (Hämeenkyrö)' },
  { id: '39180', label: '39180  Herttuala (Hämeenkyrö)' },
  { id: '39195', label: '39195  Kaipio (Hämeenkyrö)' },
  { id: '39200', label: '39200  Kyröskoski (Hämeenkyrö)' },
  { id: '39230', label: '39230  Osara (Hämeenkyrö)' },
  { id: '39290', label: '39290  Vesajärvi (Hämeenkyrö)' },
  { id: '39310', label: '39310  Viljakkala Keskus (Ylöjärvi)' },
  { id: '39340', label: '39340  Karhe (Ylöjärvi)' },
  { id: '39360', label: '39360  Komi (Ylöjärvi)' },
  { id: '39380', label: '39380  Lavajärvi (Hämeenkyrö)' },
  { id: '39410', label: '39410  Luhalahti (Ikaalinen)' },
  { id: '39430', label: '39430  Röyhiö (Ikaalinen)' },
  { id: '39450', label: '39450  Sisättö (Ikaalinen)' },
  { id: '39500', label: '39500  Ikaalinen Keskus (Ikaalinen)' },
  { id: '39530', label: '39530  Kilvakkala (Ikaalinen)' },
  { id: '39580', label: '39580  Riitiala (Ikaalinen)' },
  { id: '39590', label: '39590  Tevaniemi (Ikaalinen)' },
  { id: '39610', label: '39610  Kovelahti (Ikaalinen)' },
  { id: '39620', label: '39620  Kovesjoki (Parkano)' },
  { id: '39660', label: '39660  Lapinneva (Parkano)' },
  { id: '39700', label: '39700  Parkano Keskus (Parkano)' },
  { id: '39750', label: '39750  Vatajanjärvi (Parkano)' },
  { id: '39770', label: '39770  Aureskoski (Parkano)' },
  { id: '39780', label: '39780  Alaskylä (Parkano)' },
  { id: '39810', label: '39810  Linnankylä (Kihniö)' },
  { id: '39820', label: '39820  Kihniö Keskus (Kihniö)' },
  { id: '39880', label: '39880  Kihniö Asemanseutu (Kihniö)' },
  { id: '39920', label: '39920  Suomijärvi (Karvia)' },
  { id: '39930', label: '39930  Karvia Keskus (Karvia)' },
  { id: '39940', label: '39940  Kantti (Karvia)' },
  { id: '39960', label: '39960  Sarvela (Karvia)' },
  { id: '39965', label: '39965  Karviankylä (Karvia)' },
  { id: '39980', label: '39980  Sara (Karvia)' },
  { id: '39990', label: '39990  Alkkia (Karvia)' },
  { id: '40100', label: '40100  Jyväskylä Keskus (Jyväskylä)' },
  { id: '40200', label: '40200  Mannila-Taulumäki (Jyväskylä)' },
  { id: '40250', label: '40250  Ritoniemi-Lohikoski (Jyväskylä)' },
  { id: '40270', label: '40270  Palokka (Jyväskylä)' },
  { id: '40320', label: '40320  Seppälän Teollisuusalue (Jyväskylä)' },
  { id: '40340', label: '40340  Huhtasuo (Jyväskylä)' },
  { id: '40400', label: '40400  Halssila (Jyväskylä)' },
  { id: '40420', label: '40420  Jyskä (Jyväskylä)' },
  { id: '40500', label: '40500  Keljo-Ristonmaa (Jyväskylä)' },
  { id: '40520', label: '40520  Kuokkala-Ristikivi (Jyväskylä)' },
  { id: '40530', label: '40530  Keljonkangas (Jyväskylä)' },
  { id: '40600', label: '40600  Mattilanpelto (Jyväskylä)' },
  { id: '40620', label: '40620  Keskussairaala-alue-Kukkumäki (Jyväskylä)' },
  { id: '40630', label: '40630  Kypärämäki (Jyväskylä)' },
  { id: '40640', label: '40640  Keltinmäki (Jyväskylä)' },
  { id: '40660', label: '40660  Länsi-Palokka (Jyväskylä)' },
  { id: '40700', label: '40700  Mäki-Matti (Jyväskylä)' },
  { id: '40720', label: '40720  Nisula (Jyväskylä)' },
  { id: '40740', label: '40740  Kortepohja (Jyväskylä)' },
  { id: '40800', label: '40800  Vaajakoski (Jyväskylä)' },
  { id: '40820', label: '40820  Vuoritsalo (Jyväskylä)' },
  { id: '40900', label: '40900  Säynätsalo (Jyväskylä)' },
  { id: '40930', label: '40930  Kinkomaa (Muurame)' },
  { id: '40950', label: '40950  Muurame Keskus (Muurame)' },
  { id: '41120', label: '41120  Puuppola (Jyväskylä)' },
  { id: '41140', label: '41140  Kuikka (Jyväskylä)' },
  { id: '41160', label: '41160  Tikkakoski (Jyväskylä)' },
  { id: '41180', label: '41180  Vehniä (Laukaa)' },
  { id: '41210', label: '41210  Jokihaara (Uurainen)' },
  { id: '41220', label: '41220  Höytiä (Uurainen)' },
  { id: '41230', label: '41230  Uurainen Keskus (Uurainen)' },
  { id: '41240', label: '41240  Kyynämöinen (Uurainen)' },
  { id: '41260', label: '41260  Hoikankylä (Saarijärvi)' },
  { id: '41270', label: '41270  Lannevesi (Saarijärvi)' },
  { id: '41290', label: '41290  Kangashäkki (Uurainen)' },
  { id: '41310', label: '41310  Leppävesi (Laukaa)' },
  { id: '41325', label: '41325  Laukkavirta (Laukaa)' },
  { id: '41330', label: '41330  Vihtavuori (Laukaa)' },
  { id: '41340', label: '41340  Laukaa Keskus (Laukaa)' },
  { id: '41350', label: '41350  Laukaa Asemanseutu (Laukaa)' },
  { id: '41360', label: '41360  Valkola (Laukaa)' },
  { id: '41370', label: '41370  Kuusa (Laukaa)' },
  { id: '41390', label: '41390  Äijälä (Laukaa)' },
  { id: '41400', label: '41400  Lievestuore (Laukaa)' },
  { id: '41410', label: '41410  Kankainen (Toivakka)' },
  { id: '41440', label: '41440  Ruuhimäki (Toivakka)' },
  { id: '41450', label: '41450  Leppälahti (Jyväskylä)' },
  { id: '41490', label: '41490  Niemisjärvi (Hankasalmi)' },
  { id: '41500', label: '41500  Hankasalmi Asemanseutu (Hankasalmi)' },
  { id: '41520', label: '41520  Hankasalmi Keskus (Hankasalmi)' },
  { id: '41530', label: '41530  Veneranta (Hankasalmi)' },
  { id: '41540', label: '41540  Ristimäki (Hankasalmi)' },
  { id: '41550', label: '41550  Hannula (Hankasalmi)' },
  { id: '41560', label: '41560  Säkinmäki (Hankasalmi)' },
  { id: '41580', label: '41580  Sauvamäki (Hankasalmi)' },
  { id: '41630', label: '41630  Oravasaari (Jyväskylä)' },
  { id: '41660', label: '41660  Toivakka Keskus (Toivakka)' },
  { id: '41710', label: '41710  Rutalahti-Kivisuo (Jyväskylä)' },
  { id: '41730', label: '41730  Kivisuo (Joutsa)' },
  { id: '41750', label: '41750  Havumäki (Joutsa)' },
  { id: '41770', label: '41770  Leivonmäki (Joutsa)' },
  { id: '41800', label: '41800  Korpilahti Keskus (Jyväskylä)' },
  { id: '41820', label: '41820  Saakoski (Jyväskylä)' },
  { id: '41840', label: '41840  Moksi (Jyväskylä)' },
  { id: '41860', label: '41860  Rannila (Jyväskylä)' },
  { id: '41870', label: '41870  Putkilahti (Jyväskylä)' },
  { id: '41880', label: '41880  Oittila (Jyväskylä)' },
  { id: '41900', label: '41900  Petäjävesi Keskus (Petäjävesi)' },
  { id: '41920', label: '41920  Kintaus (Petäjävesi)' },
  { id: '41925', label: '41925  Ylä-Kintaus (Petäjävesi)' },
  { id: '41930', label: '41930  Kuohu (Jyväskylä)' },
  { id: '41940', label: '41940  Vesanka (Jyväskylä)' },
  { id: '41970', label: '41970  Huttula (Keuruu)' },
  { id: '41980', label: '41980  Kuivasmäki (Petäjävesi)' },
  { id: '42100', label: '42100  Jämsä Keskus (Jämsä)' },
  { id: '42140', label: '42140  Juokslahti (Jämsä)' },
  { id: '42220', label: '42220  Kaipola (Jämsä)' },
  { id: '42300', label: '42300  Jämsänkoski Keskus (Jämsä)' },
  { id: '42330', label: '42330  Haavisto (Jämsä)' },
  { id: '42440', label: '42440  Koskenpää (Jämsä)' },
  { id: '42520', label: '42520  Asunta (Keuruu)' },
  { id: '42560', label: '42560  Pohjoisjärvi (Keuruu)' },
  { id: '42600', label: '42600  Multia Keskus-Väätäiskylä (Multia)' },
  { id: '42660', label: '42660  Sahrajärvi (Multia)' },
  { id: '42700', label: '42700  Keuruu Keskus (Keuruu)' },
  { id: '42720', label: '42720  Valkealahti (Keuruu)' },
  { id: '42800', label: '42800  Haapamäki (Keuruu)' },
  { id: '42820', label: '42820  Riiho (Keuruu)' },
  { id: '42830', label: '42830  Ylä-Kolkki (Mänttä-Vilppula)' },
  { id: '42840', label: '42840  Kolkki (Mänttä-Vilppula)' },
  { id: '42850', label: '42850  Pohjaslahti (Mänttä-Vilppula)' },
  { id: '42870', label: '42870  Kotala (Virrat)' },
  { id: '42910', label: '42910  Pihlajavesi (Keuruu)' },
  { id: '42930', label: '42930  Katajamäki (Keuruu)' },
  { id: '43100', label: '43100  Saarijärvi Keskus (Saarijärvi)' },
  { id: '43130', label: '43130  Tarvaala (Saarijärvi)' },
  { id: '43170', label: '43170  Häkkilä (Saarijärvi)' },
  { id: '43220', label: '43220  Mahlu (Saarijärvi)' },
  { id: '43240', label: '43240  Lehtola (Saarijärvi)' },
  { id: '43250', label: '43250  Kolkanlahti (Saarijärvi)' },
  { id: '43270', label: '43270  Kalmari (Saarijärvi)' },
  { id: '43300', label: '43300  Kannonkoski Keskus (Kannonkoski)' },
  { id: '43340', label: '43340  Kannonjärvi (Kannonkoski)' },
  { id: '43390', label: '43390  Lokakylä (Kivijärvi)' },
  { id: '43420', label: '43420  Kukko (Saarijärvi)' },
  { id: '43430', label: '43430  Väätäiskylä (Multia)' },
  { id: '43440', label: '43440  Pylkönmäki Keskus (Saarijärvi)' },
  { id: '43480', label: '43480  Pääjärvi (Saarijärvi)' },
  { id: '43490', label: '43490  Kangasaho (Karstula)' },
  { id: '43500', label: '43500  Karstula Keskus (Karstula)' },
  { id: '43520', label: '43520  Kiminki (Karstula)' },
  { id: '43540', label: '43540  Rantakylä-Pirttilahti (Karstula)' },
  { id: '43560', label: '43560  Vahanka (Karstula)' },
  { id: '43610', label: '43610  Aho (Karstula)' },
  { id: '43640', label: '43640  Humppi (Karstula)' },
  { id: '43660', label: '43660  Vastinki (Karstula)' },
  { id: '43700', label: '43700  Kyyjärvi Keskus (Kyyjärvi)' },
  { id: '43720', label: '43720  Kumpulainen (Kyyjärvi)' },
  { id: '43800', label: '43800  Kivijärvi Keskus-Sompala (Kivijärvi)' },
  { id: '43840', label: '43840  Sompala (Kivijärvi)' },
  { id: '43900', label: '43900  Kinnula Keskus (Kinnula)' },
  { id: '43940', label: '43940  Muhola-Saarensalmi (Kinnula)' },
  { id: '43960', label: '43960  Saarensalmi (Kinnula)' },
  { id: '44100', label: '44100  Äänekoski Keskus (Äänekoski)' },
  { id: '44120', label: '44120  Mämmensalmi-Mustaniemi (Äänekoski)' },
  { id: '44150', label: '44150  Likolahti-Karhunlähde (Äänekoski)' },
  { id: '44160', label: '44160  Huutomäki (Äänekoski)' },
  { id: '44170', label: '44170  Hietama (Äänekoski)' },
  { id: '44190', label: '44190  Parantala (Äänekoski)' },
  { id: '44200', label: '44200  Suolahti Keskus (Äänekoski)' },
  { id: '44220', label: '44220  Sörkänmäki (Äänekoski)' },
  { id: '44250', label: '44250  Äänekoivisto (Äänekoski)' },
  { id: '44260', label: '44260  Vihijärvi (Äänekoski)' },
  { id: '44280', label: '44280  Sumiainen Keskus (Äänekoski)' },
  { id: '44300', label: '44300  Konnevesi Keskus (Konnevesi)' },
  { id: '44320', label: '44320  Sirkkamäki (Konnevesi)' },
  { id: '44330', label: '44330  Hytölä (Konnevesi)' },
  { id: '44350', label: '44350  Istunmäki (Konnevesi)' },
  { id: '44370', label: '44370  Mäntymäki (Konnevesi)' },
  { id: '44400', label: '44400  Konginkangas Keskus (Äänekoski)' },
  { id: '44420', label: '44420  Liimattala (Äänekoski)' },
  { id: '44440', label: '44440  Räihä (Äänekoski)' },
  { id: '44460', label: '44460  Kalaniemi (Äänekoski)' },
  { id: '44480', label: '44480  Niinilahti (Viitasaari)' },
  { id: '44500', label: '44500  Viitasaari Keskus (Viitasaari)' },
  { id: '44530', label: '44530  Ilmolahti (Viitasaari)' },
  { id: '44580', label: '44580  Huopanankoski (Viitasaari)' },
  { id: '44590', label: '44590  Vuorilahti (Viitasaari)' },
  { id: '44610', label: '44610  Kärnä (Viitasaari)' },
  { id: '44630', label: '44630  Kumpumäki (Viitasaari)' },
  { id: '44640', label: '44640  Kymönkoski (Viitasaari)' },
  { id: '44670', label: '44670  Suovanlahti (Viitasaari)' },
  { id: '44680', label: '44680  Lahnanen (Viitasaari)' },
  { id: '44730', label: '44730  Löytänä (Viitasaari)' },
  { id: '44740', label: '44740  Keitelepohja-Kotvala (Viitasaari)' },
  { id: '44760', label: '44760  Keihärinkoski (Viitasaari)' },
  { id: '44770', label: '44770  Valkeisjärvi (Viitasaari)' },
  { id: '44790', label: '44790  Kotvala (Viitasaari)' },
  { id: '44800', label: '44800  Pihtipudas Keskus (Pihtipudas)' },
  { id: '44860', label: '44860  Alvajärvi (Pihtipudas)' },
  { id: '44880', label: '44880  Muurasjärvi (Pihtipudas)' },
  { id: '44910', label: '44910  Elämäjärvi (Pihtipudas)' },
  { id: '44920', label: '44920  Peninki (Pihtipudas)' },
  { id: '44950', label: '44950  Kärväsjärvi (Pihtipudas)' },
  { id: '44970', label: '44970  Korppinen (Pihtipudas)' },
  { id: '45100', label: '45100  Kouvola Keskus (Kouvola)' },
  { id: '45120', label: '45120  Kotkankallio-Vahteronmäki (Kouvola)' },
  { id: '45130', label: '45130  Korjala-Kaunisnurmi (Kouvola)' },
  { id: '45150', label: '45150  Kankaronmäki-Eskolanmäki (Kouvola)' },
  { id: '45160', label: '45160  Lehtomäki (Kouvola)' },
  { id: '45200', label: '45200  Tornionmäki (Kouvola)' },
  { id: '45330', label: '45330  Harju (Kouvola)' },
  { id: '45360', label: '45360  Kukkuramäki (Kouvola)' },
  { id: '45370', label: '45370  Valkeala kk (Kouvola)' },
  { id: '45410', label: '45410  Utti (Kouvola)' },
  { id: '45460', label: '45460  Savero (Kouvola)' },
  { id: '45610', label: '45610  Koria (Kouvola)' },
  { id: '45700', label: '45700  Kuusankoski Keskus (Kouvola)' },
  { id: '45720', label: '45720  Kotiharju-Puhjonmäki (Kouvola)' },
  { id: '45740', label: '45740  Maunuksela (Kouvola)' },
  { id: '45750', label: '45750  Kuusankosken Sairaala-alue (Kouvola)' },
  { id: '45810', label: '45810  Pilkanmaa (Kouvola)' },
  { id: '45910', label: '45910  Voikkaa (Kouvola)' },
  { id: '45940', label: '45940  Oravala (Kouvola)' },
  { id: '46110', label: '46110  Tuohikotti (Kouvola)' },
  { id: '46140', label: '46140  Vekaranjärvi (Kouvola)' },
  { id: '46230', label: '46230  Aro (Kouvola)' },
  { id: '46400', label: '46400  Kaipiainen (Kouvola)' },
  { id: '46430', label: '46430  Tirva-Inkerilä (Kouvola)' },
  { id: '46450', label: '46450  Enäjärvi (Kouvola)' },
  { id: '46530', label: '46530  Kannuskoski (Luumäki)' },
  { id: '46570', label: '46570  Saaramaa (Kouvola)' },
  { id: '46710', label: '46710  Sippola (Kouvola)' },
  { id: '46730', label: '46730  Ruotila (Kouvola)' },
  { id: '46750', label: '46750  Liikkala (Kouvola)' },
  { id: '46800', label: '46800  Myllykoski (Kouvola)' },
  { id: '46810', label: '46810  Ummeljoki (Kouvola)' },
  { id: '46860', label: '46860  Anjalankoski Keskus (Kouvola)' },
  { id: '46900', label: '46900  Inkeroinen (Kouvola)' },
  { id: '46910', label: '46910  Anjala (Kouvola)' },
  { id: '46930', label: '46930  Huruksela (Kotka)' },
  { id: '46950', label: '46950  Ahvio (Kouvola)' },
  { id: '46960', label: '46960  Muhniemi (Kouvola)' },
  { id: '47110', label: '47110  Löytty (Kouvola)' },
  { id: '47150', label: '47150  Raussila (Kouvola)' },
  { id: '47200', label: '47200  Elimäki kk (Kouvola)' },
  { id: '47260', label: '47260  Ratula (Kouvola)' },
  { id: '47310', label: '47310  Haapa-Kimola (Iitti)' },
  { id: '47350', label: '47350  Kimonkylä (Lapinjärvi)' },
  { id: '47400', label: '47400  Kausala (Iitti)' },
  { id: '47440', label: '47440  Sääskjärvi (Iitti)' },
  { id: '47450', label: '47450  Perheniemi (Iitti)' },
  { id: '47460', label: '47460  Hiisiö (Iitti)' },
  { id: '47490', label: '47490  Mankala-Hiisiö (Iitti)' },
  { id: '47520', label: '47520  Iitti kk (Iitti)' },
  { id: '47540', label: '47540  Löyttilä (Iitti)' },
  { id: '47610', label: '47610  Kymentaka (Iitti)' },
  { id: '47650', label: '47650  Kimola (Kouvola)' },
  { id: '47710', label: '47710  Jaala kk (Kouvola)' },
  { id: '47760', label: '47760  Huhdasjärvi (Kouvola)' },
  { id: '47810', label: '47810  Selänpää (Kouvola)' },
  { id: '47830', label: '47830  Hasula (Kouvola)' },
  { id: '47850', label: '47850  Verla (Kouvola)' },
  { id: '47900', label: '47900  Vuohijärvi (Kouvola)' },
  { id: '47910', label: '47910  Hillosensalmi (Kouvola)' },
  { id: '48100', label: '48100  Kotkansaari (Kotka)' },
  { id: '48130', label: '48130  Katariina (Kotka)' },
  { id: '48200', label: '48200  Hovinsaari (Kotka)' },
  { id: '48210', label: '48210  Toukolan Sairaala-Alue (Kotka)' },
  { id: '48220', label: '48220  Metsola (Kotka)' },
  { id: '48230', label: '48230  Korela-Jylppy (Kotka)' },
  { id: '48300', label: '48300  Ruonala (Kotka)' },
  { id: '48310', label: '48310  Mussalo (Kotka)' },
  { id: '48350', label: '48350  Karhuvuori (Kotka)' },
  { id: '48400', label: '48400  Sutela (Kotka)' },
  { id: '48410', label: '48410  Kaukola (Kotka)' },
  { id: '48600', label: '48600  Karhula (Kotka)' },
  { id: '48700', label: '48700  Kyminlinna (Kotka)' },
  { id: '48710', label: '48710  Koivula (Kotka)' },
  { id: '48720', label: '48720  Kymi (Kotka)' },
  { id: '48750', label: '48750  Nikeli (Kotka)' },
  { id: '48770', label: '48770  Ristinkallio (Kotka)' },
  { id: '48800', label: '48800  Otsola-Mäntykangas (Kotka)' },
  { id: '48810', label: '48810  Rantahaka (Kotka)' },
  { id: '48900', label: '48900  Sunila (Kotka)' },
  { id: '48910', label: '48910  Suulisniemi (Kotka)' },
  { id: '48930', label: '48930  Tiutinen (Kotka)' },
  { id: '49200', label: '49200  Heinlahti (Pyhtää)' },
  { id: '49210', label: '49210  Huutjärvi (Pyhtää)' },
  { id: '49220', label: '49220  Siltakylä (Pyhtää)' },
  { id: '49240', label: '49240  Purolanlahti (Pyhtää)' },
  { id: '49270', label: '49270  Pyhtää kk (Pyhtää)' },
  { id: '49290', label: '49290  Vastila (Pyhtää)' },
  { id: '49300', label: '49300  Tavastila (Kotka)' },
  { id: '49340', label: '49340  Juurikorpi (Kotka)' },
  { id: '49400', label: '49400  Hamina Keskus (Hamina)' },
  { id: '49410', label: '49410  Poitsila (Hamina)' },
  { id: '49420', label: '49420  Uusi-Summa (Hamina)' },
  { id: '49460', label: '49460  Hillo (Hamina)' },
  { id: '49480', label: '49480  Vanha-Summa (Hamina)' },
  { id: '49490', label: '49490  Neuvoton (Hamina)' },
  { id: '49510', label: '49510  Husula (Hamina)' },
  { id: '49520', label: '49520  Reitkalli (Hamina)' },
  { id: '49540', label: '49540  Metsäkylä (Hamina)' },
  { id: '49570', label: '49570  Kannusjärvi (Hamina)' },
  { id: '49610', label: '49610  Sivatti (Hamina)' },
  { id: '49630', label: '49630  Vehkajoki (Hamina)' },
  { id: '49640', label: '49640  Paijärvi (Hamina)' },
  { id: '49660', label: '49660  Pyhältö (Hamina)' },
  { id: '49700', label: '49700  Miehikkälä Kirkonkylä (Miehikkälä)' },
  { id: '49720', label: '49720  Hurttala (Miehikkälä)' },
  { id: '49730', label: '49730  Muurikkala (Miehikkälä)' },
  { id: '49750', label: '49750  Kalliokoski (Miehikkälä)' },
  { id: '49760', label: '49760  Suur-Miehikkälä (Miehikkälä)' },
  { id: '49770', label: '49770  Salo-Miehikkälä (Miehikkälä)' },
  { id: '49780', label: '49780  Onkamaa (Hamina)' },
  { id: '49840', label: '49840  Mäntlahti (Hamina)' },
  { id: '49860', label: '49860  Klamila (Virolahti)' },
  { id: '49900', label: '49900  Virolahti Keskus (Virolahti)' },
  { id: '49930', label: '49930  Vaalimaa (Virolahti)' },
  { id: '49960', label: '49960  Ala-Pihlaja (Virolahti)' },
  { id: '49980', label: '49980  Ravijoki (Virolahti)' },
  { id: '50100', label: '50100  Mikkeli Keskus (Mikkeli)' },
  { id: '50120', label: '50120  Siekkilä (Mikkeli)' },
  { id: '50130', label: '50130  Nuijamies (Mikkeli)' },
  { id: '50150', label: '50150  Karkialampi (Mikkeli)' },
  { id: '50160', label: '50160  Kattilansilta (Mikkeli)' },
  { id: '50170', label: '50170  Peitsari (Mikkeli)' },
  { id: '50180', label: '50180  Visulahti (Mikkeli)' },
  { id: '50190', label: '50190  Tuppurala (Mikkeli)' },
  { id: '50200', label: '50200  Kovala (Mikkeli)' },
  { id: '50300', label: '50300  Rämälä (Mikkeli)' },
  { id: '50350', label: '50350  Norola (Mikkeli)' },
  { id: '50500', label: '50500  Tuukkala-Kaituenmäki (Mikkeli)' },
  { id: '50520', label: '50520  Moision Sairaala-alue (Mikkeli)' },
  { id: '50600', label: '50600  Karikko (Mikkeli)' },
  { id: '50670', label: '50670  Otava (Mikkeli)' },
  { id: '50700', label: '50700  Syysjärvi (Mikkeli)' },
  { id: '50770', label: '50770  Korpikoski (Mikkeli)' },
  { id: '50800', label: '50800  Annilanselkä (Mikkeli)' },
  { id: '50970', label: '50970  Ihastjärvi (Mikkeli)' },
  { id: '51130', label: '51130  Vanhamäki (Mikkeli)' },
  { id: '51200', label: '51200  Kangasniemi Keskus (Kangasniemi)' },
  { id: '51260', label: '51260  Tahkomäki (Kangasniemi)' },
  { id: '51270', label: '51270  Kutemajärvi (Kangasniemi)' },
  { id: '51310', label: '51310  Levä (Kangasniemi)' },
  { id: '51335', label: '51335  Kaihlamäki (Kangasniemi)' },
  { id: '51340', label: '51340  Hännilä-Särkiharju (Kangasniemi)' },
  { id: '51360', label: '51360  Koivula-Synsiö (Kangasniemi)' },
  { id: '51380', label: '51380  Synsiö (Kangasniemi)' },
  { id: '51420', label: '51420  Harjumaa-Pajulankylä (Mikkeli)' },
  { id: '51430', label: '51430  Läsäkoski (Kangasniemi)' },
  { id: '51440', label: '51440  Vuojalahti (Kangasniemi)' },
  { id: '51450', label: '51450  Pajulankylä (Mikkeli)' },
  { id: '51460', label: '51460  Luusniemi (Kangasniemi)' },
  { id: '51520', label: '51520  Hiirola (Mikkeli)' },
  { id: '51540', label: '51540  Kalvitsa (Mikkeli)' },
  { id: '51600', label: '51600  Haukivuori Keskus-Piitkäaho (Mikkeli)' },
  { id: '51620', label: '51620  Pitkäaho (Mikkeli)' },
  { id: '51670', label: '51670  Nykälä (Mikkeli)' },
  { id: '51720', label: '51720  Rahula (Mikkeli)' },
  { id: '51740', label: '51740  Huuhanaho (Mikkeli)' },
  { id: '51760', label: '51760  Nuutilanmäki (Juva)' },
  { id: '51780', label: '51780  Kuosmala (Juva)' },
  { id: '51810', label: '51810  Maivala (Juva)' },
  { id: '51820', label: '51820  Hatsola (Juva)' },
  { id: '51850', label: '51850  Vuorenmaa (Juva)' },
  { id: '51860', label: '51860  Narila (Juva)' },
  { id: '51880', label: '51880  Koikkala (Juva)' },
  { id: '51890', label: '51890  Risulahti (Juva)' },
  { id: '51900', label: '51900  Juva Keskus (Juva)' },
  { id: '51930', label: '51930  Paatela (Juva)' },
  { id: '51940', label: '51940  Härkälä (Juva)' },
  { id: '51980', label: '51980  Lauteala (Juva)' },
  { id: '52100', label: '52100  Anttola (Mikkeli)' },
  { id: '52110', label: '52110  Majavesi (Mikkeli)' },
  { id: '52150', label: '52150  Hauhala (Mikkeli)' },
  { id: '52200', label: '52200  Puumala Keskus (Puumala)' },
  { id: '52230', label: '52230  Hurissalo (Puumala)' },
  { id: '52270', label: '52270  Ryhälä (Puumala)' },
  { id: '52300', label: '52300  Ristiina Keskus (Mikkeli)' },
  { id: '52320', label: '52320  Vitsiälä (Mikkeli)' },
  { id: '52330', label: '52330  Heimari (Mikkeli)' },
  { id: '52340', label: '52340  Hangastenmaa (Mikkeli)' },
  { id: '52360', label: '52360  Someenjärvi (Mikkeli)' },
  { id: '52420', label: '52420  Pellosniemi (Mikkeli)' },
  { id: '52510', label: '52510  Hietanen (Hirvensalmi)' },
  { id: '52520', label: '52520  Syväsmäki (Hirvensalmi)' },
  { id: '52550', label: '52550  Hirvensalmi Keskus (Hirvensalmi)' },
  { id: '52610', label: '52610  Tuukkalanlahti (Hirvensalmi)' },
  { id: '52620', label: '52620  Kuitula (Hirvensalmi)' },
  { id: '52700', label: '52700  Mäntyharju Keskus (Mäntyharju)' },
  { id: '52720', label: '52720  Karankamäki-Koirakivi (Mäntyharju)' },
  { id: '52730', label: '52730  Koirakivi (Mäntyharju)' },
  { id: '52740', label: '52740  Tuustaipale (Mäntyharju)' },
  { id: '52760', label: '52760  Mynttilä (Mäntyharju)' },
  { id: '52780', label: '52780  Kuomiokoski (Mikkeli)' },
  { id: '52830', label: '52830  Suomenniemi (Mikkeli)' },
  { id: '52850', label: '52850  Halmeniemi (Mäntyharju)' },
  { id: '52890', label: '52890  Käävänkylä (Mäntyharju)' },
  { id: '52920', label: '52920  Voikoski (Kouvola)' },
  { id: '52960', label: '52960  Nurmaa (Mäntyharju)' },
  { id: '52970', label: '52970  Pankalahti-Ahvenainen (Mäntyharju)' },
  { id: '52980', label: '52980  Ahvenainen (Heinola)' },
  { id: '53100', label: '53100  Lappeenranta keskus (Lappeenranta)' },
  { id: '53130', label: '53130  Keskussairaala-alue-Kimpinen (Lappeenranta)' },
  { id: '53200', label: '53200  Kaukas-Tirilä (Lappeenranta)' },
  { id: '53300', label: '53300  Lauritsala-Mälkiä (Lappeenranta)' },
  { id: '53400', label: '53400  Laihia (Lappeenranta)' },
  { id: '53420', label: '53420  Mustola (Lappeenranta)' },
  { id: '53500', label: '53500  Parkkarila-Harapainen-Reijola (Lappeenranta)' },
  { id: '53550', label: '53550  Kourulanmäki (Lappeenranta)' },
  { id: '53600', label: '53600  Kesämäki (Lappeenranta)' },
  { id: '53650', label: '53650  Mattila-Kuusela (Lappeenranta)' },
  { id: '53810', label: '53810  Kuusimäki-Lavola (Lappeenranta)' },
  { id: '53830', label: '53830  Rutola (Lappeenranta)' },
  { id: '53850', label: '53850  Skinnarila (Lappeenranta)' },
  { id: '53900', label: '53900  Pallo-Tysterniemi-Leiri (Lappeenranta)' },
  { id: '53920', label: '53920  Voisalmi (Lappeenranta)' },
  { id: '53950', label: '53950  Kivisalmi (Lappeenranta)' },
  { id: '54100', label: '54100  Joutseno Keskus (Lappeenranta)' },
  { id: '54110', label: '54110  Lampikangas (Lappeenranta)' },
  { id: '54120', label: '54120  Pulp (Lappeenranta)' },
  { id: '54190', label: '54190  Konnunsuo (Lappeenranta)' },
  { id: '54230', label: '54230  Nuijamaa (Lappeenranta)' },
  { id: '54250', label: '54250  Rapattila (Lappeenranta)' },
  { id: '54260', label: '54260  Hanhijärvi (Lappeenranta)' },
  { id: '54270', label: '54270  Vainikkala (Lappeenranta)' },
  { id: '54300', label: '54300  Vilkjärvi (Lappeenranta)' },
  { id: '54310', label: '54310  Hytti (Lappeenranta)' },
  { id: '54330', label: '54330  Simola (Lappeenranta)' },
  { id: '54390', label: '54390  Pulsa (Lappeenranta)' },
  { id: '54410', label: '54410  Ylämaa Keskus (Lappeenranta)' },
  { id: '54430', label: '54430  Hujakkala (Lappeenranta)' },
  { id: '54460', label: '54460  Ylijärvi (Lappeenranta)' },
  { id: '54490', label: '54490  Ahomäki (Miehikkälä)' },
  { id: '54500', label: '54500  Taavetti-Somerharju (Luumäki)' },
  { id: '54510', label: '54510  Uro (Luumäki)' },
  { id: '54530', label: '54530  Luumäki (Luumäki)' },
  { id: '54550', label: '54550  Suo-Anttila (Luumäki)' },
  { id: '54580', label: '54580  Somerharju (Luumäki)' },
  { id: '54590', label: '54590  Kaitjärvi (Luumäki)' },
  { id: '54710', label: '54710  Lemi Keskus (Lemi)' },
  { id: '54720', label: '54720  Laksiainen (Lemi)' },
  { id: '54750', label: '54750  Välijoki (Savitaipale)' },
  { id: '54770', label: '54770  Heituinlahti (Savitaipale)' },
  { id: '54800', label: '54800  Savitaipale Keskus (Savitaipale)' },
  { id: '54820', label: '54820  Kotimäki (Lemi)' },
  { id: '54850', label: '54850  Kuukanniemi (Lemi)' },
  { id: '54915', label: '54915  Saimaanharju-Kyläniemi (Taipalsaari)' },
  { id: '54920', label: '54920  Taipalsaari (Taipalsaari)' },
  { id: '54930', label: '54930  Levänen (Taipalsaari)' },
  { id: '54940', label: '54940  Solkei (Taipalsaari)' },
  { id: '54950', label: '54950  Pettilä (Savitaipale)' },
  { id: '54960', label: '54960  Vehkataipale (Taipalsaari)' },
  { id: '55100', label: '55100  Imatra Keskus (Imatra)' },
  { id: '55120', label: '55120  Mansikkala-Tuulikallio (Imatra)' },
  { id: '55300', label: '55300  Rauha-Tiuruniemi (Lappeenranta)' },
  { id: '55320', label: '55320  Rauha (Lappeenranta)' },
  { id: '55330', label: '55330  Tiuruniemi (Lappeenranta)' },
  { id: '55400', label: '55400  Tainionkoski-Mustalampi (Imatra)' },
  { id: '55420', label: '55420  Karhumäki-Karhukallio (Imatra)' },
  { id: '55510', label: '55510  Sotkulampi-Linnasuo (Imatra)' },
  { id: '55610', label: '55610  Rajapatsas-Teppanala (Imatra)' },
  { id: '55700', label: '55700  Vintteri-Virasoja (Imatra)' },
  { id: '55800', label: '55800  Vuoksenniska-Saarlampi (Imatra)' },
  { id: '55910', label: '55910  Rautio-Kurkvuori (Imatra)' },
  { id: '56100', label: '56100  Ruokolahti Keskus (Ruokolahti)' },
  { id: '56120', label: '56120  Salosaari (Ruokolahti)' },
  { id: '56140', label: '56140  Äitsaari (Ruokolahti)' },
  { id: '56210', label: '56210  Virmutjoki (Ruokolahti)' },
  { id: '56310', label: '56310  Syyspohja (Ruokolahti)' },
  { id: '56330', label: '56330  Utula (Ruokolahti)' },
  { id: '56350', label: '56350  Kyläniemi (Taipalsaari)' },
  { id: '56410', label: '56410  Kaiturinpää (Ruokolahti)' },
  { id: '56440', label: '56440  Pohja-Lankila (Ruokolahti)' },
  { id: '56510', label: '56510  Puntala (Ruokolahti)' },
  { id: '56550', label: '56550  Niska-Pietilä (Rautjärvi)' },
  { id: '56610', label: '56610  Rautjärvi (Rautjärvi)' },
  { id: '56640', label: '56640  Purnujärvi (Rautjärvi)' },
  { id: '56710', label: '56710  Miettilä (Rautjärvi)' },
  { id: '56730', label: '56730  Laikko (Rautjärvi)' },
  { id: '56800', label: '56800  Simpele (Rautjärvi)' },
  { id: '57100', label: '57100  Heikinpohja-Piispanmäki (Savonlinna)' },
  { id: '57120', label: '57120  Savonlinna (Savonlinna)' },
  { id: '57130', label: '57130  Savonlinna Keskus (Savonlinna)' },
  { id: '57170', label: '57170  Sortteerinlahti-Talvisalo (Savonlinna)' },
  { id: '57200', label: '57200  Miekonniemi-Venäjänniemi (Savonlinna)' },
  { id: '57210', label: '57210  Nojanmaa (Savonlinna)' },
  { id: '57220', label: '57220  Haka-alue-Viuhonmäki (Savonlinna)' },
  { id: '57230', label: '57230  Inkerinkylä (Savonlinna)' },
  { id: '57310', label: '57310  Rinkilä (Savonlinna)' },
  { id: '57510', label: '57510  Laitaatsilta (Savonlinna)' },
  { id: '57600', label: '57600  Poukkusilta-Pihlajaniemi (Savonlinna)' },
  { id: '57710', label: '57710  Kellarpelto-Rantakoivikko (Savonlinna)' },
  { id: '57810', label: '57810  Aholahti (Savonlinna)' },
  { id: '58130', label: '58130  Oravi (Savonlinna)' },
  { id: '58140', label: '58140  Ahvensalmi (Savonlinna)' },
  { id: '58150', label: '58150  Makkola (Savonlinna)' },
  { id: '58160', label: '58160  Karvila (Enonkoski)' },
  { id: '58170', label: '58170  Simanala (Enonkoski)' },
  { id: '58175', label: '58175  Enonkoski Keskus (Enonkoski)' },
  { id: '58180', label: '58180  Ihamaniemi (Enonkoski)' },
  { id: '58200', label: '58200  Kerimäki Keskus (Savonlinna)' },
  { id: '58220', label: '58220  Louhi (Savonlinna)' },
  { id: '58260', label: '58260  Kumpuranta (Savonlinna)' },
  { id: '58300', label: '58300  Savonranta Keskus (Savonlinna)' },
  { id: '58320', label: '58320  Raikuu (Savonlinna)' },
  { id: '58350', label: '58350  Villala (Kitee)' },
  { id: '58360', label: '58360  Säimen (Savonlinna)' },
  { id: '58390', label: '58390  Rönkönvaara (Savonlinna)' },
  { id: '58410', label: '58410  Haapakallio (Savonlinna)' },
  { id: '58420', label: '58420  Silvola (Savonlinna)' },
  { id: '58430', label: '58430  Kulennoinen (Savonlinna)' },
  { id: '58450', label: '58450  Punkaharju (Savonlinna)' },
  { id: '58500', label: '58500  Punkasalmi (Savonlinna)' },
  { id: '58520', label: '58520  Hiukkajoki (Savonlinna)' },
  { id: '58550', label: '58550  Putikko (Savonlinna)' },
  { id: '58570', label: '58570  Ruhvana (Savonlinna)' },
  { id: '58580', label: '58580  Vuoriniemi (Savonlinna)' },
  { id: '58610', label: '58610  Telakanava (Sulkava)' },
  { id: '58620', label: '58620  Lohilahti (Sulkava)' },
  { id: '58650', label: '58650  Kiviapaja (Savonlinna)' },
  { id: '58680', label: '58680  Piojärvi (Savonlinna)' },
  { id: '58690', label: '58690  Ala-Särkilahti-Piojärvi (Savonlinna)' },
  { id: '58700', label: '58700  Sulkava Keskus (Sulkava)' },
  { id: '58720', label: '58720  Kaartilankoski (Sulkava)' },
  { id: '58770', label: '58770  Pihlajalahti (Savonlinna)' },
  { id: '58810', label: '58810  Kallislahti (Savonlinna)' },
  { id: '58830', label: '58830  Parkumäki (Rantasalmi)' },
  { id: '58850', label: '58850  Hiltula (Rantasalmi)' },
  { id: '58900', label: '58900  Rantasalmi Keskus (Rantasalmi)' },
  { id: '58910', label: '58910  Rantasalmi Asemanseutu (Rantasalmi)' },
  { id: '58920', label: '58920  Kolkontaipale (Rantasalmi)' },
  { id: '58940', label: '58940  Tuusmäki (Rantasalmi)' },
  { id: '59100', label: '59100  Parikkala Keskus (Parikkala)' },
  { id: '59130', label: '59130  Koitsanlahti (Parikkala)' },
  { id: '59210', label: '59210  Melkoniemi (Parikkala)' },
  { id: '59310', label: '59310  Särkisalmi (Parikkala)' },
  { id: '59320', label: '59320  Haapaoja (Parikkala)' },
  { id: '59410', label: '59410  Kirjavala (Parikkala)' },
  { id: '59510', label: '59510  Maironiemi (Parikkala)' },
  { id: '59520', label: '59520  Saari (Parikkala)' },
  { id: '59530', label: '59530  Mikkolanniemi (Parikkala)' },
  { id: '59610', label: '59610  Tarnala (Parikkala)' },
  { id: '59710', label: '59710  Kummunkylä (Parikkala)' },
  { id: '59720', label: '59720  Niukkala (Parikkala)' },
  { id: '59730', label: '59730  Uukuniemi (Parikkala)' },
  { id: '59800', label: '59800  Kesälahti Keskus (Kitee)' },
  { id: '59810', label: '59810  Suurikylä (Kitee)' },
  { id: '59820', label: '59820  Purujärvi-Rajavaara (Kitee)' },
  { id: '60100', label: '60100  Seinäjoki Keskus (Seinäjoki)' },
  { id: '60120', label: '60120  Pohja (Seinäjoki)' },
  { id: '60150', label: '60150  Kasperi (Seinäjoki)' },
  { id: '60200', label: '60200  Törnävä (Seinäjoki)' },
  { id: '60220', label: '60220  Huhtala (Seinäjoki)' },
  { id: '60280', label: '60280  Eskoon Keskuslaitosalue (Seinäjoki)' },
  { id: '60320', label: '60320  Alakylä-Jouppi (Seinäjoki)' },
  { id: '60420', label: '60420  Impivaara (Seinäjoki)' },
  { id: '60450', label: '60450  Munakka (Ilmajoki)' },
  { id: '60510', label: '60510  Hyllykallio (Seinäjoki)' },
  { id: '60550', label: '60550  Nurmo Keskus (Seinäjoki)' },
  { id: '60560', label: '60560  Halkosaari (Seinäjoki)' },
  { id: '60640', label: '60640  Kurjenneva (Seinäjoki)' },
  { id: '60720', label: '60720  Tuomikylä (Ilmajoki)' },
  { id: '60760', label: '60760  Pojanluoma (Ilmajoki)' },
  { id: '60800', label: '60800  Ilmajoki Keskus (Ilmajoki)' },
  { id: '61100', label: '61100  Peräseinäjoki Keskus (Seinäjoki)' },
  { id: '61110', label: '61110  Vuolle (Seinäjoki)' },
  { id: '61120', label: '61120  Louko (Seinäjoki)' },
  { id: '61140', label: '61140  Pasto (Seinäjoki)' },
  { id: '61150', label: '61150  Sydänmaa (Alavus)' },
  { id: '61160', label: '61160  Koura (Seinäjoki)' },
  { id: '61170', label: '61170  Sääskiniemi (Alavus)' },
  { id: '61180', label: '61180  Haapaluoma (Seinäjoki)' },
  { id: '61220', label: '61220  Myllysalo (Kurikka)' },
  { id: '61230', label: '61230  Luopa (Kurikka)' },
  { id: '61250', label: '61250  Jalasto (Kurikka)' },
  { id: '61270', label: '61270  Luopajärvi (Kurikka)' },
  { id: '61280', label: '61280  Jokipii (Kurikka)' },
  { id: '61290', label: '61290  Taivalmaa (Kurikka)' },
  { id: '61300', label: '61300  Kurikka Keskus (Kurikka)' },
  { id: '61310', label: '61310  Panttila (Kurikka)' },
  { id: '61330', label: '61330  Koskenkorva (Ilmajoki)' },
  { id: '61340', label: '61340  Nopankylä (Ilmajoki)' },
  { id: '61350', label: '61350  Huissi (Ilmajoki)' },
  { id: '61360', label: '61360  Mieto (Kurikka)' },
  { id: '61370', label: '61370  Lohiluoma (Kurikka)' },
  { id: '61380', label: '61380  Polvenkylä (Kurikka)' },
  { id: '61400', label: '61400  Ylistaro Keskus (Seinäjoki)' },
  { id: '61410', label: '61410  Ylistaro Asemanseutu (Seinäjoki)' },
  { id: '61430', label: '61430  Isorehto (Seinäjoki)' },
  { id: '61440', label: '61440  Untamala (Seinäjoki)' },
  { id: '61450', label: '61450  Kylänpää (Seinäjoki)' },
  { id: '61460', label: '61460  Hanhikoski (Seinäjoki)' },
  { id: '61470', label: '61470  Kitinoja (Seinäjoki)' },
  { id: '61500', label: '61500  Isokyrö Keskus (Isokyrö)' },
  { id: '61520', label: '61520  Lehmäjoki (Isokyrö)' },
  { id: '61550', label: '61550  Orismala (Isokyrö)' },
  { id: '61560', label: '61560  Orisberg (Isokyrö)' },
  { id: '61600', label: '61600  Jalasjärvi Keskus (Kurikka)' },
  { id: '61630', label: '61630  Hirvijärvi (Kurikka)' },
  { id: '61640', label: '61640  Ala-Valli (Kurikka)' },
  { id: '61650', label: '61650  Kalakoski (Seinäjoki)' },
  { id: '61680', label: '61680  Mantila (Kurikka)' },
  { id: '61710', label: '61710  Pentinmäki (Kurikka)' },
  { id: '61720', label: '61720  Koskue (Kurikka)' },
  { id: '61730', label: '61730  Yli-Valli (Kurikka)' },
  { id: '61760', label: '61760  Ilvesjoki (Kurikka)' },
  { id: '61800', label: '61800  Kauhajoki Keskus (Kauhajoki)' },
  { id: '61810', label: '61810  Luomankylä (Kauhajoki)' },
  { id: '61820', label: '61820  Kainasto (Kauhajoki)' },
  { id: '61840', label: '61840  Norinkylä (Teuva)' },
  { id: '61850', label: '61850  Kauhajoki Asemanseutu (Kauhajoki)' },
  { id: '61860', label: '61860  Harja (Kauhajoki)' },
  { id: '61880', label: '61880  Ikkeläjärvi (Kauhajoki)' },
  { id: '61910', label: '61910  Nummijärvi (Kauhajoki)' },
  { id: '61920', label: '61920  Nummikoski (Kauhajoki)' },
  { id: '61940', label: '61940  Hyyppä (Kauhajoki)' },
  { id: '61950', label: '61950  Juonikylä (Kauhajoki)' },
  { id: '61960', label: '61960  Kyrönlatva (Kauhajoki)' },
  { id: '61980', label: '61980  Päntäne (Kauhajoki)' },
  { id: '62100', label: '62100  Lapua Keskus (Lapua)' },
  { id: '62130', label: '62130  Hellanmaa (Lapua)' },
  { id: '62150', label: '62150  Rintakangas (Kauhava)' },
  { id: '62160', label: '62160  Karhunkylä (Lapua)' },
  { id: '62165', label: '62165  Tiisteenjoki (Lapua)' },
  { id: '62170', label: '62170  Lakaluoma (Lapua)' },
  { id: '62175', label: '62175  Ylikylä-Mäenpää (Lapua)' },
  { id: '62185', label: '62185  Mäki-Paavola (Lapua)' },
  { id: '62190', label: '62190  Ruha (Lapua)' },
  { id: '62200', label: '62200  Kauhava Keskus (Kauhava)' },
  { id: '62220', label: '62220  Pernaa (Kauhava)' },
  { id: '62230', label: '62230  Jylhä (Kauhava)' },
  { id: '62240', label: '62240  Huhmarkoski (Kauhava)' },
  { id: '62260', label: '62260  Hirvijoki (Kauhava)' },
  { id: '62280', label: '62280  Mustamaa (Lapua)' },
  { id: '62290', label: '62290  Hirvikylä (Lapua)' },
  { id: '62295', label: '62295  Kauhajärvi (Lapua)' },
  { id: '62300', label: '62300  Härmä (Kauhava)' },
  { id: '62310', label: '62310  Voltti (Kauhava)' },
  { id: '62340', label: '62340  Kuoppa-Vakkuri (Kauhava)' },
  { id: '62350', label: '62350  Ekola (Kauhava)' },
  { id: '62370', label: '62370  Kangasto (Kauhava)' },
  { id: '62375', label: '62375  Ylihärmä Keskus-Vesiluoma (Kauhava)' },
  { id: '62380', label: '62380  Kosolankylä (Kauhava)' },
  { id: '62395', label: '62395  Petterinmäki (Vöyri)' },
  { id: '62410', label: '62410  Rintala (Kauhava)' },
  { id: '62420', label: '62420  Kortesjärvi Keskus (Kauhava)' },
  { id: '62430', label: '62430  Peltotupa (Kauhava)' },
  { id: '62435', label: '62435  Pirttinen (Kauhava)' },
  { id: '62440', label: '62440  Perkiömäki (Kauhava)' },
  { id: '62470', label: '62470  Purmojärvi (Kauhava)' },
  { id: '62500', label: '62500  Evijärvi Keskus (Evijärvi)' },
  { id: '62510', label: '62510  Kuoppa-aho (Evijärvi)' },
  { id: '62520', label: '62520  Kautiainen (Evijärvi)' },
  { id: '62540', label: '62540  Vasikka-aho (Evijärvi)' },
  { id: '62570', label: '62570  Särkilä (Evijärvi)' },
  { id: '62600', label: '62600  Lappajärvi Keskus (Lappajärvi)' },
  { id: '62610', label: '62610  Tarvola (Lappajärvi)' },
  { id: '62620', label: '62620  Ala-Seppä (Lappajärvi)' },
  { id: '62630', label: '62630  Karvala (Lappajärvi)' },
  { id: '62640', label: '62640  Savonkylä (Lappajärvi)' },
  { id: '62660', label: '62660  Itäkylä (Lappajärvi)' },
  { id: '62710', label: '62710  Kurejoki (Alajärvi)' },
  { id: '62720', label: '62720  Sissala (Alajärvi)' },
  { id: '62730', label: '62730  Haukkala (Alajärvi)' },
  { id: '62740', label: '62740  Koskenvarsi (Alajärvi)' },
  { id: '62750', label: '62750  Saukonkylä (Alajärvi)' },
  { id: '62760', label: '62760  Menkijärvi (Alajärvi)' },
  { id: '62800', label: '62800  Vimpeli Keskus (Vimpeli)' },
  { id: '62810', label: '62810  Savonjoki (Vimpeli)' },
  { id: '62830', label: '62830  Luoma-Aho (Alajärvi)' },
  { id: '62840', label: '62840  Lakaniemi-Pokela (Vimpeli)' },
  { id: '62860', label: '62860  Sahi (Vimpeli)' },
  { id: '62870', label: '62870  Vinni (Vimpeli)' },
  { id: '62880', label: '62880  Sääksvesi-Vinni (Vimpeli)' },
  { id: '62900', label: '62900  Alajärvi Keskus (Alajärvi)' },
  { id: '62920', label: '62920  Levijoki (Alajärvi)' },
  { id: '62940', label: '62940  Hoisko (Alajärvi)' },
  { id: '62950', label: '62950  Paalijärvi (Alajärvi)' },
  { id: '62990', label: '62990  Möksy (Alajärvi)' },
  { id: '63100', label: '63100  Kuortane Keskus (Kuortane)' },
  { id: '63120', label: '63120  Leppälänkylä (Kuortane)' },
  { id: '63130', label: '63130  Mäyry (Kuortane)' },
  { id: '63150', label: '63150  Ruona (Kuortane)' },
  { id: '63160', label: '63160  Salmi (Kuortane)' },
  { id: '63210', label: '63210  Niinimaa (Alavus)' },
  { id: '63230', label: '63230  Lentilä (Kuortane)' },
  { id: '63250', label: '63250  Rantala (Kuortane)' },
  { id: '63300', label: '63300  Alavus Keskus (Alavus)' },
  { id: '63320', label: '63320  Kattelus (Alavus)' },
  { id: '63330', label: '63330  Pahajoki (Alavus)' },
  { id: '63340', label: '63340  Sapsalampi (Alavus)' },
  { id: '63350', label: '63350  Sulkavankylä (Alavus)' },
  { id: '63355', label: '63355  Seinäjärvi (Alavus)' },
  { id: '63360', label: '63360  Kuivaskylä (Alavus)' },
  { id: '63370', label: '63370  Taipalus (Alavus)' },
  { id: '63400', label: '63400  Alavus Asemanseutu (Alavus)' },
  { id: '63410', label: '63410  Rantatöysä (Alavus)' },
  { id: '63430', label: '63430  Kätkänjoki (Alavus)' },
  { id: '63450', label: '63450  Löyä (Kuortane)' },
  { id: '63500', label: '63500  Lehtimäki Keskus (Alajärvi)' },
  { id: '63510', label: '63510  Lehtimäki Kirkonkylä (Alajärvi)' },
  { id: '63530', label: '63530  Länsikylä (Alajärvi)' },
  { id: '63540', label: '63540  Hernesmaa (Alajärvi)' },
  { id: '63600', label: '63600  Töysä Keskus (Alavus)' },
  { id: '63610', label: '63610  Tuuri (Alavus)' },
  { id: '63640', label: '63640  Ritola (Alavus)' },
  { id: '63660', label: '63660  Ähtärinranta (Ähtäri)' },
  { id: '63680', label: '63680  Hakojärvi (Alavus)' },
  { id: '63700', label: '63700  Ähtäri Keskus (Ähtäri)' },
  { id: '63770', label: '63770  Peränne (Ähtäri)' },
  { id: '63780', label: '63780  Alastaipale (Ähtäri)' },
  { id: '63800', label: '63800  Soini Keskus (Soini)' },
  { id: '63830', label: '63830  Keisala (Soini)' },
  { id: '63880', label: '63880  Kukonkylä (Soini)' },
  { id: '63890', label: '63890  Hautakylä (Soini)' },
  { id: '63900', label: '63900  Myllymäki-Vehunkylä (Ähtäri)' },
  { id: '63910', label: '63910  Inhan Tehtaat (Ähtäri)' },
  { id: '63920', label: '63920  Inha (Ähtäri)' },
  { id: '63950', label: '63950  Vehunkylä (Ähtäri)' },
  { id: '64100', label: '64100  Kristiinankaupunki keskus (Kristiinankaupunki)' },
  { id: '64140', label: '64140  Tiukka (Kristiinankaupunki)' },
  { id: '64200', label: '64200  Närpiö Keskus (Närpiö)' },
  { id: '64210', label: '64210  Kalax (Närpiö)' },
  { id: '64220', label: '64220  Yttermark (Närpiö)' },
  { id: '64230', label: '64230  Närpiön Asemanseutu (Närpiö)' },
  { id: '64240', label: '64240  Böle (Närpiö)' },
  { id: '64250', label: '64250  Pjelax (Närpiö)' },
  { id: '64260', label: '64260  Kaskinen (Kaskinen)' },
  { id: '64300', label: '64300  Lapväärtti (Kristiinankaupunki)' },
  { id: '64320', label: '64320  Dagsmark (Kristiinankaupunki)' },
  { id: '64350', label: '64350  Karijoki Keskus (Karijoki)' },
  { id: '64370', label: '64370  Myrkky (Karijoki)' },
  { id: '64440', label: '64440  Metsälä-Kallträsk (Kristiinankaupunki)' },
  { id: '64450', label: '64450  Kallträsk (Kristiinankaupunki)' },
  { id: '64460', label: '64460  Härkmeri (Kristiinankaupunki)' },
  { id: '64480', label: '64480  Skaftung (Kristiinankaupunki)' },
  { id: '64490', label: '64490  Siipyy (Kristiinankaupunki)' },
  { id: '64510', label: '64510  Nämpnäs (Närpiö)' },
  { id: '64530', label: '64530  Norrnäs (Närpiö)' },
  { id: '64550', label: '64550  Rangsby (Närpiö)' },
  { id: '64610', label: '64610  Övermark (Närpiö)' },
  { id: '64700', label: '64700  Teuva Keskus (Teuva)' },
  { id: '64720', label: '64720  Perälä (Teuva)' },
  { id: '64740', label: '64740  Horo (Teuva)' },
  { id: '64760', label: '64760  Peltola (Teuva)' },
  { id: '64770', label: '64770  Äystö (Teuva)' },
  { id: '64810', label: '64810  Vanhakylä (Isojoki)' },
  { id: '64820', label: '64820  Kärjenkoski (Isojoki)' },
  { id: '64830', label: '64830  Villamo (Isojoki)' },
  { id: '64840', label: '64840  Heikkilänjoki (Isojoki)' },
  { id: '64850', label: '64850  Möykky (Isojoki)' },
  { id: '64900', label: '64900  Isojoki Keskus (Isojoki)' },
  { id: '64930', label: '64930  Kodesjärvi (Isojoki)' },
  { id: '65100', label: '65100  Vaasa Keskus (Vaasa)' },
  { id: '65130', label: '65130  Hietalahti (Vaasa)' },
  { id: '65170', label: '65170  Vaskiluoto (Vaasa)' },
  { id: '65200', label: '65200  Palosaari (Vaasa)' },
  { id: '65230', label: '65230  Vetokannas (Vaasa)' },
  { id: '65280', label: '65280  Gerby (Vaasa)' },
  { id: '65300', label: '65300  Asevelikylä (Vaasa)' },
  { id: '65320', label: '65320  Huutoniemi (Vaasa)' },
  { id: '65350', label: '65350  Suvilahti (Vaasa)' },
  { id: '65370', label: '65370  Ristinummi (Vaasa)' },
  { id: '65380', label: '65380  Vanha Vaasa (Vaasa)' },
  { id: '65410', label: '65410  Sundom (Vaasa)' },
  { id: '65450', label: '65450  Sulva (Mustasaari)' },
  { id: '65460', label: '65460  Tölby-Rimal (Mustasaari)' },
  { id: '65470', label: '65470  Rimal (Mustasaari)' },
  { id: '65480', label: '65480  Vikby (Mustasaari)' },
  { id: '65520', label: '65520  Helsingby (Mustasaari)' },
  { id: '65610', label: '65610  Mustasaari Keskus (Mustasaari)' },
  { id: '65630', label: '65630  Karperö (Mustasaari)' },
  { id: '65650', label: '65650  Koskö (Mustasaari)' },
  { id: '65710', label: '65710  Singsby (Mustasaari)' },
  { id: '65730', label: '65730  Jungsund (Mustasaari)' },
  { id: '65760', label: '65760  Iskmo (Mustasaari)' },
  { id: '65800', label: '65800  Raippaluoto (Mustasaari)' },
  { id: '65870', label: '65870  Björköby (Mustasaari)' },
  { id: '65920', label: '65920  Norra Vallgrund-Söderudden (Mustasaari)' },
  { id: '65930', label: '65930  Södra Vallgrund (Mustasaari)' },
  { id: '65970', label: '65970  Söderudden (Mustasaari)' },
  { id: '66100', label: '66100  Maalahti (Maalahti)' },
  { id: '66140', label: '66140  Övermalax (Maalahti)' },
  { id: '66160', label: '66160  Långåminne (Maalahti)' },
  { id: '66200', label: '66200  Korsnäs Keskus (Korsnäs)' },
  { id: '66210', label: '66210  Moikipää (Korsnäs)' },
  { id: '66220', label: '66220  Bergö (Maalahti)' },
  { id: '66230', label: '66230  Korsbäck (Korsnäs)' },
  { id: '66240', label: '66240  Petolahti (Maalahti)' },
  { id: '66260', label: '66260  Svarvar (Maalahti)' },
  { id: '66270', label: '66270  Pirttikylä-Sorvari (Närpiö)' },
  { id: '66280', label: '66280  Taklax (Korsnäs)' },
  { id: '66290', label: '66290  Harrström (Korsnäs)' },
  { id: '66295', label: '66295  Töjby (Närpiö)' },
  { id: '66300', label: '66300  Jurva Keskus (Kurikka)' },
  { id: '66320', label: '66320  Niemenkylä (Kurikka)' },
  { id: '66330', label: '66330  Hakko (Kurikka)' },
  { id: '66340', label: '66340  Sarvijoki (Kurikka)' },
  { id: '66350', label: '66350  Tainus (Kurikka)' },
  { id: '66360', label: '66360  Kesti (Kurikka)' },
  { id: '66370', label: '66370  Närvijoki (Kurikka)' },
  { id: '66400', label: '66400  Laihia Keskus (Laihia)' },
  { id: '66420', label: '66420  Ruto (Laihia)' },
  { id: '66430', label: '66430  Vedenoja (Vaasa)' },
  { id: '66440', label: '66440  Tervajoki (Isokyrö)' },
  { id: '66450', label: '66450  Jakkula (Laihia)' },
  { id: '66460', label: '66460  Haapala (Laihia)' },
  { id: '66470', label: '66470  Jukaja-Jokisalo (Laihia)' },
  { id: '66500', label: '66500  Vähäkyrö Keskus (Vaasa)' },
  { id: '66510', label: '66510  Merikaarto (Vaasa)' },
  { id: '66520', label: '66520  Veikkaala (Mustasaari)' },
  { id: '66530', label: '66530  Koivulahti (Mustasaari)' },
  { id: '66540', label: '66540  Petsmo (Mustasaari)' },
  { id: '66550', label: '66550  Västerhankmo (Mustasaari)' },
  { id: '66560', label: '66560  Österhankmo (Mustasaari)' },
  { id: '66580', label: '66580  Kuni (Mustasaari)' },
  { id: '66590', label: '66590  Vassor (Mustasaari)' },
  { id: '66600', label: '66600  Vöyri Keskus (Vöyri)' },
  { id: '66640', label: '66640  Maksamaa (Vöyri)' },
  { id: '66660', label: '66660  Bertby (Vöyri)' },
  { id: '66680', label: '66680  Kaurajärvi (Vöyri)' },
  { id: '66710', label: '66710  Kaitsor (Vöyri)' },
  { id: '66730', label: '66730  Oxkangar (Vöyri)' },
  { id: '66800', label: '66800  Oravainen Keskus (Vöyri)' },
  { id: '66810', label: '66810  Kimo (Vöyri)' },
  { id: '66820', label: '66820  Komossa (Vöyri)' },
  { id: '66830', label: '66830  Oravaisten Tehdas (Vöyri)' },
  { id: '66840', label: '66840  Pensala (Uusikaarlepyy)' },
  { id: '66850', label: '66850  Jepua (Uusikaarlepyy)' },
  { id: '66900', label: '66900  Uusikaarlepyy Keskus (Uusikaarlepyy)' },
  { id: '66910', label: '66910  Soklot (Uusikaarlepyy)' },
  { id: '66930', label: '66930  Kovjoki (Uusikaarlepyy)' },
  { id: '66950', label: '66950  Munsala (Uusikaarlepyy)' },
  { id: '66970', label: '66970  Hirvlax (Uusikaarlepyy)' },
  { id: '66980', label: '66980  Monå (Uusikaarlepyy)' },
  { id: '66999', label: '66999  Kaukaluoto (Mustasaari)' },
  { id: '67100', label: '67100  Kokkola Keskus (Kokkola)' },
  { id: '67200', label: '67200  Kiviniitty-Tullimäki (Kokkola)' },
  { id: '67300', label: '67300  Halkokari (Kokkola)' },
  { id: '67400', label: '67400  Rytimäki (Kokkola)' },
  { id: '67410', label: '67410  Vitsari (Kokkola)' },
  { id: '67500', label: '67500  Sokoja (Kokkola)' },
  { id: '67600', label: '67600  Linnusperä-Kaustari (Kokkola)' },
  { id: '67700', label: '67700  Kirkonmäki-Isokylä (Kokkola)' },
  { id: '67800', label: '67800  Koivuhaka (Kokkola)' },
  { id: '67900', label: '67900  Ykspihjala (Kokkola)' },
  { id: '68100', label: '68100  Himanka Keskus (Kalajoki)' },
  { id: '68150', label: '68150  Hillilä (Kalajoki)' },
  { id: '68210', label: '68210  Marinkainen (Kokkola)' },
  { id: '68220', label: '68220  Karhi (Kokkola)' },
  { id: '68230', label: '68230  Lohtaja Keskus (Kokkola)' },
  { id: '68240', label: '68240  Ala-Viirre (Kokkola)' },
  { id: '68300', label: '68300  Kälviä Keskus (Kokkola)' },
  { id: '68320', label: '68320  Ruotsalo (Kokkola)' },
  { id: '68370', label: '68370  Ullava Keskus (Kokkola)' },
  { id: '68380', label: '68380  Yli-Ullava (Kokkola)' },
  { id: '68390', label: '68390  Rahkonen (Kokkola)' },
  { id: '68410', label: '68410  Alaveteli (Kruunupyy)' },
  { id: '68500', label: '68500  Kruunupyy Keskus (Kruunupyy)' },
  { id: '68530', label: '68530  Lepplax (Pedersören kunta)' },
  { id: '68550', label: '68550  Öja (Kokkola)' },
  { id: '68555', label: '68555  Bosund (Luoto)' },
  { id: '68560', label: '68560  Eugmo (Luoto)' },
  { id: '68570', label: '68570  Luoto (Luoto)' },
  { id: '68580', label: '68580  Furuholmen (Luoto)' },
  { id: '68600', label: '68600  Pietarsaari Keskus (Pietarsaari)' },
  { id: '68620', label: '68620  Skutnäs-Västermalm (Pietarsaari)' },
  { id: '68630', label: '68630  Kyrkostrand (Pietarsaari)' },
  { id: '68660', label: '68660  Östermalm-Björnholmen (Pietarsaari)' },
  { id: '68690', label: '68690  Sundby (Pedersören kunta)' },
  { id: '68700', label: '68700  Teerijärvi (Kruunupyy)' },
  { id: '68750', label: '68750  Småbönders (Kruunupyy)' },
  { id: '68800', label: '68800  Kolppi (Pedersören kunta)' },
  { id: '68810', label: '68810  Ytteresse (Pedersören kunta)' },
  { id: '68820', label: '68820  Ähtävä (Pedersören kunta)' },
  { id: '68830', label: '68830  Bäckby (Pedersören kunta)' },
  { id: '68840', label: '68840  Nederlappfors (Pedersören kunta)' },
  { id: '68870', label: '68870  Edsevö (Pedersören kunta)' },
  { id: '68910', label: '68910  Pännäinen (Pedersören kunta)' },
  { id: '68920', label: '68920  Forsby (Pedersören kunta)' },
  { id: '68930', label: '68930  Purmo (Pedersören kunta)' },
  { id: '68940', label: '68940  Lillby (Pedersören kunta)' },
  { id: '68970', label: '68970  Överpurmo (Pedersören kunta)' },
  { id: '68999', label: '68999  Rita (Kokkola)' },
  { id: '69100', label: '69100  Kannus Keskus (Kannus)' },
  { id: '69150', label: '69150  Eskola (Kannus)' },
  { id: '69170', label: '69170  Yli-Kannus (Kannus)' },
  { id: '69300', label: '69300  Toholampi Keskus (Toholampi)' },
  { id: '69310', label: '69310  Laitala (Toholampi)' },
  { id: '69330', label: '69330  Määttälä (Toholampi)' },
  { id: '69340', label: '69340  Purontaka (Toholampi)' },
  { id: '69410', label: '69410  Sykäräinen (Toholampi)' },
  { id: '69420', label: '69420  Syri (Lestijärvi)' },
  { id: '69440', label: '69440  Lestijärvi Keskus (Lestijärvi)' },
  { id: '69450', label: '69450  Yli-Lesti (Lestijärvi)' },
  { id: '69510', label: '69510  Halsua Keskus-Marjusaari (Halsua)' },
  { id: '69550', label: '69550  Kanala (Halsua)' },
  { id: '69570', label: '69570  Karvonen (Halsua)' },
  { id: '69600', label: '69600  Kaustinen Keskus (Kaustinen)' },
  { id: '69660', label: '69660  Köyhäjoki (Kaustinen)' },
  { id: '69700', label: '69700  Veteli Keskus (Veteli)' },
  { id: '69730', label: '69730  Tunkkari (Veteli)' },
  { id: '69750', label: '69750  Sillanpää (Veteli)' },
  { id: '69820', label: '69820  Räyrinki (Veteli)' },
  { id: '69830', label: '69830  Pulkkinen (Veteli)' },
  { id: '69850', label: '69850  Patana (Veteli)' },
  { id: '69910', label: '69910  Kivikangas (Perho)' },
  { id: '69920', label: '69920  Oksakoski-Kivikangas (Perho)' },
  { id: '69950', label: '69950  Perho Keskus-Salamajärvi (Perho)' },
  { id: '69970', label: '69970  Salamajärvi (Perho)' },
  { id: '69980', label: '69980  Möttönen (Perho)' },
  { id: '70100', label: '70100  Kuopio Keskus (Kuopio)' },
  { id: '70110', label: '70110  Etu-Niirala (Kuopio)' },
  { id: '70150', label: '70150  Neulamäki (Kuopio)' },
  { id: '70200', label: '70200  Puijonlaakso (Kuopio)' },
  { id: '70210', label: '70210  Kuopion Keskussairaala-alue (Kuopio)' },
  { id: '70240', label: '70240  Niuvaniemen Sairaala-alue (Kuopio)' },
  { id: '70260', label: '70260  Julkula (Kuopio)' },
  { id: '70280', label: '70280  Länsi-Puijo (Kuopio)' },
  { id: '70300', label: '70300  Linnanpelto (Kuopio)' },
  { id: '70340', label: '70340  Inkilänmäki (Kuopio)' },
  { id: '70400', label: '70400  Kettulanlahti (Kuopio)' },
  { id: '70420', label: '70420  Päiväranta (Kuopio)' },
  { id: '70460', label: '70460  Saarijärvi (Kuopio)' },
  { id: '70500', label: '70500  Männistö (Kuopio)' },
  { id: '70600', label: '70600  Niirala-Huuhanmäki (Kuopio)' },
  { id: '70620', label: '70620  Haapaniemi (Kuopio)' },
  { id: '70700', label: '70700  Särkiniemi (Kuopio)' },
  { id: '70780', label: '70780  Jynkkä (Kuopio)' },
  { id: '70800', label: '70800  Pitkälahti (Kuopio)' },
  { id: '70820', label: '70820  Litmanen (Kuopio)' },
  { id: '70840', label: '70840  Jynkänmäki (Kuopio)' },
  { id: '70870', label: '70870  Hiltulanlahti (Kuopio)' },
  { id: '70900', label: '70900  Toivala (Siilinjärvi)' },
  { id: '70910', label: '70910  Vuorela (Siilinjärvi)' },
  { id: '70940', label: '70940  Jännevirta (Kuopio)' },
  { id: '71130', label: '71130  Kortejoki (Kuopio)' },
  { id: '71150', label: '71150  Vartiala (Kuopio)' },
  { id: '71160', label: '71160  Riistavesi (Kuopio)' },
  { id: '71170', label: '71170  Laukka-aho (Tuusniemi)' },
  { id: '71200', label: '71200  Tuusniemi Keskus (Tuusniemi)' },
  { id: '71210', label: '71210  Tuusjärvi (Tuusniemi)' },
  { id: '71240', label: '71240  Paakkila (Tuusniemi)' },
  { id: '71260', label: '71260  Juurikkamäki (Tuusniemi)' },
  { id: '71280', label: '71280  Kosula (Tuusniemi)' },
  { id: '71310', label: '71310  Vehmersalmi Keskus (Kuopio)' },
  { id: '71330', label: '71330  Räsälä (Kuopio)' },
  { id: '71360', label: '71360  Litmaniemi (Kuopio)' },
  { id: '71380', label: '71380  Mustinlahti (Kuopio)' },
  { id: '71460', label: '71460  Paukarlahti (Leppävirta)' },
  { id: '71470', label: '71470  Oravikoski (Leppävirta)' },
  { id: '71480', label: '71480  Kurkimäki (Kuopio)' },
  { id: '71490', label: '71490  Airaksela (Kuopio)' },
  { id: '71520', label: '71520  Kaislastenlahti (Kuopio)' },
  { id: '71570', label: '71570  Syvänniemi (Kuopio)' },
  { id: '71610', label: '71610  Salonkulma (Kuopio)' },
  { id: '71640', label: '71640  Talluskylä (Tervo)' },
  { id: '71650', label: '71650  Hirvilahti-Salonkulma (Kuopio)' },
  { id: '71660', label: '71660  Leinolanlahti (Kuopio)' },
  { id: '71670', label: '71670  Kurolanlahti (Kuopio)' },
  { id: '71680', label: '71680  Pulkonkoski-Saarinen (Kuopio)' },
  { id: '71690', label: '71690  Varpasmaa (Kuopio)' },
  { id: '71720', label: '71720  Käärmelahti (Kuopio)' },
  { id: '71730', label: '71730  Kinnulanlahti (Kuopio)' },
  { id: '71740', label: '71740  Tavinsalmi (Kuopio)' },
  { id: '71745', label: '71745  Haatala (Kuopio)' },
  { id: '71750', label: '71750  Maaninka Keskus (Kuopio)' },
  { id: '71760', label: '71760  Ahkionlahti-Vianta (Kuopio)' },
  { id: '71775', label: '71775  Tuovilanlahti-Haatala (Kuopio)' },
  { id: '71800', label: '71800  Siilinjärvi Keskus (Siilinjärvi)' },
  { id: '71820', label: '71820  Pöljä (Siilinjärvi)' },
  { id: '71840', label: '71840  Kuuslahti (Siilinjärvi)' },
  { id: '71850', label: '71850  Leppäkaarre (Siilinjärvi)' },
  { id: '71870', label: '71870  Harjamäki (Siilinjärvi)' },
  { id: '71890', label: '71890  Hamula (Siilinjärvi)' },
  { id: '71910', label: '71910  Alapitkä (Lapinlahti)' },
  { id: '71920', label: '71920  Pajujärvi (Lapinlahti)' },
  { id: '71950', label: '71950  Lammasaho (Kuopio)' },
  { id: '71960', label: '71960  Lukkarila (Lapinlahti)' },
  { id: '72100', label: '72100  Karttula Keskus (Kuopio)' },
  { id: '72140', label: '72140  Itä-Karttula-Riuttasenpää (Kuopio)' },
  { id: '72210', label: '72210  Tervo Keskus-Eliaksela (Tervo)' },
  { id: '72220', label: '72220  Eliaksela (Tervo)' },
  { id: '72300', label: '72300  Vesanto Keskus (Vesanto)' },
  { id: '72310', label: '72310  Niinivesi (Vesanto)' },
  { id: '72330', label: '72330  Tiitilänkylä (Vesanto)' },
  { id: '72350', label: '72350  Pienola (Vesanto)' },
  { id: '72360', label: '72360  Närhilä (Vesanto)' },
  { id: '72380', label: '72380  Horontaipale (Vesanto)' },
  { id: '72400', label: '72400  Pielavesi Keskus-Jylänki (Pielavesi)' },
  { id: '72430', label: '72430  Kumpula (Pielavesi)' },
  { id: '72490', label: '72490  Saarinen (Kuopio)' },
  { id: '72510', label: '72510  Jokijärvi (Pielavesi)' },
  { id: '72530', label: '72530  Säviäntaipale (Pielavesi)' },
  { id: '72550', label: '72550  Säviä (Pielavesi)' },
  { id: '72570', label: '72570  Vuorikylä (Pielavesi)' },
  { id: '72600', label: '72600  Keitele Keskus (Keitele)' },
  { id: '72630', label: '72630  Kuusela-Rajala (Keitele)' },
  { id: '72710', label: '72710  Vaaraslahti (Pielavesi)' },
  { id: '72740', label: '72740  Laukkala (Pielavesi)' },
  { id: '72810', label: '72810  Jylhänkylä (Pielavesi)' },
  { id: '72840', label: '72840  Saarela (Pielavesi)' },
  { id: '72930', label: '72930  Tossavanlahti (Keitele)' },
  { id: '72950', label: '72950  Porttila (Keitele)' },
  { id: '72980', label: '72980  Petäjäkylä (Keitele)' },
  { id: '73100', label: '73100  Lapinlahti Keskus (Lapinlahti)' },
  { id: '73110', label: '73110  Mäntylahti-Karvasalmi (Lapinlahti)' },
  { id: '73120', label: '73120  Nerkoo-Horsmanmäki (Lapinlahti)' },
  { id: '73200', label: '73200  Varpaisjärvi Keskus (Lapinlahti)' },
  { id: '73230', label: '73230  Syvärinpää (Lapinlahti)' },
  { id: '73250', label: '73250  Korpijärvi (Lapinlahti)' },
  { id: '73300', label: '73300  Nilsiä Keskus (Kuopio)' },
  { id: '73310', label: '73310  Tahkovuori (Kuopio)' },
  { id: '73320', label: '73320  Reittiö (Kuopio)' },
  { id: '73350', label: '73350  Haluna (Kuopio)' },
  { id: '73360', label: '73360  Pajulahti (Kuopio)' },
  { id: '73410', label: '73410  Pieksänkoski (Kuopio)' },
  { id: '73460', label: '73460  Muuruvesi (Kuopio)' },
  { id: '73470', label: '73470  Västinniemi (Kuopio)' },
  { id: '73500', label: '73500  Juankoski Keskus (Kuopio)' },
  { id: '73600', label: '73600  Kaavi Keskus (Kaavi)' },
  { id: '73620', label: '73620  Kortteinen-Rasilampi (Kaavi)' },
  { id: '73640', label: '73640  Sivakkavaara (Kaavi)' },
  { id: '73645', label: '73645  Niinivaara-Onnivaara (Kaavi)' },
  { id: '73670', label: '73670  Luikonlahti (Kaavi)' },
  { id: '73710', label: '73710  Viitaniemi (Kuopio)' },
  { id: '73730', label: '73730  Losomäki (Kuopio)' },
  { id: '73770', label: '73770  Säyneinen-Ala-Siikajärvi (Kuopio)' },
  { id: '73810', label: '73810  Palonurmi-Aholansaari (Kuopio)' },
  { id: '73830', label: '73830  Keski-Siikajärvi (Kuopio)' },
  { id: '73850', label: '73850  Ala-Luosta-Riitasalo (Rautavaara)' },
  { id: '73900', label: '73900  Rautavaara Keskus (Rautavaara)' },
  { id: '73970', label: '73970  Riitasalo (Rautavaara)' },
  { id: '73990', label: '73990  Kangaslahti (Rautavaara)' },
  { id: '74100', label: '74100  Iisalmi Keskus, Eteläinen (Iisalmi)' },
  { id: '74120', label: '74120  Iisalmi Keskus Pohjoinen (Iisalmi)' },
  { id: '74130', label: '74130  Kihmula (Iisalmi)' },
  { id: '74140', label: '74140  Ritoniemi (Iisalmi)' },
  { id: '74150', label: '74150  Laidunmäki (Iisalmi)' },
  { id: '74160', label: '74160  Koljonvirta (Iisalmi)' },
  { id: '74170', label: '74170  Soinlahti (Iisalmi)' },
  { id: '74200', label: '74200  Vieremä Keskus (Vieremä)' },
  { id: '74230', label: '74230  Salahmi (Vieremä)' },
  { id: '74240', label: '74240  Marttisenjärvi (Vieremä)' },
  { id: '74250', label: '74250  Nissilä (Vieremä)' },
  { id: '74270', label: '74270  Kauppilanmäki (Vieremä)' },
  { id: '74300', label: '74300  Sonkajärvi Keskus (Sonkajärvi)' },
  { id: '74340', label: '74340  Sukeva-Kainuunmäki (Sonkajärvi)' },
  { id: '74345', label: '74345  Kalliosuo (Sonkajärvi)' },
  { id: '74360', label: '74360  Sonkakoski (Sonkajärvi)' },
  { id: '74380', label: '74380  Jyrkkä (Sonkajärvi)' },
  { id: '74390', label: '74390  Laakajärvi (Sotkamo)' },
  { id: '74420', label: '74420  Hernejärvi (Sonkajärvi)' },
  { id: '74470', label: '74470  Paloinen (Lapinlahti)' },
  { id: '74490', label: '74490  Koirakoski (Sonkajärvi)' },
  { id: '74510', label: '74510  Peltosalmi-Sourunsalo (Iisalmi)' },
  { id: '74520', label: '74520  Kirmanranta (Iisalmi)' },
  { id: '74540', label: '74540  Kotikylä (Iisalmi)' },
  { id: '74550', label: '74550  Pörsänmäki (Iisalmi)' },
  { id: '74590', label: '74590  Kurenpolvi (Vieremä)' },
  { id: '74595', label: '74595  Runni (Iisalmi)' },
  { id: '74610', label: '74610  Honkaranta (Kiuruvesi)' },
  { id: '74630', label: '74630  Heinäkylä (Kiuruvesi)' },
  { id: '74640', label: '74640  Sulkavanjärvi (Kiuruvesi)' },
  { id: '74670', label: '74670  Rapakkojoki (Kiuruvesi)' },
  { id: '74680', label: '74680  Myllyniemi (Kiuruvesi)' },
  { id: '74700', label: '74700  Kiuruvesi Keskus (Kiuruvesi)' },
  { id: '74720', label: '74720  Niemiskylä (Kiuruvesi)' },
  { id: '74740', label: '74740  Aittojärvi (Kiuruvesi)' },
  { id: '74770', label: '74770  Kalliokylä (Kiuruvesi)' },
  { id: '74840', label: '74840  Koppeloharju (Kiuruvesi)' },
  { id: '74940', label: '74940  Remeskylä-Tihilänkangas (Kiuruvesi)' },
  { id: '74980', label: '74980  Tihilänkangas (Kiuruvesi)' },
  { id: '75500', label: '75500  Nurmes Keskus (Nurmes)' },
  { id: '75530', label: '75530  Porokylä (Nurmes)' },
  { id: '75650', label: '75650  Savikylä (Nurmes)' },
  { id: '75680', label: '75680  Ylä-Luosta (Rautavaara)' },
  { id: '75700', label: '75700  Valtimo Keskus (Nurmes)' },
  { id: '75710', label: '75710  Karhunpää (Nurmes)' },
  { id: '75740', label: '75740  Pajukoski (Nurmes)' },
  { id: '75770', label: '75770  Puukari (Nurmes)' },
  { id: '75790', label: '75790  Rumo (Nurmes)' },
  { id: '75840', label: '75840  Ylä-Valtimo (Nurmes)' },
  { id: '75890', label: '75890  Saramo-Petäiskylä-Mujejärvi (Nurmes)' },
  { id: '75930', label: '75930  Petäiskylä (Nurmes)' },
  { id: '75940', label: '75940  Mujejärvi (Nurmes)' },
  { id: '75970', label: '75970  Kohtavaara (Nurmes)' },
  { id: '75990', label: '75990  Höljäkkä (Nurmes)' },
  { id: '76100', label: '76100  Pieksämäki Keskus (Pieksämäki)' },
  { id: '76120', label: '76120  Kontiopuisto (Pieksämäki)' },
  { id: '76130', label: '76130  Pappilanmäki (Pieksämäki)' },
  { id: '76150', label: '76150  Länsirinne (Pieksämäki)' },
  { id: '76280', label: '76280  Partaharju (Pieksämäki)' },
  { id: '76620', label: '76620  Pyhitty (Pieksämäki)' },
  { id: '76780', label: '76780  Lamminmäki (Pieksämäki)' },
  { id: '76850', label: '76850  Naarajärvi (Pieksämäki)' },
  { id: '76940', label: '76940  Nenonpelto (Pieksämäki)' },
  { id: '77110', label: '77110  Paltanen (Pieksämäki)' },
  { id: '77120', label: '77120  Venetmäki (Pieksämäki)' },
  { id: '77140', label: '77140  Huumala (Pieksämäki)' },
  { id: '77220', label: '77220  Neuvola (Pieksämäki)' },
  { id: '77240', label: '77240  Halkokumpu (Pieksämäki)' },
  { id: '77320', label: '77320  Hurskaala (Pieksämäki)' },
  { id: '77330', label: '77330  Virtasalmi Keskus (Pieksämäki)' },
  { id: '77350', label: '77350  Montola (Pieksämäki)' },
  { id: '77380', label: '77380  Kantala (Mikkeli)' },
  { id: '77430', label: '77430  Siikamäki (Pieksämäki)' },
  { id: '77460', label: '77460  Maavesi (Joroinen)' },
  { id: '77480', label: '77480  Savuniemi (Joroinen)' },
  { id: '77520', label: '77520  Haapakoski-Partaharju (Pieksämäki)' },
  { id: '77570', label: '77570  Jäppilä Keskus (Pieksämäki)' },
  { id: '77580', label: '77580  Tihusniemi (Pieksämäki)' },
  { id: '77600', label: '77600  Suonenjoki Keskus (Suonenjoki)' },
  { id: '77630', label: '77630  Lempyy (Suonenjoki)' },
  { id: '77690', label: '77690  Suontee (Suonenjoki)' },
  { id: '77700', label: '77700  Rautalampi Keskus (Rautalampi)' },
  { id: '77770', label: '77770  Myhinpää (Rautalampi)' },
  { id: '77800', label: '77800  Iisvesi (Suonenjoki)' },
  { id: '77910', label: '77910  Vaajasalmi (Rautalampi)' },
  { id: '77930', label: '77930  Kerkonjoensuu (Rautalampi)' },
  { id: '77960', label: '77960  Pakarila (Rautalampi)' },
  { id: '78200', label: '78200  Varkaus Keskus (Varkaus)' },
  { id: '78210', label: '78210  Kuoppakangas (Varkaus)' },
  { id: '78250', label: '78250  Päiviönsaari (Varkaus)' },
  { id: '78300', label: '78300  Kommila (Varkaus)' },
  { id: '78310', label: '78310  Puurtila (Varkaus)' },
  { id: '78400', label: '78400  Luttila (Varkaus)' },
  { id: '78480', label: '78480  Timola (Leppävirta)' },
  { id: '78500', label: '78500  Pitkälänniemi-Könönpelto (Varkaus)' },
  { id: '78610', label: '78610  Kurola (Varkaus)' },
  { id: '78710', label: '78710  Akonlahti (Varkaus)' },
  { id: '78850', label: '78850  Käpykangas (Varkaus)' },
  { id: '78870', label: '78870  Kaura-Aho (Varkaus)' },
  { id: '78880', label: '78880  Kuvansi (Joroinen)' },
  { id: '78900', label: '78900  Ruokokoski (Varkaus)' },
  { id: '79100', label: '79100  Leppävirta Keskus (Leppävirta)' },
  { id: '79130', label: '79130  Sorsakoski (Leppävirta)' },
  { id: '79140', label: '79140  Kotalahti (Leppävirta)' },
  { id: '79150', label: '79150  Konnuslahti-Valkeamäki (Leppävirta)' },
  { id: '79160', label: '79160  Saamaiskylä (Leppävirta)' },
  { id: '79180', label: '79180  Tuppurinmäki (Leppävirta)' },
  { id: '79190', label: '79190  Valkeamäki (Leppävirta)' },
  { id: '79230', label: '79230  Moninmäki (Leppävirta)' },
  { id: '79255', label: '79255  Kurjala-Moninmäki (Leppävirta)' },
  { id: '79265', label: '79265  Puponmäki (Leppävirta)' },
  { id: '79330', label: '79330  Näädänmaa (Leppävirta)' },
  { id: '79350', label: '79350  Itä-Soisalo (Leppävirta)' },
  { id: '79410', label: '79410  Niinimäki (Leppävirta)' },
  { id: '79480', label: '79480  Kangaslampi Keskus (Varkaus)' },
  { id: '79520', label: '79520  Viljolahti (Varkaus)' },
  { id: '79600', label: '79600  Joroinen Keskus (Joroinen)' },
  { id: '79620', label: '79620  Huutokoski (Joroinen)' },
  { id: '79630', label: '79630  Kolma (Joroinen)' },
  { id: '79660', label: '79660  Kerisalo (Joroinen)' },
  { id: '79680', label: '79680  Lahnalahti (Joroinen)' },
  { id: '79690', label: '79690  Kaitainen (Joroinen)' },
  { id: '79700', label: '79700  Heinävesi Keskus (Heinävesi)' },
  { id: '79810', label: '79810  Karvion kanava (Heinävesi)' },
  { id: '79820', label: '79820  Varistaipale (Heinävesi)' },
  { id: '79830', label: '79830  Palokki (Heinävesi)' },
  { id: '79850', label: '79850  Uusi-Valamo (Heinävesi)' },
  { id: '79860', label: '79860  Suuraho - Uusi-Valamo (Heinävesi)' },
  { id: '79885', label: '79885  Etelä-Petruma (Heinävesi)' },
  { id: '79895', label: '79895  Sarvikumpu (Heinävesi)' },
  { id: '79910', label: '79910  Kerma (Heinävesi)' },
  { id: '79940', label: '79940  Vihtari-Poikiinaho (Heinävesi)' },
  { id: '80100', label: '80100  Joensuu Keskus, Pohjoinen (Joensuu)' },
  { id: '80110', label: '80110  Joensuu Keskus Eteläinen (Joensuu)' },
  { id: '80130', label: '80130  Kanervala (Joensuu)' },
  { id: '80140', label: '80140  Noljakka (Joensuu)' },
  { id: '80160', label: '80160  Rantakylä-Latola (Joensuu)' },
  { id: '80170', label: '80170  Utra (Joensuu)' },
  { id: '80200', label: '80200  Niinivaara (Joensuu)' },
  { id: '80210', label: '80210  Sairaala-alue (Joensuu)' },
  { id: '80220', label: '80220  Penttilä (Joensuu)' },
  { id: '80230', label: '80230  Hukanhauta (Joensuu)' },
  { id: '80260', label: '80260  Kettuvaara (Joensuu)' },
  { id: '80330', label: '80330  Reijola (Joensuu)' },
  { id: '80400', label: '80400  Ylämylly (Liperi)' },
  { id: '80510', label: '80510  Onttola-Kunnasniemi (Kontiolahti)' },
  { id: '80710', label: '80710  Lehmo (Kontiolahti)' },
  { id: '80770', label: '80770  Kontiolahti as (Kontiolahti)' },
  { id: '80780', label: '80780  Kontioniemi (Kontiolahti)' },
  { id: '80790', label: '80790  Kontioranta-Kontioniemi (Kontiolahti)' },
  { id: '80850', label: '80850  Paihola (Kontiolahti)' },
  { id: '80910', label: '80910  Kulho (Kontiolahti)' },
  { id: '81100', label: '81100  Kontiolahti (Kontiolahti)' },
  { id: '81120', label: '81120  Katajaranta (Kontiolahti)' },
  { id: '81160', label: '81160  Romppala-Riihikangas (Kontiolahti)' },
  { id: '81200', label: '81200  Eno Keskus (Joensuu)' },
  { id: '81210', label: '81210  Louhioja (Joensuu)' },
  { id: '81220', label: '81220  Jakokoski (Kontiolahti)' },
  { id: '81230', label: '81230  Mönni (Kontiolahti)' },
  { id: '81235', label: '81235  Lehtoi (Kontiolahti)' },
  { id: '81260', label: '81260  Ahveninen (Joensuu)' },
  { id: '81270', label: '81270  Paukkaja (Joensuu)' },
  { id: '81280', label: '81280  Uimaharju (Joensuu)' },
  { id: '81290', label: '81290  Ukkola (Joensuu)' },
  { id: '81295', label: '81295  Haapalahti (Joensuu)' },
  { id: '81320', label: '81320  Kuismavaara (Joensuu)' },
  { id: '81330', label: '81330  Luhtapohja (Joensuu)' },
  { id: '81350', label: '81350  Tokrajärvi (Ilomantsi)' },
  { id: '81360', label: '81360  Sarvinki (Joensuu)' },
  { id: '81390', label: '81390  Revonkylä (Joensuu)' },
  { id: '81420', label: '81420  Tyrjänsaari (Ilomantsi)' },
  { id: '81430', label: '81430  Kivilahti (Ilomantsi)' },
  { id: '81450', label: '81450  Huhus-Käenkoski (Ilomantsi)' },
  { id: '81460', label: '81460  Käenkoski (Ilomantsi)' },
  { id: '81470', label: '81470  Naarva (Ilomantsi)' },
  { id: '81560', label: '81560  Vuonisjärvi (Lieksa)' },
  { id: '81570', label: '81570  Kelvä (Lieksa)' },
  { id: '81590', label: '81590  Vuonislahti (Lieksa)' },
  { id: '81650', label: '81650  Hattuvaara (Lieksa)' },
  { id: '81660', label: '81660  Lehmikylä (Lieksa)' },
  { id: '81700', label: '81700  Lieksa Keskus (Lieksa)' },
  { id: '81720', label: '81720  Mähkö-Timitra (Lieksa)' },
  { id: '81750', label: '81750  Pankakoski (Lieksa)' },
  { id: '81810', label: '81810  Jamali (Lieksa)' },
  { id: '81820', label: '81820  Kylänlahti (Lieksa)' },
  { id: '81850', label: '81850  Mätäsvaara (Lieksa)' },
  { id: '81860', label: '81860  Viekijärvi (Lieksa)' },
  { id: '81950', label: '81950  Pankajärvi (Lieksa)' },
  { id: '81970', label: '81970  Jongunjoki-Pankajärvi (Lieksa)' },
  { id: '82110', label: '82110  Heinävaara (Joensuu)' },
  { id: '82120', label: '82120  Keskijärvi (Joensuu)' },
  { id: '82140', label: '82140  Kiihtelysvaara Keskus (Joensuu)' },
  { id: '82160', label: '82160  Oskola (Joensuu)' },
  { id: '82170', label: '82170  Uskali (Joensuu)' },
  { id: '82180', label: '82180  Huhtilampi (Joensuu)' },
  { id: '82200', label: '82200  Hammaslahti (Joensuu)' },
  { id: '82210', label: '82210  Suhmura (Joensuu)' },
  { id: '82220', label: '82220  Niittylahti (Joensuu)' },
  { id: '82290', label: '82290  Nieminen (Rääkkylä)' },
  { id: '82300', label: '82300  Rääkkylä Keskus (Rääkkylä)' },
  { id: '82310', label: '82310  Oravisalo-Varpasalo (Rääkkylä)' },
  { id: '82335', label: '82335  Rasivaara (Rääkkylä)' },
  { id: '82350', label: '82350  Tikkala (Tohmajärvi)' },
  { id: '82360', label: '82360  Onkamo (Tohmajärvi)' },
  { id: '82380', label: '82380  Tolosenmäki (Kitee)' },
  { id: '82395', label: '82395  Haarajärvi (Kitee)' },
  { id: '82430', label: '82430  Puhos-Leinovaara (Kitee)' },
  { id: '82460', label: '82460  Hummovaara (Kitee)' },
  { id: '82490', label: '82490  Heinoniemi (Kitee)' },
  { id: '82500', label: '82500  Kitee Keskus (Kitee)' },
  { id: '82510', label: '82510  Kiteenlahti (Kitee)' },
  { id: '82580', label: '82580  Juurikka (Kitee)' },
  { id: '82590', label: '82590  Närsäkkälä (Kitee)' },
  { id: '82600', label: '82600  Tohmajärvi Keskus (Tohmajärvi)' },
  { id: '82655', label: '82655  Värtsilä Keskus (Tohmajärvi)' },
  { id: '82660', label: '82660  Uusi-Värtsilä (Tohmajärvi)' },
  { id: '82670', label: '82670  Kaurila (Tohmajärvi)' },
  { id: '82675', label: '82675  Niirala-Jänisjoki (Tohmajärvi)' },
  { id: '82685', label: '82685  Littilä (Tohmajärvi)' },
  { id: '82710', label: '82710  Kovero (Joensuu)' },
  { id: '82730', label: '82730  Tuupovaara Keskus-Pirttiaho (Joensuu)' },
  { id: '82750', label: '82750  Öllölä (Joensuu)' },
  { id: '82760', label: '82760  Hoilola (Joensuu)' },
  { id: '82770', label: '82770  Kaustajärvi (Tohmajärvi)' },
  { id: '82815', label: '82815  Marjovaara (Joensuu)' },
  { id: '82820', label: '82820  Maukkula (Ilomantsi)' },
  { id: '82830', label: '82830  Haukivaara (Ilomantsi)' },
  { id: '82840', label: '82840  Pirttiaho (Joensuu)' },
  { id: '82850', label: '82850  Kinnasniemi (Joensuu)' },
  { id: '82865', label: '82865  Kokinvaara-Mannervaara (Joensuu)' },
  { id: '82870', label: '82870  Luutalahti (Joensuu)' },
  { id: '82880', label: '82880  Mannervaara (Joensuu)' },
  { id: '82900', label: '82900  Ilomantsi Keskus (Ilomantsi)' },
  { id: '82915', label: '82915  Sonkaja (Ilomantsi)' },
  { id: '82960', label: '82960  Lehtovaara-Kuikkalampi (Ilomantsi)' },
  { id: '82967', label: '82967  Hattu (Ilomantsi)' },
  { id: '82980', label: '82980  Möhkö (Ilomantsi)' },
  { id: '83100', label: '83100  Liperi Keskus (Liperi)' },
  { id: '83130', label: '83130  Salokylä (Liperi)' },
  { id: '83140', label: '83140  Puromäki (Liperi)' },
  { id: '83150', label: '83150  Roukalahti (Liperi)' },
  { id: '83160', label: '83160  Tutjunniemi (Liperi)' },
  { id: '83320', label: '83320  Risti-Juojärvi (Liperi)' },
  { id: '83330', label: '83330  Kaatamo (Liperi)' },
  { id: '83340', label: '83340  Kaarnalampi (Liperi)' },
  { id: '83400', label: '83400  Viinijärvi (Liperi)' },
  { id: '83430', label: '83430  Käsämä (Liperi)' },
  { id: '83450', label: '83450  Vaivio (Liperi)' },
  { id: '83460', label: '83460  Harinjärvi (Liperi)' },
  { id: '83480', label: '83480  Ahonkylä (Liperi)' },
  { id: '83500', label: '83500  Outokumpu Keskus (Outokumpu)' },
  { id: '83550', label: '83550  Kokonvaara (Outokumpu)' },
  { id: '83630', label: '83630  Kuusjärvi (Outokumpu)' },
  { id: '83660', label: '83660  Varislahti (Outokumpu)' },
  { id: '83700', label: '83700  Polvijärvi Keskus (Polvijärvi)' },
  { id: '83720', label: '83720  Kuorevaara (Polvijärvi)' },
  { id: '83750', label: '83750  Sotkuma (Polvijärvi)' },
  { id: '83760', label: '83760  Sola (Polvijärvi)' },
  { id: '83780', label: '83780  Horsmanaho (Polvijärvi)' },
  { id: '83825', label: '83825  Kinahmo (Polvijärvi)' },
  { id: '83830', label: '83830  Hukkala (Polvijärvi)' },
  { id: '83835', label: '83835  Ruvaslahti (Polvijärvi)' },
  { id: '83840', label: '83840  Saarivaara (Polvijärvi)' },
  { id: '83855', label: '83855  Martonvaara-Sammakkovaara (Polvijärvi)' },
  { id: '83870', label: '83870  Polvela-Timovaara (Juuka)' },
  { id: '83880', label: '83880  Kajoo (Juuka)' },
  { id: '83900', label: '83900  Juuka Keskus (Juuka)' },
  { id: '83910', label: '83910  Paalasmaa (Juuka)' },
  { id: '83915', label: '83915  Vihtasuo (Juuka)' },
  { id: '83940', label: '83940  Nunnanlahti (Juuka)' },
  { id: '83950', label: '83950  Ahmovaara (Juuka)' },
  { id: '83960', label: '83960  Koli (Lieksa)' },
  { id: '83985', label: '83985  Tuopanjoki (Juuka)' },
  { id: '84100', label: '84100  Ylivieska Keskus (Ylivieska)' },
  { id: '84460', label: '84460  Tuomiperä (Ylivieska)' },
  { id: '84540', label: '84540  Vähäkangas (Ylivieska)' },
  { id: '84650', label: '84650  Kantokylä (Ylivieska)' },
  { id: '84770', label: '84770  Isokoski (Ylivieska)' },
  { id: '84880', label: '84880  Raudaskylä (Ylivieska)' },
  { id: '85100', label: '85100  Kalajoki Keskus (Kalajoki)' },
  { id: '85120', label: '85120  Metsä (Kalajoki)' },
  { id: '85140', label: '85140  Tynkä (Kalajoki)' },
  { id: '85150', label: '85150  Typpö (Kalajoki)' },
  { id: '85160', label: '85160  Rautio (Kalajoki)' },
  { id: '85180', label: '85180  Rahja (Kalajoki)' },
  { id: '85200', label: '85200  Alavieska Keskus (Alavieska)' },
  { id: '85210', label: '85210  Kähtävä (Alavieska)' },
  { id: '85230', label: '85230  Talus (Alavieska)' },
  { id: '85310', label: '85310  Sievi Asemanseutu (Sievi)' },
  { id: '85320', label: '85320  Markkula (Sievi)' },
  { id: '85340', label: '85340  Jyrinki (Sievi)' },
  { id: '85410', label: '85410  Sievi Keskus (Sievi)' },
  { id: '85430', label: '85430  Koivisto (Sievi)' },
  { id: '85450', label: '85450  Joenkylä (Sievi)' },
  { id: '85470', label: '85470  Kiiskilampi (Sievi)' },
  { id: '85500', label: '85500  Nivala Keskus (Nivala)' },
  { id: '85540', label: '85540  Mönkö (Nivala)' },
  { id: '85560', label: '85560  Ainastalo (Nivala)' },
  { id: '85580', label: '85580  Karvoskylä (Nivala)' },
  { id: '85620', label: '85620  Sarja (Nivala)' },
  { id: '85630', label: '85630  Sarjanahde (Nivala)' },
  { id: '85640', label: '85640  Maliskylä (Nivala)' },
  { id: '85660', label: '85660  Ruuskankylä (Nivala)' },
  { id: '85710', label: '85710  Parkkila (Haapajärvi)' },
  { id: '85730', label: '85730  Tulppo (Haapajärvi)' },
  { id: '85800', label: '85800  Haapajärvi Keskus (Haapajärvi)' },
  { id: '85820', label: '85820  Oksava (Haapajärvi)' },
  { id: '85840', label: '85840  Kuona (Haapajärvi)' },
  { id: '85900', label: '85900  Reisjärvi Keskus (Reisjärvi)' },
  { id: '85930', label: '85930  Kangaskylä-Tolpankangas (Reisjärvi)' },
  { id: '85940', label: '85940  Räisälänmäki (Reisjärvi)' },
  { id: '85980', label: '85980  Köyhänperä (Reisjärvi)' },
  { id: '86100', label: '86100  Pyhäjoki Keskus (Pyhäjoki)' },
  { id: '86110', label: '86110  Parhalahti (Pyhäjoki)' },
  { id: '86160', label: '86160  Pirttikoski (Pyhäjoki)' },
  { id: '86170', label: '86170  Yppäri (Pyhäjoki)' },
  { id: '86210', label: '86210  Petäjäskoski (Oulainen)' },
  { id: '86220', label: '86220  Merijärvi Keskus (Merijärvi)' },
  { id: '86230', label: '86230  Ylipää (Merijärvi)' },
  { id: '86240', label: '86240  Pyhänkoski (Merijärvi)' },
  { id: '86300', label: '86300  Oulainen Keskus (Oulainen)' },
  { id: '86350', label: '86350  Kilpua (Oulainen)' },
  { id: '86360', label: '86360  Ilveskorpi (Raahe)' },
  { id: '86400', label: '86400  Vihanti Keskus (Raahe)' },
  { id: '86440', label: '86440  Lampinsaari (Raahe)' },
  { id: '86460', label: '86460  Alpua (Raahe)' },
  { id: '86470', label: '86470  Lumimetsä (Raahe)' },
  { id: '86480', label: '86480  Karhukangas (Haapavesi)' },
  { id: '86510', label: '86510  Matkaniva (Oulainen)' },
  { id: '86530', label: '86530  Annonen (Haapavesi)' },
  { id: '86550', label: '86550  Mieluskylä (Haapavesi)' },
  { id: '86600', label: '86600  Haapavesi Keskus (Haapavesi)' },
  { id: '86650', label: '86650  Kytökylä-Joutenniva (Haapavesi)' },
  { id: '86680', label: '86680  Vatjusjärvi (Haapavesi)' },
  { id: '86690', label: '86690  Karsikas (Haapavesi)' },
  { id: '86710', label: '86710  Kärsämäki Keskus (Kärsämäki)' },
  { id: '86790', label: '86790  Venetpalo (Kärsämäki)' },
  { id: '86800', label: '86800  Pyhäsalmi (Pyhäjärvi)' },
  { id: '86810', label: '86810  Pellikkaperä (Pyhäjärvi)' },
  { id: '86850', label: '86850  Hiidenniemi (Pyhäjärvi)' },
  { id: '86870', label: '86870  Vesikoski (Pyhäjärvi)' },
  { id: '86900', label: '86900  Pyhäkumpu (Pyhäjärvi)' },
  { id: '86980', label: '86980  Väliaho (Pyhäjärvi)' },
  { id: '87100', label: '87100  Kajaani Keskus (Kajaani)' },
  { id: '87150', label: '87150  Purola (Kajaani)' },
  { id: '87200', label: '87200  Teppana (Kajaani)' },
  { id: '87250', label: '87250  Kättö-Kätönlahti (Kajaani)' },
  { id: '87300', label: '87300  Palokangas (Kajaani)' },
  { id: '87400', label: '87400  Petäisenniska (Kajaani)' },
  { id: '87500', label: '87500  Lehtikangas (Kajaani)' },
  { id: '87700', label: '87700  Lohtaja-Kettu (Kajaani)' },
  { id: '87800', label: '87800  Vuoreslahti (Kajaani)' },
  { id: '87830', label: '87830  Nakertaja (Kajaani)' },
  { id: '87850', label: '87850  Paltaniemi (Kajaani)' },
  { id: '87900', label: '87900  Karankalahti (Kajaani)' },
  { id: '87910', label: '87910  Linnantaus (Kajaani)' },
  { id: '87930', label: '87930  Salmijärvi (Kajaani)' },
  { id: '87950', label: '87950  Kuluntalahti (Kajaani)' },
  { id: '87970', label: '87970  Jormua (Kajaani)' },
  { id: '88120', label: '88120  Tuhkakylä (Sotkamo)' },
  { id: '88200', label: '88200  Otanmäki-Kytökoski (Kajaani)' },
  { id: '88270', label: '88270  Vuolijoki Keskus (Kajaani)' },
  { id: '88300', label: '88300  Paltamo Keskus (Paltamo)' },
  { id: '88310', label: '88310  Melalahti (Paltamo)' },
  { id: '88340', label: '88340  Manamansalo (Vaala)' },
  { id: '88350', label: '88350  Kivesjärvi (Paltamo)' },
  { id: '88380', label: '88380  Mieslahti (Paltamo)' },
  { id: '88400', label: '88400  Ristijärvi Keskus-Hiisijärvi (Ristijärvi)' },
  { id: '88440', label: '88440  Jokikylä (Ristijärvi)' },
  { id: '88460', label: '88460  Hiisijärvi (Ristijärvi)' },
  { id: '88470', label: '88470  Kontiomäki (Paltamo)' },
  { id: '88490', label: '88490  Pohjavaara (Sotkamo)' },
  { id: '88530', label: '88530  Paakki (Sotkamo)' },
  { id: '88600', label: '88600  Sotkamo Keskus (Sotkamo)' },
  { id: '88610', label: '88610  Vuokatti (Sotkamo)' },
  { id: '88615', label: '88615  Vuokatin Urheiluopisto (Sotkamo)' },
  { id: '88620', label: '88620  Korholanmäki (Sotkamo)' },
  { id: '88640', label: '88640  Ontojoki (Sotkamo)' },
  { id: '88670', label: '88670  Juurikkalahti-Maanselkä (Sotkamo)' },
  { id: '88690', label: '88690  Maanselkä (Sotkamo)' },
  { id: '88730', label: '88730  Ylä-Vieksi (Kuhmo)' },
  { id: '88740', label: '88740  Härmänkylä-Iivantiira (Kuhmo)' },
  { id: '88760', label: '88760  Iivantiira (Kuhmo)' },
  { id: '88820', label: '88820  Katerma (Kuhmo)' },
  { id: '88900', label: '88900  Kuhmo Keskus (Kuhmo)' },
  { id: '88930', label: '88930  Lentiira-Kuumu (Kuhmo)' },
  { id: '88940', label: '88940  Kuumu (Kuhmo)' },
  { id: '89110', label: '89110  Törmänmäki (Puolanka)' },
  { id: '89140', label: '89140  Kotila-Törmänmäki (Puolanka)' },
  { id: '89200', label: '89200  Puolanka Keskus (Puolanka)' },
  { id: '89210', label: '89210  Puokiovaara (Puolanka)' },
  { id: '89320', label: '89320  Joukokylä (Puolanka)' },
  { id: '89330', label: '89330  Suolijärvi (Puolanka)' },
  { id: '89400', label: '89400  Hyrynsalmi Keskus (Hyrynsalmi)' },
  { id: '89430', label: '89430  Hoikka (Hyrynsalmi)' },
  { id: '89440', label: '89440  Sakara (Suomussalmi)' },
  { id: '89540', label: '89540  Moisiovaara (Hyrynsalmi)' },
  { id: '89560', label: '89560  Karhuvaara (Hyrynsalmi)' },
  { id: '89600', label: '89600  Ämmänsaari (Suomussalmi)' },
  { id: '89610', label: '89610  Jumaliskylä (Suomussalmi)' },
  { id: '89640', label: '89640  Pesiökylä (Suomussalmi)' },
  { id: '89660', label: '89660  Takalo (Suomussalmi)' },
  { id: '89670', label: '89670  Näljänkä (Suomussalmi)' },
  { id: '89680', label: '89680  Vaaranniva (Suomussalmi)' },
  { id: '89730', label: '89730  Ahjola (Suomussalmi)' },
  { id: '89740', label: '89740  Kianta (Suomussalmi)' },
  { id: '89760', label: '89760  Piispajärvi-Peranka-Kaikkoinen (Suomussalmi)' },
  { id: '89770', label: '89770  Peranka (Suomussalmi)' },
  { id: '89780', label: '89780  Kaikkonen (Suomussalmi)' },
  { id: '89800', label: '89800  Suomussalmi Keskus (Suomussalmi)' },
  { id: '89830', label: '89830  Ala-Vuokki (Suomussalmi)' },
  { id: '89840', label: '89840  Ylä-Vuokki (Suomussalmi)' },
  { id: '89920', label: '89920  Ruhtinansalmi (Suomussalmi)' },
  { id: '90100', label: '90100  Oulu Keskus (Oulu)' },
  { id: '90120', label: '90120  Heinäpää (Oulu)' },
  { id: '90130', label: '90130  Raksila (Oulu)' },
  { id: '90140', label: '90140  Karjasilta (Oulu)' },
  { id: '90150', label: '90150  Höyhtyä (Oulu)' },
  { id: '90220', label: '90220  Kontinkangas (Oulu)' },
  { id: '90230', label: '90230  Värttö-Maikkula (Oulu)' },
  { id: '90240', label: '90240  Iinatti (Oulu)' },
  { id: '90250', label: '90250  Kaukovainio (Oulu)' },
  { id: '90310', label: '90310  Madekoski (Oulu)' },
  { id: '90400', label: '90400  Äimärautio (Oulu)' },
  { id: '90410', label: '90410  Kaakkuri (Oulu)' },
  { id: '90420', label: '90420  Metsokangas (Oulu)' },
  { id: '90440', label: '90440  Kempele Keskus (Kempele)' },
  { id: '90450', label: '90450  Honkanen (Kempele)' },
  { id: '90460', label: '90460  Oulunsalo Keskus (Oulu)' },
  { id: '90470', label: '90470  Varjakka (Oulu)' },
  { id: '90480', label: '90480  Hailuoto (Hailuoto)' },
  { id: '90500', label: '90500  Tuira (Oulu)' },
  { id: '90510', label: '90510  Hietasaari (Oulu)' },
  { id: '90520', label: '90520  Taskila-Toppila (Oulu)' },
  { id: '90530', label: '90530  Välivainio (Oulu)' },
  { id: '90540', label: '90540  Kuivasjärvi (Oulu)' },
  { id: '90550', label: '90550  Pyykösjärvi-Puolivälinkangas (Oulu)' },
  { id: '90560', label: '90560  Koskela-Oulu (Oulu)' },
  { id: '90570', label: '90570  Kaijonharju-Linnanmaa (Oulu)' },
  { id: '90580', label: '90580  Rajakylä (Oulu)' },
  { id: '90590', label: '90590  Teknologiakylä (Oulu)' },
  { id: '90600', label: '90600  Oulun rahtiterminaali (Oulu)' },
  { id: '90620', label: '90620  Rusko-Heikinharju (Oulu)' },
  { id: '90630', label: '90630  Korvensuora (Oulu)' },
  { id: '90650', label: '90650  Haapalehto (Oulu)' },
  { id: '90660', label: '90660  Sanginsuu (Oulu)' },
  { id: '90670', label: '90670  Hiukkavaara (Oulu)' },
  { id: '90800', label: '90800  Pateniemi (Oulu)' },
  { id: '90810', label: '90810  Kiviniemi (Oulu)' },
  { id: '90820', label: '90820  Kello (Oulu)' },
  { id: '90830', label: '90830  Haukipudas Keskus (Oulu)' },
  { id: '90840', label: '90840  Haukipudas Asemanseutu (Oulu)' },
  { id: '90850', label: '90850  Martinniemi (Oulu)' },
  { id: '90860', label: '90860  Halosenniemi (Oulu)' },
  { id: '90900', label: '90900  Kiiminki Keskus (Oulu)' },
  { id: '90910', label: '90910  Kontio (Oulu)' },
  { id: '90940', label: '90940  Jääli (Oulu)' },
  { id: '91100', label: '91100  Ii Keskus (Ii)' },
  { id: '91110', label: '91110  Ii Asemanseutu (Ii)' },
  { id: '91140', label: '91140  Olhava (Ii)' },
  { id: '91150', label: '91150  Yli-Olhava (Ii)' },
  { id: '91200', label: '91200  Yli-Ii Keskus (Oulu)' },
  { id: '91210', label: '91210  Jakkukylä (Ii)' },
  { id: '91240', label: '91240  Tannila (Oulu)' },
  { id: '91260', label: '91260  Pahkakoski-Räinä (Oulu)' },
  { id: '91300', label: '91300  Ylikiiminki Keskus (Oulu)' },
  { id: '91310', label: '91310  Arkala (Oulu)' },
  { id: '91410', label: '91410  Jokirinne (Muhos)' },
  { id: '91430', label: '91430  Leppiniemi (Muhos)' },
  { id: '91500', label: '91500  Muhos Keskus (Muhos)' },
  { id: '91510', label: '91510  Rova (Muhos)' },
  { id: '91560', label: '91560  Kylmälänkylä (Muhos)' },
  { id: '91600', label: '91600  Utajärvi Keskus (Utajärvi)' },
  { id: '91620', label: '91620  Sanginkylä (Utajärvi)' },
  { id: '91630', label: '91630  Juorkuna (Utajärvi)' },
  { id: '91640', label: '91640  Särkijärvi (Utajärvi)' },
  { id: '91660', label: '91660  Ahmas (Utajärvi)' },
  { id: '91670', label: '91670  Rokua (Utajärvi)' },
  { id: '91700', label: '91700  Vaala Keskus-Oterma (Vaala)' },
  { id: '91710', label: '91710  Jylhämä (Vaala)' },
  { id: '91720', label: '91720  Oterma (Vaala)' },
  { id: '91730', label: '91730  Kankari (Vaala)' },
  { id: '91740', label: '91740  Jaalanka-Liminpuro (Vaala)' },
  { id: '91750', label: '91750  Liminpuro (Vaala)' },
  { id: '91760', label: '91760  Säräisniemi (Vaala)' },
  { id: '91780', label: '91780  Neittävä (Vaala)' },
  { id: '91790', label: '91790  Nuojua (Vaala)' },
  { id: '91800', label: '91800  Tyrnävä (Tyrnävä)' },
  { id: '91900', label: '91900  Liminka Keskus (Liminka)' },
  { id: '91910', label: '91910  Tupos (Liminka)' },
  { id: '91930', label: '91930  Ala-Temmes (Liminka)' },
  { id: '91950', label: '91950  Temmes (Tyrnävä)' },
  { id: '91980', label: '91980  Lumijoki (Lumijoki)' },
  { id: '92100', label: '92100  Raahe Keskus (Raahe)' },
  { id: '92120', label: '92120  Ollinsaari-Mestauskallio (Raahe)' },
  { id: '92130', label: '92130  Isoholmi-Antinkangas (Raahe)' },
  { id: '92140', label: '92140  Pattijoki Keskus (Raahe)' },
  { id: '92150', label: '92150  Kummatti-Junnilanmäki (Raahe)' },
  { id: '92160', label: '92160  Saloinen (Raahe)' },
  { id: '92180', label: '92180  Lapaluoto (Raahe)' },
  { id: '92210', label: '92210  Arkkukari (Raahe)' },
  { id: '92220', label: '92220  Piehinki (Raahe)' },
  { id: '92230', label: '92230  Mattilanperä (Raahe)' },
  { id: '92240', label: '92240  Lasikangas (Raahe)' },
  { id: '92260', label: '92260  Kopsa (Raahe)' },
  { id: '92320', label: '92320  Siikajoki Keskus (Siikajoki)' },
  { id: '92330', label: '92330  Karinkanta (Siikajoki)' },
  { id: '92350', label: '92350  Revonlahti (Siikajoki)' },
  { id: '92400', label: '92400  Ruukki Keskus (Siikajoki)' },
  { id: '92430', label: '92430  Paavola-Huumola (Siikajoki)' },
  { id: '92440', label: '92440  Saarikoski (Siikajoki)' },
  { id: '92450', label: '92450  Luohua (Siikajoki)' },
  { id: '92470', label: '92470  Tuomioja (Siikajoki)' },
  { id: '92500', label: '92500  Rantsila Keskus (Siikalatva)' },
  { id: '92520', label: '92520  Sipola (Siikalatva)' },
  { id: '92530', label: '92530  Mankila (Siikalatva)' },
  { id: '92600', label: '92600  Pulkkila Keskus (Siikalatva)' },
  { id: '92610', label: '92610  Vorna (Siikalatva)' },
  { id: '92620', label: '92620  Piippola Keskus (Siikalatva)' },
  { id: '92640', label: '92640  Leskelä (Siikalatva)' },
  { id: '92650', label: '92650  Laakkola (Siikalatva)' },
  { id: '92700', label: '92700  Kestilä (Siikalatva)' },
  { id: '92810', label: '92810  Pelsonsuo (Vaala)' },
  { id: '92830', label: '92830  Veneheitto (Vaala)' },
  { id: '92910', label: '92910  Tavastkenkä (Pyhäntä)' },
  { id: '92920', label: '92920  Ahokylä (Pyhäntä)' },
  { id: '92930', label: '92930  Pyhäntä (Pyhäntä)' },
  { id: '93100', label: '93100  Pudasjärvi Keskus (Pudasjärvi)' },
  { id: '93140', label: '93140  Kipinä (Pudasjärvi)' },
  { id: '93160', label: '93160  Hetejärvi (Pudasjärvi)' },
  { id: '93170', label: '93170  Yli-Kollaja (Pudasjärvi)' },
  { id: '93180', label: '93180  Ikosenniemi (Pudasjärvi)' },
  { id: '93187', label: '93187  Ala-Siurua (Pudasjärvi)' },
  { id: '93190', label: '93190  Yli-Siurua-Ikosenniemi (Pudasjärvi)' },
  { id: '93195', label: '93195  Kokkokylä (Pudasjärvi)' },
  { id: '93220', label: '93220  Livo (Pudasjärvi)' },
  { id: '93225', label: '93225  Pärjänsuo (Pudasjärvi)' },
  { id: '93240', label: '93240  Rytinki (Pudasjärvi)' },
  { id: '93250', label: '93250  Sarajärvi (Pudasjärvi)' },
  { id: '93270', label: '93270  Sotkajärvi (Pudasjärvi)' },
  { id: '93277', label: '93277  Iinattijärvi (Pudasjärvi)' },
  { id: '93280', label: '93280  Syöte (Pudasjärvi)' },
  { id: '93290', label: '93290  Pintamo (Pudasjärvi)' },
  { id: '93350', label: '93350  Jaurakkajärvi (Pudasjärvi)' },
  { id: '93390', label: '93390  Puhoskylä (Pudasjärvi)' },
  { id: '93400', label: '93400  Taivalkoski Keskus (Taivalkoski)' },
  { id: '93420', label: '93420  Jurmu (Taivalkoski)' },
  { id: '93440', label: '93440  Loukusa (Taivalkoski)' },
  { id: '93470', label: '93470  Inget (Taivalkoski)' },
  { id: '93540', label: '93540  Tyrövaara (Taivalkoski)' },
  { id: '93590', label: '93590  Vanhala (Taivalkoski)' },
  { id: '93600', label: '93600  Kuusamo (Kuusamo)' },
  { id: '93700', label: '93700  Kurkijärvi - Oijusluoma (Kuusamo)' },
  { id: '93800', label: '93800  Muojärvi (Kuusamo)' },
  { id: '93830', label: '93830  Rukatunturi (Kuusamo)' },
  { id: '93900', label: '93900  Oulangan kansallispuisto (Kuusamo)' },
  { id: '93999', label: '93999  Lososaari (Kuusamo)' },
  { id: '94100', label: '94100  Kemi Keskus (Kemi)' },
  { id: '94200', label: '94200  Torvinen-Vainio (Kemi)' },
  { id: '94300', label: '94300  Pajusaari (Kemi)' },
  { id: '94400', label: '94400  Keminmaa Keskus (Keminmaa)' },
  { id: '94430', label: '94430  Kaakamo (Tornio)' },
  { id: '94450', label: '94450  Lassila-Jokisuu (Keminmaa)' },
  { id: '94460', label: '94460  Ruottala (Tornio)' },
  { id: '94500', label: '94500  Lautiosaari (Keminmaa)' },
  { id: '94600', label: '94600  Ristikangas-Kivikko (Kemi)' },
  { id: '94700', label: '94700  Takajärvi-Haukkari (Kemi)' },
  { id: '94720', label: '94720  Peurasaari (Kemi)' },
  { id: '94800', label: '94800  Veitsiluoto (Kemi)' },
  { id: '94830', label: '94830  Pikku Berliini-Hepola (Kemi)' },
  { id: '94900', label: '94900  Ajos (Kemi)' },
  { id: '95100', label: '95100  Kuivaniemi Keskus (Ii)' },
  { id: '95110', label: '95110  Kuivaniemi kk (Ii)' },
  { id: '95130', label: '95130  Hyryoja (Ii)' },
  { id: '95160', label: '95160  Oijärvi-Lamminperä (Ii)' },
  { id: '95200', label: '95200  Simo Keskus (Simo)' },
  { id: '95210', label: '95210  Filpus (Simo)' },
  { id: '95220', label: '95220  Simoniemi (Simo)' },
  { id: '95225', label: '95225  Viantie (Simo)' },
  { id: '95230', label: '95230  Maksniemi (Simo)' },
  { id: '95255', label: '95255  Alaniemi (Simo)' },
  { id: '95260', label: '95260  Tainionjoki (Simo)' },
  { id: '95270', label: '95270  Yli-Kärppä-Tainionjoki (Simo)' },
  { id: '95300', label: '95300  Tervola Keskus (Tervola)' },
  { id: '95310', label: '95310  Liedakkala (Keminmaa)' },
  { id: '95315', label: '95315  Törmä (Keminmaa)' },
  { id: '95325', label: '95325  Paakkola (Tervola)' },
  { id: '95330', label: '95330  Tervola (Tervola)' },
  { id: '95340', label: '95340  Loue (Tervola)' },
  { id: '95350', label: '95350  Peura (Tervola)' },
  { id: '95355', label: '95355  Koivu-Peura (Tervola)' },
  { id: '95365', label: '95365  Maula (Keminmaa)' },
  { id: '95370', label: '95370  Itäkoski (Keminmaa)' },
  { id: '95375', label: '95375  Yli-Paakkola (Tervola)' },
  { id: '95400', label: '95400  Tornio Keskus (Tornio)' },
  { id: '95410', label: '95410  Kiviranta (Tornio)' },
  { id: '95420', label: '95420  Miukki-Palosaari-Luotomäki (Tornio)' },
  { id: '95430', label: '95430  Suensaari (Tornio)' },
  { id: '95440', label: '95440  Kyläjoki (Tornio)' },
  { id: '95450', label: '95450  Kirkonmäki (Tornio)' },
  { id: '95460', label: '95460  Kantojärvi (Tornio)' },
  { id: '95470', label: '95470  Pirkkiö-Röyttä (Tornio)' },
  { id: '95490', label: '95490  Terästehtaanalue (Tornio)' },
  { id: '95500', label: '95500  Vojakkala-Yli-Liakka (Tornio)' },
  { id: '95520', label: '95520  Kukkola (Tornio)' },
  { id: '95530', label: '95530  Karunki (Tornio)' },
  { id: '95540', label: '95540  Korpikylä (Tornio)' },
  { id: '95560', label: '95560  Aapajoki (Tornio)' },
  { id: '95590', label: '95590  Arpela (Tornio)' },
  { id: '95600', label: '95600  Ylitornio Keskus (Ylitornio)' },
  { id: '95610', label: '95610  Kainuunkylä (Ylitornio)' },
  { id: '95615', label: '95615  Pekanpää (Ylitornio)' },
  { id: '95620', label: '95620  Aavasaksa (Ylitornio)' },
  { id: '95630', label: '95630  Kuivakangas (Ylitornio)' },
  { id: '95635', label: '95635  Kaulinranta (Ylitornio)' },
  { id: '95640', label: '95640  Juoksenki (Pello)' },
  { id: '95645', label: '95645  Turtola (Pello)' },
  { id: '95655', label: '95655  Etelä-Portimojärvi (Ylitornio)' },
  { id: '95660', label: '95660  Kantomaanpää (Ylitornio)' },
  { id: '95670', label: '95670  Pessalompolo (Ylitornio)' },
  { id: '95675', label: '95675  Meltosjärvi (Ylitornio)' },
  { id: '95680', label: '95680  Lohijärvi (Ylitornio)' },
  { id: '95690', label: '95690  Mellakoski (Ylitornio)' },
  { id: '95700', label: '95700  Pello Keskus (Pello)' },
  { id: '95710', label: '95710  Naamijoki (Pello)' },
  { id: '95720', label: '95720  Jarhoinen (Pello)' },
  { id: '95750', label: '95750  Orajärvi (Pello)' },
  { id: '95760', label: '95760  Konttajärvi (Pello)' },
  { id: '95770', label: '95770  Lankojärvi (Pello)' },
  { id: '95780', label: '95780  Sirkkakoski (Pello)' },
  { id: '95790', label: '95790  Lampsijärvi (Pello)' },
  { id: '95800', label: '95800  Sieppijärvi-Taapajärvi (Kolari)' },
  { id: '95830', label: '95830  Vaattojärvi (Kolari)' },
  { id: '95840', label: '95840  Venejärvenkylä (Kolari)' },
  { id: '95850', label: '95850  Ruokojärvi (Kolari)' },
  { id: '95860', label: '95860  Pasmajärvi (Kolari)' },
  { id: '95900', label: '95900  Kolari Keskus (Kolari)' },
  { id: '95920', label: '95920  Saaripudas (Kolari)' },
  { id: '95930', label: '95930  Äkäsjoensuu (Kolari)' },
  { id: '95940', label: '95940  Tapojärvi (Muonio)' },
  { id: '95950', label: '95950  Kihlanki-Tapojärvi (Muonio)' },
  { id: '95970', label: '95970  Äkäslompolo (Kolari)' },
  { id: '95980', label: '95980  Ylläsjärvi (Kolari)' },
  { id: '95990', label: '95990  Kurtakko (Kolari)' },
  { id: '96100', label: '96100  Rovaniemi Keskus (Rovaniemi)' },
  { id: '96190', label: '96190  Lapinrinne-Ahokangas (Rovaniemi)' },
  { id: '96200', label: '96200  Sahanperä-Ratantaus (Rovaniemi)' },
  { id: '96300', label: '96300  Niemelänkangas-Korvanniemi (Rovaniemi)' },
  { id: '96320', label: '96320  Teollisuuskylä (Rovaniemi)' },
  { id: '96400', label: '96400  Pullinranta-Kiiruna (Rovaniemi)' },
  { id: '96440', label: '96440  Ounasrinne (Rovaniemi)' },
  { id: '96460', label: '96460  Pöykkölä (Rovaniemi)' },
  { id: '96500', label: '96500  Korkalovaara (Rovaniemi)' },
  { id: '96600', label: '96600  Koskenkylä (Rovaniemi)' },
  { id: '96700', label: '96700  Niskanperä (Rovaniemi)' },
  { id: '96800', label: '96800  Ylikylä-Patosaari (Rovaniemi)' },
  { id: '96900', label: '96900  Saarenkylä (Rovaniemi)' },
  { id: '96910', label: '96910  Syväsenvaara (Rovaniemi)' },
  { id: '96930', label: '96930  Napapiiri Rovaniemi (Rovaniemi)' },
  { id: '96960', label: '96960  Someroharju (Rovaniemi)' },
  { id: '97110', label: '97110  Rautionsaari (Rovaniemi)' },
  { id: '97130', label: '97130  Hirvas (Rovaniemi)' },
  { id: '97140', label: '97140  Muurola (Rovaniemi)' },
  { id: '97145', label: '97145  Totonvaara (Rovaniemi)' },
  { id: '97160', label: '97160  Petäjäinen (Rovaniemi)' },
  { id: '97170', label: '97170  Jaatila (Rovaniemi)' },
  { id: '97220', label: '97220  Sinettä (Rovaniemi)' },
  { id: '97240', label: '97240  Sonka (Rovaniemi)' },
  { id: '97250', label: '97250  Raanujärvi (Ylitornio)' },
  { id: '97280', label: '97280  Tapio (Rovaniemi)' },
  { id: '97290', label: '97290  Marraskoski (Rovaniemi)' },
  { id: '97310', label: '97310  Patokoski (Rovaniemi)' },
  { id: '97320', label: '97320  Marrasjärvi (Rovaniemi)' },
  { id: '97330', label: '97330  Rattosjärvi (Pello)' },
  { id: '97335', label: '97335  Ruuhivaara (Pello)' },
  { id: '97340', label: '97340  Meltaus-Jääskö-Perttaus (Rovaniemi)' },
  { id: '97370', label: '97370  Unarin-Luusua (Sodankylä)' },
  { id: '97380', label: '97380  Kukasranta (Sodankylä)' },
  { id: '97390', label: '97390  Kierinki (Sodankylä)' },
  { id: '97420', label: '97420  Lohiniva (Kittilä)' },
  { id: '97430', label: '97430  Taapajärvi (Kolari)' },
  { id: '97470', label: '97470  Alakylä-Sailehto (Kittilä)' },
  { id: '97510', label: '97510  Vikajärvi (Rovaniemi)' },
  { id: '97520', label: '97520  Nampa (Rovaniemi)' },
  { id: '97530', label: '97530  Nampa-Yli-Nampa (Rovaniemi)' },
  { id: '97540', label: '97540  Tiainen (Rovaniemi)' },
  { id: '97580', label: '97580  Misi (Rovaniemi)' },
  { id: '97590', label: '97590  Raajärvi (Kemijärvi)' },
  { id: '97610', label: '97610  Oikarainen (Rovaniemi)' },
  { id: '97615', label: '97615  Tennirova (Rovaniemi)' },
  { id: '97620', label: '97620  Viiri-Tennirova (Rovaniemi)' },
  { id: '97625', label: '97625  Vanttauskoski (Rovaniemi)' },
  { id: '97630', label: '97630  Pekkala (Rovaniemi)' },
  { id: '97635', label: '97635  Juuniemi (Rovaniemi)' },
  { id: '97645', label: '97645  Juujärvi (Kemijärvi)' },
  { id: '97650', label: '97650  Juotas (Rovaniemi)' },
  { id: '97655', label: '97655  Autti (Rovaniemi)' },
  { id: '97665', label: '97665  Pohjasperä (Ranua)' },
  { id: '97670', label: '97670  Kivitaipale (Rovaniemi)' },
  { id: '97675', label: '97675  Narkaus-Kämät (Rovaniemi)' },
  { id: '97680', label: '97680  Saari-Kämä (Rovaniemi)' },
  { id: '97685', label: '97685  Siika-Kämä (Rovaniemi)' },
  { id: '97690', label: '97690  Sulaoja (Rovaniemi)' },
  { id: '97700', label: '97700  Ranua Keskus (Ranua)' },
  { id: '97715', label: '97715  Raiskio (Ranua)' },
  { id: '97720', label: '97720  Yli-Simo-Raiskio (Ranua)' },
  { id: '97760', label: '97760  Saariharju (Ranua)' },
  { id: '97765', label: '97765  Kuha-Impiö-Itäranua (Ranua)' },
  { id: '97780', label: '97780  Kuukasjärvi-Petäjäjärvi (Ranua)' },
  { id: '97785', label: '97785  Kelankylä (Ranua)' },
  { id: '97810', label: '97810  Suorsa (Posio)' },
  { id: '97815', label: '97815  Pernu-Suorsa (Posio)' },
  { id: '97820', label: '97820  Perä-Posio (Posio)' },
  { id: '97840', label: '97840  Lehtiniemi (Posio)' },
  { id: '97850', label: '97850  Ristilä (Posio)' },
  { id: '97870', label: '97870  Jumisko (Posio)' },
  { id: '97880', label: '97880  Nolimo-Jumisko (Posio)' },
  { id: '97890', label: '97890  Karjalaisenniemi (Posio)' },
  { id: '97895', label: '97895  Mourujärvi (Posio)' },
  { id: '97900', label: '97900  Posio Keskus (Posio)' },
  { id: '97920', label: '97920  Suonnankylä (Posio)' },
  { id: '97925', label: '97925  Tolva (Posio)' },
  { id: '97940', label: '97940  Lohiranta (Posio)' },
  { id: '97950', label: '97950  Säikkä (Posio)' },
  { id: '97960', label: '97960  Kuloharju-Säikkä (Posio)' },
  { id: '97965', label: '97965  Kynsilä (Posio)' },
  { id: '97970', label: '97970  Sirniö (Posio)' },
  { id: '97980', label: '97980  Anetjärvi (Posio)' },
  { id: '97999', label: '97999  Köngäs (Kittilä)' },
  { id: '98100', label: '98100  Kemijärvi Keskus (Kemijärvi)' },
  { id: '98120', label: '98120  Särkikangas (Kemijärvi)' },
  { id: '98230', label: '98230  Luusua-Karsimus (Kemijärvi)' },
  { id: '98310', label: '98310  Karvakko (Kemijärvi)' },
  { id: '98350', label: '98350  Tapionniemi (Kemijärvi)' },
  { id: '98360', label: '98360  Vuostimo (Kemijärvi)' },
  { id: '98400', label: '98400  Isokylä-Ryti-Lehtola (Kemijärvi)' },
  { id: '98420', label: '98420  Ryti-Lehtola (Kemijärvi)' },
  { id: '98440', label: '98440  Kallaanvaara (Kemijärvi)' },
  { id: '98450', label: '98450  Kostamo (Kemijärvi)' },
  { id: '98500', label: '98500  Pelkosenniemi Keskus (Pelkosenniemi)' },
  { id: '98510', label: '98510  Saunavaara (Pelkosenniemi)' },
  { id: '98530', label: '98530  Pyhätunturi (Pelkosenniemi)' },
  { id: '98550', label: '98550  Suvanto (Pelkosenniemi)' },
  { id: '98560', label: '98560  Kairala-Luiro (Pelkosenniemi)' },
  { id: '98570', label: '98570  Aapajärvi (Pelkosenniemi)' },
  { id: '98580', label: '98580  Luiro (Pelkosenniemi)' },
  { id: '98600', label: '98600  Kursu (Salla)' },
  { id: '98620', label: '98620  Pahkakumpu (Salla)' },
  { id: '98630', label: '98630  Ahvenselkä (Pelkosenniemi)' },
  { id: '98660', label: '98660  Salmivaara (Salla)' },
  { id: '98710', label: '98710  Joutsijärvi (Kemijärvi)' },
  { id: '98720', label: '98720  Suomutunturi (Kemijärvi)' },
  { id: '98760', label: '98760  Hirvasvaara-Paloperä (Salla)' },
  { id: '98770', label: '98770  Aholanvaara (Salla)' },
  { id: '98780', label: '98780  Maaninkavaara (Posio)' },
  { id: '98790', label: '98790  Paloperä (Salla)' },
  { id: '98800', label: '98800  Savukoski Keskus-Värriö (Savukoski)' },
  { id: '98810', label: '98810  Lunkkaus (Savukoski)' },
  { id: '98820', label: '98820  Värriö (Savukoski)' },
  { id: '98830', label: '98830  Martti-Ruuvaoja (Savukoski)' },
  { id: '98840', label: '98840  Ruuvaoja (Savukoski)' },
  { id: '98850', label: '98850  Kuosku (Savukoski)' },
  { id: '98900', label: '98900  Salla Keskus (Salla)' },
  { id: '98920', label: '98920  Kelloselkä (Salla)' },
  { id: '98930', label: '98930  Kotalankylä (Salla)' },
  { id: '98950', label: '98950  Saija (Salla)' },
  { id: '98960', label: '98960  Naruska (Salla)' },
  { id: '98970', label: '98970  Onkamojärvi (Salla)' },
  { id: '98980', label: '98980  Kallunki-Niemelä (Salla)' },
  { id: '98995', label: '98995  Hautajärvi (Salla)' },
  { id: '99100', label: '99100  Kittilä Keskus (Kittilä)' },
  { id: '99110', label: '99110  Kaukonen (Kittilä)' },
  { id: '99120', label: '99120  Kallo (Kittilä)' },
  { id: '99130', label: '99130  Sirkka-Rauhala (Kittilä)' },
  { id: '99135', label: '99135  Rauhala (Kittilä)' },
  { id: '99140', label: '99140  Köngäs-Hanhivaara (Kittilä)' },
  { id: '99150', label: '99150  Tepasto (Kittilä)' },
  { id: '99160', label: '99160  Lompolo-Pulju (Kittilä)' },
  { id: '99170', label: '99170  Pulju (Kittilä)' },
  { id: '99180', label: '99180  Rautusjärvi (Kittilä)' },
  { id: '99190', label: '99190  Hanhimaa (Kittilä)' },
  { id: '99195', label: '99195  Pokka (Kittilä)' },
  { id: '99230', label: '99230  Jeesiöjärvi (Kittilä)' },
  { id: '99240', label: '99240  Kuivasalmi (Kittilä)' },
  { id: '99250', label: '99250  Kiistala-Jeesiöjärvi (Kittilä)' },
  { id: '99270', label: '99270  Hormakumpu (Kittilä)' },
  { id: '99280', label: '99280  Tepsa (Kittilä)' },
  { id: '99290', label: '99290  Kelontekemä (Kittilä)' },
  { id: '99300', label: '99300  Muonio Keskus (Muonio)' },
  { id: '99310', label: '99310  Yli-Muonio (Muonio)' },
  { id: '99320', label: '99320  Kätkäsuvanto (Muonio)' },
  { id: '99330', label: '99330  Pallastunturi (Muonio)' },
  { id: '99340', label: '99340  Raattama-Pallastunturi (Kittilä)' },
  { id: '99360', label: '99360  Kangosjärvi (Muonio)' },
  { id: '99400', label: '99400  Enontekiö Keskus (Enontekiö)' },
  { id: '99410', label: '99410  Vuontisjärvi (Enontekiö)' },
  { id: '99420', label: '99420  Peltovuoma (Enontekiö)' },
  { id: '99430', label: '99430  Nunnanen (Enontekiö)' },
  { id: '99440', label: '99440  Leppäjärvi (Enontekiö)' },
  { id: '99450', label: '99450  Palojoensuu-Kuttanen (Enontekiö)' },
  { id: '99460', label: '99460  Kuttanen (Enontekiö)' },
  { id: '99470', label: '99470  Karesuvanto (Enontekiö)' },
  { id: '99490', label: '99490  Kilpisjärvi (Enontekiö)' },
  { id: '99510', label: '99510  Raudanjoki-Seipäjärvi (Sodankylä)' },
  { id: '99520', label: '99520  Seipäjärvi (Sodankylä)' },
  { id: '99530', label: '99530  Vuojärvi (Sodankylä)' },
  { id: '99540', label: '99540  Torvinen (Sodankylä)' },
  { id: '99550', label: '99550  Aska-Orakylä (Sodankylä)' },
  { id: '99555', label: '99555  Luosto (Sodankylä)' },
  { id: '99560', label: '99560  Orakylä (Sodankylä)' },
  { id: '99600', label: '99600  Sodankylä Keskus (Sodankylä)' },
  { id: '99610', label: '99610  Siurunmaa (Sodankylä)' },
  { id: '99620', label: '99620  Kelujärvi (Sodankylä)' },
  { id: '99640', label: '99640  Tanhua (Savukoski)' },
  { id: '99645', label: '99645  Lokka (Sodankylä)' },
  { id: '99650', label: '99650  Sattanen (Sodankylä)' },
  { id: '99655', label: '99655  Sattasvaara (Sodankylä)' },
  { id: '99660', label: '99660  Kersilö (Sodankylä)' },
  { id: '99665', label: '99665  Moskuvaara (Sodankylä)' },
  { id: '99670', label: '99670  Petkula (Sodankylä)' },
  { id: '99690', label: '99690  Vuotso (Sodankylä)' },
  { id: '99695', label: '99695  Tankavaara (Sodankylä)' },
  { id: '99710', label: '99710  Vaalajärvi (Sodankylä)' },
  { id: '99720', label: '99720  Riipi (Sodankylä)' },
  { id: '99740', label: '99740  Syväjärvi (Sodankylä)' },
  { id: '99750', label: '99750  Sassali (Sodankylä)' },
  { id: '99760', label: '99760  Uimaniemi (Sodankylä)' },
  { id: '99770', label: '99770  Jeesiö (Sodankylä)' },
  { id: '99800', label: '99800  Ivalo (Inari)' },
  { id: '99830', label: '99830  Saariselkä (Inari)' },
  { id: '99860', label: '99860  Nellim (Inari)' },
  { id: '99870', label: '99870  Inari Keskus-Lemmenjoki (Inari)' },
  { id: '99885', label: '99885  Lemmenjoki (Inari)' },
  { id: '99910', label: '99910  Kaamanen-Partakko (Inari)' },
  { id: '99930', label: '99930  Sevettijärvi-Näätämö (Inari)' },
  { id: '99940', label: '99940  Näätämö (Inari)' },
  { id: '99950', label: '99950  Karigasniemi (Utsjoki)' },
  { id: '99980', label: '99980  Utsjoki Keskus (Utsjoki)' },
  { id: '99990', label: '99990  Nuorgam (Utsjoki)' },
];
