import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

interface Props {
  href: string;
  text: string;
  onClick?: () => void; 
  vari?: any;
}

const CustomButton = ({ href, text, onClick, vari }: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <Button
      component="a"
      href={href}
      variant="text"
      onClick={onClick}
      sx={{
        fontSize: "1.1rem",
        color: vari ? vari : theme.palette.text.primary,
        justifyContent: "right",
        alignItems: "center",
        minWidth: "180px",
        maxWidth: "180px",
        textTransform: "uppercase",
        "&:active": {
          color: theme.palette.primary.main,
        },
        "&:hover": {
          color: theme.palette.primary.main,
        },
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
