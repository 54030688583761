import React from "react";
import { BrowserRouter, Outlet, Routes, Route } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// GOOGLE ANALYTICS:---------------------------------------------------
import AnalyticsTracker from "./AnalyticsTracker";

// PROTECTED:----------------------------------------------------------
import ProtectedLayout from "./pages/www/Protected/layout/ProtectedLayout";
import Tilaajataulu from "./pages/www/Protected/Tilaajataulu/Tilaajataulu";
import JalleenmyyjaTaulu from "./pages/www/Protected/Jalleenmyyjataulu/Jalleenmyyjataulu";
import JalleenmyyjaDetail from "./pages/www/Protected/Jalleenmyyjataulu/JalleenmyyjaDetail";

import AuthProvider from "./pages/www/Unprotected/Login/AuthContext";
import ProtectedRoute from "./pages/www/Unprotected/Login/ProtectedRoute";

// UNPROTECTED:----------------------------------------------------------
import { AppProvider } from "./pages/www/Unprotected/CommonFormComponents/state";
import SuspenseWrapper from "./SuspenseWrapper";

import { Asiakaskyselyrakenne } from "./pages/www/Unprotected/Asiakaskysely/Asiakaskyselyrakenne";
import { Henkilostokyselyrakenne } from "./pages/www/Unprotected/Henkilostokysely/Henkilostokyselyrakenne";
import Home from "./pages/www/Unprotected/Home/Home";
import Kysely from "./pages/www/Unprotected/CommonFormComponents/Kysely";
import Login from "./pages/www/Unprotected/Login/Login";
import RegistrationForm from './pages/www/Unprotected/Rekisteroityminen/RegistrationForm';
import VastausajanPidennysForm from './pages/www/Unprotected/VastausajanPidennys/VastausajanPidennysForm';

// TYYLIT:----------------------------------------------------------
import getTheme from "./pages/theme/theme";
import Layout from "./pages/www/Unprotected/layout/Layout";

// 40x, 50x ja muut Errorit:----------------------------------------------------------
import Error401Unauthorized from "./pages/www/Unprotected/Error401Unauthorized";
import Error404PageNotFound from "./pages/www/Unprotected/Error404PageNotFound";
import Error500InternalServerError from "./pages/www/Unprotected/Error500InternalServerError";
import ErrorKyselySuljettu from "./pages/www/Unprotected/ErrorKyselySuljettu";

const App = (): JSX.Element => {
  return (
    <SuspenseWrapper>
      <HelmetProvider>
        <ThemeProvider theme={getTheme()}>
          <CssBaseline />
          <AuthProvider>
            <BrowserRouter>
              <AnalyticsTracker />
              <Routes>
                <Route
                  path="/"
                  element={
                    <Layout>
                      <Home />
                    </Layout>
                  }
                />
                <Route
                  path="/401"
                  element={<Error401Unauthorized />}
                />
                <Route
                  path="/404"
                  element={<Error404PageNotFound />}
                />
                <Route
                  path="/500"
                  element={<Error500InternalServerError />}
                />
                <Route
                  path="/kysely-suljettu"
                  element={<ErrorKyselySuljettu />}
                />
                <Route
                  path="/kirjautuminen"
                  element={<Login />}
                />
                <Route path="/rekisteroityminen" element={<RegistrationForm />} />
                <Route path="/vastausajan-muuttaminen" element={<VastausajanPidennysForm />} />
                <Route
                  path="/8plusnps1/:identifier/"
                  element={
                    <>
                      <AppProvider formId="8plusnps1">
                        <Kysely>
                          <Outlet />
                        </Kysely>
                      </AppProvider>
                    </>
                  }
                >
                  {Object.values(Asiakaskyselyrakenne).map(({ polku, element }) => (
                    <Route
                      key={polku}
                      path={polku}
                      element={element}
                    />
                  ))}
                </Route>
                <Route
                  path="/8plusnps2/:identifier/"
                  element={
                    <>
                      <AppProvider formId="8plusnps2">
                        <Kysely>
                          <Outlet />
                        </Kysely>
                      </AppProvider>
                    </>
                  }
                >
                  {Object.values(Henkilostokyselyrakenne).map(({ polku, element }) => (
                    <Route
                      key={polku}
                      path={polku}
                      element={element}
                    />
                  ))}
                </Route>
                <Route path="/pr/*" element={<ProtectedLayout />}>
                  <Route index element={<ProtectedRoute />} />
                  <Route path="tilaajataulu" element={<Tilaajataulu />} />
                  <Route path="jalleenmyyjataulu" element={<JalleenmyyjaTaulu />} />
                  <Route path="jalleenmyyjataulu/:y_tunnus" element={<JalleenmyyjaDetail />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </HelmetProvider>
    </SuspenseWrapper>
  );
};

export default App;
