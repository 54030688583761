import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(8),
  paddingBottom: theme.spacing(4),
  paddingRight: theme.spacing(8),
  borderRadius: 4,
  color: grey[800],
  backgroundColor: "white",
  textAlign: "center",
}));

export default StyledPaper;
