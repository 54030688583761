import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import positify_8plusNPS_logo from "../../../assets/images/positify_8plusNPS.png";

import CustomButton from "../../../components/CustomButton";
import KielipalkkiLyhenteet from "../../../components/KielipalkkiLyhenteet";

interface Props {
  onClose: () => void;
  open: boolean;
}



const Sidebar = ({ open, onClose }: Props): JSX.Element => {
  const theme = useTheme();

  const skaalaaja = 0.7;
  const box_height = `${skaalaaja * 50}px`;
  const button_height = `${skaalaaja * 30}px`;

  const scrollToHome = () => {
    onClose();
    const homeElement = document.getElementById("home");
    if (homeElement) {
      const yOffset = -64; // TODO oletus: yläpalkin korkeus on 64px. Säädä tarvittaessa.
      const y = homeElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <>
      <Drawer
        anchor="left"
        onClose={() => onClose()}
        open={open}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default,
            width: 220,
          },
        }}
      >
        <Box height="100%">

          <Box padding={2}>

            <Box
              onClick={scrollToHome}
              sx={{ mb: 2, textDecoration: "none", display: "flex", cursor: "pointer" }}
            >
              <IconButton
                sx={{ ml: 'auto', p: 0, pl: 'auto' }}
                size="large"
                disabled
              >
                <img
                  style={{ width: "180px" }}
                  alt="Positify 8+NPS"
                  src={positify_8plusNPS_logo}
                />
              </IconButton>
            </Box>

            <Box
              sx={{
                // paddingRight: "28px"
              }}
            >
              <KielipalkkiLyhenteet
                box_height={box_height}
                button_height={button_height}
                onClose={onClose}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                component="a"
                variant="text"
                onClick={scrollToHome}
                sx={{
                  fontSize: "1.1rem",
                  color: theme.palette.text.primary,
                  justifyContent: "right",
                  alignItems: "center",
                  minWidth: "180px",
                  maxWidth: "180px",
                  textTransform: "uppercase",
                  "&:active": {
                    color: theme.palette.primary.main,
                  },
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                Sivun alkuun
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                href="#miksi"
                text="Miksi?"
                onClick={onClose}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                href="#mita"
                text="Mitä?"
                onClick={onClose}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                href="#miten"
                text="Miten?"
                onClick={onClose}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                href="#analyysi"
                text="8+NPS analyysi"
                onClick={onClose}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                href="#hinta"
                text="Hinta"
                onClick={onClose}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                href="#tietoa"
                text="Meistä"
                onClick={onClose}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                href="#tilaa"
                text="Tilaa"
                onClick={onClose}
              // vari={theme.palette.primary.main}
              />
            </Box>

          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
