import React from "react";
import { FieldError, FieldErrors, FieldErrorsImpl, Merge, UseFormRegister } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormGroup } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { grey } from "@mui/material/colors";

import { useTranslation } from "react-i18next";

import Otsikko from "../KyselysivunOsat/Otsikko";
import Avokentta from "../KyselysivunOsat/Avokentta";

import { Kysymys, IsFocusedState, AsiakasTaustaFormValues } from "../types";

interface KysymysComponentProps {
  kysymys: Kysymys;
  register: UseFormRegister<AsiakasTaustaFormValues>;
  errors: FieldErrors<AsiakasTaustaFormValues>;
  isFocused: IsFocusedState;
  setIsFocused: React.Dispatch<React.SetStateAction<IsFocusedState>>;
}

const TaustaKysymysComponent: React.FC<KysymysComponentProps> = ({
  kysymys,
  register,
  errors,
  isFocused,
  setIsFocused,
}) => {
  const { t } = useTranslation();

  const isNoneChecked = () => {
    if (Array.isArray(kysymys.value)) {
      return kysymys.value.length === 0;
    }
    return false;
  };

  function getErrorMessage(error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined): string {
    if (!error) return "";
    if ("message" in error && typeof error.message === "string") {
      return error.message;
    }
    return "";
  }

  return (
    <>
      {kysymys.type === "paaOtsikko" && <Otsikko otsikko={kysymys.text} />}

      {kysymys.type === "valiOtsikko" && (
        <Typography
          variant="h6"
          align="left"
          sx={{
            mt: 1,
            ...kysymys.sx,
          }}
        >
          {t(kysymys.text)}
        </Typography>
      )}

      {kysymys.type === "radio" && (
        <Grid
          item
          md={kysymys.gridSizeMd}
          sm={kysymys.gridSizeSm}
        >
          <Box mt={2}>
            <FormLabel
              sx={{
                color: "#646562",
                fontSize: "1.15rem",
                fontWeight: isFocused.radio || !kysymys.value ? 700 : 400,
              }}
            >
              {t(kysymys.text)}
            </FormLabel>
            {kysymys.description && (
              <FormHelperText
                sx={{
                  color: grey[800],
                  fontSize: "0.9rem",
                  fontStyle: "italic",
                }}
              >
                {t(kysymys.description)}
              </FormHelperText>
            )}
            <FormHelperText sx={{ color: "red", fontWeight: 900 }}>
              {getErrorMessage(errors[kysymys.name])}
            </FormHelperText>

            <RadioGroup
              name={kysymys.name}
              value={kysymys.value || kysymys.defaultOption}
              onChange={kysymys.handleChange}
              sx={{
                color: grey[800],
                display: "flex",
                flexDirection: "column",
              }}
            >
              {kysymys.options?.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={
                    <Radio
                      {...register(kysymys.name, {
                        required: t("Tämä kenttä vaaditaan") as string,
                      })}
                      sx={{ m: 0, py: 0.6 }}
                    />
                  }
                  label={t(option.label)}
                  sx={{ m: 0 }}
                />
              ))}
            </RadioGroup>
          </Box>
        </Grid>
      )}

      {kysymys.type === "checkbox" && (
        <Box
          sx={{
            mt: 2,
            ...kysymys.sx,
          }}
        >
          <FormLabel
            sx={{
              color: "#646562",
              fontSize: "1.15rem",
              fontWeight: isFocused.checkbox || isNoneChecked() ? 700 : 400,
            }}
          >
            {t(kysymys.text)}
          </FormLabel>
          {kysymys.description && (
            <FormHelperText
              sx={{
                color: grey[800],
                fontSize: "0.9rem",
                fontStyle: "italic",
              }}
            >
              {t(kysymys.description)}
            </FormHelperText>
          )}
          <FormGroup>
            {kysymys.options?.map((option, index) => (
              <FormControlLabel
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChange={() => {}}
                key={index}
                name={`${kysymys.name}[${index}]`}
                value={option.value}
                control={
                  <Checkbox
                    sx={{ m: 0, ml: 1.5, py: 0.6 }}
                    {...register(kysymys.name, { setValueAs: (v: string[]) => (v ? [option.value] : []) })}
                    onChange={kysymys.handleChange}
                    value={option.value}
                    checked={Array.isArray(kysymys.value) && kysymys.value.includes(option.value)}
                    onFocus={() => setIsFocused((prevState: IsFocusedState) => ({ ...prevState, checkbox: true }))}
                    onBlur={() => setIsFocused((prevState: IsFocusedState) => ({ ...prevState, checkbox: false }))}
                  />
                }
                label={t(option.label)}
              />
            ))}
          </FormGroup>
          {errors[kysymys.name] && <FormHelperText error>{getErrorMessage(errors[kysymys.name])}</FormHelperText>}
        </Box>
      )}

      {kysymys.type === "textfield" && (
        <Box sx={{ mt: 2, width: "100%" }}>
          <Avokentta
            sx={{ ...kysymys.sx }}
            label={kysymys.text}
            name={kysymys.name}
            onChange={kysymys.handleChange}
            value={kysymys.value ? String(kysymys.value) : ""}
            helperText={kysymys.description ? t(kysymys.description) : undefined}
          />
        </Box>
      )}
    </>
  );
};

export default TaustaKysymysComponent;
