import React, { useState } from 'react';
import axios from 'axios';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Button, Box, Container, Modal } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import RFHTextField from '../../../components/FormComponents/RHFTextField';
import CustomButton from "../../../components/FormComponents/CustomButton";
import Typography from '@mui/material/Typography';

import config from "../../../../config";
import axiosUnauthenticatedInstance from '../axiosUnauthenticatedInstance'

import { useTheme } from "@mui/material/styles";
import Spacer from "../../../components/Spacer";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import fi from 'dayjs/locale/fi';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';



// TODO linkitä tämä valittuun kieliversioon
// Aseta dayjs:n lokaali suomeksi
dayjs.locale('fi');

interface IFormInput {
  kyselyn_paattymis_pvm: string;
  tilauskoodi: string;
}

interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgba(31, 48, 69, .75)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));
const StyledDay = styled(PickersDay)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const VastausajanPidennysForm: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }) => {
  const theme = useTheme();
  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors } } = useForm<IFormInput>();
  const [openModal, setOpenModal] = useState(false);
  const [virheLomakkeella, setVirheLomakkeella] = useState(false);
  const [modaaliOtsikko, setModaaliOtsikko] = useState("");
  const [modaaliKuvaus, setModaaliKuvaus] = useState("");


  const handleCloseModal = () => {
    setOpenModal(false);
    if (virheLomakkeella) {
      setVirheLomakkeella(false);
    } else {
      window.location.reload();
    }
  };

  const handleModal = (ok: boolean, otsikko: string, kuvaus: string) => {
    setModaaliOtsikko(otsikko);
    setModaaliKuvaus(kuvaus);
    setOpenModal(true);
  };

  const validateRaporttiCode = async (code: string) => {
    try {
      const response = await axios.get(`${config.baseApiUrl}/validate_vertailuraportti_code/`, {
        params: { code }
      });
      return response.data.isValid;
    } catch (error) {
      console.error('Error validating raportti code:', error);
      return false;
    }
  };

  const handleTilauskoodiBlur = async () => {
    // Use the ?? operator to provide an empty string as a fallback value
    const tilauskoodiValue = getValues("tilauskoodi") ?? '';

    // Tutkitaan onko tilauskoodi validi,
    // mutta armistetaan ensin että tilauskoodi on string (ei undefined tai null)
    if (typeof tilauskoodiValue === 'string') {
      if (tilauskoodiValue === '') {
        // If tilauskoodi is an empty string, clear any existing errors
        clearErrors("tilauskoodi");
        return;
      }
      const isVertailuraporttiValid = await validateRaporttiCode(tilauskoodiValue);
      if (!isVertailuraporttiValid) {
        setError("tilauskoodi", { type: "manual", message: "Tarkista tilauskoodi." });
      } else {
        clearErrors("tilauskoodi");
      }
    } else {
      // TODO: Handle the case where tilauskoodi is undefined, if necessary
      // For example, show an error or take some default action
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = data => {
    if (data) {
      axiosUnauthenticatedInstance.post(`${config.vastausajanPidennysApiUrl}`, data)
        .then(response => {
          // console.log("Registration successful:", response.data);
          setVirheLomakkeella(false);
          handleModal(true, "VASTAUSAIKA MUUTETTU!", "Lähetimme vahvistuksen sähköpostiosoitteeseenne.");
        })
        .catch(error => {
          // console.error("Registration error:", error.response.data);
          if (error.response.data.non_field_errors[0] === "Tilauskoodi ei kelpaa." || error.response.data.non_field_errors[0] === "Tilausta ei löydy.") { // ks. validate() @serializers.VastausaikaSerializer
            setVirheLomakkeella(true);
            handleModal(true, "TILAUSKOODI EI KELPAA", "Tarkista tilauskoodi ja yritä uudelleen.");
          } else if (error.response.data.non_field_errors[0] === "Tilaus on jo suljettu.") { // ks. validate() @serializers.VastausaikaSerializer
            setVirheLomakkeella(true);
            handleModal(true, "PÄÄTTYNYTTÄ TILAUSTA EI VOI AVATA UUDELLEEN", "Tee uusi tilaus tai ota meihin yhteyttä: info@toimialadata.fi");
          } else {
            setVirheLomakkeella(true);
            handleModal(true, "Valitettavasti jokin meni pieleen :(", "");
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {modaaliOtsikko}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {modaaliKuvaus}
          </Typography>
        </Box>
      </Modal>

      <Box sx={{
        mt: 8,
        pt: 12,
        pb: 16,
        backgroundColor: theme.palette.background.paper
      }}
      >
        <Container maxWidth="sm">
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate >
            <Typography variant="h4" sx={{ mb: 2 }}>
              Voit muuttaa kyselyn päättymispäivää tämän lomakkeen avulla.
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={fi}>  {/* TODO: datepickerin kieliasetus: suomi tai lokaali */}
              <Controller
                name="kyselyn_paattymis_pvm"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...restField } }) => (
                  <DesktopDatePicker
                    {...restField}
                    value={restField.value ? restField.value : null}
                    label="Haluttu kyselyn päättymispäivä"
                    inputFormat="DD.MM.YYYY"
                    minDate={dayjs().subtract(1, 'day')} // päivämäärä voi olla aikaisintaan eilen
                    maxDate={dayjs().add(6, 'month')} // päivämäärä voi olla korkeintaan 6 kk tulevaisuudessa
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        required
                        error={!!errors.kyselyn_paattymis_pvm}
                        helperText='Kyselyn päättymispäivä voi aikaisintaan olla eilinen päivä ja viimeistään 6 kk kuluttua.'
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              variant="filled"
              margin="normal"
              required
              fullWidth
              label="Tilauskoodi"
              {...register('tilauskoodi', { required: true })}
              error={!!errors.tilauskoodi}
              helperText={errors.tilauskoodi ? `${errors.tilauskoodi.message} Tilauskoodi löytyy kyselyn tilausvahvistussähköpostista.` : 'Tilauskoodi löytyy kyselyn tilausvahvistussähköpostista.'}
              onBlur={handleTilauskoodiBlur}
            />


            <CustomButton
              id="lahetaTilaus"
              fullWidth={true}
              buttonText="Vahvista muutos"
              type="submit"
              sx={{ mt: 2 }}
            />
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default VastausajanPidennysForm;
