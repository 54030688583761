import { useAppState } from "./state";
import * as Yup from "yup";

export function useTaustaHandleChange<T extends Yup.Schema<any>>(validation?: T | null) {
  const { setState } = useAppState();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const updatedValue = type === "checkbox" ? checked : value;

    // Validate the updated value if a validation schema is provided
    let isValid = true;
    if (validation) {
      validation
        .validateAt(name, { [name]: updatedValue })
        .then(() => {
          isValid = true;
        })
        .catch(() => {
          isValid = false;
        });
    }
       
    // Only update the state if the updated value is valid
    if (isValid) {
      setState((prevState: any) => {
        if (type === "checkbox") {
          const prevOptions = Array.isArray(prevState[name]) ? prevState[name] : [];
          if (checked) {
            // Add the selected option
            return { ...prevState, [name]: [...prevOptions, value] };
          } else {
            // Remove the unselected option
            return { ...prevState, [name]: prevOptions.filter((option: string) => option !== value) };
          }
        } else {
          return { ...prevState, [name]: updatedValue };
        }
      });
    }
  };

  return handleChange;
}

// Peruskysymysten käsittelyssä käytettävä funktio.
// Sliderin eos-checboxin kanssa tarvitaan true/false arvoja
export function useKyselyHandleChange() {
  const { setState } = useAppState();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("handleChange", event.target.name, event.target.value);
    const { name, value, type, checked } = event.target;
    const updatedValue = type === "checkbox" ? checked : value;

    setState((prevState: any) => {
      return { ...prevState, [name]: updatedValue };
    });
  };

  return handleChange;
}
