import React from "react";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";

interface YritysnimiProps {
  sx?: SxProps<Theme>;
  yritysnimi: string;
  tarkenne?: string;
  kysymysNro?: string;
}

const Yritysnimi: React.FC<YritysnimiProps> = ({
  sx,
  yritysnimi,
  tarkenne = "Kun ajattelet meitä, kuinka arvioisit seuraavaa väittämää?",
  kysymysNro,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          ...sx,
          fontStyle: "italic",
          textAlign: "right",
        }}
      >
        {typeof kysymysNro !== 'undefined' && kysymysNro !== null ? `${kysymysNro}/9` : "\u00A0"}
      </Typography>

      <Typography
        variant="h5"
        sx={{
          ...sx,
          fontStyle: "italic",
          fontWeight: "bold",
          textAlign: "left",
          mt: 2,
          mb: 1,
        }}
      >
        {yritysnimi}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          ...sx,
          textAlign: "left",
          mb: 2,
        }}
      >
        {t(tarkenne)}
      </Typography>
    </>
  );
};

export default Yritysnimi;
