import React from "react";
import { useNavigate } from 'react-router-dom';
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Box } from "@mui/material";

import * as Yup from 'yup';

import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import NagigationButtons from "./NavigationButtons";
import Otsikko from "./Otsikko";
import PaakysymysOsio from "./PaakysymysOsio";
import Yritysnimi from "./Yritysnimi";

import { useKyselyHandleChange } from "../useHandleChange";
import { useAppState, ApplicationState } from "../state";
import { Kysymys } from '../types';

export interface KyselySivuProps {
  sx?: SxProps<Theme>;
  otsikko: string;
  seuraavaSivu: string;
  edellinenSivu?: string;
  showEdellinen?: boolean;
  kysymys?: string;
  eriMielta?: string;
  samaaMielta?: string;
  naytaNapit?: boolean;
  avo1?: string | undefined | null;
  avo2?: string | undefined | null;
  avo3?: string | undefined | null;
}

// Function to generate validation schema
const generateValidationSchema = (kysymys = '1') => { // Default value for kysymys is '1', which handles the possibility of kysymys being undefined
  const schemaFields = {
    // [`q${kysymys}`]: Yup.string().required('Vastaus vaaditaan!'),
    // [`q${kysymys}`]: Yup.string().optional(),s
    [`q${kysymys}_a1`]: Yup.string().optional(),
    [`q${kysymys}_a2`]: Yup.string().optional(),
    [`q${kysymys}_a3`]: Yup.string().optional(),
    [`q${kysymys}_eos`]: Yup.boolean().optional(),
  };

  return Yup.object().shape(schemaFields);
};

const KyselySivu: React.FC<KyselySivuProps> = ({
  sx,
  otsikko,
  seuraavaSivu,
  edellinenSivu,
  showEdellinen = true,
  kysymys,
  eriMielta = "Täysin eri mieltä",
  samaaMielta = "Täysin samaa mieltä",
  naytaNapit = true,
  avo1 = "mikaOllutHyvaa",
  avo2 = "mitenToimiaParemmin",
  avo3 = "mika...",
}) => {
  const name_q = `q${kysymys}`;
  const name_a1 = `q${kysymys}_a1`;
  const name_a2 = `q${kysymys}_a2`;
  const name_a3 = `q${kysymys}_a3`;
  const name_eos = `q${kysymys}_eos`;

  const { state, setState } = useAppState();
  const handleChange = useKyselyHandleChange();
  const navigate = useNavigate();

  const validationSchema = generateValidationSchema(kysymys);
  const formMethods = useForm<ApplicationState>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, register, formState: { errors } } = formMethods;

  const onSubmit: SubmitHandler<ApplicationState> = (data) => {
    navigate(`/${state.formId}/${state.identifier}/${seuraavaSivu}`);
  };


  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Yritysnimi yritysnimi={state.yritysNimi || ""} kysymysNro={kysymys} />
          <Otsikko otsikko={otsikko} />
          {kysymys && (
            <PaakysymysOsio
              name_q={name_q}
              name_a1={name_a1}
              name_a2={name_a2}
              name_a3={name_a3}
              name_eos={name_eos}
              eriMielta={eriMielta}
              samaaMielta={samaaMielta}
              handleChange={handleChange}
              handleCheckboxChange={handleChange}
              avo1={avo1}
              avo2={avo2}
              avo3={avo3}
            />
          )}

          {naytaNapit && (
            <NagigationButtons
              edellinenSivu={edellinenSivu}
              showEdellinen={showEdellinen}
              seuraavaSivu={seuraavaSivu}
              type="submit"
            />
          )}
        </Box>
      </form>
    </FormProvider>
  );
};

export default KyselySivu;
