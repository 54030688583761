import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { Grid, useMediaQuery } from "@mui/material";

import tunnus from "../../../../assets/images/tunnus-siniharmaa-tausta-valkoiset-kirjaimet.png";
import kahdeksan_plus_yksi from "../../../../assets/images/kahdeksan_plus_yksi.png";
import HeroButtons from "./HeroButtons";
import { VpnKey } from "@mui/icons-material";



const Hero = (): JSX.Element => {
  const theme = useTheme();
  const texts = [
    "Asiakassuhteiden laatu ja organisaation asiakaslähtöisyys rakentavat menestystä",
    "Positify 8+NPS -malli mittaa sisäisen ja ulkoisen asiakaslähtöisyyden",
    "Positify aktivoi organisaation kehittymään asiakaslähtöisemmäksi",
  ];
  const backgroundColors = ['#d06149', '#d1983a', '#22D7C1']; // Määritellään taustavärit // TODO tuo tiedostosta omat_varit.ts
  const [zoomIn, setZoomIn] = React.useState([false, false, false]);
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmOrXsScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isLgDownScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const boxAppearanceInterval = 100; // Laatikoiden ilmestymistiheys (ms)
  const boxAppearanceLength = 300; // Laatikoiden ilmestymiseen kuluva aika (ms)
  const boxExitLength = 100; // Laatikoiden katoamiseen kuluva aika (ms)
  const allBoxesVisibleDuration = 20000; // Kuinka kauan kaikki laatikot pysyvät näkyvissä ennen piilotusta
  const cycleDuration = texts.length * boxAppearanceInterval + allBoxesVisibleDuration;

  React.useEffect(() => {
    const sequence = () => {
      texts.forEach((_, index) => {
        setTimeout(() => {
          setZoomIn(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
          });
        }, index * boxAppearanceInterval);
      });

      setTimeout(() => {
        setZoomIn([false, false, false]);
      }, cycleDuration - boxAppearanceInterval);
    };

    sequence();
    const intervalId = setInterval(sequence, cycleDuration);

    return () => clearInterval(intervalId);
  }, [cycleDuration, boxAppearanceInterval]);


  const icon = (text: string, index: number) => (
    <Fade in={zoomIn[index]} timeout={{ enter: boxAppearanceLength, exit: boxExitLength }}>
      <Paper
        elevation={20}
        sx={{
          width: 350,
          minHeight: 100, // Asetetaan korkeus, jotta on tilaa keskitykselle
          backgroundColor: backgroundColors[index],
          display: 'flex', // Ota käyttöön flexbox asettelu
          flexDirection: 'column', // Aseta lapset pystysuunnassa
          justifyContent: 'center', // Keskittää lapset vertikaalisesti
          alignItems: 'center', // Keskittää lapset horisontaalisesti
          flex: '1 0 auto',
          p: 4,
        }}
      >
        <Typography
          component="p"
          variant="h5"
          align="center"
          sx={{
            fontWeight: 600,
            color: "#1f3045",
          }}
        >
          {text}
        </Typography>
      </Paper>
    </Fade>
  );

  return (
    <div>
      <Box
        width={"100%"}
        justifyContent={"right"}
        display={'flex'}
        sx={{
          mt: 5,
          pr: 3,
          backgroundColor: theme.palette.background.paper,
        }}
      >
      </Box>

      <Box
        id="hero"
        sx={{
          pt: 0, pb: 13, px: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Container
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              mt: 7,
              mb: isXsScreen ? 4 : 8,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: 0
            }}
          >

            {/* <Box 
              sx={{ 
                px: 4,
                pb: 1,
                display: 'flex', 
              }}>
              <img 
                alt="tunnus" 
                src={tunnus} 
                style={{ 
                  height: isSmScreen ? "150px" : isXsScreen ? "120px" : "200px",
                  objectFit: "contain", // Säilyttää kuvan mittasuhteet
                }} 
              />
            </Box> */}

            <Box
              sx={{
                maxWidth: '100%',
                display: 'flex', // Ota käyttöön flexbox asettelu
                flexDirection: 'column', // Aseta lapset pystysuunnassa
                alignItems: 'center', // Keskittää lapset horisontaalisesti
                justifyContent: 'flex-end',
                flex: '1 0 auto',
                pt: 8,
                pb: 0,
                fontWeight: 600,
              }}
            >
              <Typography
                variant="h3"
                component="p"
                color="#fff"
                sx={{
                  px: 4,
                  textAlign: 'center',
                }}
              >
                AUTAMME MENESTYMÄÄN
              </Typography>
            </Box>

            <Box
              sx={{
                maxWidth: '100%',
                display: 'flex', // Ota käyttöön flexbox asettelu
                flexDirection: 'column', // Aseta lapset pystysuunnassa
                alignItems: 'center', // Keskittää lapset horisontaalisesti
                justifyContent: 'center',
                py: 0,
                fontWeight: 600,
              }}>
              <Typography
                variant="h4"
                component="p"
                color="#fff"
                sx={{
                  display: 'flex', // Ota käyttöön flexbox asettelu
                  flexDirection: 'column', // Aseta lapset pystysuunnassa
                  textAlign: 'center',
                  mt: 1,
                }}
              >
                mittaamalla asiakaslähtöisyyttä
              </Typography>
              <Typography
                variant="h4"
                component="p"
                color="#fff"
                sx={{
                  display: 'flex', // Ota käyttöön flexbox asettelu
                  flexDirection: 'column', // Aseta lapset pystysuunnassa
                  textAlign: 'center',
                  mt: 1,
                  mb: 1,
                }}
              >
                sekä asiakkailta että henkilöstöltä.
              </Typography>
            </Box>



          </Box>

          <Box marginBottom={3} width={"100%"} >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
              {texts.map((text, index) => icon(text, index))}
            </Box>
          </Box>

          <HeroButtons />

        </Container>
      </Box>
    </div>
  );
};

export default Hero;
