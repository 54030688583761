import React, { useState, useEffect } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axiosInstance from "../../Unprotected/Login/axiosInstance";
import { useAuth } from "../../Unprotected/Login/AuthContext";
import { JalleenmyyjaData } from "../tauluInterfaces";
import JalleenmyyjatauluComponent from "../JalleenmyyjatauluComponent";


const JalleenmyyjaTaulu = () => {
  const [data, setData] = useState<JalleenmyyjaData[]>([]);
  const [error, setError] = useState<null | string>(null);
  const theme = useTheme();
  const { refreshToken } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refreshToken();
        const response = await axiosInstance.get("/jalleenmyyjataulu/");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching Jalleenmyyja data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="jalleenmyyjataulu">
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          pt: 14,
          pb: 13,
          px: 2,
        }}
      >
        {error ? (
          <Typography variant="h6" color="error" sx={{ mt: 10 }}>
            {error}
          </Typography>
        ) : data.length === 0 ? (
          <Typography variant="h6" sx={{ mt: 20 }}>
            Ei tietoja saatavilla.
          </Typography>
        ) : (
          <JalleenmyyjatauluComponent
            data={data}  // an array (multiple objects)
            otsikko="Jälleenmyyjät"
            naytaLinkki={true}
          />
        )}
      </Box>
    </div>
  );
};

export default JalleenmyyjaTaulu;
