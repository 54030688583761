import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { FieldError, FieldErrors, FieldErrorsImpl, Merge, UseFormRegister } from "react-hook-form";
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { Theme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { SxProps } from "@mui/system";
import { Box, RadioGroup, FormControlLabel, Radio, FormLabel, FormHelperText, Typography } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { grey } from "@mui/material/colors";

import { useAppState } from '../CommonFormComponents/state';
import { useTaustaHandleChange } from "../CommonFormComponents/useHandleChange";
import { Kysymys, HenkilostoTaustaFormValues } from '../CommonFormComponents/types';
import Yritysnimi from '../CommonFormComponents/KyselysivunOsat/Yritysnimi';
import TaustatietoKomponentit from "../CommonFormComponents/Tausta/TaustatietoKomponentit";
import NavigationButtons from '../CommonFormComponents/KyselysivunOsat/NavigationButtons';

type NonEmptyArray<T> = [T, ...T[]];

interface RooliSivuProps {
  sx?: SxProps<Theme>;
  tarkenne: string;
  otsikko: string;
  sivuotsikko: string;
  edellinenSivu: string;
  seuraavaSivu: string;
  vaihtoehdot: NonEmptyArray<string>;
}

const RooliSivu: React.FC<RooliSivuProps> = ({
  sx, tarkenne, sivuotsikko, otsikko, vaihtoehdot, edellinenSivu, seuraavaSivu
}) => {
  const { t } = useTranslation();
  const { state, setState } = useAppState();

  const navigate = useNavigate();
  const formMethods = useForm<HenkilostoTaustaFormValues>({

    defaultValues: { tausta_2: state.tausta_2 || '' },
  });
  const { handleSubmit, register, formState: { errors } } = formMethods;

  const onSubmit: SubmitHandler<HenkilostoTaustaFormValues> = (data) => {
    const name = 'tausta_2';
    const value = data.tausta_2;

    console.log('errors', errors) // TODO remove

    if (Object.keys(errors).length === 0) { // Check if there are no validation errors
      setState((prevState: any) => { // viedään valittu arvo stateen
        return { ...prevState, [name]: value };
      });
      navigate(`/${state.formId}/${state.identifier}/${seuraavaSivu}`);
    }
  };

  function getErrorMessage(error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined): string {
    if (!error) return "";
    if ("message" in error && typeof error.message === "string") {
      return error.message;
    }
    return "";
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ textAlign: 'left' }}>
          <Yritysnimi yritysnimi={state.yritysNimi || ''} tarkenne={tarkenne} />
          <Typography
            sx={{
              ...sx,
              textAlign: "left",
              fontWeight: 500,
              fontSize: "1.85rem",
              width: "100%",
              mb: 1,
            }}
          >
            {t(sivuotsikko)}
          </Typography>
          <Grid
            container
            spacing={1}
            sx={{
              m: 0,
              mt: 1.5,
              mx: 0,
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
            >
              <Box mt={2}>
                <FormLabel
                  sx={{
                    color: "#646562",
                    fontSize: "1.15rem",
                    fontWeight: 700,
                  }}
                >
                  {t(otsikko)}
                </FormLabel>
                <FormHelperText
                  sx={{
                    color: grey[800],
                    fontSize: "0.9rem",
                    fontStyle: "italic",
                  }}
                >
                  {t("henkilosto.taustat.rooli.helper")}
                </FormHelperText>
                <FormHelperText sx={{ color: "red", fontWeight: 900 }}>
                  {getErrorMessage(errors['tausta_2'])}
                </FormHelperText>
                <RadioGroup
                  aria-label="rooli" // TODO lisää kaikkiin kysymmyksiin aria-label
                  name="tausta_2"
                  value={formMethods.watch("tausta_2")} // This ensures the selected value reflects the state
                  sx={{
                    color: grey[800],
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {vaihtoehdot.map((option, index) => {
                    const [label, value] = option.split(':').map(part => part.trim());
                    return (
                      <FormControlLabel
                        key={index}
                        value={value}
                        control={
                          <Radio
                            {...register('tausta_2', {
                              required: t("Tämä kenttä vaaditaan") as string,
                            })}
                            sx={{ m: 0, py: 0.6 }}
                          />
                        }
                        label={t(label)}
                        sx={{ m: 0 }} />
                    );
                  })}
                </RadioGroup>
              </Box>
            </Grid>
          </Grid>

          <NavigationButtons
            edellinenSivu={edellinenSivu}
            showEdellinen={true}
            seuraavaSivu={seuraavaSivu}
            type="submit"
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default RooliSivu;
