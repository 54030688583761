import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import { SxProps } from "@mui/system";

import StyledPaper from "../styles/StyledPaper";

interface KyselyPaperProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const KyselyPaper: React.FC<KyselyPaperProps> = ({ children, sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        display: "flex",
        justifyContent: "center",
        mt: 1,
        // mt: 8,
        mb: 10,
        ...sx,
      }}
    >
      <Container maxWidth="md">
        <StyledPaper>{children}</StyledPaper>
      </Container>
    </Box>
  );
};

export default KyselyPaper;
