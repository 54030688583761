import { ReactElement } from "react";

import KyselySivu from "../CommonFormComponents/KyselysivunOsat/KyselySivu";
import TervetuloaSivu from "./TervetuloaSivu";
import KiitosSivu from "../CommonFormComponents/Kiitos/KiitosSivu";
import AsiakasTaustatietoSivu from "./AsiakasTaustatietoSivu";
import RooliSivu from "./RooliSivu";

export type KyselyRakenne = {
  polku: string;
  element: ReactElement;
}[];

export const Asiakaskyselyrakenne: KyselyRakenne = [
  {
    polku: "",
    element: (
      <TervetuloaSivu
        seuraavaSivu="rooli"
        buttonSeuraava="buttonAloitetaan"
      />
    ),
  },
  {
    polku: "rooli",
    element: (
      <RooliSivu // TODO refactor to use the same RooliSivu on both Asiakaskysely and Henkilostokysely
        tarkenne=""
        sivuotsikko="asiakas.taustat.rooli.sivuotsikko"
        otsikko="asiakas.taustat.rooli.otsikko"
        edellinenSivu=""
        seuraavaSivu="1"
        vaihtoehdot={["Asiakas: 4", "Yhteistyökumppani: 5", "Jokin muu rooli: 6"]}
      // NOTE: vaihtoehtojen arvojen tulee vastata backendin arvoja
      />
    ),
  },
  {
    polku: "1",
    element: (
      <KyselySivu
        kysymys="1"
        otsikko="kysymykset.k1.otsikko"
        eriMielta="kysymykset.k1.eriMielta"
        samaaMielta="kysymykset.k1.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa_1"
        avo2="asiakas.mitenToimiaParemmin_1"
        avo3={null}
        edellinenSivu="rooli"
        seuraavaSivu="2"
      />
    ),
  },
  {
    polku: "2",
    element: (
      <KyselySivu
        kysymys="2"
        otsikko="kysymykset.k2.otsikko"
        eriMielta="kysymykset.k2.eriMielta"
        samaaMielta="kysymykset.k2.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa_2"
        avo2="asiakas.mitenToimiaParemmin_2"
        avo3={null}
        edellinenSivu="1"
        seuraavaSivu="3"
      />
    ),
  },
  {
    polku: "3",
    element: (
      <KyselySivu
        kysymys="3"
        otsikko="kysymykset.k3.otsikko"
        eriMielta="kysymykset.k3.eriMielta"
        samaaMielta="kysymykset.k3.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa_3"
        avo2="asiakas.mitenToimiaParemmin_3"
        avo3={null}
        edellinenSivu="2"
        seuraavaSivu="4"
      />
    ),
  },
  {
    polku: "4",
    element: (
      <KyselySivu
        kysymys="4"
        otsikko="kysymykset.k4.otsikko"
        eriMielta="kysymykset.k4.eriMielta"
        samaaMielta="kysymykset.k4.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa_4"
        avo2="asiakas.mitenToimiaParemmin_4"
        avo3={null}
        edellinenSivu="3"
        seuraavaSivu="5"
      />
    ),
  },
  {
    polku: "5",
    element: (
      <KyselySivu
        kysymys="5"
        otsikko="kysymykset.k5.otsikko"
        eriMielta="kysymykset.k5.eriMielta"
        samaaMielta="kysymykset.k5.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa_5"
        avo2="asiakas.mitenToimiaParemmin_5"
        avo3={null}
        edellinenSivu="4"
        seuraavaSivu="6"
      />
    ),
  },
  {
    polku: "6",
    element: (
      <KyselySivu
        kysymys="6"
        otsikko="kysymykset.k6.otsikko"
        eriMielta="kysymykset.k6.eriMielta"
        samaaMielta="kysymykset.k6.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa_6"
        avo2="asiakas.mitenToimiaParemmin_6"
        avo3={null}
        edellinenSivu="5"
        seuraavaSivu="7"
      />
    ),
  },
  {
    polku: "7",
    element: (
      <KyselySivu
        kysymys="7"
        otsikko="kysymykset.k7.otsikko"
        eriMielta="kysymykset.k7.eriMielta"
        samaaMielta="kysymykset.k7.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa_7"
        avo2="asiakas.mitenToimiaParemmin_7"
        avo3={null}
        edellinenSivu="6"
        seuraavaSivu="8"
      />
    ),
  },
  {
    polku: "8",
    element: (
      <KyselySivu
        kysymys="8"
        otsikko="kysymykset.k8.otsikko"
        eriMielta="kysymykset.k8.eriMielta"
        samaaMielta="kysymykset.k8.samaaMielta"
        avo1="asiakas.mikaOllutHyvaa_8"
        avo2="asiakas.mitenToimiaParemmin_8"
        avo3={null}
        edellinenSivu="7"
        seuraavaSivu="9"
      />
    ),
  },
  {
    polku: "9",
    element: (
      <KyselySivu
        kysymys="9"
        otsikko="kysymykset.k9.otsikko"
        eriMielta="kysymykset.k9.eriMielta"
        samaaMielta="kysymykset.k9.samaaMielta"
        avo1="asiakas.perustelusi"
        avo2={null}
        avo3={null}
        showEdellinen={true}
        edellinenSivu="8"
        seuraavaSivu="taustat"
      />
    ),
  },
  {
    polku: "taustat",
    element: (
      <AsiakasTaustatietoSivu
        edellinenSivu="9"
        seuraavaSivu="kiitos"
        lahetaData={true}
      />
    ),
  },
  {
    polku: "kiitos",
    element: (
      <KiitosSivu
        otsikko="kiitos.otsikko"
        p1="kiitos.p1"
        p2="kiitos.p2"
        p3="kiitos.p3"
        naytaLiidilomake={true}
        naytaNapit={false}
      />
    ),
  },
];
