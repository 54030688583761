import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";  // Importing useParams to use the y_tunnus from the URL
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axiosInstance from "../../Unprotected/Login/axiosInstance";
import { useAuth } from "../../Unprotected/Login/AuthContext";
import { TilaajaData } from "../tauluInterfaces";
import { JalleenmyyjaData } from "../tauluInterfaces";
import TilaajatauluComponent from "../TilaajatauluComponent";
import JalleenmyyjatauluComponent from "../JalleenmyyjatauluComponent";


const JalleenmyyjaDetail = () => {
  const { y_tunnus } = useParams();
  const [jalleenmyyjaData, setJalleenmyyjaData] = useState<JalleenmyyjaData | null>(null);
  const [tilaajaData, setTilaajaData] = useState<TilaajaData[]>([]);
  const [error, setError] = useState<null | string>(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/jalleenmyyjataulu/${y_tunnus}/`);
        setJalleenmyyjaData(response.data.jalleenmyyja);  // a single object
        setTilaajaData(response.data.tilaukset);  // an array
      } catch (error) {
        console.error("Error fetching data:", error);
        setError('Failed to fetch data');
      }
    };

    fetchData();
  }, [y_tunnus]);

  if (!jalleenmyyjaData) return <Typography>Loading...</Typography>;

  return (
    <div id="jalleenmyyjaDetail">
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          pt: 14,
          pb: 13,
          px: 2,
        }}
      >
        {error ? (
          <Typography variant="h6" color="error" sx={{ mt: 10 }}>
            {error}
          </Typography>
        ) : (
          <>
            <JalleenmyyjatauluComponent
              data={[jalleenmyyjaData]} // single object
              otsikko="Jälleenmyyjä"
            />
            <TilaajatauluComponent
              otsikkoSx={{ mt: 4 }}
              data={tilaajaData}
              otsikko="Jälleenmyyjän kautta tulleet tilaukset"
            />
          </>
        )}
      </Box>
    </div>
  );
};

export default JalleenmyyjaDetail;
