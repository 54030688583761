import React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { alpha, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import tunnus from "../../../assets/images/tunnus-siniharmaa-tausta-valkoiset-kirjaimet.png";
import positify_8plusNPS_logo from "../../../assets/images/positify_8plusNPS.png";

import CustomButton from "../../../components/CustomButton";
import KielipalkkiLyhenteet from "../../../components/KielipalkkiLyhenteet";

interface Props {
  onSidebarOpen: () => void;
  position?: "fixed" | "absolute" | "sticky" | "static" | "relative";
  isSidebarOpen: boolean;
}

const Header = ({ onSidebarOpen, position = "sticky", isSidebarOpen }: Props): JSX.Element => {
  const theme = useTheme();
  const skaalaaja = 0.7;
  const box_height = `${skaalaaja * 50}px`;
  const button_height = `${skaalaaja * 30}px`;
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const scrollToHome = () => {
    const homeElement = document.getElementById("home");
    if (homeElement) {
      const yOffset = -64; // TODO oletus: yläpalkin korkeus on 64px. Säädä tarvittaessa.
      const y = homeElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <>
      <AppBar
        color="transparent"
        position={position}
        sx={{
          backgroundColor: "#545759",
          border: 0,
          padding: "0px",
          paddingBottom: "5px",
          marginBottom: "5px",
          boxShadow: "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">

            <Grid item xs={3}>
              <Box
                onClick={scrollToHome}
                sx={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <IconButton size="large" disabled>
                  <img
                    style={{ width: "180px", marginBottom: "-5px" }}
                    alt="Positify 8+NPS"
                    src={positify_8plusNPS_logo}
                  />
                </IconButton>
              </Box>
            </Grid>

            <Grid item xs={!isXsScreen ? 9 : 6} sx={{ display: "flex", justifyContent: "flex-end" }}>
              {!isXsScreen && (
                <KielipalkkiLyhenteet box_height={box_height} button_height={button_height} />
              )}
              <Button
                onClick={onSidebarOpen}
                aria-label="Menu"
                variant="outlined"
                sx={{
                  borderRadius: 0,
                  padding: 1,
                  borderColor: alpha(theme.palette.divider, 0.2),
                  marginLeft: 2,
                }}
              >
                <MenuIcon />
              </Button>
            </Grid>

          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
