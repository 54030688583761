import React from "react";

import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

interface TervetuloaKappaleProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

const TervetuloaKappale: React.FC<TervetuloaKappaleProps> = ({ sx, children }) => {
  return (
    <Typography
      variant="h6"
      sx={{
        textAlign: "left",
        margin: "1rem 0",
      }}
    >
      {children}
    </Typography>
  );
};

export default TervetuloaKappale;
