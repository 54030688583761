import React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

import Avokentta from "../CommonFormComponents/KyselysivunOsat/Avokentta";

import { useAppState } from "../CommonFormComponents/state";

interface AsiakasArvosanaAvotProps {
  sx?: SxProps<Theme>;
  name_a1: string;
  name_a2: string;
  name_a3: string;
  avo1?: string | undefined | null;
  avo2?: string | undefined | null;
  avo3?: string | undefined | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AsiakasArvosanaAvot: React.FC<AsiakasArvosanaAvotProps> = ({
  sx,
  name_a1,
  name_a2,
  name_a3,
  handleChange,
  avo1,
  avo2,
  avo3,
}) => {
  const { state } = useAppState();
  const a1Value = state[name_a1] || "";
  const a2Value = state[name_a2] || "";
  const a3Value = state[name_a3] || "";

  return (
    <>
      {avo1 && (
        <Avokentta
          name={name_a1}
          value={a1Value}
          onChange={handleChange}
          label={avo1}
        />
      )}
      {avo2 && (
        <Avokentta
          name={name_a2}
          value={a2Value}
          onChange={handleChange}
          label={avo2}
        />
      )}
      {avo3 && (
        <Avokentta
          name={name_a3}
          value={a3Value}
          onChange={handleChange}
          label={avo3}
        />
      )}
    </>
  );
};

export default AsiakasArvosanaAvot;
