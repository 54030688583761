import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { List, ListItem } from "@mui/material";

import KolmeVaihetta from "../Sisalto/KolmeVaihetta";
import OsioOtsikko from "../../../../components/OsioOtsikko";
import Spacer from "../../../../components/Spacer";
interface CustomComponentProps {
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
}

const Miten: React.FC<CustomComponentProps> = ({ pt = 0, pr = 2, pb = 0, pl = 2 }): JSX.Element => {
  const theme = useTheme();
  const [showFullText, setShowFullText] = useState(false);

  return (
    <div id="miten">
      <Spacer />
      <Box sx={{ pt, pr, pb, pl }}>
        <OsioOtsikko otsikko="Miten?  - Palvelukuvaus" />
        <Container>
          <Box>
            <Grid
              container
              spacing={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                md={8}
                xs={12}
              >
                <Box
                  sx={{
                    padding: 2,
                    backgroundColor: theme.palette.background.paper,
                    margin: 0,
                  }}
                  component={Card}
                  height={1}
                  display="flex"
                  flexDirection="column"
                  boxShadow={0}
                >
                  <CardContent>

                    <Box
                      marginBottom={0}
                      display="flex"
                      flexDirection="column"
                    >

                      <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ 
                            textTransform: "uppercase", 
                            marginTop: 1,
                            color: theme.palette.primary.main,
                          }}
                        >
                          Palvelukuvaus
                        </Typography>
                        
                        <ul>
                          <li style={{marginBottom: "20px", color: "rgb(207, 207, 207)"}}>Mittaus toteutetaan samanaikaisesti ulkoisille sidosryhmille (asiakkaat ja yhteistyökumppanit) ja sisäisesti (henkilöstö, johto, omistajat/hallitus) sähköisesti.</li>
                          {showFullText && (
                          <>
                            <li style={{marginBottom: "20px", color: "rgb(207, 207, 207)"}}>Tilauksen jälkeen yhteyshenkilö saa tilausvahvistuksen lisäksi ohjeet, jossa on kaksi vastauslinkkiä, jotka yrityksenne toimittaa vastaajaryhmille.</li>
                            <li style={{marginBottom: "20px", color: "rgb(207, 207, 207)"}}>Toimitamme myös valmiit viestipohjat ja ohjeet, sekä säännölllisesti tietoa vastaustilanteen etenemisestä ja aktivoinnista.</li>
                            <li style={{marginBottom: "20px", color: "rgb(207, 207, 207)"}}>Vastausaikaa tutkimuksessa on vakiona viisi viikkoa. Tarvittaessa voitte muuttaa vastausaikaa tilausvahvistuksessa tulevasta linkistä.</li>
                            <li style={{marginBottom: "20px", color: "rgb(207, 207, 207)"}}>Vastausajan päättymisen jälkeen saatte kattavan materiaalipaketin, joka sisältää analyysin ja viestintämateriaalit.</li>
                          </>
                          )}
                        </ul>

                    </Box>

                    <Button onClick={() => setShowFullText(!showFullText)}>
                      {showFullText ? "Näytä vähemmän..." : "Lue lisää..."}
                    </Button>
                  </CardContent>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Miten;
