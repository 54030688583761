import React from "react";
import { useFormContext } from "react-hook-form";
import { Box, Checkbox, FormControlLabel, Slider, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled, useTheme } from "@mui/material/styles";

import { useAppState } from '../state';

import { useTranslation } from "react-i18next";

import { vari_2, vari_5, vari_9 } from "../../../../assets/colors/omat_varit";

const punainen = vari_2;
const oranssi = vari_5;
const vihrea = vari_9;

const getColorBasedOnValue = (value: number | null | undefined) => {
  if (value === null || value === undefined) {
    return grey[400];
  } else if (value < 34) {
    return punainen;
  } else if (value >= 34 && value < 67) {
    return oranssi;
  } else {
    return vihrea;
  }
};

const getEriMieltaColor = (value: number) => {
  if (value < 34) {
    return punainen;
  } else if (value >= 34 && value < 67) {
    return oranssi;
  } else {
    return "";
  }
};
const getSamaaMieltaColor = (value: number) => {
  if (value >= 67) {
    return vihrea;
  } else if (value >= 34 && value < 67) {
    return oranssi;
  } else {
    return "";
  }
};

const getEriMieltaFontWeight = (value: number) => {
  if (value < 34 || (value >= 34 && value < 67)) {
    return "bold";
  } else {
    return "normal";
  }
};

const getSamaaMieltaFontWeight = (value: number) => {
  if ((value >= 34 && value < 67) || value >= 67) {
    return "bold";
  } else {
    return "normal";
  }
};

const PrettoSlider = styled(Slider)(({ theme, ...props }) => {
  const value = props.value as number | null | undefined;
  return {
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 30,
      width: 30,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 16,
      fontWeight: 600,
      background: "unset",
      padding: 0,
      width: 42,
      height: 42,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: getColorBasedOnValue(value),
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
        display: value !== undefined ? "block" : "none",
      },
    },
  };
}) as React.FC<{
  value?: number | null | undefined;
  thumblabel?: string | undefined;
  // thumbLabel?: string | undefined;
  onChange?: (event: any, newValue: number | number[]) => void;
  valueLabelDisplay?: "auto" | "on" | "off";
  valueLabelFormat?: (val: number | number[]) => number | "--";
  sx?: any;
}>;

interface CustomizedSliderProps {
  name: string;
  eriMieltaOtsikko: string;
  samaaMieltaOtsikko: string;
  valueLabelDisplay?: "auto" | "on" | "off";
  value: number | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name_eos: string;
  checked: boolean;
  handleCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}
type CustomChangeEvent<T> = Omit<React.ChangeEvent<HTMLInputElement>, "target"> & {
  target: {
    name: string;
    value: T;
  };
};

const CustomizedSlider = ({
  name,
  valueLabelDisplay = "auto",
  eriMieltaOtsikko,
  samaaMieltaOtsikko,
  value,
  handleChange,
  name_eos,
  checked,
  handleCheckboxChange,
}: CustomizedSliderProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { state, setState } = useAppState();
  const { register, setValue, watch, formState: { errors } } = useFormContext();
  const sliderValue = watch(name);
  const eosValue = watch(name_eos);


  const handleChangeLocal = (event: React.ChangeEvent<HTMLInputElement>, newValue?: number | number[]) => {
    if (event.target.type === "checkbox") {
      const customEvent: CustomChangeEvent<string | null> = {
        ...event,
        target: {
          name,
          value: newValue === undefined ? null : newValue.toString(),
        },
      };
      handleChange(customEvent as unknown as React.ChangeEvent<HTMLInputElement>);
      if (event.target.checked) {
        const sliderCustomEvent: CustomChangeEvent<string | null> = {
          ...event,
          target: {
            name,
            value: null,
          },
        };
        handleChange(sliderCustomEvent as unknown as React.ChangeEvent<HTMLInputElement>);
      }
    } else if (typeof newValue === "number") {
      const customEvent: CustomChangeEvent<string | null> = {
        ...event,
        target: {
          name,
          value: newValue === undefined ? null : newValue.toString(),
        },
      };
      handleChange(customEvent as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleCheckboxChangeLocal = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (handleCheckboxChange) {
      handleCheckboxChange(event, checked);
    }
    handleChangeLocal(event, checked ? undefined : value === null ? undefined : value);
  };


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        mt: 0,
        mb: 2,
      }}
    >
      {!checked && (
        <Box
          id="slider"
          sx={{ width: "80%", mt: 8, ml: 2 }}
        >
          {value !== undefined ? (
            <PrettoSlider
              value={value !== undefined && value !== null ? +value : 50}
              onChange={(event, newValue) => handleChangeLocal(event as any, newValue)}
              valueLabelDisplay={valueLabelDisplay}
              valueLabelFormat={(val: number | number[]) => (typeof val === "number" && value !== null ? val : "--")}
              aria-label="arvosanaliukuri"
              thumblabel={value === undefined ? "--" : undefined}
              sx={{
                color: getColorBasedOnValue(value),
                "& .MuiSlider-rail": {
                  backgroundColor: "rgba(100, 101, 98, 0.4)",
                },
                "& .MuiSlider-track": {
                  backgroundColor: getColorBasedOnValue(value),
                },
                "& .MuiSlider-thumb": {
                  borderColor: getColorBasedOnValue(value),
                },
                "& .MuiSlider-valueLabel": {
                  backgroundColor: getColorBasedOnValue(value),
                },
              }}
            />
          ) : (
            <div style={{ height: "8px" }} />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 0,
            }}
          >
            <Box sx={{ width: "35%", mt: -1 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: value ? getEriMieltaColor(value) : theme.palette.primary.contrastText,
                  textAlign: "left",
                  fontWeight: value ? getEriMieltaFontWeight(value) : "normal",
                }}
              >
                {t(eriMieltaOtsikko)}
              </Typography>
            </Box>
            <Box sx={{ width: "35%", mt: -1 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: value ? getSamaaMieltaColor(value) : theme.palette.primary.contrastText,
                  textAlign: "right",
                  fontWeight: value ? getSamaaMieltaFontWeight(value) : "normal",
                }}
              >
                {t(samaaMieltaOtsikko)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        id="eos"
        sx={{ width: checked ? "100%" : "20%", mt: 7, ml: checked ? 0 : 2 }}
      >
        <FormControlLabel
          control={
            <Checkbox
              name={name_eos}
              checked={checked}
              onChange={handleCheckboxChangeLocal}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 32 },
                mt: -1,
                color: "rgba(100, 101, 98, 0.3)",
                "&.Mui-checked": {
                  color: grey[600],
                },
              }}
            />
          }
          label={
            <Typography
              sx={{
                fontWeight: checked ? 900 : 400,
              }}
            >
              {t("eos")}
            </Typography>
          }
          labelPlacement="bottom"
        />
      </Box>
    </Box>
  );
};

export default CustomizedSlider;
