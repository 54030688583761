import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CountUp from "react-countup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useInView } from "react-intersection-observer";

interface CustomComponentProps {
  numero: number;
  suffix: string;
  kuvaus: string;
}

const Fakta: React.FC<CustomComponentProps> = ({ numero, suffix, kuvaus }) => {
  const theme = useTheme();
  const [countUpValues, setCountUpValues] = useState({ start: 0, end: 0 });
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      setCountUpValues({ start: 0, end: numero });
    }
  }, [inView, numero]);

  return (
    <Grid
      item
      xs={12}
      md={4}
    >
      <Box
        component={Card}
        height={1}
        display="flex"
        flexDirection="column"
        boxShadow={0}
      >
        <CardContent sx={{ padding: { sm: 4 } }}>
          <Box
            marginBottom={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              variant="h4"
              color="primary"
              gutterBottom
              ref={inViewRef}
            >
              <Box fontWeight={600}>
                <CountUp
                  duration={2}
                  start={countUpValues.start}
                  end={countUpValues.end}
                  suffix={suffix}
                />
              </Box>
            </Typography>
            <Typography
              component="p"
              color={theme.palette.text.secondary}
            >
              {kuvaus}
            </Typography>
          </Box>
        </CardContent>
      </Box>
    </Grid>
  );
};

export default Fakta;
